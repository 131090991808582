import type { ErrorResult } from "./ErrorResult";
import type { ChatMessageDataResult } from "./ChatMessageDataResult";

export enum GetRequestChatMessagesV1QueryParamsType {
    "ORDER" = "ORDER",
    "SALE_REQUEST" = "SALE_REQUEST",
    "MULTI_REQUEST" = "MULTI_REQUEST",
    "FUNDING_REQUEST" = "FUNDING_REQUEST"
}
export type GetRequestChatMessagesV1QueryParams = {
    /**
     * @description Тип чата
     * @type string
    */
    type: GetRequestChatMessagesV1QueryParamsType;
    /**
     * @description Id сущности
     * @type integer int64
    */
    entityId: number;
};

/**
 * @description Некорректный запрос
*/
export type GetRequestChatMessagesV1400 = ErrorResult;

/**
 * @description Внутренняя ошибка
*/
export type GetRequestChatMessagesV1500 = ErrorResult;

/**
 * @description Сообщения успешно получены
*/
export type GetRequestChatMessagesV1QueryResponse = ChatMessageDataResult;
