
export enum CreateWebBannerDataV1Action {
    "REGISTRATION_FORM" = "REGISTRATION_FORM",
    "URL" = "URL"
}
export type CreateWebBannerDataV1 = {
    /**
     * @type string
    */
    action: CreateWebBannerDataV1Action;
    /**
     * @type string | undefined
    */
    url?: string;
    /**
     * @type string
    */
    desktopImgUrl: string;
    /**
     * @type string
    */
    mobileImgUrl: string;
};
