export enum ELeasingFieldName {
  FULL_NAME = 'fullName',
  PHONE = 'phone',
  EMAIL = 'email',
  ORGANIZATION_NAME = 'organizationName',
  COMMENT = 'comment',
  ORGANIZATION_INN = 'organizationInn',
  CITY = 'city',
  CITY_NAME = 'cityName',
  POLICY_AGREEMENT = 'policyAgreement',
}

export enum ETinkoffFieldName {
  FULL_NAME = 'fullName',
  PHONE = 'phone',
  ORGANIZATION_INN = 'organizationInn',
  ORGANIZATION_NAME = 'organizationName',
  LOAN_AMOUNT = 'loanAmount',
  POLICY_AGREEMENT = 'policyAgreement',
}

export enum EMethodValidateRegion {
  ISO_CODE = 'ISO_CODE',
  CODE = 'CODE',
}
