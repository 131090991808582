import type { NDataAction } from './actions'
import { apiUrlNames } from './constants'
import type { TApiUrlName } from './interfaces'

export const isApiUrlName = (apiUrlName: unknown): apiUrlName is TApiUrlName => apiUrlNames.includes(apiUrlName as TApiUrlName)

export const isFinishedDataLoadingPayload = <GData>(payload: unknown): payload is NDataAction.ILoadingDataFinished<GData> =>
  'data' in ((payload || {}) as NDataAction.ILoadingDataFinished<GData>) &&
  isApiUrlName((payload as NDataAction.ILoadingDataFinished<GData>).name)

export const isFailureDataLoadingPayload = (payload: unknown): payload is NDataAction.ILoadingDataFailed =>
  'meta' in ((payload || {}) as NDataAction.ILoadingDataFailed) && isApiUrlName((payload as NDataAction.ILoadingDataFailed).name)
