import React, { useCallback } from 'react'
import type { FieldPath, FieldValues } from 'react-hook-form'
import { useController } from 'react-hook-form'
import { splitHookFormControllerProps } from '@/utils/fields'
import RadioButtonBase from '../../RadioButtonBase'
import type { IHookFormRadioButtonProps } from './types'

export function HookFormRadioButton<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>(props: IHookFormRadioButtonProps<TFieldValues, TName>) {
  const [controllerProps, { onBlur, onChange, ...componentProps }] = splitHookFormControllerProps(props)

  const { field, fieldState } = useController(controllerProps)

  const isChecked = field.value === props.value

  const onInputBlur: NonNullable<typeof onBlur> = useCallback(
    event => {
      field.onBlur()
      onBlur?.(event)
    },
    [field, onBlur],
  )

  const onInputChange: NonNullable<typeof onChange> = useCallback(
    event => {
      field.onChange(event)
      onChange?.(event)
    },
    [field, onChange],
  )

  return (
    <RadioButtonBase
      error={!!fieldState.error?.message}
      {...field}
      {...componentProps}
      checked={isChecked}
      onBlur={onInputBlur}
      onChange={onInputChange}
    />
  )
}
