import { EFinancingOfferRoute, EInstallmentCalculatorProduct } from '@/types'
import type { TResponsiveValue } from '@/utils/hooks/responsive/interfaces'
import { messages } from './messages'
import type { IInstallmentCalculatorProductDescription } from './types'

export const DEBOUNCE_DELAY = 400

export const RANGES = {
  sum: {
    minValue: 100_000,
    maxValue: 25_000_000,
    step: 10_000,
  },
  days: {
    minValue: 1,
    maxValue: 365,
    step: 1,
  },
}

export const RANGES_FOR_SBER = {
  sum: {
    minValue: 100_000,
    maxValue: 10_000_000,
    step: 10_000,
  },
  days: {
    minValue: 1,
    maxValue: 365,
    step: 1,
  },
}

export const DEFAULT_VALUES = {
  sum: 500_000,
  days: 120,
}

export const MILLION_RUBLES = 1e6

export const PRODUCTS = <Record<EInstallmentCalculatorProduct, IInstallmentCalculatorProductDescription>>{
  [EInstallmentCalculatorProduct.BNPL_SBER_BUSINESS]: {
    name: 'sberBusiness',
    link: EFinancingOfferRoute.sberRassrochka,
    maxSum: 5 * MILLION_RUBLES,
    disableIfMaxSumExceeded: true,
    textDescription: messages.products.sberBusiness,
    logo: 'logos/sberBusiness.svg',
  },
  [EInstallmentCalculatorProduct.BNPL_AGROINTEGRATOR]: {
    name: 'agrointegrator',
    maxSum: 25 * MILLION_RUBLES,
    textDescription: messages.products.agrointegrator,
    logo: 'logos/polerf.svg',
  },
  [EInstallmentCalculatorProduct.BNPL_MONEY_KAPITAL]: {
    name: 'moneykapital',
    maxSum: 30 * MILLION_RUBLES,
    textDescription: messages.products.moneykapital,
    logo: 'logos/moneykapital.svg',
  },
  [EInstallmentCalculatorProduct.BNPL_POLECREDIT]: {
    name: 'poleCredit',
    maxSum: 25 * MILLION_RUBLES,
    textDescription: messages.products.poleCredit,
    logo: 'logos/polerf.svg',
  },
}

export const PRICE_REQUEST_BUTTON_SCROLL_TO_OFFSET: TResponsiveValue<number> = {
  desktop: 200,
  tablet: 180,
  mobile: 50,
}
