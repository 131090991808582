import type { TMessages } from '@/types/TMessages'

export const messages = {
  error: {
    shouldBeInPhoneNumberFormat: 'Некорректный формат номера',
    shouldBeInEmailFormat: 'Некорректный формат E-mail',
    required: 'Необходимо заполнить',
    invalidCurrentMonth: 'Отчет можно сформировать в рамках одного месяца',
    incorrectMinValue: 'Минимальное значение: {minValue}',
    incorrectMaxValue: 'Максимальное значение: {maxValue}',
    numbersAfterDot: 'Не более {numAfterDot} знак{numAfterDot, plural, one {а} other {ов}} после запятой',
    minLength: 'Минимальная длина: {minLength}',
    maxLength: 'Максимальная длина: {maxLength}',
    requiredStoreId: 'Заполните для расчета цены и сортировки',
    incorrectInn: 'Выберите вашу организацию из списка предложенных вариантов',
    emptyDadataResponse: 'Не удалось найти организацию по введенным данным',
  },
} satisfies TMessages
