import type { TApiUrlName } from '@/logic/data/interfaces'
import { ECultureId } from '@/types'
import type { ESaleRequestType } from '@/types/ESaleRequestType'

export const maxTextQualityIndicatorLength = 200

//TODO Временный рубильник(для скрытия функционала до решения о релизе), в дальнейшем планируется вынести рубильник в админку
export const isLogisticFeaturesActive = true

export const culturesWithImportantProtein = [ECultureId.DURUM, ECultureId.WHEAT]

export const SaleRequestApiName: Record<ESaleRequestType, TApiUrlName> = {
  single: 'saleRequest',
  multi: 'saleMultiRequest',
}
