import type { FC } from 'react'
import React from 'react'
import { CommonLink } from '@frontend/pole-ui/lib/components/CommonLink'
import { ErrorMessage, Loader } from '@/components/ui'
import { pushFormattedGtmEvent } from '@/logic/metrika/helpers'
import { getTransliteratedGtmEvents } from '../../gtmHelpers'
import { getArticleUrl } from '../../helpers'
import type { IFaqTreeQuestionsProps } from './interfaces'
import './styles.scss'

const FaqTreeQuestions: FC<IFaqTreeQuestionsProps> = ({ questions, categoryId, specializationId, isSpecialization }) => {
  if (!isSpecialization) return null

  return (
    <div className="row row_column space-holder16 faq-tree-questions">
      {questions?.map(({ question, id }) => (
        <CommonLink
          url={getArticleUrl({ categoryId, specializationId, questionId: id })}
          key={id}
          className="space-holder16"
          onClick={() =>
            pushFormattedGtmEvent(
              getTransliteratedGtmEvents({
                blockName: 'blockQuestionsAndAnswers',
                eventLabel: 'buttonQuestion',
                context: { name: question, prefix: 'txt' },
              }),
            )
          }
        >
          {question}
        </CommonLink>
      ))}
      <ErrorMessage isVisible={questions?.length === 0} textId="faqCategoriesTree.questionsNotFound" />
      <Loader isVisible={questions === undefined} withoutOverlay isStatic className="space-holder-top16 space-holder32" />
    </div>
  )
}

export default FaqTreeQuestions
