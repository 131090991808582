import type { QueryKey, UseQueryResult, UseQueryOptions, QueryOptions } from "@tanstack/react-query";
import { useQuery } from "@tanstack/react-query";
import client from "@/api/client/axios";
import type { GetMainPagePublicationQueryResponse } from "../types/GetMainPagePublication";

export const getMainPagePublicationQueryKey = () =>     [{url:`/api/ms-content-infoportal-backend/v1/publications/mainPage`}] as const;
      export function getMainPagePublicationQueryOptions <TData = GetMainPagePublicationQueryResponse, TError = unknown>(options: Partial<Parameters<typeof client>[0]> = {}): UseQueryOptions<TData, TError> {
        const queryKey = getMainPagePublicationQueryKey();

        return {
          queryKey,
          queryFn: () => {
            
            return client<TData, TError>({
              method: "get",
              url: `/api/ms-content-infoportal-backend/v1/publications/mainPage`,
              
              
              ...options,
            }).then(res => res.data);
          },
        };
      };
      
/**
 * @link /api/ms-content-infoportal-backend/v1/publications/mainPage
 */

export function useGetMainPagePublication <TData = GetMainPagePublicationQueryResponse, TError = unknown>(options: {
          query?: UseQueryOptions<TData, TError>,
          client?: Partial<Parameters<typeof client<TData, TError>>[0]>,
        } = {}): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const { query: queryOptions, client: clientOptions = {} } = options ?? {};
  const queryKey = queryOptions?.queryKey ?? getMainPagePublicationQueryKey();

  const query = useQuery<TData, TError>({
    ...getMainPagePublicationQueryOptions<TData, TError>(clientOptions),
    ...queryOptions
  }) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey as QueryKey;

  return query;
};
