import { useCallback, useEffect, useRef } from 'react'

export const useFallbackImageProps = (placeholderImage: string, hideIfEmpty?: boolean) => {
  const ref = useRef<HTMLImageElement>(null)

  const onError = useCallback(
    event => {
      if (event.target.src !== placeholderImage) {
        event.target.src = placeholderImage
      }
    },
    [placeholderImage],
  )

  useEffect(() => {
    if (!ref.current) return

    const { complete, naturalHeight } = ref.current
    const errorLoadingImgBeforeHydration = complete && naturalHeight === 0

    if (errorLoadingImgBeforeHydration) {
      ref.current.src = placeholderImage
    }
  }, [placeholderImage])

  if (hideIfEmpty) return {}

  return {
    ref,
    onError,
  }
}
