import loadable from '@loadable/component'

export { default as DocumentStatus } from './DocumentStatus'
export { default as DocumentView } from './DocumentView'

export const UploadDocumentModal = loadable(
  /* webpackChunkName: "UploadDocumentModal" */
  /* webpackPrefetch: "true" */
  /* #__LOADABLE__ */
  () => import('./UploadDocumentModal'),
)

export const UploadMultipleDocumentsModal = loadable(
  /* webpackChunkName: "UploadMultipleDocumentsModal" */
  /* webpackPrefetch: "true" */
  /* #__LOADABLE__ */
  () => import('./UploadMultipleDocumentsModal/UploadMultipleDocumentsModal'),
)
