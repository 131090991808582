import type { QueryKey, UseQueryResult, UseQueryOptions, QueryOptions } from "@tanstack/react-query";
import { useQuery } from "@tanstack/react-query";
import client from "@/api/client/axios";
import type { GetTopBannerQueryResponse, GetTopBanner400, GetTopBanner404, GetTopBanner500 } from "../types/GetTopBanner";

export const getTopBannerQueryKey = () =>     [{url:`/api/mainPage/topBanner`}] as const;
      export function getTopBannerQueryOptions <TData = GetTopBannerQueryResponse, TError = GetTopBanner400 | GetTopBanner404 | GetTopBanner500>(options: Partial<Parameters<typeof client>[0]> = {}): UseQueryOptions<TData, TError> {
        const queryKey = getTopBannerQueryKey();

        return {
          queryKey,
          queryFn: () => {
            
            return client<TData, TError>({
              method: "get",
              url: `/api/mainPage/topBanner`,
              
              
              ...options,
            }).then(res => res.data);
          },
        };
      };
      
/**
 * @summary Получение верхнего баннера главной страницы
 * @link /api/mainPage/topBanner
 */

export function useGetTopBanner <TData = GetTopBannerQueryResponse, TError = GetTopBanner400 | GetTopBanner404 | GetTopBanner500>(options: {
          query?: UseQueryOptions<TData, TError>,
          client?: Partial<Parameters<typeof client<TData, TError>>[0]>,
        } = {}): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const { query: queryOptions, client: clientOptions = {} } = options ?? {};
  const queryKey = queryOptions?.queryKey ?? getTopBannerQueryKey();

  const query = useQuery<TData, TError>({
    ...getTopBannerQueryOptions<TData, TError>(clientOptions),
    ...queryOptions
  }) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey as QueryKey;

  return query;
};
