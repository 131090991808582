import React from 'react'
import { useQueryClient } from '@tanstack/react-query'
import { history } from '@/browserHistory'
import { Button } from '..'
import { useAuthenticateUser } from './hooks'
import type { IAuthButtonOwnProps } from './interfaces'

const AuthButton: React.FC<IAuthButtonOwnProps> = props => {
  const { children, buttonProps, onClick, shouldHideForAuthenticatedUser, shouldRedirectToProfileIfAuth } = props
  const { profilePath, isAuthenticated, logoutUser, openAuthModal } = useAuthenticateUser()
  const queryClient = useQueryClient()

  if (shouldHideForAuthenticatedUser && isAuthenticated) return null

  return (
    <Button
      isUnstyled
      {...buttonProps}
      onClick={e => {
        if (shouldRedirectToProfileIfAuth && profilePath && isAuthenticated) history.push(profilePath)
        else if (isAuthenticated) {
          logoutUser()
          queryClient.clear()
        } else openAuthModal()
        onClick?.(isAuthenticated, e)
      }}
    >
      {children}
    </Button>
  )
}

export default AuthButton
