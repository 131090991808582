import { useReducer } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { changePageNumberInQuery } from '@/components/ui/PaginationDeprecated/helpers'
import type { RootState } from '@/redux/interfaces'
import { getPageNumberInQuery } from '../app/reducer'
import { loadData } from './actions'
import type { IUseDataOptions, IUsePaginatedDataResponse, TApiUrlName } from './interfaces'
import { useData } from './useData'

const mapState = (state: RootState) => ({
  pageNumber: getPageNumberInQuery(state),
})

export interface IUsePaginatedDataOptions {
  config: IUseDataOptions
}

export const usePaginatedData = <TRespondedData>(
  name: TApiUrlName,
  options?: IUsePaginatedDataOptions,
): IUsePaginatedDataResponse<TRespondedData> => {
  const { pageNumber } = useSelector(mapState)
  const [paginationKey, setPaginationKey] = useReducer(state => state + 1, 0)
  const dispatch = useDispatch()
  const { config } = options || {}

  return {
    ...useData<TRespondedData>(name, { shouldLoadForcibly: true, ...config }),
    paginationKey,
    goToFirstPage: () => {
      changePageNumberInQuery(1)
      setPaginationKey()
    },
    pageNumber,
    updateData: payload => {
      dispatch(loadData({ name, shouldLoadForcibly: true, ...payload, ...config }))
    },
  }
}
