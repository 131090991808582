import * as Sentry from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing'

enum EEnvironmentType {
  PROD = 'production',
  TEST = 'test',
}

const initSentry = () => {
  if (!process.env.DEP_VERSION) {
    return
  }

  Sentry.init({
    release: process.env.DEP_VERSION,
    dsn: process.env.SENTRY_DSN,
    ignoreErrors: ['Non-Error exception captured', 'Non-Error promise rejection captured'],
    integrations: [new BrowserTracing()],
    tracesSampleRate: 1,
    attachStacktrace: true,
    maxValueLength: 65536,
    normalizeDepth: 16,
    environment: process.env.IS_PROD === 'true' ? EEnvironmentType.PROD : EEnvironmentType.TEST,
    beforeSend: event => ({
      ...event,
      breadcrumbs: event.breadcrumbs?.filter(breadcrumb => {
        const url = breadcrumb.data?.url

        return (
          !url ||
          (!url.startsWith('https://mc.yandex.ru/') &&
            !url.startsWith('https://www.google-analytics.com/') &&
            !url.startsWith('https://www.googletagmanager.com/') &&
            !url.startsWith('/static/icons/') &&
            !url.startsWith('/dist/assets/logo'))
        )
      }),
    }),
  })
}

export default initSentry
