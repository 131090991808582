import React, { memo } from 'react'
import type { IAccreditationDocumentTitleProps } from './interfaces'
import './styles.scss'

const AccreditationDocumentTitle: React.FC<IAccreditationDocumentTitleProps> = props => {
  const { title, description } = props

  return (
    <div className="accreditationDocumentTitle__wrapper">
      <div className="h3">{title}</div>
      {description && <div>{description}</div>}
    </div>
  )
}

export default memo(AccreditationDocumentTitle)
