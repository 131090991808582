import React, { useMemo } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { CommonLink } from '@frontend/pole-ui/lib/components/CommonLink'
import { Button, Chip, InjectHtml } from '@/components/ui'
import { useAgroserviceProducts } from '@/logic/agroservicesProducts/hooks'
import { injectMessages } from '@/logic/translation/utils'
import { scrollTo } from '@/utils/helpers'
import { formatMoney } from '@/utils/price'
import { history } from '../../../browserHistory'
import { ExactFarmingOffer } from './constants'
import type { IExactFarmingOffer, IExactFarmingOtherOffersSectionProps } from './interfaces'
import messages from './messages.json'
import './styles.scss'

injectMessages(messages)

const ExactFarmingOtherOffersSection: React.FC<IExactFarmingOtherOffersSectionProps> = ({ shownOfferIds, blockId }) => {
  const { agroserviceProducts } = useAgroserviceProducts()
  const intl = useIntl()

  const shownOffers = useMemo(
    () =>
      shownOfferIds.map(offerId => ({
        ...ExactFarmingOffer[offerId],
        price: agroserviceProducts[offerId]?.price?.value,
      })),
    //eslint-disable-next-line react-hooks/exhaustive-deps
    [agroserviceProducts],
  )

  const goToAnotherOfferForm = (offer: IExactFarmingOffer) => {
    history.push(`${offer.url}?noscrolltop`)
    setTimeout(() => scrollTo(`#${offer.formId}`), 500)
  }

  return (
    <section className="exact-farming-other-offers">
      <div className="page-wrapper">
        <InjectHtml textId="exactFarmingOtherOffersSection.title" TagName="h2" className="exact-farming-other-offers__title" />
      </div>

      <div className="page-wrapper page-wrapper_no-mobile" id={blockId}>
        <div className="exact-farming-other-offers__row">
          {shownOffers.map(offerItem => {
            if (!offerItem) return null

            return (
              <div key={offerItem.id} className="exact-farming-other-offer-item">
                <div className="exact-farming-other-offer-item__top">
                  <Chip textId="global.exclusiveOnPole" className="space-holder16 exact-farming-other-offer-item__exclusive" />
                  <InjectHtml textId={offerItem.condition} className="exact-farming-other-offer-item__condition text_super-small" />
                  <InjectHtml textId={offerItem.name} className="exact-farming-other-offer-item__name" />
                  <InjectHtml textId={offerItem.description} className="exact-farming-other-offer-item__description" />
                </div>

                <div className="exact-farming-other-offer-item__bottom">
                  <div className="exact-farming-other-offer-item__old-price text_body">
                    {offerItem?.oldPrice && `${formatMoney(offerItem.oldPrice, intl)} ₽`}
                  </div>
                  {offerItem?.price && (
                    <div className="exact-farming-other-offer-item__price-wrapper">
                      <span className="exact-farming-other-offer-item__price h2-from-sm">{formatMoney(offerItem.price, intl)} ₽</span>

                      <span className="exact-farming-other-offer-item__price-per text_body">
                        /
                        <FormattedMessage id="exactFarmingOtherOffersSection.perYear" />
                      </span>
                    </div>
                  )}

                  <div className="exact-farming-other-offer-item__bottom-text text_super-small-from-sm">
                    {offerItem.bottomText && <FormattedMessage id={offerItem.bottomText} />}
                  </div>

                  <div className="exact-farming-other-offer-item__actions-row">
                    <Button
                      onClick={() => {
                        offerItem?.onLeaveRequestPushGtmEvent?.()
                        goToAnotherOfferForm(offerItem)
                      }}
                      modifiers={['green']}
                      textId="global.orderRequest"
                      className="exact-farming-other-offer-item__action-button"
                    />

                    <CommonLink
                      onClick={() => {
                        offerItem?.onMoreButtonClickPushGtmEvent?.()
                      }}
                      url={offerItem.url}
                      className="underline exact-farming-other-offer-item__action-more"
                    >
                      <FormattedMessage id="global.more" />{' '}
                    </CommonLink>
                  </div>
                </div>
              </div>
            )
          })}
        </div>
      </div>
    </section>
  )
}

export default ExactFarmingOtherOffersSection
