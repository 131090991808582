import client from "@/api/client/axios";
import type { ResponseConfig } from "@/api/client/axios";
import type { GetBrandsListQueryResponse } from "../../types/GetBrandsList";

/**
     * @summary Получение списка производителей
     * @link /api/catalog/mainPage/brands
     */
export async function getBrandsList (options: Partial<Parameters<typeof client>[0]> = {}): Promise<ResponseConfig<GetBrandsListQueryResponse>["data"]> {
    const { data: resData } = await client<GetBrandsListQueryResponse>({
        method: "get",
        url: `/api/catalog/mainPage/brands`,
        ...options
    });
    
    return resData;
};