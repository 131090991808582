import Loadable from '@loadable/component'

export * from './types'

export const UserRegionSelectConfirmDesktop = Loadable(
  /* webpackChunkName: "UserRegionSelectConfirmDesktop" */
  /* webpackPrefetch: true */
  /* #__LOADABLE__ */
  () => import('./UserRegionSelectConfirmDesktop'),
)

export const UserRegionSelectConfirmMobile = Loadable(
  /* webpackChunkName: "UserRegionSelectConfirmMobile" */
  /* webpackPrefetch: true */
  /* #__LOADABLE__ */
  () => import('./UserRegionSelectConfirmMobile'),
)
