import client from "@/api/client/axios";
import type { ResponseConfig } from "@/api/client/axios";
import type { GetProductQueryResponse, GetProductPathParams, GetProductQueryParams, GetProductHeaderParams } from "../../types/GetProduct";

/**
     * @summary Получение данных по товару и товарным предложениям
     * @link /api/catalog/products/:id
     */
export async function getProduct (id: GetProductPathParams["id"], params?: GetProductQueryParams, headers?: GetProductHeaderParams, options: Partial<Parameters<typeof client>[0]> = {}): Promise<ResponseConfig<GetProductQueryResponse>["data"]> {
    const { data: resData } = await client<GetProductQueryResponse>({
        method: "get",
        url: `/api/catalog/products/${id}`,
        params,
        headers: { ...headers, ...options.headers },
        ...options
    });
    
    return resData;
};