import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import keyBy from 'lodash/keyBy'
import without from 'lodash/without'
import { agroservicesBreadcrumb, homeBreadcrumb } from '@/components/ui/Breadcrumbs/constants'
import type { IAgroserviceProduct } from '@/types'
import { EExactFarmingProductId } from '@/types'
import { scrollTo } from '@/utils/helpers'
import { hasWebpSupport } from '../app/reducer'
import { useData } from '../data'
import type { TAgroservicesProductPageName } from './interfaces'

export const useAgroserviceProducts = () => {
  const { data: agroserviceProducts = [], isLoading = true, isLoaded, isFailure } = useData<IAgroserviceProduct[]>('agroserviceProducts')

  const products = useMemo(
    () => keyBy(agroserviceProducts, 'id') as Record<EExactFarmingProductId, IAgroserviceProduct>,
    [agroserviceProducts],
  )

  return {
    isLoaded,
    isFailure,
    isLoading,
    agroserviceProducts: products,
  }
}

export const useAgroserviceProductApi = (productId: EExactFarmingProductId, pageId: TAgroservicesProductPageName) => {
  const { agroserviceProducts, ...requestState } = useAgroserviceProducts()

  const breadcrumbs = useMemo(
    () => [homeBreadcrumb, agroservicesBreadcrumb, { captionId: `agroserviceProductPage.title.${pageId}` }],
    //eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  )

  const shownOtherOfferIds = useMemo(
    () => without(Object.values(EExactFarmingProductId), productId) as EExactFarmingProductId[],
    //eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  )

  return {
    ...requestState,
    product: agroserviceProducts[productId],
    imageExtension: useSelector(hasWebpSupport()) ? 'webp' : 'png',
    scrollToForm: () => scrollTo(`#${pageId}Form`),
    breadcrumbs,
    shownOtherOfferIds,
    productId,
  }
}
