import type { QueryKey, UseQueryResult, UseQueryOptions, QueryOptions } from "@tanstack/react-query";
import { useQuery } from "@tanstack/react-query";
import client from "@/api/client/axios";
import type { GetMultiRequestQueryResponse, GetMultiRequestPathParams, GetMultiRequest400, GetMultiRequest404, GetMultiRequest500 } from "../types/GetMultiRequest";

export const getMultiRequestQueryKey = (requestId: GetMultiRequestPathParams["requestId"]) =>     [{url:`/api/multirequests/${requestId}`,params:{requestId:requestId}}] as const;
      export function getMultiRequestQueryOptions <TData = GetMultiRequestQueryResponse, TError = GetMultiRequest400 | GetMultiRequest404 | GetMultiRequest500>(requestId: GetMultiRequestPathParams["requestId"], options: Partial<Parameters<typeof client>[0]> = {}): UseQueryOptions<TData, TError> {
        const queryKey = getMultiRequestQueryKey(requestId, );

        return {
          queryKey,
          queryFn: () => {
            
            return client<TData, TError>({
              method: "get",
              url: `/api/multirequests/${requestId}`,
              
              
              ...options,
            }).then(res => res.data);
          },
        };
      };
      
/**
 * @summary Получение заявки пользователя
 * @link /api/multirequests/:requestId
 */

export function useGetMultiRequest <TData = GetMultiRequestQueryResponse, TError = GetMultiRequest400 | GetMultiRequest404 | GetMultiRequest500>(requestId: GetMultiRequestPathParams["requestId"], options: {
          query?: UseQueryOptions<TData, TError>,
          client?: Partial<Parameters<typeof client<TData, TError>>[0]>,
        } = {}): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const { query: queryOptions, client: clientOptions = {} } = options ?? {};
  const queryKey = queryOptions?.queryKey ?? getMultiRequestQueryKey(requestId, );

  const query = useQuery<TData, TError>({
    ...getMultiRequestQueryOptions<TData, TError>(requestId, clientOptions),
    ...queryOptions
  }) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey as QueryKey;

  return query;
};
