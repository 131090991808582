import type { FC } from 'react'
import React from 'react'
import { Icon } from '@/components/ui'
import type { IReviewsNoteProps } from './types'

export const ReviewsNote: FC<IReviewsNoteProps> = props => {
  const { label, value, icon } = props

  if (!value) return null

  return (
    <div className="reviewsNote">
      <Icon className="reviewsNote__icon" name={icon} />
      <div>
        <div className="reviewsNote__label text_super-small">{label}</div>
        <div className="reviewsNote__value">{value}</div>
      </div>
    </div>
  )
}
