export enum EStatus {
  LOADED = 'loaded',
  REJECTED = 'rejected',
  NOT_LOADED = 'notLoaded',
}

export interface IStatusMarkerProps {
  status: EStatus
  mobileLabelId?: string
}
