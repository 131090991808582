import { createAsyncThunk } from '@reduxjs/toolkit'
import { isSuccess } from '@/api/helpers'
import { getProduct } from '@/api/kubik/monolith'
import type { IThunkProps } from '@/redux/interfaces'
import { ClientIdCookieService } from '@/services'
import { agriculturalProductRequestFailed, agriculturalProductRequestFinished, agriculturalProductRequestStarted } from './actions'
import type { IFetchAgriculturalProductPayload } from './types'

export const fetchAgriculturalProduct = createAsyncThunk<unknown, IFetchAgriculturalProductPayload, IThunkProps>(
  '@@agriculturalProduct/FETCH_PRODUCT',
  async (payload, thunkAPI) => {
    const { dispatch, getState } = thunkAPI
    const clientId = ClientIdCookieService.get()
    const headers: Record<string, string> = { 'uniq-id': clientId }

    const regionCode = getState().userRegion.region.code

    if (!payload.token) {
      console.warn('SSR needs token to fetch agricultural product')
    }

    if (payload.token) {
      headers['Authorization'] = `Bearer ${payload.token}`
    }

    const { productId } = payload.params || {}
    dispatch(agriculturalProductRequestStarted(productId))

    const response = await getProduct(productId, { regionCode }, headers)

    if (!isSuccess(response) || !response.data) {
      dispatch(agriculturalProductRequestFailed(response, productId))

      return
    }

    dispatch(agriculturalProductRequestFinished(response.data))
  },
)
