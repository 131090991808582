import { useLocation } from '@/router/hooks'

export const useConvertToRelativeUrl = () => {
  const { origin } = useLocation()

  const convertToRelativeUrl = (link: string) => {
    try {
      const parsedUrl = new URL(link, origin)

      return parsedUrl.pathname + parsedUrl.search + parsedUrl.hash
    } catch (_) {
      return ''
    }
  }

  return convertToRelativeUrl
}
