import type { UseMutationOptions, UseMutationResult } from "@tanstack/react-query";
import { useMutation } from "@tanstack/react-query";
import client from "@/api/client/auctions";
import type { ResponseConfig } from "@/api/client/auctions";
import type { CreateAuctionBetV2MutationRequest, CreateAuctionBetV2MutationResponse, CreateAuctionBetV2QueryParams } from "../types/CreateAuctionBetV2";

/**
 * @description Метод предназначен для приема ставки аукциона.
Ставку могут делать авторизованные пользователи.
Обязательным параметром organizationId передается идентификатор организации, от имени которой пользователь делает ставку. Его можно брать из токена, но гипотетически возможна ситуация, когда кто-то у кого организация не привязана к токену тоже может совершить действие. Ну и проверка Security на беке в этом случае может быть реализована статически.

 * @link /v2/auction/do_create-bet
 */

export function useCreateAuctionBetV2 <TData = CreateAuctionBetV2MutationResponse, TError = unknown, TVariables = CreateAuctionBetV2MutationRequest>(params: CreateAuctionBetV2QueryParams, options: {
          mutation?: UseMutationOptions<ResponseConfig<TData>, TError, TVariables>,
          client?: Partial<Parameters<typeof client<TData, TError, TVariables>>[0]>,
      } = {}): UseMutationResult<ResponseConfig<TData>, TError, TVariables> {
  const { mutation: mutationOptions, client: clientOptions = {} } = options ?? {};

  return useMutation<ResponseConfig<TData>, TError, TVariables>({
    mutationFn: (data) => {
      
      return client<TData, TError, TVariables>({
        method: "post",
        url: `/v2/auction/do_create-bet`,
        data,
        params,
        
        ...clientOptions
      });
    },
    ...mutationOptions
  });
};
