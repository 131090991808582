import type { FC } from 'react'
import React from 'react'
import { EColor } from '@frontend/pole-ui/lib/common/constants'
import { EIconName, EIconSize, Icon } from '@frontend/pole-ui/lib/components/Icon'
import classNames from 'classnames'
import { isNotRussianCurrency } from '@/utils/price'
import { HintWrapper, InjectHtml } from '../..'
import type { IConditionalCurrencyHintProps } from './interfaces'

const ConditionalCurrencyHint: FC<IConditionalCurrencyHintProps> = ({ children, uom, className, shouldBeHintInModal, shouldHideHint }) => {
  const shouldDisplayHint = !shouldHideHint && isNotRussianCurrency(uom)

  return (
    <HintWrapper
      shouldDisplayHint={shouldDisplayHint}
      header={
        <InjectHtml
          className="text_small-from-sm space-holder8 font-weight_bold"
          TagName="p"
          textId="global.conditionalCurrencyHint.notice.title"
        />
      }
      messageId="global.conditionalCurrencyHint.notice.text"
      messageClass="text_small-from-sm"
      className={classNames(className, 'row row_nowrap row-align_center')}
      shouldBeHintInModal={shouldBeHintInModal}
      messageValues={{ uom }}
    >
      {children}
      {shouldDisplayHint && (
        <Icon name={EIconName.InfoFill} style={{ color: EColor.ORANGE }} size={EIconSize.XS} className="space-holder-left4" />
      )}
    </HintWrapper>
  )
}

export default ConditionalCurrencyHint
