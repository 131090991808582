import client from "@/api/client/dadata";
import type { ResponseConfig } from "@/api/client/dadata";
import type { SuggestAddressMutationRequest, SuggestAddressMutationResponse } from "../../types/SuggestAddress";

/**
     * @link /v1/suggest_address
     */
export async function suggestAddress (data: SuggestAddressMutationRequest, options: Partial<Parameters<typeof client>[0]> = {}): Promise<ResponseConfig<SuggestAddressMutationResponse>["data"]> {
    const { data: resData } = await client<SuggestAddressMutationResponse, SuggestAddressMutationRequest>({
        method: "post",
        url: `/v1/suggest_address`,
        data,
        ...options
    });
    
    return resData;
};