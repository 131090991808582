import type { FC } from 'react'
import React from 'react'
import { CommonLink } from '@frontend/pole-ui/lib/components/CommonLink'
import classNames from 'classnames'
import format from 'date-fns/format'
import { useFormatPublicationDate } from '@/utils/hooks/format/useFormatPublicationDate'
import { getJournalPublicationUrl } from '@/utils/infoportal/helpers'
import type { IArticlePreviewWithoutImageProps } from './interfaces'
import './styles.scss'

const ArticlePreviewWithoutImage: FC<IArticlePreviewWithoutImageProps> = ({ className, article }) => {
  const { subtitle, title, slug, publicationDate, type } = article
  const { formatPublicationDate } = useFormatPublicationDate(type)

  return (
    <article className={classNames(className, 'article-preview-without-image text_center')}>
      <CommonLink url={getJournalPublicationUrl(slug)} className="article-preview-without-image-link row-justify_between">
        <div>
          <h4 className="article-preview-without-image__title space-holder24-from-md space-holder16-md">{title}</h4>
          <p> {subtitle}</p>
        </div>

        {publicationDate && (
          <div>
            <p className="text_super-small color_pale-black">
              <time itemProp="publishedDate" dateTime={format(new Date(publicationDate), 'yyyy-MM-dd')}>
                {formatPublicationDate(publicationDate)}
              </time>
            </p>
          </div>
        )}
      </CommonLink>
    </article>
  )
}

export default ArticlePreviewWithoutImage
