import type { TMessages } from '@/types/TMessages'

export const title = 'Мои сделки'

export const sections = {
  buy: {
    title: 'Закупка сельхозтоваров',
    orders: 'Заказы на сельхозтовары',
    tenders: 'Запросы предложений',
  },
  sell: {
    title: 'Продажа урожая',
    orders: 'Заявки на урожай',
  },
  another: {
    title: 'Другие заявки',
    funding: 'Кредиты и лизинг',
    services: 'Агросервисы',
  },
} satisfies TMessages
