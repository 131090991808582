import client from "@/api/client/axios";
import type { ResponseConfig } from "@/api/client/axios";
import type { GetQualityIndicatorsQueryResponse, GetQualityIndicatorsPathParams, GetQualityIndicatorsQueryParams } from "../../types/GetQualityIndicators";

/**
     * @summary Получение списка значений индикаторов качества для культуры
     * @link /api/requests/data/quality-indicators/culture/:culture
     */
export async function getQualityIndicators (culture: GetQualityIndicatorsPathParams["culture"], params?: GetQualityIndicatorsQueryParams, options: Partial<Parameters<typeof client>[0]> = {}): Promise<ResponseConfig<GetQualityIndicatorsQueryResponse>["data"]> {
    const { data: resData } = await client<GetQualityIndicatorsQueryResponse>({
        method: "get",
        url: `/api/requests/data/quality-indicators/culture/${culture}`,
        params,
        ...options
    });
    
    return resData;
};