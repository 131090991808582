import type { CookieOptions } from 'express'
import type Cookies from 'js-cookie'
import { ONE_DAY_MS } from '@/constants/ages'
import { decodeJwt } from '@/utils/helpers'
import type { TGetCookieExpiration } from './types'

export const getCookieExpiration: TGetCookieExpiration = jwt => {
  const { exp } = decodeJwt(jwt)

  const expiresInMS = exp ? exp * 1000 - Date.now() : 0
  const maxAgeInMS = expiresInMS > 0 ? expiresInMS : 0

  const expressOptions: CookieOptions = { maxAge: maxAgeInMS }

  const browserOptions: Cookies.CookieAttributes = {}

  if (expiresInMS > 0) {
    browserOptions.expires = expiresInMS / ONE_DAY_MS
  }

  return { expressOptions, browserOptions }
}
