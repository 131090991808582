import type { FC, PropsWithChildren } from 'react'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import { BubblingTooltip as PoleBubblingTooltip } from '@frontend/pole-ui/lib/components/BubblingTooltip'
import { InjectHtml } from '@/components/ui'
import type { IBubblingTooltipProps } from './interfaces'

const BubblingTooltip: FC<IBubblingTooltipProps> = props => {
  const { content, messageId, ...otherProps } = props

  let contentResult = content

  if (typeof content === 'string' && !messageId) {
    contentResult = <InjectHtml html={String(content)} />
  }

  if (messageId) contentResult = <FormattedMessage id={messageId} />

  return <PoleBubblingTooltip {...otherProps} content={contentResult ?? ''} />
}

export default React.memo<PropsWithChildren<IBubblingTooltipProps>>(BubblingTooltip)
