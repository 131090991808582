import React, { memo } from 'react'
import { useIntl } from 'react-intl'
import classNames from 'classnames'
import { EUserRegionSelectVariation, UserRegionSelect } from '@/components/ui/UserRegionSelect'
import { injectMessages } from '@/logic/translation/utils'
import messages from './messages.json'
import './styles.scss'

injectMessages(messages)

const RegionChooserTop: React.FC = () => {
  const { formatMessage } = useIntl()

  return (
    <UserRegionSelect
      className={classNames('regionChooserTop')}
      picker={{
        desktop: EUserRegionSelectVariation.DESKTOP,
        tablet: EUserRegionSelectVariation.DESKTOP,
        mobile: EUserRegionSelectVariation.MOBILE,
      }}
      confirm={{
        desktop: EUserRegionSelectVariation.DESKTOP,
        tablet: false,
        mobile: false,
      }}
      selector={{
        desktop: EUserRegionSelectVariation.DESKTOP,
        tablet: EUserRegionSelectVariation.MOBILE,
        mobile: EUserRegionSelectVariation.MOBILE,
      }}
      label={formatMessage({ id: 'header.regionChooserTop.label' })}
    />
  )
}

export default memo(RegionChooserTop)
