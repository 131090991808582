import React from 'react'
import { Route, Switch } from 'react-router'
import type { IRoute } from '../../../interfaces'

const FaqRoutes: React.FC<{ routes: IRoute[] }> = ({ routes }) => (
  <Switch>
    {routes.map(({ component: Component, ...route }) => (
      <Route {...route} render={() => <Component />} />
    ))}
  </Switch>
)

export default FaqRoutes
