import client from "@/api/client/auctions";
import type { ResponseConfig } from "@/api/client/auctions";
import type { GetAuctionsForVitrinaQueryResponse, GetAuctionsForVitrinaQueryParams } from "../../types/GetAuctionsForVitrina";

/**
     * @description Метод предназначен для получения списка аукционов с возможностью фильтрации.
     * @link /v2/auctions/view_for-vitrina
     */
export async function getAuctionsForVitrina (params?: GetAuctionsForVitrinaQueryParams, options: Partial<Parameters<typeof client>[0]> = {}): Promise<ResponseConfig<GetAuctionsForVitrinaQueryResponse>["data"]> {
    const { data: resData } = await client<GetAuctionsForVitrinaQueryResponse>({
        method: "get",
        url: `/v2/auctions/view_for-vitrina`,
        params,
        ...options
    });
    
    return resData;
};