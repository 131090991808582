import some from 'lodash/some'
import { ApplyingOfferFormSteps } from '@/constants/financing'
import { getJwt } from '@/logic/auth/reducer'
import type { TGetApplyingFinancingOfferInitialValues } from '@/logic/financing/interfaces'
import { reducerManager } from '@/redux/reducerManager'
import { AuthCookiesService } from '@/services'
import type { IPrice } from '@/types'
import { getTokenInfo } from '@/utils/helpers'

export const getApplyingFinancingOfferFormInitialValues: TGetApplyingFinancingOfferInitialValues = options => {
  const { offerType, productId, profile, formValues, bankTitle, isLeasing, iconUrl } = options

  const {
    sub: phone,
    organizationInn,
    organizationName,
    // TODO https://jira.poleinvest.ru/browse/OUT-1908
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  } = getTokenInfo(getJwt()(reducerManager.store?.getState()!) || AuthCookiesService.getJwt())?.payload || {}

  const steps = ApplyingOfferFormSteps[offerType]
  const firstStepFields = steps?.[0]?.fields

  return {
    ...formValues,
    loanAmount: formValues?.loanAmount || ({ uom: 'RUB' } as IPrice),
    ...(productId && { productId }),
    phone,
    email: formValues?.email || profile.email,
    ...(some(firstStepFields, { name: 'organizationInn' }) && { organizationInn }),
    ...(some(firstStepFields, { name: 'organizationName' }) && { organizationName }),
    ...(isLeasing && {
      lessor: productId,
      isLeasing,
      bankTitle,
      iconUrl,
    }),
    agreement: true,
  }
}
