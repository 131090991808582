import { HeadingVariant } from './constants'

export const messages = {
  loanAmount: 'Сумма',
  purpose: 'Цель',
  phone: 'Номер телефона',
  submit: 'Получить консультацию',
  footer: {
    text: 'Ввод номера телефона подтверждает ваше согласие с {link}',
    link: 'политикой обработки персональных данных',
  },
  error: {
    submit: 'Не удалось отправить заявку, проверьте номер телефона или наличие доступа в интернет',
    incorrectPrice: 'Некорректная сумма',
    required: 'Необходимо заполнить',
  },
  headings: {
    [HeadingVariant.FINANCING_CONSULTING]: {
      title: 'Предварительная консультация',
      description: 'Отправьте заявку на консультацию, и с вами свяжется менеджер в течение часа в рабочий день',
    },
    [HeadingVariant.FORM_ON_MAIN_FINANCING_PAGE]: {
      title: 'Предварительная консультация',
      description:
        'Отправьте заявку прямо сейчас, мы свяжемся с Вами в рабочее время, ответим на все вопросы и поможем получить финансирование',
    },
  },
}
