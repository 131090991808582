import type { QueryKey, UseQueryResult, UseQueryOptions, QueryOptions } from "@tanstack/react-query";
import { useQuery } from "@tanstack/react-query";
import client from "@/api/client/axios";
import type { GetEntityStatusByIdQueryResponse, GetEntityStatusByIdPathParams, GetEntityStatusById400, GetEntityStatusById404, GetEntityStatusById500 } from "../types/GetEntityStatusById";

export const getEntityStatusByIdQueryKey = (entity: GetEntityStatusByIdPathParams["entity"], entityId: GetEntityStatusByIdPathParams["entityId"]) =>     [{url:`/api/entitystatus/${entity}/${entityId}/status`,params:{entity:entity,entityId:entityId}}] as const;
      export function getEntityStatusByIdQueryOptions <TData = GetEntityStatusByIdQueryResponse, TError = GetEntityStatusById400 | GetEntityStatusById404 | GetEntityStatusById500>(entity: GetEntityStatusByIdPathParams["entity"], entityId: GetEntityStatusByIdPathParams["entityId"], options: Partial<Parameters<typeof client>[0]> = {}): UseQueryOptions<TData, TError> {
        const queryKey = getEntityStatusByIdQueryKey(entity, entityId, );

        return {
          queryKey,
          queryFn: () => {
            
            return client<TData, TError>({
              method: "get",
              url: `/api/entitystatus/${entity}/${entityId}/status`,
              
              
              ...options,
            }).then(res => res.data);
          },
        };
      };
      
/**
 * @summary Получение статуса сущности
 * @link /api/entitystatus/:entity/:entityId/status
 */

export function useGetEntityStatusById <TData = GetEntityStatusByIdQueryResponse, TError = GetEntityStatusById400 | GetEntityStatusById404 | GetEntityStatusById500>(entity: GetEntityStatusByIdPathParams["entity"], entityId: GetEntityStatusByIdPathParams["entityId"], options: {
          query?: UseQueryOptions<TData, TError>,
          client?: Partial<Parameters<typeof client<TData, TError>>[0]>,
        } = {}): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const { query: queryOptions, client: clientOptions = {} } = options ?? {};
  const queryKey = queryOptions?.queryKey ?? getEntityStatusByIdQueryKey(entity, entityId, );

  const query = useQuery<TData, TError>({
    ...getEntityStatusByIdQueryOptions<TData, TError>(entity, entityId, clientOptions),
    ...queryOptions
  }) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey as QueryKey;

  return query;
};
