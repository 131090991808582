import type { YMapsProps } from 'react-yandex-maps'
import { getStaticPath } from '@/utils/getStaticPath'

export const staticGeocoderQueryParameters = { apikey: process.env.YANDEX_API_KEY, format: 'json', lang: 'ru_RU' }

export const yandexMapStaticProps: YMapsProps = {
  query: {
    apikey: process.env.YANDEX_API_KEY,
    lang: 'ru_RU',
  },
}

export const placemarkOptions = { iconLayout: 'default#image', iconImageHref: getStaticPath('icons/flat/filled/placemark.svg') }

export enum EChoosingLocationRequestName {
  SAVE = 'saveUserAddress',
}

export const permittedStationType = ['station', 'platform', 'stop', 'train_station']

export const permittedKindType = ['house', 'street', 'metro', 'district', 'locality', 'other']
