import React, { useContext, useMemo } from 'react'
import { AccreditationContext } from '@/logic/accreditation/constants'
import { AccreditationBanner } from '.'

const SignAccreditationBanner: React.FC = () => {
  const {
    accreditationActions,
    openSigningAccreditationModal,
    openChoosingSigningTypeModal,
    openCryptoSigningAccreditationModal,
    isLogistics,
    bannerText,
  } = useContext(AccreditationContext)
  const { sign, signCrypto } = accreditationActions

  const buttonParams = useMemo(() => {
    if (sign && signCrypto) {
      return { onClick: openChoosingSigningTypeModal, text: sign.name }
    } else if (sign && !signCrypto) {
      return { onClick: openSigningAccreditationModal, text: sign.name }
    } else if (!sign && signCrypto) {
      return { onClick: openCryptoSigningAccreditationModal, text: signCrypto.name }
    }

    return null
  }, [signCrypto, openChoosingSigningTypeModal, openCryptoSigningAccreditationModal, openSigningAccreditationModal, sign])

  if (!buttonParams) return null

  return (
    <AccreditationBanner
      buttonText={buttonParams?.text}
      className="userSignAccreditationBanner"
      iconUrl="black/deal"
      title={isLogistics ? bannerText : undefined}
      titleId={isLogistics ? undefined : 'userAccreditation.signAccreditationBanner.title'}
      textId={isLogistics ? undefined : 'userAccreditation.signAccreditationBanner.text'}
      onButtonClick={buttonParams?.onClick}
    />
  )
}

export default SignAccreditationBanner
