import { EDocumentCategory, EDocumentTypeId } from '@/types/Documents'

export const categoryGroups = [
  [EDocumentCategory.COMPANY_VALIDATION_OUTPUTS, EDocumentCategory.PRIVATE_VALIDATION_OUTPUTS],
  [EDocumentCategory.COMPANY_VALIDATION_INPUTS, EDocumentCategory.PRIVATE_VALIDATION_INPUTS],
]

export const CommonDocumentType: Partial<Record<EDocumentTypeId, true>> = {
  [EDocumentTypeId.COMMON]: true,
  [EDocumentTypeId.ORDER_VALIDATION_COMMON]: true,
}

export const actionPermissions = {
  canAddDoc: true,
  canDeleteDoc: true,
  canRejectDoc: true,
  canUpdateDoc: true,
}
