import type { OrganizationProfileDataV2 } from '@/api/kubik/monolith'

export const initialOrganizationData: Readonly<OrganizationProfileDataV2> = {
  organization: {
    inn: '',
    name: '',
    ogrn: '',
    kpp: '',
    okved: '',
    okpo: '',
    director: '',
    creationDate: '',
    updateDate: '',
    id: '',
    fcaCalculateSetting: '',
  },
  additionalInformation: {
    employees: 0,
    activity: '',
    area: 0,
    address: '',
    phone: '',
    email: '',
  },
  organizationBankAccounts: [
    {
      id: '',
      accountName: '',
      account: '',
      correspondentAccount: '',
      bankName: '',
      bik: '',
      defaultAccount: false,
    },
  ],
}

export const getInitialOrganizationData = () => JSON.parse(JSON.stringify(initialOrganizationData))

export const apiUrlNames = [
  'saleRequestDocuments',
  'saleRequest',
  'saleMultiRequest',
  'stores',
  'deliveryBasisVariants',
  'elevators',
  'legalDocuments',
  'myChats',
  'regions',
  'sberRegions',
  'accreditation',
  'accreditationDocuments',
  'accreditationHistory',
  'harvest',
  'faqCategoryQuestions',
  'faqCategories',
  'faqCategory',
  'faqArticle',
  'faqSpecialization',
  'faqQuestions',
  'faqAuthor',
  'agroserviceProducts',
  'indexAgriculturalProducts',
  'publicationsCount',
  'userOrganization',
  'addOrganization',
  'expressCreditWithRedirection',
  'cultureIndicators',
  'harvestSaleQualityIndicators',
  'harvestSaleCultureClasses',
  'harvestSalePage',
  'partnershipProfiles',
  'addOrganization',
  'cultures',
  'multiCart',
  'cart',
  'statusModel',
  'logistics',
  'vehicleTypes',
  'agrarianLoadingMethods',
  'agrarianWorkingDays',
  'transactionReports',
  'agrarianWarehouses',
  'paymentVariants',
  'agriculturalProductCategoriesFilters',
] as const
