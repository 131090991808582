export enum EAgrarianOrderStatus {
  NEW = 'NEW',
  AGRARIAN_PROPOSAL = 'AGRARIAN_PROPOSAL',
  SUPPLIER_PROPOSAL = 'SUPPLIER_PROPOSAL',
  DOCUMENTS_VERIFICATION = 'DOCUMENTS_VERIFICATION',
  DELIVERY = 'DELIVERY',
  DISPATCH = 'DISPATCH',
  CANCELED = 'CANCELED',
  CLOSED = 'CLOSED',
  PAYMENT_WAITING = 'PAYMENT_WAITING',
  LOADING_CLOSING_DOCUMENTS = 'LOADING_CLOSING_DOCUMENTS',
  ACCREDITATION = 'ACCREDITATION',
  AGRARIAN_OFFER_WAITING = 'AGRARIAN_OFFER_WAITING',
  AGRARIAN_ASSIGNMENT_WAITING = 'AGRARIAN_ASSIGNMENT_WAITING',
  PREPAYMENT_WAITING = 'PREPAYMENT_WAITING',
}
