import React from 'react'
import DocumentView from '@/components/composed/Documents/components/DocumentView/DocumentView'
import { TypedDocumentCard } from '@/components/resolvers'
import { DocumentStatus } from '../../components'
import { actionPermissions, categoryGroups, CommonDocumentType } from '../../constants'
import type { DocumentGroupProps } from './interfaces'
import './styles.scss'

const DocumentGroup: React.FC<DocumentGroupProps> = ({
  title,
  visibleTypes,
  documentsByType,
  typesById,
  onUpdateDocument,
  downloadDoc,
  uploadDoc,
  downloadDocTemplate,
  shouldDisplayDocumentStatusElement,
  shouldHideUpdatingDocumentButton,
  documentPermissions,
  shouldCheckPermissionsFromActionsByCategories,
  openRejectingDocumentModal,
  actions,
}) => {
  return (
    <section className="documents-group">
      {title && <h2 className="documents-group__title space-holder-top8 h4">{title}</h2>}

      {visibleTypes.map(typedDocument => {
        const { id, categories } = typedDocument
        const shownDocuments = documentsByType[id]

        const { canUpdateDoc, canDeleteDoc, canRejectDoc, canAddDoc } = shouldCheckPermissionsFromActionsByCategories
          ? documentPermissions(id, categories)
          : actionPermissions

        if (shownDocuments && shownDocuments.length > 0) {
          return shownDocuments.map(document => (
            <DocumentView
              document={document}
              downloadDoc={downloadDoc}
              onUpdateDocument={onUpdateDocument}
              key={document.creationDate}
              canUpdateDocument={canUpdateDoc}
              canDeleteDocument={canDeleteDoc}
              shouldDisplayDocumentStatusElement={shouldDisplayDocumentStatusElement}
              shouldHideUpdatingDocumentButton={shouldHideUpdatingDocumentButton}
              typesById={typesById}
              rejectingDocumentButtonName={canRejectDoc ? actions?.rejectDocument?.name : undefined}
              onRejectDocument={openRejectingDocumentModal ? openRejectingDocumentModal(document.id) : undefined}
            />
          ))
        }

        if (CommonDocumentType[id]) return null

        const DocumentStatusElement = shouldDisplayDocumentStatusElement ? (
          <DocumentStatus categoryGroups={categoryGroups} typedCategories={typedDocument.categories} />
        ) : undefined

        return (
          <TypedDocumentCard
            onUploadDocument={file => file && uploadDoc(file, typedDocument)}
            key={id}
            onDownloadDocument={() => downloadDocTemplate(typedDocument)}
            typedDocument={typedDocument}
            canAddDocument={canAddDoc}
            uploadDocumentRequestName={`uploadDocument_${id}`}
            DocumentStatusElement={DocumentStatusElement}
            shouldUploadTemplateAsAction
            shouldHideUpdatingDocumentButton={shouldHideUpdatingDocumentButton}
          />
        )
      })}
    </section>
  )
}

export default DocumentGroup
