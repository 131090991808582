export enum EOrderPaymentVariantType {
  PREPAYMENT = 'PREPAYMENT',
  DEFERMENT = 'DEFERMENT',
  POSTPAID = 'POSTPAID',
  INSTALLMENT = 'INSTALLMENT',
  MICROCREDIT = 'MICROCREDIT',
  MONEY_KAPITAL = 'MONEY_KAPITAL',
  MANDARIN = 'MANDARIN',
  POLE_CREDIT = 'POLECREDIT',
}
