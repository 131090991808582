import type { UseMutationOptions, UseMutationResult } from "@tanstack/react-query";
import { useMutation } from "@tanstack/react-query";
import client from "@/api/client/axios";
import type { ResponseConfig } from "@/api/client/axios";
import type { CancelRequestByMultiRequestIdMutationResponse, CancelRequestByMultiRequestIdPathParams, CancelRequestByMultiRequestIdQueryParams, CancelRequestByMultiRequestId400, CancelRequestByMultiRequestId404, CancelRequestByMultiRequestId500 } from "../types/CancelRequestByMultiRequestId";

/**
 * @summary Отмена мультизаявки пользователя
 * @link /api/multirequests/fullclosed/:multiRequestId
 */

export function useCancelRequestByMultiRequestId <TData = CancelRequestByMultiRequestIdMutationResponse, TError = CancelRequestByMultiRequestId400 | CancelRequestByMultiRequestId404 | CancelRequestByMultiRequestId500>(multiRequestId: CancelRequestByMultiRequestIdPathParams["multiRequestId"], params: CancelRequestByMultiRequestIdQueryParams, options: {
          mutation?: UseMutationOptions<ResponseConfig<TData>, TError, void>,
          client?: Partial<Parameters<typeof client<TData, TError, void>>[0]>,
      } = {}): UseMutationResult<ResponseConfig<TData>, TError, void> {
  const { mutation: mutationOptions, client: clientOptions = {} } = options ?? {};

  return useMutation<ResponseConfig<TData>, TError, void>({
    mutationFn: () => {
      
      return client<TData, TError, void>({
        method: "delete",
        url: `/api/multirequests/fullclosed/${multiRequestId}`,
        
        params,
        
        ...clientOptions
      });
    },
    ...mutationOptions
  });
};
