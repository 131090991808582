import { ExecutionEnvironment } from '@/utils/ExecutionEnvironment'
import type { RootState, TReduxStateName } from './interfaces'
import { preloadedState } from './preloadedState'

export const getStore = async () => {
  let store
  if (!ExecutionEnvironment.isSsr) store = await import('./clientStore')

  return store
}

export const getInitialState = <TName extends TReduxStateName>(reducerName: TName, initialState: RootState[TName]): RootState[TName] => {
  if (ExecutionEnvironment.isSsr) return initialState

  return preloadedState[reducerName] ?? initialState
}
