import { useState } from 'react'
import { ExecutionEnvironment } from '@/utils/ExecutionEnvironment'

const isStringArray = (value: unknown): value is string[] => {
  return Array.isArray(value) && value.every(item => typeof item === 'string')
}

const getLocalStorageValue = (key: string, initialValue: string[]): string[] => {
  if (ExecutionEnvironment.isSsr || !window.localStorage) {
    return initialValue
  }

  try {
    const item = localStorage.getItem(key)

    if (!item) return initialValue

    const parsedItem = JSON.parse(item)

    return isStringArray(parsedItem) ? parsedItem : initialValue
  } catch {
    return initialValue
  }
}

export const useLocalStorage = (key: string, initialValue: string[]) => {
  const [storedValue, setStoredValue] = useState<string[]>(() => getLocalStorageValue(key, initialValue))

  const setValue = (value: string[] | ((val: string[]) => string[])) => {
    if (ExecutionEnvironment.isSsr || !window.localStorage) {
      return
    }

    try {
      const valueToStore = value instanceof Function ? value(storedValue) : value

      localStorage.setItem(key, JSON.stringify(valueToStore))
      setStoredValue(valueToStore)
    } catch {
      // on error do nothing
    }
  }

  return [storedValue, setValue] as const
}
