import type { FC } from 'react'
import React from 'react'
import classNames from 'classnames'
import { AuthButton, InjectHtml } from '@/components/ui'
import { injectMessages } from '@/logic/translation/utils'
import type { ILoginToWatchProps } from './interfaces'
import messages from './messages.json'

injectMessages(messages)

const LoginToWatchPrice: FC<ILoginToWatchProps> = ({ className, shouldUseAuthorization = true, authLinkClass = 'color_green' }) => {
  return (
    <div className={classNames('text_small-from-sm', className)}>
      {shouldUseAuthorization ? (
        <AuthButton
          buttonProps={{
            textId: 'loginToWatchPrice.login',
            className: `underline auth-button-instead__auth-button ${authLinkClass}`,
          }}
          onClick={(_, e) => e.preventDefault()}
        />
      ) : (
        <InjectHtml TagName="span" textId="loginToWatchPrice.login" />
      )}

      <InjectHtml className="color_outline" TagName="span" textId="loginToWatchPrice.watchPrice" />
    </div>
  )
}

export default LoginToWatchPrice
