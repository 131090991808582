import type { QueryKey, UseQueryResult, UseQueryOptions, QueryOptions } from "@tanstack/react-query";
import { useQuery } from "@tanstack/react-query";
import client from "@/api/client/axios";
import type { GetSaleRequestShippingQueryResponse, GetSaleRequestShippingPathParams, GetSaleRequestShipping404, GetSaleRequestShipping500 } from "../types/GetSaleRequestShipping";

export const getSaleRequestShippingQueryKey = (requestId: GetSaleRequestShippingPathParams["requestId"], shippingId: GetSaleRequestShippingPathParams["shippingId"]) =>     [{url:`/api/salerequests/${requestId}/shippings/${shippingId}`,params:{requestId:requestId,shippingId:shippingId}}] as const;
      export function getSaleRequestShippingQueryOptions <TData = GetSaleRequestShippingQueryResponse, TError = GetSaleRequestShipping404 | GetSaleRequestShipping500>(requestId: GetSaleRequestShippingPathParams["requestId"], shippingId: GetSaleRequestShippingPathParams["shippingId"], options: Partial<Parameters<typeof client>[0]> = {}): UseQueryOptions<TData, TError> {
        const queryKey = getSaleRequestShippingQueryKey(requestId, shippingId, );

        return {
          queryKey,
          queryFn: () => {
            
            return client<TData, TError>({
              method: "get",
              url: `/api/salerequests/${requestId}/shippings/${shippingId}`,
              
              
              ...options,
            }).then(res => res.data);
          },
        };
      };
      
/**
 * @summary Запрос на получение информации об отгрузке
 * @link /api/salerequests/:requestId/shippings/:shippingId
 */

export function useGetSaleRequestShipping <TData = GetSaleRequestShippingQueryResponse, TError = GetSaleRequestShipping404 | GetSaleRequestShipping500>(requestId: GetSaleRequestShippingPathParams["requestId"], shippingId: GetSaleRequestShippingPathParams["shippingId"], options: {
          query?: UseQueryOptions<TData, TError>,
          client?: Partial<Parameters<typeof client<TData, TError>>[0]>,
        } = {}): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const { query: queryOptions, client: clientOptions = {} } = options ?? {};
  const queryKey = queryOptions?.queryKey ?? getSaleRequestShippingQueryKey(requestId, shippingId, );

  const query = useQuery<TData, TError>({
    ...getSaleRequestShippingQueryOptions<TData, TError>(requestId, shippingId, clientOptions),
    ...queryOptions
  }) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey as QueryKey;

  return query;
};
