import client from "@/api/client/axios";
import type { ResponseConfig } from "@/api/client/axios";
import type { GetTopBannerQueryResponse } from "../../types/GetTopBanner";

/**
     * @summary Получение верхнего баннера главной страницы
     * @link /api/mainPage/topBanner
     */
export async function getTopBanner (options: Partial<Parameters<typeof client>[0]> = {}): Promise<ResponseConfig<GetTopBannerQueryResponse>["data"]> {
    const { data: resData } = await client<GetTopBannerQueryResponse>({
        method: "get",
        url: `/api/mainPage/topBanner`,
        ...options
    });
    
    return resData;
};