export enum EAuthGtmFieldName {
  phone = 'phone',
  fullName = 'fullName',
  organizationProfile = 'organizationProfile',
  email = 'email',
  isAgreedWithPolicy = 'isAgreedWithPolicy',
  isAgreedWithRules = 'isAgreedWithRules',
  receiveAdvertising = 'receiveAdvertising',
  code = 'code',
}

const baseGtmEventPayload = {
  event: 'userEvent',
  pagePart: 'popUp',
  eventCategory: 'interactions',
  ecommerce: null,
  eventContext: null,
  eventStatus: 'success',
  eventNonInteraction: '0',
  firingOptions: 'oncePerEvent',
} as const

export const gtmChangeEventPayloads: Record<
  EAuthGtmFieldName,
  {
    blockName: string
    eventAction: string
    eventLabel: string
  } & typeof baseGtmEventPayload
> = {
  [EAuthGtmFieldName.fullName]: {
    ...baseGtmEventPayload,
    blockName: 'formRegistrationStep2',
    eventAction: 'input',
    eventLabel: 'inputFullName',
  },
  [EAuthGtmFieldName.organizationProfile]: {
    ...baseGtmEventPayload,
    blockName: 'formRegistrationStep2',
    eventAction: 'input',
    eventLabel: 'inputOrganizationName',
  },
  [EAuthGtmFieldName.email]: {
    ...baseGtmEventPayload,
    blockName: 'formRegistrationStep2',
    eventAction: 'input',
    eventLabel: 'inputEmail',
  },
  [EAuthGtmFieldName.isAgreedWithPolicy]: {
    ...baseGtmEventPayload,
    blockName: 'formRegistrationStep2',
    eventAction: 'click',
    eventLabel: 'checkboxPersonalData',
  },
  [EAuthGtmFieldName.isAgreedWithRules]: {
    ...baseGtmEventPayload,
    blockName: 'formRegistrationStep2',
    eventAction: 'click',
    eventLabel: 'checkboxtMarketplaceUse',
  },
  [EAuthGtmFieldName.receiveAdvertising]: {
    ...baseGtmEventPayload,
    blockName: 'formRegistrationStep2',
    eventAction: 'click',
    eventLabel: 'checkboxAdvertising',
  },
  [EAuthGtmFieldName.code]: {
    ...baseGtmEventPayload,
    blockName: 'formRegistrationStep2',
    eventAction: 'input',
    eventLabel: 'inputCode',
  },
  [EAuthGtmFieldName.phone]: {
    ...baseGtmEventPayload,
    blockName: 'formRegistrationStep2',
    eventAction: 'input',
    eventLabel: 'inputPhoneNumber',
  },
} as const

export const gtmFocusEventPayloads = {
  [EAuthGtmFieldName.fullName]: {
    ...baseGtmEventPayload,
    blockName: 'formRegistrationStep2',
    eventAction: 'click',
    eventLabel: 'focusFulName',
  },
  [EAuthGtmFieldName.organizationProfile]: {
    ...baseGtmEventPayload,
    blockName: 'formRegistrationStep2',
    eventAction: 'click',
    eventLabel: 'focusOrganizationName',
  },
  [EAuthGtmFieldName.email]: {
    ...baseGtmEventPayload,
    blockName: 'formRegistrationStep2',
    eventAction: 'click',
    eventLabel: 'focusEmail',
  },
  [EAuthGtmFieldName.code]: {
    ...baseGtmEventPayload,
    blockName: 'formRegistrationStep3',
    eventAction: 'click',
    eventLabel: 'focusConfirmNumber',
  },
}
