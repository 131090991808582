import type { FC } from 'react'
import React, { memo } from 'react'
import { CommonLink } from '@frontend/pole-ui/lib/components/CommonLink'
import { Image } from '@/components/ui'
import { EMobileAppLinks } from '@/types/Index/EMobileAppLinks'
import { EPageHashLinks } from '@/types/Index/EPageHashLinks'
import { EDownloadMobileAppImage } from './constants'
import { messages } from './messages'
import './styles.scss'

const DownloadMobileApp: FC = () => {
  return (
    <div className="mobileAppBlock" id={EPageHashLinks.MOBILEAPP}>
      <div className="mobileAppBlock__mobileHeader mobileAppBlock__isMobile">
        <p className="mobileAppBlock__header text_label">{messages.mobileHeader}</p>
        <p className="text_super-small">{messages.mobileSubHeader}</p>
      </div>
      <div className="mobileAppBlock__desktopHeader mobileAppBlock__isDesktop">
        <p className="mobileAppBlock__header text_label">{messages.blockHeader}</p>
        <p className="mobileAppBlock__subheader text_small">{messages.blockSubHeader}</p>
      </div>
      <Image
        alt={messages.qrCodeAlt}
        src={EDownloadMobileAppImage.QR_CODE}
        className="mobileAppBlock__qrCode mobileAppBlock__isDesktop"
        isInStatic
      />
      <Image
        alt={messages.mobilePhoneAlt}
        src={EDownloadMobileAppImage.IPHONE}
        className="mobileAppBlock__phoneImage mobileAppBlock__isMobile"
        isInStatic
      />
      <div className="mobileAppBlock__storeBadge">
        <CommonLink spanIfEmpty url={EMobileAppLinks.ANDROID}>
          <Image alt={messages.googlePlayAlt} src={EDownloadMobileAppImage.GOOGLE_PLAY} isInStatic />
        </CommonLink>

        <CommonLink spanIfEmpty url={EMobileAppLinks.IOS}>
          <Image alt={messages.appStoreAlt} src={EDownloadMobileAppImage.APP_STORE} isInStatic />
        </CommonLink>
      </div>
    </div>
  )
}

export default memo(DownloadMobileApp)
