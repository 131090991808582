import type { GetCatalogQueryParams } from '@/api/kubik/inputs-catalog'
import { getCatalog, getCatalogQueryKey } from '@/api/kubik/inputs-catalog'
import type { TSsrPrefetchQueries } from '@/interfaces'
import { mapCatalogPayloadToFetchRequestParams } from '@/pages/AgriculturalProductCatalog/utils'

export const inputsAgriculturalCatalogSsrQueries: TSsrPrefetchQueries<'categoryId'> = (routeParams, query, additionalParams) => {
  const selectedCategory = routeParams.categoryId

  const catalogQueryParams: GetCatalogQueryParams = {
    ...(mapCatalogPayloadToFetchRequestParams({
      params: { categoryId: selectedCategory! },
      search: query,
    }) as GetCatalogQueryParams),
    regions: additionalParams?.regionId,
  }

  const queryKey = getCatalogQueryKey(catalogQueryParams)

  return [
    {
      queryFn: () =>
        getCatalog(catalogQueryParams, {
          ...(additionalParams?.token && { headers: { Authorization: `Bearer ${additionalParams?.token}` } }),
          paramsSerializer: params => new URLSearchParams(params).toString(),
        }),
      queryKey,
    },
  ]
}
