import type { UseMutationOptions, UseMutationResult } from "@tanstack/react-query";
import { useMutation } from "@tanstack/react-query";
import client from "@/api/client/axios";
import type { ResponseConfig } from "@/api/client/axios";
import type { AddSaleRequestShippingSurchargeDocumentMutationResponse, AddSaleRequestShippingSurchargeDocumentPathParams, AddSaleRequestShippingSurchargeDocument404, AddSaleRequestShippingSurchargeDocument500 } from "../types/AddSaleRequestShippingSurchargeDocument";

/**
 * @summary Формирование счета на доплату
 * @link /api/salerequests/:requestId/shippings/surcharge/:surchargeId/document
 */

export function useAddSaleRequestShippingSurchargeDocument <TData = AddSaleRequestShippingSurchargeDocumentMutationResponse, TError = AddSaleRequestShippingSurchargeDocument404 | AddSaleRequestShippingSurchargeDocument500>(requestId: AddSaleRequestShippingSurchargeDocumentPathParams["requestId"], surchargeId: AddSaleRequestShippingSurchargeDocumentPathParams["surchargeId"], options: {
          mutation?: UseMutationOptions<ResponseConfig<TData>, TError, void>,
          client?: Partial<Parameters<typeof client<TData, TError, void>>[0]>,
      } = {}): UseMutationResult<ResponseConfig<TData>, TError, void> {
  const { mutation: mutationOptions, client: clientOptions = {} } = options ?? {};

  return useMutation<ResponseConfig<TData>, TError, void>({
    mutationFn: () => {
      
      return client<TData, TError, void>({
        method: "post",
        url: `/api/salerequests/${requestId}/shippings/surcharge/${surchargeId}/document`,
        
        
        
        ...clientOptions
      });
    },
    ...mutationOptions
  });
};
