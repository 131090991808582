export const main = {
  title: 'Основные разделы',
  agriculturalProducts: 'Сельхозтовары',
  offerRequests: 'Запросы цен',
  harvestSale: 'Продажа урожая',
  financing: 'Кредиты и лизинг',
  services: 'Агросервисы',
  journal: 'Журнал',
}

export const company = {
  title: 'Компания',
  aboutUs: 'О нас',
  partners: 'Стать партнером',
  requisites: 'Реквизиты',
  vacancies: 'Вакансии',
  policy: 'Правовая информация',
}

export const social = {
  title: 'поле.рф в соц. сетях',
  telegram: 'Телеграм',
  vkontakte: 'Вконтакте',
  youtube: 'YouTube',
  yandexZen: 'Яндекс Дзен',
}

export const contacts = {
  title: 'Контакты',
  phone: '8 800 707-03-85',
  feedback: 'Задать вопрос',
}

export const contactUs = {
  title: 'Написать нам',
  cooperation: 'Сотрудничество: ',
  pr: 'PR-отдел: ',
  hr: 'HR-отдел: ',
  journal: 'Журнал поле.рф: ',
}

export const messages = {
  main,
  company,
  social,
  contacts,
  contactUs,
  allRightsReserved: 'Все права защищены. Использование материалов разрешено с указанием прямой гиперссылки.',
  copyright: '©',
  site: 'поле.рф',
}
