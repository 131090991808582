import { useAppAccessControl } from '@/App/components/AppAccessControlProvider'
import { EAccessControlValue } from '@/components/composed/AccessControlProvider/types'
import { useData } from '@/logic/data'
import { EPriceUom } from '@/types'
import type { IMultiCartResponse } from '@/types/Cart'
import type { IUseMultiCartOptions, TUseMultiCart } from './interfaces'

const defaultData: IMultiCartResponse = {
  carts: [],
  cartsItemsNumber: 0,
  cartsItemsTotalPrices: [{ value: 0, uom: EPriceUom.RUB }],
  cartsNumber: 0,
}

const defaultOptions: IUseMultiCartOptions = {
  shouldLoadForcibly: true,
}

export const useMultiCart: TUseMultiCart = ({ shouldLoadForcibly } = defaultOptions) => {
  const { resolve } = useAppAccessControl()
  const isCartShown = resolve('header.cart') === EAccessControlValue.VISIBLE

  const { data = defaultData, ...requestState } = useData<IMultiCartResponse>('multiCart', {
    shouldLoadForcibly,
    shouldNotLoadDataOnMount: !isCartShown,
  })

  return { data, ...requestState }
}
