import { useEffect, useRef } from 'react'
import { ExecutionEnvironment } from '@/utils/ExecutionEnvironment'

function createPortalTarget(id: string): HTMLElement {
  const portalTarget = document.createElement('div')
  portalTarget.setAttribute('id', id)
  document.body.appendChild(portalTarget)

  return portalTarget
}

export function usePortal(id: string): HTMLElement | null {
  const componentWrapperRef = useRef<HTMLElement | null>(null)

  useEffect(() => {
    if (ExecutionEnvironment.isSsr || !componentWrapperRef.current) return

    const portalTarget = document.querySelector(`#${id}`) || createPortalTarget(id)

    portalTarget.appendChild(componentWrapperRef.current)

    // eslint-disable-next-line consistent-return
    return () => {
      componentWrapperRef.current?.remove()
      if (!portalTarget.childNodes.length) {
        portalTarget.remove()
      }
    }
  }, [id])

  const getCurrentWrapper = () => {
    if (!componentWrapperRef.current) {
      componentWrapperRef.current = !ExecutionEnvironment.isSsr ? document.createElement('div') : null
    }

    return componentWrapperRef.current
  }

  return getCurrentWrapper()
}
