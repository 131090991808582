
export enum OperatorAuctionSearchRequestSort {
    "CREATED_DATE_TIME_ASC" = "CREATED_DATE_TIME_ASC",
    "CREATED_DATE_TIME_DESC" = "CREATED_DATE_TIME_DESC",
    "AMOUNT_ASC" = "AMOUNT_ASC",
    "AMOUNT_DESC" = "AMOUNT_DESC",
    "TYPE_ASC" = "TYPE_ASC",
    "TYPE_DESC" = "TYPE_DESC",
    "BET_SIZE_ASC" = "BET_SIZE_ASC",
    "BET_SIZE_DESC" = "BET_SIZE_DESC"
}
export type OperatorAuctionSearchRequest = {
    /**
     * @type string | undefined
    */
    searchField?: string;
    /**
     * @type array | undefined
    */
    cultures?: string[];
    /**
     * @type string | undefined date-time
    */
    tenderStartDate?: string;
    /**
     * @type string | undefined date-time
    */
    tenderStartDateFrom?: string;
    /**
     * @type string | undefined date-time
    */
    tenderStartDateTo?: string;
    /**
     * @type string | undefined date-time
    */
    tenderEndDate?: string;
    /**
     * @type string | undefined date-time
    */
    tenderEndDateFrom?: string;
    /**
     * @type string | undefined date-time
    */
    tenderEndDateTo?: string;
    /**
     * @type integer | undefined int64
    */
    amountFrom?: number;
    /**
     * @type integer | undefined int64
    */
    amountTo?: number;
    /**
     * @type string | undefined
    */
    sort?: OperatorAuctionSearchRequestSort;
};
