import { EJournalRoute } from '@/types'
import type { TPublicationSourcePage } from '@/types/Infoportal'
import { EInfoportalAdministrationRoute } from '@/types/Infoportal'
import { createRouterLink } from '@/utils/url'
import { base64ImageRegExp } from './constants'

export const getJournalPublicationUrl = (slug: string) => createRouterLink(EJournalRoute.PUBLICATION_ITEM, { publicationSlug: slug })
export const getJournalArticlesUrlByRubric = (id: string) => `${EJournalRoute.MAIN}/${id}`
export const getJournalNewsUrlByRubric = (id: number) => `${EJournalRoute.NEWS}/${id}`
export const getInfoportalPublicationEditorUrl = (id: string, sourcePage: TPublicationSourcePage) =>
  `${EInfoportalAdministrationRoute.MAIN}/${sourcePage}/editor/${id}`

export const getBase64Image = (readerResult: string) => readerResult.replace(base64ImageRegExp, '')

export const isValidExtension = (acceptedExtensions: string[], fileType: string) =>
  acceptedExtensions.some(element => fileType.includes(element))
