import loadable from '@loadable/component'

export { default as CheckboxButtons } from './CheckboxButtons'
export { default as Dropdown } from './Dropdown'
export { default as DropdownWithTags } from './DropdownWithTags'
export { default as DatePicker } from './DatePicker'
export { default as SwitchButton } from './SwitchButton'
export { default as RadioButtonsGroup } from './RadioButtonsGroup'
export { default as QuantityPickerInput } from './QuantityPickerInput'
export { default as UploadFileButton } from './UploadFileButton'
export { default as Autosuggest } from './Autosuggest'
export { default as RangeSlider } from './RangeSlider'
export { default as RegionSelect } from './RegionSelect'
export { default as RegionMultiSelect } from './RegionMultiSelect'
export { default as PolicyAgreement } from './PolicyAgreement'
export { default as SearchCitiesWithSuggestions } from './SearchCitiesWithSuggestions'
export { default as AutoSave } from './AutoSave'
export { default as LoanPurposeSelect } from './LoanPurposeSelect'

// TODO при импорте компонента Dropdown компонентом loadable, less файлы rsuit'a переопределяют основные стили

export const SmsCode = loadable(
  /* webpackChunkName: "SmsCode" */
  /* webpackPrefetch: "true" */
  /* #__LOADABLE__ */
  () => import('./SmsCode'),
)

export const TextInput = loadable(
  /* webpackChunkName: "TextInput" */
  /* webpackPrefetch: "true" */
  /* #__LOADABLE__ */
  () => import('./TextInput'),
)

export const Checkbox = loadable(
  /* webpackChunkName: "Checkbox" */
  /* webpackPrefetch: "true" */
  /* #__LOADABLE__ */
  () => import('./CheckboxInput'),
)

export const MultiCheckbox = loadable(
  /* webpackChunkName: "MultiCheckbox" */
  /* webpackPrefetch: "true" */
  /* #__LOADABLE__ */
  () => import('./MultiCheckboxInput'),
)

export const ButtonSelector = loadable(
  /* webpackChunkName: "ButtonSelector" */
  /* webpackPrefetch: "true" */
  /* #__LOADABLE__ */
  () => import('./ButtonSelector'),
)

export const UploadButtonField = loadable(
  /* webpackChunkName: "UploadButtonField" */
  /* webpackPrefetch: "true" */
  /* #__LOADABLE__ */
  () => import('./UploadButtonField'),
)

export const Wysiwyg = loadable(
  /* webpackChunkName: "Wysiwyg" */
  /* webpackPrefetch: "true" */
  /* #__LOADABLE__ */
  () => import('./Wysiwyg'),
)

export const DatePickerNew = loadable(
  /* webpackChunkName: "DatePickerNew" */
  /* webpackPrefetch: "true" */
  /* #__LOADABLE__ */
  () => import('./DatePickerNew'),
)

export const TextEditor = loadable(
  /* webpackChunkName: "TextEditor" */
  /* webpackPrefetch: "true" */
  /* #__LOADABLE__ */
  () => import('./TextEditor'),
)
