export enum EOrderRequestName {
  ADD_PRODUCT_TO_EXISTING_ORDER = 'addProductToExistingOrder',
  ADD_IMPERSONAL_PRODUCT_TO_EXISTING_ORDER = 'addImpersonalProductToExistingOrder',
  DELETE_PRODUCT_FROM_ORDER = 'deleteProductFromOrder',
  MAKE_ORDER = 'makeOrder',
  MAKE_MULTI_ORDER = 'makeMultiOrder',
  FETCH_ORDER = 'fetchAgrarianOrder',
  FETCH_ORDER_ADDRESSES = 'fetchAgrarianOrderAddresses',
  RECALCULATE_ORDER = 'recalculateOrder',
  CHANGE_ORDER_STATUS = 'changeOrderStatus',
  REJECT_ORDER = 'rejectOrder',
  CHANGE_ORDER = 'changeOrder',
}
