import loadable from '@loadable/component'

export const RejectedEntityHintModal = loadable(
  /* webpackChunkName: "RejectedEntityHintModal" */
  /* webpackPrefetch: "true" */
  /* #__LOADABLE__ */
  () => import('./RejectedEntityHintModal'),
)

export { default as DocumentStatus } from './DocumentStatus'
export { default as DocumentHint } from './DocumentHint'
