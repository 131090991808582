import type { RegionData } from "./RegionData";

export enum MainPageSliderBannerDataSpecialAction {
    "REGISTRATION_FORM" = "REGISTRATION_FORM",
    "URL" = "URL"
}
export type MainPageSliderBannerData = {
    /**
     * @type string
    */
    id: string;
    /**
     * @type string | undefined
    */
    url?: string;
    /**
     * @type string | undefined
    */
    specialAction?: MainPageSliderBannerDataSpecialAction;
    /**
     * @type string
    */
    name: string;
    /**
     * @type array
    */
    regions: RegionData[];
    /**
     * @type string
    */
    webImage: string;
    /**
     * @type string
    */
    mobileImage: string;
    /**
     * @type integer int32
    */
    position: number;
    /**
     * @type string
    */
    status: string;
};
