import type { ResponsePublicationsWithPaginationDtoV1 } from "./ResponsePublicationsWithPaginationDtoV1";

export enum GetClientPublicationsQueryParamsType {
    "ARTICLE" = "ARTICLE",
    "NEWS" = "NEWS"
}
export type GetClientPublicationsQueryParams = {
    /**
     * @type string
    */
    type: GetClientPublicationsQueryParamsType;
    /**
     * @type integer | undefined int64
    */
    rubricId?: number;
    /**
     * @type integer | undefined int64
    */
    tagId?: number;
    /**
     * @type boolean | undefined
    */
    includeMain?: boolean;
    /**
     * @type integer | undefined int32
    */
    offset?: number;
    /**
     * @type integer | undefined int32
    */
    limit?: number;
};

/**
 * @description OK
*/
export type GetClientPublicationsQueryResponse = ResponsePublicationsWithPaginationDtoV1;
