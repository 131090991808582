export enum EAgriculturalProductsRoute {
  main = '/agricultural-products',
  search = '/agricultural-products/search',
  fertilizers = '/agricultural-products/1',
  seeds = '/agricultural-products/2',
  remedies = '/agricultural-products/3',
  fuel = '/agricultural-products/4',
  agrotechnics = '/agricultural-products/5',
  spares = '/agricultural-products/6',
  category = '/agricultural-products/:categoryId',
  product = '/agricultural-products/:category/:productId',
}
