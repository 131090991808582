import type { TAccreditationState } from './interfaces'

export const statusesByParam: TAccreditationState = {
  isValid: {
    textId: 'validTo',
    labelColor: 'light-green',
  },
  isExpired: {
    textId: 'expired',
    labelColor: 'light-red',
  },
  isProcessing: {
    textId: 'processing',
    labelColor: 'light-orange',
  },
  isRejected: {
    textId: 'rejected',
    labelColor: 'light-red',
  },
}
