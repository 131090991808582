import client from "@/api/client/axios";
import type { ResponseConfig } from "@/api/client/axios";
import type { UpdateOrderSupplyMutationRequest, UpdateOrderSupplyMutationResponse, UpdateOrderSupplyPathParams } from "../../types/UpdateOrderSupply";

/**
     * @summary Обновление поставки
     * @link /api/orders/:orderId/supplies/:supplyId
     */
export async function updateOrderSupply (orderId: UpdateOrderSupplyPathParams["orderId"], supplyId: UpdateOrderSupplyPathParams["supplyId"], data: UpdateOrderSupplyMutationRequest, options: Partial<Parameters<typeof client>[0]> = {}): Promise<ResponseConfig<UpdateOrderSupplyMutationResponse>["data"]> {
    const { data: resData } = await client<UpdateOrderSupplyMutationResponse, UpdateOrderSupplyMutationRequest>({
        method: "put",
        url: `/api/orders/${orderId}/supplies/${supplyId}`,
        data,
        ...options
    });
    
    return resData;
};