import React, { memo } from 'react'
import { FormattedMessage } from 'react-intl'
import classNames from 'classnames'
import { PriceInfo } from '@/components/composed'
import { InjectHtml } from '@/components/ui'
import { ConditionalCurrencyHint } from '@/components/ui/inputs'
import { injectMessages } from '@/logic/translation/utils'
import type { IOfferPriceProps } from './interfaces'
import messages from './messages.json'
import './styles.scss'

injectMessages(messages)

const OfferPrice: React.FC<IOfferPriceProps> = props => {
  const {
    className,
    price,
    unitPrice,
    unitCode = '',
    isPriceHidden,
    isMobileDevice = false,
    pieceProduct,
    isOldPrice,
    packagePriceClassName,
    unitPriceClassName,
    vatRate,
    shouldShowVatRate,
    shouldHideConditionalCurrencyHint,
    shouldHidePackagePrice,
  } = props

  const shouldShowPackagePrice = price && !shouldHidePackagePrice
  const shouldShowPriceSetNotification = !price && !shouldHidePackagePrice

  return (
    <div className={classNames('offer-price', className, { 'offer-price_old': isOldPrice && !isPriceHidden })}>
      {isPriceHidden ? (
        <div className={classNames('offer-price-unit offer-price-unit_hidden-price')}>
          <div className="offer-price-unit__hide-line" />
          <PriceInfo
            className="offer-price-unit__price-info text_large font-weight_bold"
            uomClassName="text_small font-weight_normal"
            price={{ value: 3338, uom: 'RUB' }}
          />
        </div>
      ) : (
        <>
          <div className={classNames('offer-price-unit', unitPriceClassName, { 'offer-price-unit_contract-price': !unitPrice })}>
            {unitPrice ? (
              <span onClick={e => isMobileDevice && e.preventDefault()}>
                <ConditionalCurrencyHint
                  uom={unitPrice.uom}
                  shouldBeHintInModal={isMobileDevice}
                  className="row-align_base"
                  shouldHideHint={shouldHideConditionalCurrencyHint}
                >
                  <PriceInfo className="offer-price-unit__price-info text_large font-weight_bold" price={unitPrice} />
                  {unitCode && !pieceProduct && <span className="offer-price-unit__measure text_small">/{unitCode}</span>}
                </ConditionalCurrencyHint>
              </span>
            ) : (
              <span className="offer-price-unit__contract-info text_large font-weight_bold">
                <FormattedMessage id="offerPrice.contractPrice" />
              </span>
            )}
          </div>

          {!pieceProduct ? (
            <div
              className={classNames('offer-price-package text_small-from-sm', packagePriceClassName, {
                'offer-price-package_empty': !price,
              })}
            >
              {shouldShowPackagePrice && (
                <>
                  <PriceInfo price={price} />
                  /<InjectHtml className="space-holder-right8" textId="global.package" />
                  {shouldShowVatRate && (
                    <>
                      <span className="space-holder-right8">•</span>
                      <InjectHtml
                        textId={vatRate ? 'offerPrice.withVatRate' : 'global.excludingVat'}
                        textValues={{ vatRate }}
                        TagName="p"
                      />
                    </>
                  )}
                </>
              )}
              {shouldShowPriceSetNotification && <FormattedMessage id="offerPrice.contractPriceDescription" />}
            </div>
          ) : (
            shouldShowVatRate && (
              <InjectHtml textId={vatRate ? 'offerPrice.withVatRate' : 'global.excludingVat'} textValues={{ vatRate }} TagName="p" />
            )
          )}
        </>
      )}
    </div>
  )
}

export default memo(OfferPrice)
