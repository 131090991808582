import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { EColor } from '@frontend/pole-ui/lib/common/constants'
import { Chip, EChipModifier } from '@frontend/pole-ui/lib/components/Chip'
import { CommonLink } from '@frontend/pole-ui/lib/components/CommonLink'
import type { IIconProps } from '@frontend/pole-ui/lib/components/Icon'
import { EIconName, EIconSize } from '@frontend/pole-ui/lib/components/Icon'
import { InfoText, StatusComment, TextWithIcon } from '@/components/ui'
import { ConditionalCurrencyHint } from '@/components/ui/inputs'
import { isUserSupplier } from '@/logic/auth/reducer'
import { canOrderPricesBeNegotiated, isOrderItemsSameVatRate } from '@/logic/cart/helpers'
import type { RootState } from '@/redux/interfaces'
import { isNonNullable } from '@/typeguards/common'
import { EUserProfileRoute } from '@/types'
import { ESkuOrderType } from '@/types/Catalog/ESkuOrderType'
import { EAgrarianOrderStatus } from '@/types/Order'
import { formatDate, formatLocalizedString } from '@/utils/formatUtils'
import { useIsBargainInfoVisible } from '@/utils/hooks/inputs/useIsBargainInfoVisible'
import { useDeviceType } from '@/utils/hooks/useDeviceType'
import { getProductInfo } from '@/utils/inputs/product/helpers'
import { getUrlWithQuery } from '@/utils/url'
import ProductsList from '../ProductsList'
import { ProductPrice, ProductSupplier } from '..'
import type { IAgrarianOrderProps } from './interfaces'
import { messages } from './messages'
import './style.scss'

const mapState = (state: RootState) => ({
  isSupplier: isUserSupplier()(state),
})

const orangeInfoIcon: IIconProps = { name: EIconName.InfoFill, style: { color: EColor.ORANGE } }

const Order: React.FC<IAgrarianOrderProps> = ({ order }) => {
  const { isSupplier } = useSelector(mapState)

  const { isMobile } = useDeviceType()

  const { id, creationDate, status, sumPrice, supplier, deliveryOptions, items, signValidTo, agrarianOrganization } = order
  const { supplierStore, store } = deliveryOptions || {}
  const { location } = store || {}
  const { address } = location || {}
  const contragent = isSupplier ? agrarianOrganization?.organization : supplier
  const orderUrl = `${EUserProfileRoute.orders}/${id}`

  const { canPricesBeNegotiated, isProductsSameVAT } = useMemo(
    () => ({
      canPricesBeNegotiated: canOrderPricesBeNegotiated(order),
      isProductsSameVAT: isOrderItemsSameVatRate(order.items),
    }),
    [order],
  )
  const vatRate = items[0]?.vatRate

  const productList = useMemo(() => {
    return order.items.length ? order.items : [getProductInfo(order.productInfoData)].filter(isNonNullable)
  }, [order])

  const isBargainChatLinkVisible = useIsBargainInfoVisible(order.status.id)
  const chatUrl = getUrlWithQuery(`${EUserProfileRoute.orders}/${id}`, { tab: 'chat' })

  return (
    <div className="order">
      <div>
        <div className="order__details">
          <div className="space-holder24">
            <CommonLink url={orderUrl}>
              <h4 className="space-holder8">
                {formatLocalizedString(messages.heading, { id, orderDate: formatDate(creationDate, 'dd.MM.yyyy') })}
              </h4>
            </CommonLink>
            {contragent && (
              <ProductSupplier
                className="order__supplier"
                linkClassName="underline underline_black link-no-hover"
                supplierStore={supplierStore}
                supplier={contragent}
                isInline
              />
            )}
            {order.typeOrder === ESkuOrderType.MULTI_ORDER && isSupplier && (
              <Chip text={messages.multiOrderText} color={EChipModifier.LIGHT_GREEN} className="space-holder-top24 space-holder-24" />
            )}
          </div>
        </div>

        <ProductsList className="text_small space-holder24-sm" items={productList} title={messages.productsHeading} />
      </div>

      <div>
        <div className="order__price-container row-sm row_centered-sm row_column-sm">
          <ConditionalCurrencyHint uom={sumPrice.uom} className="space-holder4-sm" shouldBeHintInModal={isMobile}>
            <ProductPrice
              price={sumPrice}
              className="order-price h3"
              shouldShowEmptyPrice={canPricesBeNegotiated || sumPrice.value === 0}
            />
          </ConditionalCurrencyHint>

          {isBargainChatLinkVisible && (
            <CommonLink className="underline underline_green color_green text_small-from-sm space-holder8 inline-block" url={chatUrl}>
              {messages.linkChatText}
            </CommonLink>
          )}

          <div className="row row-justify_center-sm">
            <InfoText
              isDeviceMobile={isMobile}
              infoTextId={isProductsSameVAT ? `global.${vatRate !== 0 ? 'vatRate' : 'excludingVat'}` : 'global.VATRateExcludingPercent'}
              className="color_pale-black text_super-small-sm"
              textValues={{ vatRate }}
            />
          </div>

          {canPricesBeNegotiated && (
            <TextWithIcon
              iconProps={orangeInfoIcon}
              text={messages.withPriceNegotiationNotice}
              textClassName="text_small"
              className="space-holder-top4 space-holder-left-auto-sm space-holder-right-auto-sm"
            />
          )}
        </div>

        {address && (
          <div className="order-address text_small text_super-small-sm space-holder-top16-from-sm space-holder-top8-sm">
            <span className="color_pale-black">{messages.addressHeading}</span>
            <p className="order-address__text">{address}</p>
          </div>
        )}
      </div>

      <div className="order__status row-from-sm order-footer-cell order-footer-cell_status">
        <TextWithIcon
          iconProps={
            status.id === EAgrarianOrderStatus.CANCELED
              ? { name: EIconName.Error, style: { color: EColor.RED }, size: isMobile ? EIconSize.XS : EIconSize.M }
              : { name: EIconName.Services, style: { color: EColor.ORANGE }, size: isMobile ? EIconSize.XS : EIconSize.M }
          }
          text={status.name}
          className="space-holder-right40-from-sm text_super-small-sm"
          textClassName="space-holder-top2-from-sm"
          textModifier="super-small"
        />

        {status.id === EAgrarianOrderStatus.CANCELED && <StatusComment className="comment-text" text={status.comment} />}

        {signValidTo && (
          <TextWithIcon
            iconProps={{ name: EIconName.Calendar, size: isMobile ? EIconSize.XS : EIconSize.M }}
            text={formatLocalizedString(messages.offerValidTo, { date: formatDate(signValidTo, 'dd.MM.yyyy') })}
            className="text_super-small-sm space-holder8"
          />
        )}
      </div>

      <div className="order-footer-cell order-footer-cell_button space-holder-top16-sm">
        <CommonLink className="order__button button button_green button_small" url={orderUrl}>
          {messages.buttonText}
        </CommonLink>
      </div>
    </div>
  )
}

export default Order
