export enum EAgroservicesRoute {
  main = '/agroservices',
  agrocalc = '/agroservices/calc',
  seedingRateCalc = '/agroservices/seeding-rate',
  poleExpert = '/agroservices/poleexpert',
  poleScouting = '/agroservices/polescouting',
  poleDiff = '/agroservices/polediff',
  polePlus = '/agroservices/poleplus',
  agroconsulting = '/agroservices/agroconsulting',
  farmlandCropValuation = '/agroservices/farmland-crop-valuation',
  fgisZerno = '/agroservices/fgis-zerno',
  analyticsPricesAgriculturalProducts = '/agroservices/analytics-prices-agricultural-products',
  youDo = '/agroservices/you-do',
  efisZsn = '/agroservices/efis',
}
