import type { UseMutationOptions, UseMutationResult } from "@tanstack/react-query";
import { useMutation } from "@tanstack/react-query";
import client from "@/api/client/axios";
import type { ResponseConfig } from "@/api/client/axios";
import type { CreateRequestMutationRequest, CreateRequestMutationResponse, CreateRequestHeaderParams, CreateRequest400, CreateRequest404, CreateRequest500 } from "../types/CreateRequest";

/**
 * @summary Создание заявки на агросопровождение
 * @link /api/agroservices/requests
 */

export function useCreateRequest <TData = CreateRequestMutationResponse, TError = CreateRequest400 | CreateRequest404 | CreateRequest500, TVariables = CreateRequestMutationRequest>(headers?: CreateRequestHeaderParams, options: {
          mutation?: UseMutationOptions<ResponseConfig<TData>, TError, TVariables>,
          client?: Partial<Parameters<typeof client<TData, TError, TVariables>>[0]>,
      } = {}): UseMutationResult<ResponseConfig<TData>, TError, TVariables> {
  const { mutation: mutationOptions, client: clientOptions = {} } = options ?? {};

  return useMutation<ResponseConfig<TData>, TError, TVariables>({
    mutationFn: (data) => {
      
      return client<TData, TError, TVariables>({
        method: "post",
        url: `/api/agroservices/requests`,
        data,
        
        headers: { ...headers, ...clientOptions.headers },
        ...clientOptions
      });
    },
    ...mutationOptions
  });
};
