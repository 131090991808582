import type { QueryKey, UseQueryResult, UseQueryOptions, QueryOptions } from "@tanstack/react-query";
import { useQuery } from "@tanstack/react-query";
import client from "@/api/client/axios";
import type { GetSaleTemplatesForEachCulturesQueryResponse, GetSaleTemplatesForEachCulturesQueryParams, GetSaleTemplatesForEachCultures400, GetSaleTemplatesForEachCultures404, GetSaleTemplatesForEachCultures500 } from "../types/GetSaleTemplatesForEachCultures";

export const getSaleTemplatesForEachCulturesQueryKey = (params?: GetSaleTemplatesForEachCulturesQueryParams) =>     [{url:`/api/requests/list/templates`},...(params ? [params] : [])] as const;
      export function getSaleTemplatesForEachCulturesQueryOptions <TData = GetSaleTemplatesForEachCulturesQueryResponse, TError = GetSaleTemplatesForEachCultures400 | GetSaleTemplatesForEachCultures404 | GetSaleTemplatesForEachCultures500>(params?: GetSaleTemplatesForEachCulturesQueryParams, options: Partial<Parameters<typeof client>[0]> = {}): UseQueryOptions<TData, TError> {
        const queryKey = getSaleTemplatesForEachCulturesQueryKey(params);

        return {
          queryKey,
          queryFn: () => {
            
            return client<TData, TError>({
              method: "get",
              url: `/api/requests/list/templates`,
              params,
              
              ...options,
            }).then(res => res.data);
          },
        };
      };
      
/**
 * @summary Получение списка шаблонов заявок для каждой из культур
 * @link /api/requests/list/templates
 */

export function useGetSaleTemplatesForEachCultures <TData = GetSaleTemplatesForEachCulturesQueryResponse, TError = GetSaleTemplatesForEachCultures400 | GetSaleTemplatesForEachCultures404 | GetSaleTemplatesForEachCultures500>(params?: GetSaleTemplatesForEachCulturesQueryParams, options: {
          query?: UseQueryOptions<TData, TError>,
          client?: Partial<Parameters<typeof client<TData, TError>>[0]>,
        } = {}): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const { query: queryOptions, client: clientOptions = {} } = options ?? {};
  const queryKey = queryOptions?.queryKey ?? getSaleTemplatesForEachCulturesQueryKey(params);

  const query = useQuery<TData, TError>({
    ...getSaleTemplatesForEachCulturesQueryOptions<TData, TError>(params, clientOptions),
    ...queryOptions
  }) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey as QueryKey;

  return query;
};
