import client from "@/api/client/axios";
import type { ResponseConfig } from "@/api/client/axios";
import type { GetAboutBannerQueryResponse } from "../../types/GetAboutBanner";

/**
     * @summary Получение баннера о площадке
     * @link /api/mainPage/aboutBanner
     */
export async function getAboutBanner (options: Partial<Parameters<typeof client>[0]> = {}): Promise<ResponseConfig<GetAboutBannerQueryResponse>["data"]> {
    const { data: resData } = await client<GetAboutBannerQueryResponse>({
        method: "get",
        url: `/api/mainPage/aboutBanner`,
        ...options
    });
    
    return resData;
};