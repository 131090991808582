import type { BaseSyntheticEvent } from 'react'
import React, { useCallback, useEffect, useState } from 'react'
import type { AutosuggestPropsBase, ContainerProps, InputProps, SuggestionsFetchRequestedParams } from 'react-autosuggest'
import Autosuggest from 'react-autosuggest'
import classNames from 'classnames'
import { BehaviorSubject, debounceTime, distinctUntilChanged, filter, map } from 'rxjs'
import { useRequestStateManager } from '@/api/hooks'
import { Loader } from '@/components/ui'
import TextInputView from '../TextInput/TextInputView'
import { baseTheme } from './constants'
import type { IAutosuggestProps, TSelectSuggestion } from './interfaces'
import './autosuggest.scss'

const KeydownRegexp = {
  letters: /^(86)/,
}

const containerProps: ContainerProps = {
  className: 'autosuggest-container',
}

function CustomAutosuggest<GSuggestion>(props: IAutosuggestProps<GSuggestion>) {
  const {
    name,
    getSuggestionValue,
    suggestionComponent,
    onSuggestionsFetchRequested,
    onSuggestionsClearRequested,
    onSuggestionSelected,
    theme,
    onSuggestChange,
    meta,
    defaultInputValue = '',
    defaultSuggestion,
    defaultSuggestions = [],
    onInputChange,
    onInputFocus,
    onInputBlur,
    shouldAlwaysRenderSuggestions,
    customInputProps,
    renderCustomInput,
    focusInputOnSuggestionClick,
    shouldHighlightFirstSuggestion,
    isRequired,
    autosearchValue,
    className,
    shouldRenderSuggestions,
  } = props

  const { state: fetchingState, actions } = useRequestStateManager()

  const [suggestions, setSuggestions] = useState<GSuggestion[]>(defaultSuggestions)
  const [inputValue, setValue] = useState<string>(defaultInputValue)
  const [focused, setFocused] = useState<boolean>(false)
  const [isSuggestionSelected, setSelectedSuggestionState] = useState<boolean>(false)

  const [fetchingSuggestsSubject] = useState(
    () => new BehaviorSubject<SuggestionsFetchRequestedParams>({ reason: 'escape-pressed', value: '' }),
  )

  useEffect(() => setValue(defaultInputValue), [defaultInputValue])

  useEffect(() => {
    if (defaultSuggestion) onSuggestChange?.(defaultSuggestion)
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(defaultSuggestion)])

  useEffect(() => {
    if (defaultSuggestions) setSuggestions(defaultSuggestions)
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(defaultSuggestions)])

  const inputProps: InputProps<GSuggestion> = {
    ...customInputProps,
    name,
    value: inputValue,
    // @ts-ignore
    meta,
    focused,
    onChange: (event: BaseSyntheticEvent, { newValue }) => {
      setValue(newValue)
      onInputChange?.(newValue, event)
      setSelectedSuggestionState(false)
    },
    onKeyDown: ({ keyCode, metaKey, altKey, ctrlKey, key }) => {
      if (
        inputValue &&
        (!(metaKey || altKey || ctrlKey) || ((metaKey || altKey || key === 'Tab') && KeydownRegexp.letters.test(String(keyCode))))
      ) {
        if (!isSuggestionSelected) {
          onSuggestChange?.(null)
        }
      }
    },
    isAutosuggest: true,
    onFocus: event => {
      setFocused(true)
      onInputFocus?.(event)
    },
    onBlur: event => {
      setFocused(false)
      setSuggestions([])
      onInputBlur?.(event)
      if (!isSuggestionSelected) {
        onSuggestChange?.(null)
      }
    },
    isRequired,
  }

  const renderInputComponent: AutosuggestPropsBase<GSuggestion>['renderInputComponent'] = renderInputProps => {
    const InputComponent = (
      //@ts-ignore
      <TextInputView {...renderInputProps}>
        <Loader isVisible={fetchingState.isLoading} withoutOverlay className="autosuggest-container__loader" />
      </TextInputView>
    )

    return renderCustomInput ? renderCustomInput(InputComponent) : InputComponent
  }

  const onSelect: TSelectSuggestion<GSuggestion> = useCallback(
    async selectedSuggestionEventData => {
      if (onSuggestionSelected) {
        const suggestion = await onSuggestionSelected(selectedSuggestionEventData)
        setSuggestions([])
        /*При выборе подсказки, вызываем только нативный onChange для установки данных в форму*/
        onSuggestChange?.(suggestion)
        setValue(selectedSuggestionEventData.suggestionValue)
        setSelectedSuggestionState(Boolean(suggestion))
      }
    },
    [setSuggestions, setValue, onSuggestChange, onSuggestionSelected],
  )

  useEffect(() => {
    const fetchingEvent = fetchingSuggestsSubject
      .pipe(
        filter(({ reason }) => reason === 'input-changed' || reason === 'input-focused'),
        debounceTime(500),
        distinctUntilChanged((previous, current) => previous.value === current.value && current.reason === 'input-changed'),
        map(({ value }) => value),
      )
      .subscribe(value => {
        actions.requestStarted()

        onSuggestionsFetchRequested({ selectSuggestion: onSelect, value })
          .then(responsedSuggestions => {
            if (focused) setSuggestions(responsedSuggestions)
            actions.requestEnded()
          })
          .catch(actions.requestEnded)
      })

    if (autosearchValue) {
      fetchingSuggestsSubject.next({
        value: autosearchValue,
        reason: 'input-changed',
      })
    }

    return () => {
      fetchingEvent.unsubscribe()
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onSuggestionsFetchRequested, setSuggestions, fetchingSuggestsSubject, actions, focused])

  return (
    <Autosuggest
      containerProps={{ ...containerProps, className: classNames(containerProps.className, className) }}
      suggestions={suggestions}
      alwaysRenderSuggestions={shouldAlwaysRenderSuggestions}
      onSuggestionsFetchRequested={data => fetchingSuggestsSubject.next(data)}
      onSuggestionsClearRequested={onSuggestionsClearRequested ? onSuggestionsClearRequested(setSuggestions) : () => setSuggestions([])}
      getSuggestionValue={getSuggestionValue}
      renderSuggestion={suggestionComponent}
      inputProps={inputProps}
      renderInputComponent={renderInputComponent}
      highlightFirstSuggestion={shouldHighlightFirstSuggestion}
      focusInputOnSuggestionClick={focusInputOnSuggestionClick}
      onSuggestionSelected={(event, selectedSuggestionEventData) => onSelect(selectedSuggestionEventData, event)}
      theme={theme || baseTheme}
      shouldRenderSuggestions={shouldRenderSuggestions}
    />
  )
}

export default CustomAutosuggest
