export enum ERequestAccreditationStatus {
  NOT_STARTED = 'NOT_STARTED',
  DOCUMENTS_SUBMISSION = 'DOCUMENTS_SUBMISSION',
  DOCUMENTS_RESUBMISSION = 'DOCUMENTS_RESUBMISSION',
  ARCHIVE_UNPACKING = 'ARCHIVE_UNPACKING',
  DOCUMENTS_CHECK = 'DOCUMENTS_CHECK',
  BUYER_SIGNING = 'BUYER_SIGNING',
  BUYER_SIGNING_CRYPTO = 'BUYER_SIGNING_CRYPTO',
  AGRARIAN_SIGNING_CRYPTO = 'AGRARIAN_SIGNING_CRYPTO',
  AGRARIAN_SIGNING = 'AGRARIAN_SIGNING',
  APPROVED_CRYPTO = 'APPROVED_CRYPTO',
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
}
