import React from 'react'
import { EColor } from '@frontend/pole-ui/lib/common/constants'
import classNames from 'classnames'
import type { CSSProperties } from 'styled-components'
import { getStaticImagePath } from '@/utils/images'
import Image from '../Image'
import type { IAvatarProps } from './interfaces'
import './styles.scss'

const Avatar: React.FC<IAvatarProps> = props => {
  const {
    avatar,
    placeholderImage = 'profile-photo.png',
    className,
    size,
    patchBackground = EColor.WHITE,
    withPatch = true,
    borderColor = EColor.WHITE,
    borderWidth = 0,
    version,
    shouldRoundAvatarContainer,
  } = props
  const placeholder = getStaticImagePath(placeholderImage)

  return (
    <div
      className={classNames(className, 'user-avatar', { 'user-avatar_with-patch': withPatch })}
      style={
        {
          width: size,
          height: size,
          '--patch-background': patchBackground,
          borderLeft: `${borderWidth} solid ${borderColor}`,
          borderRight: `${borderWidth} solid ${borderColor}`,
          borderTop: `${borderWidth} solid ${borderColor}`,
          borderRadius: `${shouldRoundAvatarContainer ? '50%' : '0'}`,
        } as CSSProperties
      }
    >
      <Image
        isInStatic={!avatar}
        src={(avatar || placeholderImage) as string}
        className="user-avatar__image"
        placeholderImage={placeholder}
        version={version}
      />
    </div>
  )
}

export default Avatar
