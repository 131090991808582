import React from 'react'
import { useLocation } from 'react-router-dom'
import ScrollRestoration from 'react-scroll-restoration'

const ConditionalScrollRestoration = () => {
  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)
  const noScroll = queryParams.get('noscrolltop')

  return !noScroll ? <ScrollRestoration /> : null
}

export default ConditionalScrollRestoration
