export const getRegionAccusativeDeclination = (regionName: string = '') =>
  regionName
    .replace('кая', 'кую')
    .replace(/(ка)(\s+|$)/g, 'ку$2')
    .replace('ква', 'кву')
    .replace(/(ая)/g, 'ую')

export const getRegionMixedGenitiveAndDativeDeclination = (regionName: string = '') =>
  regionName
    .replace(/(ая)(\s+|$)/g, 'ой$2')
    .replace(/(ка)(\s+|$)/g, 'ке$2')
    .replace(/(ай)(\s+|$)/g, 'ае$2')
    .replace(/г$/, 'ге')
    .replace('ный', 'ном')
    .replace(/кий/g, 'ком')
    .replace(/ть/g, 'ти')
