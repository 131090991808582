import { LOCATION_CHANGE } from 'connected-react-router'
import { combineEpics, ofType } from 'redux-observable'
import { map, pairwise } from 'rxjs'
import type { TLocationChangeAction, TLocationChangeEpic } from '@/types'
import { filterChangingLocationPathname, shouldNotCloseModalOnChangingLocationAfterOpeningSuccessOrderModal } from '@/utils/rxjs/filter'
import { closeModal } from './actions'

const onLocationChangedDoCloseDialog: TLocationChangeEpic = action$ =>
  action$.pipe(
    ofType(LOCATION_CHANGE),
    pairwise<TLocationChangeAction>(),
    filterChangingLocationPathname(),
    shouldNotCloseModalOnChangingLocationAfterOpeningSuccessOrderModal(),
    map(closeModal),
  )

//eslint-disable-next-line @typescript-eslint/no-explicit-any
export default (<any>combineEpics)(onLocationChangedDoCloseDialog)
