import type { QueryKey, UseQueryResult, UseQueryOptions, QueryOptions } from "@tanstack/react-query";
import { useQuery } from "@tanstack/react-query";
import client from "@/api/client/axios";
import type { GetRegionsQueryResponse, GetRegionsQueryParams, GetRegions400, GetRegions500 } from "../types/GetRegions";

export const getRegionsQueryKey = (params?: GetRegionsQueryParams) =>     [{url:`/api/regions`},...(params ? [params] : [])] as const;
      export function getRegionsQueryOptions <TData = GetRegionsQueryResponse, TError = GetRegions400 | GetRegions500>(params?: GetRegionsQueryParams, options: Partial<Parameters<typeof client>[0]> = {}): UseQueryOptions<TData, TError> {
        const queryKey = getRegionsQueryKey(params);

        return {
          queryKey,
          queryFn: () => {
            
            return client<TData, TError>({
              method: "get",
              url: `/api/regions`,
              params,
              
              ...options,
            }).then(res => res.data);
          },
        };
      };
      
/**
 * @summary Получение регионов
 * @link /api/regions
 */

export function useGetRegions <TData = GetRegionsQueryResponse, TError = GetRegions400 | GetRegions500>(params?: GetRegionsQueryParams, options: {
          query?: UseQueryOptions<TData, TError>,
          client?: Partial<Parameters<typeof client<TData, TError>>[0]>,
        } = {}): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const { query: queryOptions, client: clientOptions = {} } = options ?? {};
  const queryKey = queryOptions?.queryKey ?? getRegionsQueryKey(params);

  const query = useQuery<TData, TError>({
    ...getRegionsQueryOptions<TData, TError>(params, clientOptions),
    ...queryOptions
  }) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey as QueryKey;

  return query;
};
