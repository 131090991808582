import { createAction } from '@reduxjs/toolkit'
import type { Details } from 'express-useragent'
import type { IApplicationTokens } from '@/interfaces'
import type { IAnalyticsInformation, IAppEnv } from './interfaces'

export const onAppMountWithEpics = createAction('@@app/MOUNT')

export const setUserLastActivityTime = createAction('@@app/SET_USER_LAST_ACTIVITY_TIME', () => ({
  payload: { userLastActivityTime: Date.now() },
}))

export const onResize = createAction('@@app/ON_RESIZE')

export const setLocationInfo = createAction<string>('@@app/SET_LOCATION_INFO')

export const setUserAgentPayload = createAction<Details | undefined>('@@app/SET_USER_AGENT_PAYLOAD')

export const setServerRenderError = createAction<undefined>('@@app/SET_SERVER_RENDER_ERROR')

export const setApiUrl = createAction<string>('@@app/SET_API_URL')

export const setAppTokens = createAction<IApplicationTokens>('@@app/SET_APP_TOKENS')

export const setAnalyticsInformation = createAction<IAnalyticsInformation>('@@app/SET_ANALYTICS_INFORMATION')

export const setAppEnv = createAction<IAppEnv>('@@app/SET_APP_ENV')
