import type { UseMutationOptions, UseMutationResult } from "@tanstack/react-query";
import { useMutation } from "@tanstack/react-query";
import client from "@/api/client/auctions";
import type { ResponseConfig } from "@/api/client/auctions";
import type { FindForLkMutationRequest, FindForLkMutationResponse, FindForLkQueryParams, FindForLk400, FindForLk404 } from "../types/FindForLk";

/**
 * @description Метод предназначен для получения моих списка аукционов с возможностью фильтрации. https://confluence.poleinvest.ru/pages/viewpage.action?pageId=425481866
 * @link /v1/auctions/view_for-lk
 */

export function useFindForLk <TData = FindForLkMutationResponse, TError = FindForLk400 | FindForLk404, TVariables = FindForLkMutationRequest>(params?: FindForLkQueryParams, options: {
          mutation?: UseMutationOptions<ResponseConfig<TData>, TError, TVariables>,
          client?: Partial<Parameters<typeof client<TData, TError, TVariables>>[0]>,
      } = {}): UseMutationResult<ResponseConfig<TData>, TError, TVariables> {
  const { mutation: mutationOptions, client: clientOptions = {} } = options ?? {};

  return useMutation<ResponseConfig<TData>, TError, TVariables>({
    mutationFn: (data) => {
      
      return client<TData, TError, TVariables>({
        method: "post",
        url: `/v1/auctions/view_for-lk`,
        data,
        params,
        
        ...clientOptions
      });
    },
    ...mutationOptions
  });
};
