import client from "@/api/client/axios";
import type { ResponseConfig } from "@/api/client/axios";
import type { GetSaleTemplatesOffersListQueryResponse } from "../../types/GetSaleTemplatesOffersList";

/**
     * @summary Получение списка наборов предложений
     * @link /api/requests/list/templates/offers
     */
export async function getSaleTemplatesOffersList (options: Partial<Parameters<typeof client>[0]> = {}): Promise<ResponseConfig<GetSaleTemplatesOffersListQueryResponse>["data"]> {
    const { data: resData } = await client<GetSaleTemplatesOffersListQueryResponse>({
        method: "get",
        url: `/api/requests/list/templates/offers`,
        ...options
    });
    
    return resData;
};