import client from "@/api/client/axios";
import type { ResponseConfig } from "@/api/client/axios";
import type { GetOrganizationProfileV2QueryResponse } from "../../types/GetOrganizationProfileV2";

/**
     * @summary Получить профиль организации
     * @link /api/v2/lk/organization
     */
export async function getOrganizationProfileV2 (options: Partial<Parameters<typeof client>[0]> = {}): Promise<ResponseConfig<GetOrganizationProfileV2QueryResponse>["data"]> {
    const { data: resData } = await client<GetOrganizationProfileV2QueryResponse>({
        method: "get",
        url: `/api/v2/lk/organization`,
        ...options
    });
    
    return resData;
};