import type { IOrganizationProfile } from '@/logic/auth/interfaces'

export const emptyOrganizationProfile: IOrganizationProfile = {
  organization: {
    inn: null,
    ogrn: null,
    kpp: null,
    okved: null,
    okpo: null,
    director: null,
    address: null,
    name: null,
    additionalOkveds: [],
    state: null,
    deliveryBasesData: [],
    capitalValue: null,
    financeYear: null,
    financeIncome: null,
  },
  additionalInformation: {
    employees: null,
    activity: null,
  },
}
