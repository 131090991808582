import loadable from '@loadable/component'

export { default as AccreditationDocumentCard } from './AccreditationDocumentCard'
export { default as AccreditationTypedDocumentCard } from './AccreditationTypedDocumentCard'
export { default as ArchiveCard } from './ArchiveCard'
export { default as UploadArchiveBanner } from './UploadArchiveBanner'
export { default as ConfirmationBlock } from './ConfirmationBlock'
export { default as SignAccreditationBanner } from './SignAccreditationBanner'
export { default as AccreditationBanner } from './AccreditationBanner'
export { default as DocumentsResubmissionBanner } from './DocumentsResubmissionBanner'
export { default as RejectedAccreditationBanner } from './RejectedAccreditationBanner'
export { default as LogisticsInfoAccreditationBanner } from './LogisticsInfoAccreditationBanner'
export { default as AccreditationDocumentCardRender } from './AccreditationDocumentCardRender'
export { default as AccreditationDocumentTitle } from './AccreditationDocumentTitle'
export { default as AccreditationUkepRequestBanner } from './AccreditationUkepRequestBanner'

export const UploadingArchiveModal = loadable(
  /* webpackChunkName: "UploadingArchiveModal" */
  /* webpackPrefetch: "true" */
  /* #__LOADABLE__ */
  () => import('./UploadingArchiveModal'),
)

export const SigningAccreditationModal = loadable(
  /* webpackChunkName: "SigningAccreditationModal" */
  /* webpackPrefetch: "true" */
  /* #__LOADABLE__ */
  () => import('./SigningAccreditationModal'),
)

export const CompletedAccreditationBanner = loadable(
  /* webpackChunkName: "CompletedAccreditationBanner" */
  /* webpackPrefetch: "true" */
  /* #__LOADABLE__ */
  () => import('./CompletedAccreditationBanner'),
)

export const AccreditationDocuments = loadable(
  /* webpackChunkName: "AccreditationDocuments" */
  /* webpackPrefetch: "true" */
  /* #__LOADABLE__ */
  () => import('./AccreditationDocuments'),
)

export const RejectingDocumentModal = loadable(
  /* webpackChunkName: "RejectingDocumentModal" */
  /* webpackPrefetch: "true" */
  /* #__LOADABLE__ */
  () => import('./RejectingDocumentModal'),
)

export const SingDocumentsWithUkepBanner = loadable(
  /* webpackChunkName: "SingDocumentsWithUkepBanner" */
  /* webpackPrefetch: "true" */
  /* #__LOADABLE__ */
  () => import('./SingDocumentsWithUkepBanner'),
)
