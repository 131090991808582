import React from 'react'
import { EIconName } from '@frontend/pole-ui/lib/components/Icon'
import type { ISwiperArrowProps } from '@/components/ui/SwiperArrow/interfaces'
import { Button } from '..'

const SwiperArrow: React.FC<ISwiperArrowProps> = ({ onClick, modifier = 'swiper-white', isDisabled, className, direction }) => (
  <Button
    modifiers={[modifier]}
    onClick={onClick}
    iconProps={{ name: direction === 'right' ? EIconName.ShevronRight : EIconName.ShevronLeft }}
    className={className}
    isDisabled={isDisabled}
  />
)

export default SwiperArrow
