import client from "@/api/client/axios";
import type { ResponseConfig } from "@/api/client/axios";
import type { CreatePartnershipRequestMutationRequest, CreatePartnershipRequestMutationResponse } from "../../types/CreatePartnershipRequest";

/**
     * @summary Создание заявки на партнерство
     * @link /api/partnership/requests
     */
export async function createPartnershipRequest (data?: CreatePartnershipRequestMutationRequest, options: Partial<Parameters<typeof client>[0]> = {}): Promise<ResponseConfig<CreatePartnershipRequestMutationResponse>["data"]> {
    const { data: resData } = await client<CreatePartnershipRequestMutationResponse, CreatePartnershipRequestMutationRequest>({
        method: "post",
        url: `/api/partnership/requests`,
        data,
        ...options
    });
    
    return resData;
};