import React from 'react'
import { FormattedMessage } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { CSSTransition } from 'react-transition-group'
import { CommonLink } from '@frontend/pole-ui/lib/components/CommonLink'
import classNames from 'classnames'
import { Button } from '@/components/ui'
import { getDevice } from '@/logic/app/reducer'
import { setCookieAgreement } from '@/logic/cookieAgreement/actions'
import { CookieAgreementStorage } from '@/logic/cookieAgreement/CookieAgreementStorage'
import { isCookieAgreed } from '@/logic/cookieAgreement/reducer'
import { useData } from '@/logic/data'
import { getLegalDocumentUrl } from '@/logic/legalDocuments/helpers'
import { injectMessages } from '@/logic/translation/utils'
import type { RootState } from '@/redux/interfaces'
import type { TLegalDocuments } from '@/types/legalDocuments'
import { ELegalDocumentType } from '@/types/legalDocuments'
import { useMount } from '@/utils/hooks'
import messages from './messages.json'
import './styles.scss'

injectMessages(messages)

const mapState = (state: RootState) => ({
  device: getDevice()(state),
  isCookieAgreementVisible: !isCookieAgreed()(state),
})

const CookieAgreement: React.FC = () => {
  const { device, isCookieAgreementVisible } = useSelector(mapState)
  const { isiPhone } = device
  const { data: documents = [] } = useData<TLegalDocuments>('legalDocuments')
  const dispatch = useDispatch()
  const setAgreement = (isAgreed: boolean) => dispatch(setCookieAgreement(isAgreed))

  const acceptAgreement = () => {
    CookieAgreementStorage.agree()
    setAgreement(true)
  }

  useMount(() => {
    if (!CookieAgreementStorage.get()) setAgreement(false)
  })

  return (
    <CSSTransition in={isCookieAgreementVisible} timeout={200} classNames="fading" unmountOnExit>
      <section className={classNames('cookie-agreement', { 'cookie-agreement_safari': isiPhone })}>
        <div>
          <h3 className="space-holder4 text_small font_base font-weight_normal">
            <FormattedMessage id="cookie.attention" />
          </h3>

          <p className="cookie-agreement__text text_super-small text_small-sm color_pale-black">
            <FormattedMessage
              id="cookie.text"
              values={{
                registrationRulesLink: (
                  <CommonLink
                    url={getLegalDocumentUrl(documents, ELegalDocumentType.registrationRules)}
                    className="underline color_pale-black link-no-hover"
                  >
                    <FormattedMessage id="cookie.userRegistrationRules.link" />
                  </CommonLink>
                ),
                cookiesPolicyLink: (
                  <CommonLink
                    url={getLegalDocumentUrl(documents, ELegalDocumentType.cookiesPolicy)}
                    className="underline color_pale-black link-no-hover"
                  >
                    <FormattedMessage id="cookie.policy.link" />
                  </CommonLink>
                ),
                registrationPolicyLink: (
                  <CommonLink
                    url={getLegalDocumentUrl(documents, ELegalDocumentType.registrationPolicy)}
                    className="underline color_pale-black link-no-hover"
                  >
                    <FormattedMessage id="cookie.userRegistrationPolicy.link" />
                  </CommonLink>
                ),
              }}
            />
          </p>
        </div>
        <Button
          textId="cookie.accept"
          modifiers={['outline', 'small']}
          onClick={acceptAgreement}
          className="cookie-agreement__button space-holder-left16-from-sm"
        />
      </section>
    </CSSTransition>
  )
}

export default CookieAgreement
