import type { FC } from 'react'
import React from 'react'
import cn from 'classnames'
import type { IStatusMessageProps } from './interfaces'
import './styles.scss'

const StatusMessage: FC<IStatusMessageProps> = props => {
  const { text, className, commentClassName = 'text_small', isRightTriangle } = props

  return text ? (
    <div className={cn(className)}>
      <div
        className={cn('harvestSaleMessageCard__triangle', {
          'harvestSaleMessageCard__triangle--right': isRightTriangle,
          'harvestSaleMessageCard__triangle--left': !isRightTriangle,
        })}
      />
      <div className={cn('harvestSaleMessageCard__comment', commentClassName)}>{text}</div>
    </div>
  ) : null
}

export default StatusMessage
