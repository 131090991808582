import type { QueryKey, UseQueryResult, UseQueryOptions, QueryOptions } from "@tanstack/react-query";
import { useQuery } from "@tanstack/react-query";
import client from "@/api/client/axios";
import type { ViewByOptionsQueryResponse, ViewByOptionsQueryParams, ViewByOptions400, ViewByOptions401, ViewByOptions403, ViewByOptions422, ViewByOptions429, ViewByOptions500 } from "../types/ViewByOptions";

export const viewByOptionsQueryKey = (params?: ViewByOptionsQueryParams) =>     [{url:`/api/credit-flow-backend/v1/funding-products/view_by-options`},...(params ? [params] : [])] as const;
      export function viewByOptionsQueryOptions <TData = ViewByOptionsQueryResponse, TError = ViewByOptions400 | ViewByOptions401 | ViewByOptions403 | ViewByOptions422 | ViewByOptions429 | ViewByOptions500>(params?: ViewByOptionsQueryParams, options: Partial<Parameters<typeof client>[0]> = {}): UseQueryOptions<TData, TError> {
        const queryKey = viewByOptionsQueryKey(params);

        return {
          queryKey,
          queryFn: () => {
            
            return client<TData, TError>({
              method: "get",
              url: `/api/credit-flow-backend/v1/funding-products/view_by-options`,
              params,
              
              ...options,
            }).then(res => res.data);
          },
        };
      };
      
/**
 * @link /api/credit-flow-backend/v1/funding-products/view_by-options
 */

export function useViewByOptions <TData = ViewByOptionsQueryResponse, TError = ViewByOptions400 | ViewByOptions401 | ViewByOptions403 | ViewByOptions422 | ViewByOptions429 | ViewByOptions500>(params?: ViewByOptionsQueryParams, options: {
          query?: UseQueryOptions<TData, TError>,
          client?: Partial<Parameters<typeof client<TData, TError>>[0]>,
        } = {}): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const { query: queryOptions, client: clientOptions = {} } = options ?? {};
  const queryKey = queryOptions?.queryKey ?? viewByOptionsQueryKey(params);

  const query = useQuery<TData, TError>({
    ...viewByOptionsQueryOptions<TData, TError>(params, clientOptions),
    ...queryOptions
  }) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey as QueryKey;

  return query;
};
