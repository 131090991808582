import React from 'react'
import { Button, TextWithIcon, UploadButton } from '@/components/ui'
import type { IDocumentHintProps } from '../interfaces'

const DocumentHint: React.FC<IDocumentHintProps> = ({ iconProps, tooltipId, className, onClick, shouldUploadFile }) => {
  const textWithIcon = (
    <TextWithIcon
      iconProps={iconProps}
      textId={`documentCard.hint.${tooltipId}`}
      textModifier="super-small"
      textClassName="underline underline_black"
    />
  )

  if (shouldUploadFile) {
    return (
      <UploadButton name="uploadDocumentHint" buttonProps={{ isUnstyled: true, className }} onChange={onClick}>
        {textWithIcon}
      </UploadButton>
    )
  }

  return (
    <Button isUnstyled className={className} onClick={onClick}>
      {textWithIcon}
    </Button>
  )
}

export default DocumentHint
