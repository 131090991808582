import type { IAjaxResponse, TUnit } from '@/api/interfaces'

export const kilobyte = 1024
export const megabyte = 1048576
export const isFileSizeCorrected = (fileSize: number, maxFileSize: number): boolean => fileSize / megabyte < maxFileSize

export const isValidFileExtension = <GFileExtension>(fileName: string, acceptedExtensions: GFileExtension[] | undefined): boolean =>
  Array.isArray(acceptedExtensions) ? new RegExp(`.(${acceptedExtensions.join('|')})$`).test(fileName) : true

export const getFileNameFromResponse = <T>(response: IAjaxResponse<T>) => {
  const contentDispositionHeader = response.headers.get('content-disposition')

  if (!contentDispositionHeader) {
    return null
  }

  const fileNameMatch = contentDispositionHeader.match(/filename=(.+?)($|;)/)

  if (!fileNameMatch || !fileNameMatch[1]) {
    return null
  }

  const name = fileNameMatch[1].replace(/"/g, '')

  try {
    return decodeURIComponent(name)
  } catch {
    return null
  }
}

export const downloadBlobFile = (data: Blob, fileName: string) => {
  const url = window.URL.createObjectURL(data)
  const a = document.createElement('a')
  a.href = url
  a.download = decodeURIComponent(fileName)
  document.body.append(a)
  a.click()
  a.remove()
  window.URL.revokeObjectURL(url)
}

export const downloadFileFromResponseBlob = async <T>(
  response: IAjaxResponse<T>,
  responseBlob: Blob,
  fallbackFileName: string,
  tab: Window | null | undefined = undefined,
) => {
  const fileName = getFileNameFromResponse(response) || fallbackFileName

  if (response.headers.get('content-type') === 'application/pdf' && tab) {
    const obj = window.URL.createObjectURL(responseBlob)
    tab.location.href = obj
    tab.document.title = fileName
  } else {
    tab?.close()
    downloadBlobFile(responseBlob, fileName)
  }
}

export const downloadFileFromResponse = async <T>(
  response: IAjaxResponse<T>,
  fallbackFileName: string,
  tab: Window | null | undefined = undefined,
) => {
  const content = await response.blob()

  await downloadFileFromResponseBlob(response, content, fallbackFileName, tab)
}

export const getFileNameFromUrl = (file: File | string) => (typeof file === 'string' ? file.split('/').pop() : '')

export const formatBytes = (bytes: number, rawDecimals: number = 2): [number, TUnit] => {
  if (!bytes || isNaN(bytes)) return [0, 'Bytes']

  const decimals = rawDecimals < 0 ? 0 : rawDecimals
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

  const positionOfUnit = Math.floor(Math.log(bytes) / Math.log(kilobyte))

  return [parseFloat((bytes / kilobyte ** positionOfUnit).toFixed(decimals)), sizes[positionOfUnit] as TUnit]
}
