import type { PayloadAction } from '@reduxjs/toolkit'
import { push } from 'connected-react-router'
import { combineEpics } from 'redux-observable'
import { catchError, concat, filter, from, mergeMap, of, switchMap, throwError } from 'rxjs'
import { isStatusOk } from '@/api/helpers'
import type { IResponse } from '@/api/interfaces'
import { fetchHistory } from '@/components/composed/History/actions'
import { closeModal, openModal } from '@/components/composed/Modal/actions'
import type { IMessageModalOptions } from '@/components/composed/modals/MessageModal/interfaces'
import { isUserAgrarian } from '@/logic/auth/reducer'
import {
  changeHarvestSaleRequest,
  changeHarvestSaleRequestStatus,
  loadHarvestSaleMultiRequest,
  loadHarvestSaleRequest,
  rejectHarvestSaleRequest,
} from '@/logic/harvestSaleRequest/actions'
import { ERequestOfHarvestSaleRequestName } from '@/logic/harvestSaleRequest/constants'
import type {
  IChangeHarvestSaleRequestPayload,
  IChangeHarvestSaleRequestStatusPayload,
  IRejectHarvestSaleRequestPayload,
} from '@/logic/harvestSaleRequest/interfaces'
import { getHarvestSaleMultiRequest, getHarvestSaleRequest } from '@/logic/harvestSaleRequest/reducer'
import type { Epic } from '@/logic/interfaces'
import { openNotUploadedDocumentsModal, openRequiredFieldsModal } from '@/logic/profile/helpers'
import { requestFailed, requestFinished, requestStarted } from '@/logic/requestStatus/actions'
import type { IRequestStatusPayload } from '@/logic/requestStatus/interfaces'
import { injectMessages } from '@/logic/translation/utils'
import { getSaleRequestType } from '@/pages/Profiles/reducer'
import { HarvestSaleMultiRequestService, HarvestSaleRequestService } from '@/services/HarvestSaleRequestService'
import { EHarvestSaleRequestMetaStatus } from '@/services/HarvestSaleRequestService/constants'
import { EUserProfileRoute } from '@/types'
import { ESaleRequestType } from '@/types/ESaleRequestType'
import { EHarvestSaleRequestStatus } from '@/types/HarvestSaleRequest'
import { EHistoryEntityType } from '@/types/History'
import { createRouterLink } from '@/utils/url'
import messages from './messages.json'

injectMessages(messages)

const HarvestSaleRequestServices = {
  [ESaleRequestType.SALEREQUEST]: new HarvestSaleRequestService(),
  [ESaleRequestType.MULTIREQUEST]: new HarvestSaleMultiRequestService(),
}

const GetHarvestSaleRequest = {
  [ESaleRequestType.SALEREQUEST]: getHarvestSaleRequest,
  [ESaleRequestType.MULTIREQUEST]: getHarvestSaleMultiRequest,
}

const LoadHarvestSaleRequest = {
  [ESaleRequestType.SALEREQUEST]: loadHarvestSaleRequest,
  [ESaleRequestType.MULTIREQUEST]: loadHarvestSaleMultiRequest,
}

const HistoryEntityType = {
  [ESaleRequestType.SALEREQUEST]: EHistoryEntityType.REQUESTS,
  [ESaleRequestType.MULTIREQUEST]: EHistoryEntityType.MULTIREQUEST,
}

//eslint-disable-next-line @typescript-eslint/no-explicit-any
const isRequestAlreadyChanged = ({ meta }: IResponse<any>) => meta.status === EHarvestSaleRequestMetaStatus.SALE_REQUEST_CHANGED

const openNotificationByUpdatingRequest = () =>
  openModal({
    options: {
      dialogId: 'MessageModal',
      headerId: 'agrarianHarvestSaleRequest.changingStatusFailure.title',
    },
    contentOptions: {
      messages: [{ id: 'agrarianHarvestSaleRequest.changingStatusFailure.text' }],
      buttons: [
        {
          textId: 'agrarianHarvestSaleRequest.changingStatusFailure.acceptButton',
          modifiers: ['green', 'full-width'],
          className: 'space-holder-top32',
        },
      ],
    } as IMessageModalOptions,
  })

const onChangeHarvestSaleRequestDoMakeRequest: Epic<IChangeHarvestSaleRequestPayload, typeof changeHarvestSaleRequest> = (
  action$,
  state$,
) =>
  action$.pipe(
    filter(changeHarvestSaleRequest.match),
    switchMap(({ payload }) => {
      const { value: state } = state$
      const saleRequestType = getSaleRequestType()(state)
      const { changedValues, statusComment, onSuccessChanging } = payload
      const requestStatusPayload: IRequestStatusPayload = { name: ERequestOfHarvestSaleRequestName.CHANGE }
      const { id, updateDate: version } = GetHarvestSaleRequest[saleRequestType](state) || {}

      if (!id || !version) {
        return of(requestFailed(requestStatusPayload))
      }

      return concat(
        of(requestStarted(requestStatusPayload)),
        from(
          HarvestSaleRequestServices[saleRequestType].changeHarvestSaleRequest({
            requestId: id,
            version,
            changedValues,
            statusComment,
          }),
        ).pipe(
          mergeMap(response => {
            if (isRequestAlreadyChanged(response)) return [openNotificationByUpdatingRequest(), LoadHarvestSaleRequest[saleRequestType](id)]

            if (isStatusOk(response)) {
              return [requestFinished(requestStatusPayload), LoadHarvestSaleRequest[saleRequestType](id), onSuccessChanging]
            }

            return throwError(response)
          }),
          catchError(({ meta, data }) => {
            //eslint-disable-next-line @typescript-eslint/no-explicit-any
            const actions: PayloadAction<any>[] = [requestFailed(requestStatusPayload)]

            switch (meta?.status) {
              case EHarvestSaleRequestMetaStatus.REQUIRED_DOCUMENTS_NOT_UPLOAD:
                actions.push(openNotUploadedDocumentsModal(data))
                break
              case EHarvestSaleRequestMetaStatus.REQUEST_FIELDS_REQUIRED:
                actions.push(openRequiredFieldsModal(data))
                break
              default:
            }

            return actions
          }),
        ),
      )
    }),
  )

const onRejectHarvestSaleRequestDoMakeRequest: Epic<IRejectHarvestSaleRequestPayload, typeof rejectHarvestSaleRequest> = (
  action$,
  state$,
) =>
  action$.pipe(
    filter(rejectHarvestSaleRequest.match),
    mergeMap(({ payload }) => {
      const { redirectUrl, statusComment } = payload
      const { value: state } = state$
      const saleRequestType = getSaleRequestType()(state)
      const requestStatusPayload: IRequestStatusPayload = { name: ERequestOfHarvestSaleRequestName.REJECT }
      const { id, updateDate: version } = GetHarvestSaleRequest[saleRequestType](state) || {}

      if (!id || !version) {
        return of(requestFailed(requestStatusPayload))
      }

      return concat(
        of(requestStarted(requestStatusPayload)),
        from(HarvestSaleRequestServices[saleRequestType].deleteHarvestSaleRequest({ requestId: id, version, statusComment })).pipe(
          mergeMap(response => {
            if (isRequestAlreadyChanged(response)) return [openNotificationByUpdatingRequest(), LoadHarvestSaleRequest[saleRequestType](id)]

            if (isStatusOk(response)) return [push(redirectUrl), requestFinished(requestStatusPayload), closeModal()]

            return throwError(response)
          }),
          catchError(() => of(requestFailed(requestStatusPayload))),
        ),
      )
    }),
  )

const onChangeHarvestSaleRequestStatusDoMakeRequest: Epic<IChangeHarvestSaleRequestStatusPayload, typeof changeHarvestSaleRequestStatus> = (
  action$,
  state$,
) =>
  action$.pipe(
    filter(changeHarvestSaleRequestStatus.match),
    mergeMap(({ payload }) => {
      //@ts-ignore
      const { statusComment, rejectHeaderId, organizationBankAccounts } = payload
      const { value: state } = state$
      const saleRequestType = getSaleRequestType()(state)
      const isAgrarian = isUserAgrarian()(state)
      const requestStatusPayload: IRequestStatusPayload = { name: ERequestOfHarvestSaleRequestName.CHANGE_STATUS }
      const { id, updateDate: version } = GetHarvestSaleRequest[saleRequestType](state) || {}

      if (!id || !version) {
        return of(requestFailed(requestStatusPayload))
      }

      return concat(
        of(requestStarted(requestStatusPayload)),
        from(
          HarvestSaleRequestServices[saleRequestType].changeHarvestSaleRequestStatus({
            requestId: id,
            version,
            statusComment,
            organizationBankAccounts,
          }),
        ).pipe(
          mergeMap(response => {
            if (isRequestAlreadyChanged(response)) {
              return [
                requestFinished(requestStatusPayload),
                LoadHarvestSaleRequest[saleRequestType](id),
                openNotificationByUpdatingRequest(),
              ]
            }

            if (isStatusOk(response)) {
              const { saleRequestId, status } = response.data
              const statusId = status.id

              if (isAgrarian && statusId === EHarvestSaleRequestStatus.SALE_REQUEST_CREATED) {
                return [
                  requestFinished(requestStatusPayload),
                  loadHarvestSaleRequest(saleRequestId),
                  fetchHistory({ entityId: saleRequestId, entity: EHistoryEntityType.REQUESTS }),
                  push(
                    createRouterLink(EUserProfileRoute.agrarianSale, {
                      requestId: saleRequestId,
                      saleRequestType: ESaleRequestType.SALEREQUEST,
                    }),
                  ),
                ]
              }

              return [
                requestFinished(requestStatusPayload),
                LoadHarvestSaleRequest[saleRequestType](id),
                fetchHistory({ entityId: id, entity: HistoryEntityType[saleRequestType] }),
              ]
            }

            return throwError(response)
          }),
          catchError(({ meta, data }) => {
            //eslint-disable-next-line @typescript-eslint/no-explicit-any
            const actions: PayloadAction<any>[] = [requestFailed(requestStatusPayload)]

            switch (meta?.status) {
              case EHarvestSaleRequestMetaStatus.REQUIRED_DOCUMENTS_NOT_UPLOAD:
                actions.push(openNotUploadedDocumentsModal(data))
                break
              case EHarvestSaleRequestMetaStatus.REQUEST_FIELDS_REQUIRED:
                actions.push(openRequiredFieldsModal(data, rejectHeaderId))
                break
              default:
            }

            return actions
          }),
        ),
      )
    }),
  )
//eslint-disable-next-line @typescript-eslint/no-explicit-any
export default combineEpics<any, any, any>(
  onRejectHarvestSaleRequestDoMakeRequest,
  onChangeHarvestSaleRequestDoMakeRequest,
  onChangeHarvestSaleRequestStatusDoMakeRequest,
)
