import client from "@/api/client/axios";
import type { ResponseConfig } from "@/api/client/axios";
import type { GetTagsQueryResponse, GetTagsQueryParams } from "../../types/GetTags";

/**
     * @link /api/ms-content-infoportal-backend/v1/tags
     */
export async function getTags (params?: GetTagsQueryParams, options: Partial<Parameters<typeof client>[0]> = {}): Promise<ResponseConfig<GetTagsQueryResponse>["data"]> {
    const { data: resData } = await client<GetTagsQueryResponse>({
        method: "get",
        url: `/api/ms-content-infoportal-backend/v1/tags`,
        params,
        ...options
    });
    
    return resData;
};