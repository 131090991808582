export enum EHarvestQualityInputsType {
  GENERAL = 'GENERAL',
  ADDITIONAL_PARAMS = 'ADDITIONAL_PARAMS',
}

export enum EQualityIndicatorsId {
  ALL = 'ALL',
  PROTEIN_FROM = 'proteinFrom',
  HARVEST_YEAR = 'harvestYear',
}
