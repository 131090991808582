import { useCallback, useState } from 'react'
import type { TIntersectedValue } from '@frontend/pole-ui/lib/common/types'
import { useDeviceType } from '@/utils/hooks/useDeviceType'
import type { IUseBlockIntersectionOptions, IUseBlockIntersectionResult } from './interfaces'

export const useBlockIntersection = (options?: IUseBlockIntersectionOptions): IUseBlockIntersectionResult => {
  const { shouldKeepIntersection, threshold = 0.5 } = options || {}

  const { isMobile } = useDeviceType()

  const [isBlockIntersected, setBlockIntersection] = useState<TIntersectedValue>(isMobile ? undefined : 'false')

  const blockRef = useCallback(
    (node: HTMLElement | null) => {
      if (!node) return undefined

      const observer = new IntersectionObserver(
        ([entity]) => {
          const { isIntersecting } = entity || {}
          setBlockIntersection(isIntersecting ? 'true' : 'false')
          if (isIntersecting && !shouldKeepIntersection) observer.disconnect()
        },
        { threshold },
      )

      observer.observe(node)

      return () => observer.disconnect()
    },
    [shouldKeepIntersection, threshold],
  )

  return {
    isBlockIntersected,
    blockRef,
  }
}
