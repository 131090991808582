import { useEffect } from 'react'

export const useEffectOnMissingClickByBlock = (blockClass: string, callback: () => void) => {
  useEffect(() => {
    const eventHandler = (event: MouseEvent) => {
      const target = event.target as HTMLElement
      const { className } = target

      if ((typeof className !== 'string' || !className.includes(blockClass)) && !target.closest(`.${blockClass}`)) {
        callback()
      }
    }

    document.body.addEventListener('click', eventHandler)

    return () => document.body.removeEventListener('click', eventHandler)
    //eslint-disable-next-line
  }, [])
}
