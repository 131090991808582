import merge from 'lodash/merge'
import omit from 'lodash/omit'
import { combineEpics } from 'redux-observable'
import { concat, EMPTY, of, throwError } from 'rxjs'
import { catchError, filter, mergeMap, switchMap } from 'rxjs/operators'
import { isStatusOk } from '@/api/helpers'
import request from '@/api/request'
import { ELkApiUrl } from '@/api/urls'
import type { IUserOrganizationData } from '@/types'
import { loadingDataFailed, loadingDataFinished, loadingDataStarted } from '../data/actions'
import { getInitialOrganizationData } from '../data/constants'
import type { Epic } from '../interfaces'
import type { NAction } from './actions'
import {
  changeOrganizationData,
  changeUserProfile,
  fetchUserProfile,
  profileRequestFailed,
  profileRequestFinished,
  profileRequestStarted,
  setUserProfile,
  uploadPhoto,
} from './actions'
import { initialState } from './reducer'

const onUploadProfilePhotoDoMakeRequest: Epic<File, typeof uploadPhoto> = action$ =>
  action$.pipe(
    filter(uploadPhoto.match),
    mergeMap(({ payload }) => {
      const formData = new FormData()
      formData.append('Image', payload)

      return request({
        url: ELkApiUrl.avatar,
        method: 'PUT',
        body: formData,
        isJson: false,
      }).pipe(
        mergeMap(response => (isStatusOk(response) ? of(fetchUserProfile()) : throwError(null))),
        catchError(() => EMPTY),
      )
    }),
  )

const onFetchUserProfileDoMakeRequest: Epic<NAction.ISetUserProfilePayload, typeof fetchUserProfile> = action$ =>
  action$.pipe(
    filter(fetchUserProfile.match),
    mergeMap(() =>
      concat(
        of(profileRequestStarted()),
        request<NAction.ISetUserProfilePayload>({
          url: ELkApiUrl.profile,
          method: 'GET',
        }).pipe(
          mergeMap(response => {
            if (!isStatusOk(response)) return throwError(response)
            const { data } = response

            return [setUserProfile(data), profileRequestFinished()]
          }),
          catchError(() => of(profileRequestFailed())),
        ),
      ),
    ),
  )

const onChangeUserProfileDoMakeRequest: Epic<NAction.IChangeUserProfilePayload, typeof changeUserProfile> = action$ =>
  action$.pipe(
    filter(changeUserProfile.match),
    switchMap(({ payload: { formValues } }) => {
      const updateFormValues =
        formValues.profile.email === '' ? omit(formValues.profile, ['email', 'photo']) : omit(formValues.profile, ['photo'])

      const parameters = {
        profile: { ...omit(initialState.profile, ['photo', 'email']), ...updateFormValues },
        ...omit(formValues, ['profile']),
      }

      return concat(
        of(profileRequestStarted()),
        request<NAction.ISetUserProfilePayload>({
          url: ELkApiUrl.profile,
          method: 'PATCH',
          body: parameters,
        }).pipe(
          mergeMap(response => {
            if (!isStatusOk(response)) return throwError(response)

            return [setUserProfile(response.data), profileRequestFinished()]
          }),
          catchError(() => of(profileRequestFailed())),
        ),
      )
    }),
  )

const onChangeOrganizationDataDoMakeRequest: Epic<NAction.IChangeOrganizationDataPayload, typeof changeOrganizationData> = action$ =>
  action$.pipe(
    filter(changeOrganizationData.match),
    switchMap(({ payload }) => {
      return concat(
        of(loadingDataStarted({ name: 'userOrganization' })),
        request<IUserOrganizationData>({
          url: ELkApiUrl.organization,
          method: 'PATCH',
          body: merge(getInitialOrganizationData(), payload.formValues),
        }).pipe(
          mergeMap(response => {
            if (!isStatusOk(response)) return throwError(response)

            return [loadingDataFinished({ data: merge(getInitialOrganizationData(), response.data), name: 'userOrganization' })]
          }),
          catchError(({ meta }) => of(loadingDataFailed({ name: 'userOrganization', meta }))),
        ),
      )
    }),
  )

//eslint-disable-next-line @typescript-eslint/no-explicit-any
export default (<any>combineEpics)(
  onFetchUserProfileDoMakeRequest,
  onChangeUserProfileDoMakeRequest,
  onChangeOrganizationDataDoMakeRequest,
  onUploadProfilePhotoDoMakeRequest,
)
