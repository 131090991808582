import type { QueryKey, UseQueryResult, UseQueryOptions, QueryOptions } from "@tanstack/react-query";
import { useQuery } from "@tanstack/react-query";
import client from "@/api/client/axios";
import type { GetSaleRequestBankAccountsQueryResponse, GetSaleRequestBankAccountsPathParams, GetSaleRequestBankAccounts400, GetSaleRequestBankAccounts404, GetSaleRequestBankAccounts500 } from "../types/GetSaleRequestBankAccounts";

export const getSaleRequestBankAccountsQueryKey = (requestId: GetSaleRequestBankAccountsPathParams["requestId"]) =>     [{url:`/api/salerequests/${requestId}/bank-accounts`,params:{requestId:requestId}}] as const;
      export function getSaleRequestBankAccountsQueryOptions <TData = GetSaleRequestBankAccountsQueryResponse, TError = GetSaleRequestBankAccounts400 | GetSaleRequestBankAccounts404 | GetSaleRequestBankAccounts500>(requestId: GetSaleRequestBankAccountsPathParams["requestId"], options: Partial<Parameters<typeof client>[0]> = {}): UseQueryOptions<TData, TError> {
        const queryKey = getSaleRequestBankAccountsQueryKey(requestId, );

        return {
          queryKey,
          queryFn: () => {
            
            return client<TData, TError>({
              method: "get",
              url: `/api/salerequests/${requestId}/bank-accounts`,
              
              
              ...options,
            }).then(res => res.data);
          },
        };
      };
      
/**
 * @summary Получение счетов заявки
 * @link /api/salerequests/:requestId/bank-accounts
 */

export function useGetSaleRequestBankAccounts <TData = GetSaleRequestBankAccountsQueryResponse, TError = GetSaleRequestBankAccounts400 | GetSaleRequestBankAccounts404 | GetSaleRequestBankAccounts500>(requestId: GetSaleRequestBankAccountsPathParams["requestId"], options: {
          query?: UseQueryOptions<TData, TError>,
          client?: Partial<Parameters<typeof client<TData, TError>>[0]>,
        } = {}): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const { query: queryOptions, client: clientOptions = {} } = options ?? {};
  const queryKey = queryOptions?.queryKey ?? getSaleRequestBankAccountsQueryKey(requestId, );

  const query = useQuery<TData, TError>({
    ...getSaleRequestBankAccountsQueryOptions<TData, TError>(requestId, clientOptions),
    ...queryOptions
  }) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey as QueryKey;

  return query;
};
