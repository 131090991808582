import type { QueryKey, UseQueryResult, UseQueryOptions, QueryOptions } from "@tanstack/react-query";
import { useQuery } from "@tanstack/react-query";
import client from "@/api/client/axios";
import type { GetTags1QueryResponse, GetTags1QueryParams } from "../types/GetTags1";

export const getTags_1QueryKey = (params?: GetTags1QueryParams) =>     [{url:`/api/ms-content-infoportal-backend/v1/admin/tags`},...(params ? [params] : [])] as const;
      export function getTags_1QueryOptions <TData = GetTags1QueryResponse, TError = unknown>(params?: GetTags1QueryParams, options: Partial<Parameters<typeof client>[0]> = {}): UseQueryOptions<TData, TError> {
        const queryKey = getTags_1QueryKey(params);

        return {
          queryKey,
          queryFn: () => {
            
            return client<TData, TError>({
              method: "get",
              url: `/api/ms-content-infoportal-backend/v1/admin/tags`,
              params,
              
              ...options,
            }).then(res => res.data);
          },
        };
      };
      
/**
 * @link /api/ms-content-infoportal-backend/v1/admin/tags
 */

export function useGetTags1 <TData = GetTags1QueryResponse, TError = unknown>(params?: GetTags1QueryParams, options: {
          query?: UseQueryOptions<TData, TError>,
          client?: Partial<Parameters<typeof client<TData, TError>>[0]>,
        } = {}): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const { query: queryOptions, client: clientOptions = {} } = options ?? {};
  const queryKey = queryOptions?.queryKey ?? getTags_1QueryKey(params);

  const query = useQuery<TData, TError>({
    ...getTags_1QueryOptions<TData, TError>(params, clientOptions),
    ...queryOptions
  }) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey as QueryKey;

  return query;
};
