import client from "@/api/client/auctions";
import type { ResponseConfig } from "@/api/client/auctions";
import type { GetAuctionQueryResponse, GetAuctionQueryParams } from "../../types/GetAuction";

/**
     * @description Метод предназначен для получения данных аукциона по ID аукциона. https://confluence.poleinvest.ru/pages/viewpage.action?pageId=425482468
    
     * @link /v1/auction
     */
export async function getAuction (params: GetAuctionQueryParams, options: Partial<Parameters<typeof client>[0]> = {}): Promise<ResponseConfig<GetAuctionQueryResponse>["data"]> {
    const { data: resData } = await client<GetAuctionQueryResponse>({
        method: "get",
        url: `/v1/auction`,
        params,
        ...options
    });
    
    return resData;
};