import type { FC } from 'react'
import React from 'react'
import classNames from 'classnames'
import type { ITemplate, ITemplatePrice } from '@/types/HarvestSaleLots'
import { LotPrice } from './components'
import { templatePriceVariants } from './constants'
import { getEmptyPriceHintMessageId } from './helpers'
import type { ILotPricesProps } from './interfaces'

const LotPrices: FC<ILotPricesProps> = props => {
  const { template, isAgrarian, isStoreChosen, isMobileDevice, className, perUnitTextId, displayVariant } = props

  return (
    <div className={classNames('row', className)}>
      {templatePriceVariants.map(priceVariant => {
        const templatePrice = template[priceVariant as keyof ITemplate] as ITemplatePrice

        if (priceVariant !== 'fob') {
          return (
            <LotPrice
              key={priceVariant}
              price={templatePrice}
              priceVariant={priceVariant}
              hintMessageId={getEmptyPriceHintMessageId({
                price: templatePrice,
                priceVariant,
                isAgrarian,
                isStoreChosen,
                buyer: template.buyer,
              })}
              isMobileDevice={isMobileDevice}
              perUnitTextId={perUnitTextId}
              displayVariant={displayVariant}
            />
          )
        }

        return undefined
      })}
    </div>
  )
}

export default LotPrices
