import React from 'react'
import { FormattedMessage } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { CommonLink } from '@frontend/pole-ui/lib/components/CommonLink'
import classNames from 'classnames'
import { Image } from '@/components/ui'
import { isUserSupplier } from '@/logic/auth/reducer'
import { injectMessages } from '@/logic/translation/utils'
import type { RootState } from '@/redux/interfaces'
import { openSupplierNoticeModal } from './helpers'
import type { IProductSupplierProps } from './interfaces'
import messages from './messages.json'
import './styles.scss'

injectMessages(messages)

const mapState = (state: RootState) => ({
  isSupplier: isUserSupplier()(state),
})

const ProductSupplier: React.FC<IProductSupplierProps> = props => {
  const { className, linkClassName, isInline, supplier, supplierStore } = props
  const { isSupplier } = useSelector(mapState)
  const dispatch = useDispatch()
  if (!supplier) return null

  const { name, image, id } = supplier
  let url
  // TODO добавить ссылку, когда дойдёт дело до разработки информации о поставщиках
  if (id) url = '' //`/supplier/${id}`

  return (
    <div
      className={classNames(
        'product-supplier row row_nowrap row-align_center',
        className,
        isInline ? 'product-supplier_inline' : 'row_column',
      )}
    >
      <Image src={image} className={classNames('product-supplier__logo', { 'product-supplier__logo_inline': isInline })} hideIfEmpty />
      <CommonLink
        url={url}
        className={classNames('text_super-small text_center', linkClassName)}
        onClick={() => dispatch(openSupplierNoticeModal(supplierStore, supplier))}
      >
        <FormattedMessage id={`productSupplier.name.${isSupplier ? 'client' : 'supplier'}`} values={{ name }} />
      </CommonLink>
    </div>
  )
}
export default ProductSupplier
