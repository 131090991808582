import type { ValidationErrorResponse } from "./ValidationErrorResponse";
import type { ErrorResponse } from "./ErrorResponse";
import type { FundingProducts } from "./FundingProducts";

export enum ViewByOptionsQueryParamsProcessingSpeed {
    "ONLINE" = "ONLINE",
    "UP_TO_SEVEN_DAYS" = "UP_TO_SEVEN_DAYS"
}
export enum ViewByOptionsQueryParamsOptions {
    "WITHOUT_MONTHLY_PAYMENTS" = "WITHOUT_MONTHLY_PAYMENTS",
    "EARLY_REPAYMENT" = "EARLY_REPAYMENT",
    "CROP_REPAYMENT" = "CROP_REPAYMENT"
}
export type ViewByOptionsQueryParams = {
    /**
     * @type integer | undefined int32
    */
    regionCode?: number;
    /**
     * @type array | undefined
    */
    fundingPurpose?: string[];
    /**
     * @type integer | undefined int32
    */
    requiredAmount?: number;
    /**
     * @type integer | undefined int32
    */
    loanPeriod?: number;
    /**
     * @type string | undefined
    */
    processingSpeed?: ViewByOptionsQueryParamsProcessingSpeed;
    /**
     * @type array | undefined
    */
    options?: ViewByOptionsQueryParamsOptions[];
};

/**
 * @description Bad Request
*/
export type ViewByOptions400 = ValidationErrorResponse;

/**
 * @description Unauthorized
*/
export type ViewByOptions401 = ErrorResponse;

/**
 * @description Forbidden
*/
export type ViewByOptions403 = ErrorResponse;

/**
 * @description Unprocessable Entity
*/
export type ViewByOptions422 = ValidationErrorResponse;

/**
 * @description Too Many Requests
*/
export type ViewByOptions429 = ErrorResponse;

/**
 * @description Internal Server Error
*/
export type ViewByOptions500 = ErrorResponse;

/**
 * @description OK
*/
export type ViewByOptionsQueryResponse = FundingProducts;
