export enum ECommonApiUrl {
  stores = '/api/stores',
  clients = '/api/clients',
  elevators = '/api/elevators',
  myChats = '/api/chats',
  regions = '/api/regions',
  banners = '/api/banners',
  accreditation = '/api/accreditations',
  accreditationSearch = '/api/accreditations/search',
  harvest = '/api/harvest',
  fundingRequests = '/api/funding',
  fundingRequestsLost = '/api/funding/lost',
  fundingRegionRequests = '/api/funding/region',
  fundingLoanProducts = '/api/credit-flow-backend/v1/get_by_options-funding-product',
  installmentCalculator = '/api/credit-flow-backend/v1/installment/get_calculate-payment-amount',
  config = '/api/config',
  configs = '/api/configs',
  legalDocuments = '/api/documents/legal',
  feedback = '/api/feedback',
  searchSuggestions = '/api/search/suggests',
  statusModel = '/cc/statusModel',
  cities = '/api/cities',
  loadingMethods = '/api/warehouse/agrarian/loading/methods',
  workingDays = '/api/warehouse/agrarian/working/days',
  buyersAndPrices = '/api/culture/market/info/cultureId/:cultureId',
  agrarianWarehouses = '/api/warehouse/agrarian',
  organisationDocuments = '/api/lk/organization/documents',
  searchAgrarians = '/api/search/agrarians',
  searchaArariansOrganizations = '/api/search/accreditation-agrarians',
  deliveryCount = '/api/logistic/calculations',
  entityStatus = '/api/entitystatus',
  agrariansSearch = '/api/v2/search/agrarians',
}
