import type { QueryKey, UseQueryResult, UseQueryOptions, QueryOptions } from "@tanstack/react-query";
import { useQuery } from "@tanstack/react-query";
import client from "@/api/client/axios";
import type { GetSaleTemplatesOffersListQueryResponse, GetSaleTemplatesOffersList400, GetSaleTemplatesOffersList404, GetSaleTemplatesOffersList500 } from "../types/GetSaleTemplatesOffersList";

export const getSaleTemplatesOffersListQueryKey = () =>     [{url:`/api/requests/list/templates/offers`}] as const;
      export function getSaleTemplatesOffersListQueryOptions <TData = GetSaleTemplatesOffersListQueryResponse, TError = GetSaleTemplatesOffersList400 | GetSaleTemplatesOffersList404 | GetSaleTemplatesOffersList500>(options: Partial<Parameters<typeof client>[0]> = {}): UseQueryOptions<TData, TError> {
        const queryKey = getSaleTemplatesOffersListQueryKey();

        return {
          queryKey,
          queryFn: () => {
            
            return client<TData, TError>({
              method: "get",
              url: `/api/requests/list/templates/offers`,
              
              
              ...options,
            }).then(res => res.data);
          },
        };
      };
      
/**
 * @summary Получение списка наборов предложений
 * @link /api/requests/list/templates/offers
 */

export function useGetSaleTemplatesOffersList <TData = GetSaleTemplatesOffersListQueryResponse, TError = GetSaleTemplatesOffersList400 | GetSaleTemplatesOffersList404 | GetSaleTemplatesOffersList500>(options: {
          query?: UseQueryOptions<TData, TError>,
          client?: Partial<Parameters<typeof client<TData, TError>>[0]>,
        } = {}): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const { query: queryOptions, client: clientOptions = {} } = options ?? {};
  const queryKey = queryOptions?.queryKey ?? getSaleTemplatesOffersListQueryKey();

  const query = useQuery<TData, TError>({
    ...getSaleTemplatesOffersListQueryOptions<TData, TError>(clientOptions),
    ...queryOptions
  }) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey as QueryKey;

  return query;
};
