import { useLocation } from 'react-router-dom'
import { changePageUrlHash } from '../../url'
import { useResponsiveValue } from '../responsive'
import { useMount } from '..'
import { ResponsiveThreshold } from './constants'

export const useSpyScroll = ({
  blockIds = [],
  defaultBlockId = '',
  disabled,
}: {
  blockIds: string[]
  defaultBlockId?: string
  disabled?: boolean
}) => {
  const location = useLocation()
  const currentHash = location.hash?.slice(1) || defaultBlockId
  const threshold = useResponsiveValue(ResponsiveThreshold)

  useMount(() => {
    if (disabled) return

    const observer = new IntersectionObserver(
      ([entity]) => {
        if (entity?.isIntersecting) {
          changePageUrlHash(entity.target.id)
        }
      },
      { threshold },
    )

    blockIds.forEach(blockId => {
      const block = document.getElementById(blockId)
      if (block) observer.observe(block)
    })
  })

  return currentHash
}
