export enum ECommonRequestStatus {
  OK = 'OK',
}

export enum ERegistrationRequestStatus {
  LOAN_AMOUNT_IS_NOT_IN_INTERVAL = 'LOAN_AMOUNT_IS_NOT_IN_INTERVAL',
  OKVEDS_NOT_FOUND = 'OKVEDS_NOT_FOUND',
  STATUS_NOT_ACTIVE = 'STATUS_NOT_ACTIVE',
  LOAN_AMOUNT_IS_NOT_ENOUGH = 'LOAN_AMOUNT_IS_NOT_ENOUGH',
}

export const financingOfferFailStatusWithMessage = [ERegistrationRequestStatus.LOAN_AMOUNT_IS_NOT_IN_INTERVAL]
