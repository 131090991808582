import type { IntlCache, IntlShape } from 'react-intl'
import { createIntl, createIntlCache } from 'react-intl'
import type { RootState } from '@/redux/interfaces'

export const getMessages =
  () =>
  //eslint-disable-next-line @typescript-eslint/no-explicit-any
  (state: RootState): any =>
    state.intl.messages
export const getCurrentLocale = () => (state: RootState) => state.intl.locale

const cache: IntlCache = createIntlCache()
let intl: IntlShape
//eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getMessage = (options: any, values?: any) => (state: RootState) => {
  const locale = getCurrentLocale()(state)

  if (!intl || locale !== intl.locale) {
    intl = createIntl({ locale, messages: getMessages()(state) }, cache)
  }

  try {
    const text = intl.formatMessage(options, values)

    return text
  } catch (e) {
    return ''
  }
}
