import type { PayloadAction } from '@reduxjs/toolkit'
import { createReducer } from '@reduxjs/toolkit'
import { getInitialState } from '@/redux/helpers'
import type { RootState } from '@/redux/interfaces'
import { reducerManager } from '@/redux/reducerManager'
import { fetchHistory, onHistoryRequestFailed, onHistoryRequestFinished } from './actions'
import type { IFetchHistoryPayload, IHistoryState } from './interfaces'

const initialState: IHistoryState = {
  isFetching: false,
  isFailure: false,
  history: [],
  errorMessage: '',
}

const reducerName = 'history'

const reducer = createReducer(getInitialState(reducerName, initialState), {
  [fetchHistory.type]: (state, { payload }: PayloadAction<IFetchHistoryPayload>) => {
    state.isFetching = !payload.shouldNotShowLoadingState
    state.isFailure = false
  },
  [onHistoryRequestFinished.type]: (state, action) => {
    state.history = action.payload.history
    state.isFetching = false
  },
  [onHistoryRequestFailed.type]: (state, action) => {
    state.errorMessage = action.payload.errorMessage
    state.isFetching = false
    state.isFailure = true
  },
})

reducerManager.add(reducerName, reducer)

export const isFetching = () => (state: RootState) => state.history.isFetching
export const isFailure = () => (state: RootState) => state.history.isFailure
export const getHistory = () => (state: RootState) => state.history.history
export const getErrorMessage = () => (state: RootState) => state.history.errorMessage
