import type { FC } from 'react'
import React, { memo, useMemo } from 'react'
import { CommonLink } from '@frontend/pole-ui/lib/components/CommonLink'
import { EIconName, EIconSize } from '@frontend/pole-ui/lib/components/Icon'
import classNames from 'classnames'
import { AddProductToCartButton } from '@/components/composed'
import LoginToWatchPrice from '@/components/composed/catalog/LoginToWatchPrice'
import { Button, Image } from '@/components/ui'
import { useAuthenticateUser } from '@/components/ui/AuthButton/hooks'
import { OfferPriceWithDiscount } from '@/components/ui/inputs'
import { EAgriculturalProductsRoute } from '@/types'
import type { ISku, ISkuOffer } from '@/types/Catalog'
import { useDeviceType } from '@/utils/hooks/useDeviceType'
import { getStaticImagePath } from '@/utils/images'
import { createRouterLink, getUrlWithQuery } from '@/utils/url'
import { messages } from './messages'
import type { IInputsCardProps } from './types'
import './styles.scss'

const InputsCard: FC<IInputsCardProps> = props => {
  const { item, showPrice, onCardClickGtmEvent, className } = props
  const { category, id, image, offers, name, categories } = item

  const { isAuthenticated } = useAuthenticateUser()

  const { isMobile } = useDeviceType()

  const productUrl = useMemo(() => {
    return createRouterLink(EAgriculturalProductsRoute.product, { category: category.id, productId: id })
  }, [category.id, id])

  const isLeasingAvailable = categories.leasingAvailable
  const shouldShowPrice = !!offers[0]?.showPriceForUnauth
  const shouldShowPriceLoginForm = !shouldShowPrice && !isAuthenticated && !showPrice

  return (
    <article key={id} className={classNames('indexInputsCard', className)}>
      <CommonLink spanIfEmpty className="indexInputsCard__overlay" url={productUrl} onClick={onCardClickGtmEvent} />
      <div className="indexInputsCard__imageBlock">
        <Image className="indexInputsCard__productImage" src={image} placeholderImage={getStaticImagePath('product/placeholder.svg')} />
      </div>
      <div className="indexInputsCard__title">{name}</div>

      <div className="indexInputsCard__priceBlock">
        <OfferPriceWithDiscount
          sku={item as unknown as ISku}
          offer={offers[0] as unknown as ISkuOffer}
          isMobileDevice={isMobile}
          isUserAuthenticated={showPrice ? true : isAuthenticated}
          isMainPageCard
          priceClassName="indexInputsCard__mainPrice"
          discountPriceClassName="indexInputsCard__discountPrice"
        />
        {shouldShowPriceLoginForm && <LoginToWatchPrice className="indexInputsCard__loginToSeePriceLink" authLinkClass="color_green" />}
      </div>

      <div className="indexInputsCard__buttonRow">
        {isLeasingAvailable ? (
          <CommonLink url={getUrlWithQuery(productUrl, { scrollToLeasingForm: true })}>
            <Button
              modifiers={['outline', 'small', 'full-width-always']}
              iconProps={{ name: EIconName.ShevronRight, size: EIconSize.XS }}
              iconPosition="right"
            >
              {messages.leasingAvailable}
            </Button>
          </CommonLink>
        ) : (
          <AddProductToCartButton
            addToCartButtonModifiers={['full-width-always', 'green']}
            buyButtonProps={{ modifiers: ['full-width-always', 'green'] }}
            offer={offers[0] as unknown as ISkuOffer}
            sku={item as unknown as ISku}
            itemPosition="mainPage"
            blockName="blockIndexBestOffers"
            addToCartButtonLabelTextId={messages.buy}
          />
        )}
      </div>
    </article>
  )
}

export default memo(InputsCard)
