import client from "@/api/client/dadata";
import type { ResponseConfig } from "@/api/client/dadata";
import type { FindCompanyByInnMutationRequest, FindCompanyByInnMutationResponse } from "../../types/FindCompanyByInn";

/**
     * @link /v1/find_company_by_inn
     */
export async function findCompanyByInn (data: FindCompanyByInnMutationRequest, options: Partial<Parameters<typeof client>[0]> = {}): Promise<ResponseConfig<FindCompanyByInnMutationResponse>["data"]> {
    const { data: resData } = await client<FindCompanyByInnMutationResponse, FindCompanyByInnMutationRequest>({
        method: "post",
        url: `/v1/find_company_by_inn`,
        data,
        ...options
    });
    
    return resData;
};