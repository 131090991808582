import React, { memo } from 'react'
import { useIntl } from 'react-intl'
import { Chip as ChipView } from '@frontend/pole-ui/lib/components/Chip'
import type { IChipProps } from './interfaces'

const Chip: React.FC<IChipProps> = ({ text, textId, textValues, ...otherProps }) => {
  const { formatMessage } = useIntl()

  return <ChipView {...otherProps} text={text || (textId && formatMessage({ id: textId }, textValues))} />
}

export default memo(Chip) as typeof Chip
