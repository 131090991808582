import type { FC } from 'react'
import React from 'react'
import classNames from 'classnames'
import { getPriceUom, isNotRussianCurrency } from '@/utils/price'
import type { IPriceUomProps } from './interfaces'

const PriceUom: FC<IPriceUomProps> = ({ uom, className }) => (
  <span className={classNames(className, { uom: !isNotRussianCurrency(uom) })}>{getPriceUom(uom)}</span>
)

export default PriceUom
