import omit from 'lodash/omit'
import pick from 'lodash/pick'
import type { IAsyncThunkFetchProductCatalogPayload } from '@/types/Catalog'
import { getObjectKeys } from '@/utils/getObjectKeys'
import { AGRICULTURAL_PRODUCT_FILTER_NAMES } from '../../constants'
import type { IAgriculturalProductCatalogFiltersForm } from '../../types'

export function mapFiltersFormToCatalogPayload(form: IAgriculturalProductCatalogFiltersForm): IAsyncThunkFetchProductCatalogPayload {
  return getObjectKeys(form).reduce(
    (payload, key) => {
      switch (key) {
        case 'categoryId': {
          const val = form[key]
          payload.params[key] = String(val)
          break
        }
        case 'pageNumber': {
          const val = form[key]
          if (val && val > 1) payload.search[key] = String(val)
          break
        }
        case 'searchText': {
          const val = form[key]
          if (val) payload.search[key] = val
          break
        }
        case 'promotionId': {
          const val = form[key]
          if (val) payload.search[key] = val
          break
        }
        case 'filters': {
          const filtersValues = form[key]

          const filters = Object.fromEntries(
            Object.entries(pick(filtersValues, AGRICULTURAL_PRODUCT_FILTER_NAMES)).filter(([, value]) => !!value),
          )

          const cfilters = Object.fromEntries(
            Object.entries(omit(filtersValues, AGRICULTURAL_PRODUCT_FILTER_NAMES)).filter(([, value]) => !!value),
          )

          Object.assign(payload.search, filters)

          if (Object.keys(cfilters).length) {
            payload.search.cfilters = encodeURIComponent(JSON.stringify(cfilters))
          }

          break
        }
        default: {
          // eslint-disable-next-line unused-imports/no-unused-vars
          const exhaustiveCheck: never = key
        }
      }

      return payload
    },
    { params: {}, search: {} } as IAsyncThunkFetchProductCatalogPayload,
  )
}
