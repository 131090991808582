import type { UseMutationOptions, UseMutationResult } from "@tanstack/react-query";
import { useMutation } from "@tanstack/react-query";
import client from "@/api/client/axios";
import type { ResponseConfig } from "@/api/client/axios";
import type { GetRequestsWithFiltersByStatusMutationRequest, GetRequestsWithFiltersByStatusMutationResponse, GetRequestsWithFiltersByStatusQueryParams, GetRequestsWithFiltersByStatus400, GetRequestsWithFiltersByStatus404, GetRequestsWithFiltersByStatus500 } from "../types/GetRequestsWithFiltersByStatus";

/**
 * @summary Отображения списка заявок на продажу
 * @link /api/v2/requests
 */

export function useGetRequestsWithFiltersByStatus <TData = GetRequestsWithFiltersByStatusMutationResponse, TError = GetRequestsWithFiltersByStatus400 | GetRequestsWithFiltersByStatus404 | GetRequestsWithFiltersByStatus500, TVariables = GetRequestsWithFiltersByStatusMutationRequest>(params?: GetRequestsWithFiltersByStatusQueryParams, options: {
          mutation?: UseMutationOptions<ResponseConfig<TData>, TError, TVariables>,
          client?: Partial<Parameters<typeof client<TData, TError, TVariables>>[0]>,
      } = {}): UseMutationResult<ResponseConfig<TData>, TError, TVariables> {
  const { mutation: mutationOptions, client: clientOptions = {} } = options ?? {};

  return useMutation<ResponseConfig<TData>, TError, TVariables>({
    mutationFn: (data) => {
      
      return client<TData, TError, TVariables>({
        method: "post",
        url: `/api/v2/requests`,
        data,
        params,
        
        ...clientOptions
      });
    },
    ...mutationOptions
  });
};
