import { addBusinessDays, addDays, format, isAfter, isBefore, startOfDay, sub } from 'date-fns'
import type { ValueType } from 'rsuite/lib/DateRangePicker'

export type TCheckingDateHelper = (date?: Date, selectDate?: ValueType, selectedDone?: boolean) => boolean

export const isBeforeToday: TCheckingDateHelper = date => date !== undefined && isBefore(date, sub(new Date(), { days: 1 }))
export const isAfterToday: TCheckingDateHelper = date => date !== undefined && isAfter(date, sub(new Date(), { days: 0 }))
export const isBeforeBusinessDays = (date?: Date, days?: number) =>
  date !== undefined && isBefore(date, addBusinessDays(new Date(), days || 0))

export const isRangeWithCustomOffset: (minimumStartDayOffset: number, minimumRangeDays: number, startDay?: Date) => TCheckingDateHelper =
  (minimumStartDayOffset, minimumRangeDays, startDay = new Date()) =>
  (date, selectDate, selectedDone) => {
    if (date === undefined || selectDate === undefined) return false

    const [startDate] = selectDate

    return (
      isBefore(date, addDays(startOfDay(startDay), minimumStartDayOffset)) ||
      (!selectedDone && isBefore(date, addDays(startDate as Date, minimumRangeDays)))
    )
  }

const weekendShortNames = ['Sa', 'Su']
export const isWeekendDay: TCheckingDateHelper = date => date !== undefined && weekendShortNames.includes(format(date, 'EEEEEE'))

const combineCheckingDateHelpers =
  (...helpers: TCheckingDateHelper[]): TCheckingDateHelper =>
  (...args) =>
    helpers.some(helper => helper(...args))

export const isRangeWithCustomOffsetAndWithoutWeekends = (minimumStartDayOffset: number, minimumRangeDays: number, startDay?: Date) =>
  combineCheckingDateHelpers(isRangeWithCustomOffset(minimumStartDayOffset, minimumRangeDays, startDay), isWeekendDay)
export const isWeekWithoutWeekends = combineCheckingDateHelpers(isBeforeToday, isWeekendDay)

export const isRangeWithWeekOffsetAndWithoutWeekends = isRangeWithCustomOffsetAndWithoutWeekends(7, 7)
export const isWithWeekRangeAndWithoutWeekends = isRangeWithCustomOffsetAndWithoutWeekends(0, 7)
