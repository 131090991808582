import { createAction } from '@reduxjs/toolkit'
import type { NAccreditationAction } from './interfaces'

export const updateAccreditationDocument = createAction<NAccreditationAction.IUpdateAccreditationDocument>(
  '@@accreditation/UPDATE_ACCREDITATION_DOCUMENT',
)

export const downloadAccreditationDocument = createAction<NAccreditationAction.IDownloadAccreditationDocument>(
  '@@accreditation/DOWNLOAD_ACCREDITATION_DOCUMENT',
)

export const createAccreditation = createAction<NAccreditationAction.ICreateAccreditation>('@@accreditation/CREATE_ACCREDITATION')

export const makeAccreditationProcess = createAction<NAccreditationAction.IMakeAccreditationProcess>(
  '@@accreditation/MAKE_ACCREDITATION_PROCESS',
)

export const sendCodeForSigningAccreditation = createAction('@@accreditation/SEND_CODE_FOR_SIGNING_ACCREDITATION')

export const saveTokenId = createAction<NAccreditationAction.ISaveTokenId>('@@accreditation/SAVE_TOKEN_ID')

export const rejectAccreditationDocument = createAction<NAccreditationAction.IRejectAccreditationDocument>(
  '@@accreditation/REJECT_ACCREDITATION_DOCUMENT',
)

export const sendSmsCode = createAction<NAccreditationAction.IRejectAccreditationDocument>('@@accreditation/SEND_SMS_CODE')

export const reloadAccreditation = createAction<NAccreditationAction.IReloadAccreditation>('@@accreditation/RELOAD_ACCREDITATION')

export const uploadAccreditationDocumentsFromModal = createAction<NAccreditationAction.IUpdateAccreditationDocument>(
  '@@accreditation/UPLOAD_ACCREDITATION_DOCUMENTS_FROM_MODAL',
)
