import type { QueryKey, UseQueryResult, UseQueryOptions, QueryOptions } from "@tanstack/react-query";
import { useQuery } from "@tanstack/react-query";
import client from "@/api/client/axios";
import type { GetOrganizationProfileV2QueryResponse, GetOrganizationProfileV2400, GetOrganizationProfileV2500 } from "../types/GetOrganizationProfileV2";

export const getOrganizationProfileV2QueryKey = () =>     [{url:`/api/v2/lk/organization`}] as const;
      export function getOrganizationProfileV2QueryOptions <TData = GetOrganizationProfileV2QueryResponse, TError = GetOrganizationProfileV2400 | GetOrganizationProfileV2500>(options: Partial<Parameters<typeof client>[0]> = {}): UseQueryOptions<TData, TError> {
        const queryKey = getOrganizationProfileV2QueryKey();

        return {
          queryKey,
          queryFn: () => {
            
            return client<TData, TError>({
              method: "get",
              url: `/api/v2/lk/organization`,
              
              
              ...options,
            }).then(res => res.data);
          },
        };
      };
      
/**
 * @summary Получить профиль организации
 * @link /api/v2/lk/organization
 */

export function useGetOrganizationProfileV2 <TData = GetOrganizationProfileV2QueryResponse, TError = GetOrganizationProfileV2400 | GetOrganizationProfileV2500>(options: {
          query?: UseQueryOptions<TData, TError>,
          client?: Partial<Parameters<typeof client<TData, TError>>[0]>,
        } = {}): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const { query: queryOptions, client: clientOptions = {} } = options ?? {};
  const queryKey = queryOptions?.queryKey ?? getOrganizationProfileV2QueryKey();

  const query = useQuery<TData, TError>({
    ...getOrganizationProfileV2QueryOptions<TData, TError>(clientOptions),
    ...queryOptions
  }) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey as QueryKey;

  return query;
};
