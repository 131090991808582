import type { QueryKey, UseQueryResult, UseQueryOptions, QueryOptions } from "@tanstack/react-query";
import { useQuery } from "@tanstack/react-query";
import client from "@/api/client/axios";
import type { GetFundingHistoryQueryResponse, GetFundingHistoryQueryParams, GetFundingHistory400, GetFundingHistory401, GetFundingHistory403, GetFundingHistory422, GetFundingHistory429, GetFundingHistory500 } from "../types/GetFundingHistory";

export const getFundingHistoryQueryKey = (params: GetFundingHistoryQueryParams) =>     [{url:`/api/credit-flow-backend/v1/funding/view_history`},...(params ? [params] : [])] as const;
      export function getFundingHistoryQueryOptions <TData = GetFundingHistoryQueryResponse, TError = GetFundingHistory400 | GetFundingHistory401 | GetFundingHistory403 | GetFundingHistory422 | GetFundingHistory429 | GetFundingHistory500>(params: GetFundingHistoryQueryParams, options: Partial<Parameters<typeof client>[0]> = {}): UseQueryOptions<TData, TError> {
        const queryKey = getFundingHistoryQueryKey(params);

        return {
          queryKey,
          queryFn: () => {
            
            return client<TData, TError>({
              method: "get",
              url: `/api/credit-flow-backend/v1/funding/view_history`,
              params,
              
              ...options,
            }).then(res => res.data);
          },
        };
      };
      
/**
 * @link /api/credit-flow-backend/v1/funding/view_history
 */

export function useGetFundingHistory <TData = GetFundingHistoryQueryResponse, TError = GetFundingHistory400 | GetFundingHistory401 | GetFundingHistory403 | GetFundingHistory422 | GetFundingHistory429 | GetFundingHistory500>(params: GetFundingHistoryQueryParams, options: {
          query?: UseQueryOptions<TData, TError>,
          client?: Partial<Parameters<typeof client<TData, TError>>[0]>,
        } = {}): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const { query: queryOptions, client: clientOptions = {} } = options ?? {};
  const queryKey = queryOptions?.queryKey ?? getFundingHistoryQueryKey(params);

  const query = useQuery<TData, TError>({
    ...getFundingHistoryQueryOptions<TData, TError>(params, clientOptions),
    ...queryOptions
  }) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey as QueryKey;

  return query;
};
