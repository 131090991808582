import type { UseMutationOptions, UseMutationResult } from "@tanstack/react-query";
import { useMutation } from "@tanstack/react-query";
import client from "@/api/client/axios";
import type { ResponseConfig } from "@/api/client/axios";
import type { CreateOrUpdateSaleRequestDiscountMutationRequest, CreateOrUpdateSaleRequestDiscountMutationResponse, CreateOrUpdateSaleRequestDiscountPathParams, CreateOrUpdateSaleRequestDiscount400, CreateOrUpdateSaleRequestDiscount404, CreateOrUpdateSaleRequestDiscount500 } from "../types/CreateOrUpdateSaleRequestDiscount";

/**
 * @summary Создание/обновление скидки
 * @link /api/salerequests/:requestId/discounts
 */

export function useCreateOrUpdateSaleRequestDiscount <TData = CreateOrUpdateSaleRequestDiscountMutationResponse, TError = CreateOrUpdateSaleRequestDiscount400 | CreateOrUpdateSaleRequestDiscount404 | CreateOrUpdateSaleRequestDiscount500, TVariables = CreateOrUpdateSaleRequestDiscountMutationRequest>(requestId: CreateOrUpdateSaleRequestDiscountPathParams["requestId"], options: {
          mutation?: UseMutationOptions<ResponseConfig<TData>, TError, TVariables>,
          client?: Partial<Parameters<typeof client<TData, TError, TVariables>>[0]>,
      } = {}): UseMutationResult<ResponseConfig<TData>, TError, TVariables> {
  const { mutation: mutationOptions, client: clientOptions = {} } = options ?? {};

  return useMutation<ResponseConfig<TData>, TError, TVariables>({
    mutationFn: (data) => {
      
      return client<TData, TError, TVariables>({
        method: "post",
        url: `/api/salerequests/${requestId}/discounts`,
        data,
        
        
        ...clientOptions
      });
    },
    ...mutationOptions
  });
};
