import client from "@/api/client/inputs-catalog";
import type { ResponseConfig } from "@/api/client/inputs-catalog";
import type { GetCategoryFiltersQueryResponse } from "../../types/GetCategoryFilters";

/**
     * @link /v1/category/filters
     */
export async function getCategoryFilters (options: Partial<Parameters<typeof client>[0]> = {}): Promise<ResponseConfig<GetCategoryFiltersQueryResponse>["data"]> {
    const { data: resData } = await client<GetCategoryFiltersQueryResponse>({
        method: "get",
        url: `/v1/category/filters`,
        ...options
    });
    
    return resData;
};