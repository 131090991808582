import type { TAgriculturalProductFilterName } from '@/types/Catalog'
import { getObjectKeys } from '@/utils/getObjectKeys'

export const PRODUCTS_PER_PAGE = 30

// В `AGRICULTURAL_PRODUCT_FILTER_NAMES` гарантированно должны быть все свойства из `TAgriculturalProductFilterName`.
// Поэтому нужен промежуточный объект (чтобы при изменении набора свойств в `TAgriculturalProductFilterName`,
// ts показал ошибку, что надо бы этот набор свойств здесь синхронизировать), из которого дальше просто вытаскиваются ключи.
//eslint-disable-next-line @typescript-eslint/no-explicit-any
const agriculturalProductFilterNamesObj: Record<TAgriculturalProductFilterName, any> = {
  packageTypes: true,
  store: true,
  producerIds: true,
  supplierIds: true,
  activeSubstance: true,
  cultures: true,
  elevators: true,
  buyers: true,
}

export const AGRICULTURAL_PRODUCT_FILTER_NAMES = getObjectKeys(agriculturalProductFilterNamesObj)

export const DONT_TOUCH_FILTER_NAMES_IN_QS: TAgriculturalProductFilterName[] = ['supplierIds']
