import client from "@/api/client/axios";
import type { ResponseConfig } from "@/api/client/axios";
import type { GetCategoryProductsQueryResponse, GetCategoryProductsQueryParams } from "../../types/GetCategoryProducts";

/**
     * @summary Получение списка товаров по категории
     * @link /api/catalog/mainPage/products
     */
export async function getCategoryProducts (params?: GetCategoryProductsQueryParams, options: Partial<Parameters<typeof client>[0]> = {}): Promise<ResponseConfig<GetCategoryProductsQueryResponse>["data"]> {
    const { data: resData } = await client<GetCategoryProductsQueryResponse>({
        method: "get",
        url: `/api/catalog/mainPage/products`,
        params,
        ...options
    });
    
    return resData;
};