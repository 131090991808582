import type { QueryKey, UseQueryResult, UseQueryOptions, QueryOptions } from "@tanstack/react-query";
import { useQuery } from "@tanstack/react-query";
import client from "@/api/client/auctions";
import type { GetAuctionsForVitrinaQueryResponse, GetAuctionsForVitrinaQueryParams, GetAuctionsForVitrina400, GetAuctionsForVitrina404 } from "../types/GetAuctionsForVitrina";

export const getAuctionsForVitrinaQueryKey = (params?: GetAuctionsForVitrinaQueryParams) =>     [{url:`/v2/auctions/view_for-vitrina`},...(params ? [params] : [])] as const;
      export function getAuctionsForVitrinaQueryOptions <TData = GetAuctionsForVitrinaQueryResponse, TError = GetAuctionsForVitrina400 | GetAuctionsForVitrina404>(params?: GetAuctionsForVitrinaQueryParams, options: Partial<Parameters<typeof client>[0]> = {}): UseQueryOptions<TData, TError> {
        const queryKey = getAuctionsForVitrinaQueryKey(params);

        return {
          queryKey,
          queryFn: () => {
            
            return client<TData, TError>({
              method: "get",
              url: `/v2/auctions/view_for-vitrina`,
              params,
              
              ...options,
            }).then(res => res.data);
          },
        };
      };
      
/**
 * @description Метод предназначен для получения списка аукционов с возможностью фильтрации.
 * @link /v2/auctions/view_for-vitrina
 */

export function useGetAuctionsForVitrina <TData = GetAuctionsForVitrinaQueryResponse, TError = GetAuctionsForVitrina400 | GetAuctionsForVitrina404>(params?: GetAuctionsForVitrinaQueryParams, options: {
          query?: UseQueryOptions<TData, TError>,
          client?: Partial<Parameters<typeof client<TData, TError>>[0]>,
        } = {}): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const { query: queryOptions, client: clientOptions = {} } = options ?? {};
  const queryKey = queryOptions?.queryKey ?? getAuctionsForVitrinaQueryKey(params);

  const query = useQuery<TData, TError>({
    ...getAuctionsForVitrinaQueryOptions<TData, TError>(params, clientOptions),
    ...queryOptions
  }) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey as QueryKey;

  return query;
};
