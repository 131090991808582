
export enum CalculatePaymentAmountDataProductId {
    "BNPL_AGROINTEGRATOR" = "BNPL_AGROINTEGRATOR",
    "BNPL_SBER_BUSINESS" = "BNPL_SBER_BUSINESS",
    "BNPL_MONEY_KAPITAL" = "BNPL_MONEY_KAPITAL",
    "BNPL_POLECREDIT" = "BNPL_POLECREDIT",
    "COMBO_MONEY_KAPITAL_AND_SBER_BUSINESS" = "COMBO_MONEY_KAPITAL_AND_SBER_BUSINESS"
}
export type CalculatePaymentAmountData = {
    /**
     * @type string
    */
    productId: CalculatePaymentAmountDataProductId;
    /**
     * @type integer int32
    */
    paymentId: number;
    /**
     * @type number
    */
    calculatePaymentAmount: number;
};
