import { useMemo } from 'react'
import type { IDocument } from '@/types/Documents'

export type TDocumentsByType<GDocument> = Record<string, GDocument[]>

export const useGetDocumentsByType = <GDocument extends IDocument>(documents: Array<GDocument>): TDocumentsByType<GDocument> =>
  useMemo(
    () =>
      documents.reduce((result: TDocumentsByType<GDocument>, document) => {
        const { type } = document

        return {
          ...result,
          [type]: [...(result[type] || []), document],
        }
      }, {}),
    //eslint-disable-next-line react-hooks/exhaustive-deps
    [JSON.stringify(documents)],
  )
