import { MAX_QUERIES, STORAGE_KEY } from '@/pages/Journal/components/JournalBySearch/constants'
import { useLocalStorage } from '@/utils/hooks/useLocalStorage'

export const useJournalSearchDefaultValue = () => {
  const [queries, setQueries] = useLocalStorage(STORAGE_KEY, [])

  const addSearchQuery = (query: string) => {
    setQueries(prevQueries => {
      let updatedQueries = [query, ...prevQueries.filter(q => q !== query)]

      if (updatedQueries.length > MAX_QUERIES) {
        updatedQueries = updatedQueries.slice(0, MAX_QUERIES)
      }

      return updatedQueries
    })
  }

  const removeSearchQueryById = (query: string) => {
    setQueries(prevValues => prevValues.filter(q => q !== query))
  }

  return { queries, addSearchQuery, removeSearchQueryById }
}
