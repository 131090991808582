import client from "@/api/client/auctions";
import type { ResponseConfig } from "@/api/client/auctions";
import type { GetAuctionBetsQueryResponse, GetAuctionBetsPathParams } from "../../types/GetAuctionBets";

/**
     * @link /auctions/:auctionId/bets
     */
export async function getAuctionBets (auctionId: GetAuctionBetsPathParams["auctionId"], options: Partial<Parameters<typeof client>[0]> = {}): Promise<ResponseConfig<GetAuctionBetsQueryResponse>["data"]> {
    const { data: resData } = await client<GetAuctionBetsQueryResponse>({
        method: "get",
        url: `/auctions/${auctionId}/bets`,
        ...options
    });
    
    return resData;
};