export enum EHarvestSaleRequestStatus {
  PAYMENT_BILL_WAITING = 'PAYMENT_BILL_WAITING',
  PREPAYMENT_BILL_WAITING = 'PREPAYMENT_BILL_WAITING',
  PREPAYMENT_BILL_WAITING_CRYPTO = 'PREPAYMENT_BILL_WAITING_CRYPTO',
  PAYMENT_BILL_WAITING_CRYPTO = 'PAYMENT_BILL_WAITING_CRYPTO',
  CLOSED = 'CLOSED',
  CANCELED = 'CANCELED',
  UNKNOWN = 'UNKNOWN',
  BUYER_PROPOSAL = 'BUYER_PROPOSAL',
  MULTIREQUEST_BUYER_PROPOSAL = 'MULTIREQUEST_BUYER_PROPOSAL',
  MULTIREQUEST_BUYER_EDIT = 'MULTIREQUEST_BUYER_EDIT',
  MULTIREQUEST_CREATED = 'MULTIREQUEST_CREATED',
  SALE_REQUEST_CREATED = 'SALE_REQUEST_CREATED',
  REQUEST_CREATED = 'REQUEST_CREATED',
  PAYMENT_BILL_PREPARED = 'PAYMENT_BILL_PREPARED',
  BUYER_EDIT = 'BUYER_EDIT',
  LOADING_CLOSING_DOCUMENTS = 'LOADING_CLOSING_DOCUMENTS',
  AGRARIAN_SIGN_OFFER = 'AGRARIAN_SIGN_OFFER',
  PHASED_SHIPPING = 'PHASED_SHIPPING',
  PHASED_PREPAID = 'PHASED_PREPAID',
}
