import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ErrorMessage, Loader } from '@/components/ui'
import { injectMessages } from '@/logic/translation/utils'
import type { RootState } from '@/redux/interfaces'
import { addNewEpicsLazily } from '@/utils/addNewEpicsLazily'
import { useMount } from '@/utils/hooks'
import messages from '../ExactFarmingOtherOffersSection/messages.json'
import HistoryList from '../HistoryList/HistoryList'
import { fetchHistory, reloadHistory } from './actions'
import epics from './epics'
import type { IHistoryProps } from './interfaces'
import { getErrorMessage, getHistory, isFailure, isFetching } from './reducer'
import './styles.scss'

addNewEpicsLazily([epics])
injectMessages(messages)

const mapState = (state: RootState) => ({
  isHistoryFetching: isFetching()(state),
  isHistoryFailure: isFailure()(state),
  history: getHistory()(state) || [],
  errorMessage: getErrorMessage()(state),
})

const History: React.FC<IHistoryProps> = ({ entity, entityId }) => {
  const { isHistoryFetching, isHistoryFailure, history, errorMessage } = useSelector(mapState)
  const dispatch = useDispatch()

  useMount(() => {
    const fetchingHistoryPayload = { entity, entityId }
    dispatch(fetchHistory(fetchingHistoryPayload))
    dispatch(reloadHistory(fetchingHistoryPayload))
  })

  return (
    <div className="history-list-container">
      <Loader isVisible={isHistoryFetching} />
      <ErrorMessage text={errorMessage} textId="history.generalError" isVisible={isHistoryFailure} className="text_center" />
      <HistoryList history={history} />
    </div>
  )
}

export default History
