import React, { memo, useMemo } from 'react'
import { useIntl } from 'react-intl'
import { useSelector } from 'react-redux'
import { matchPath } from 'react-router-dom'
import classNames from 'classnames'
import type { IUserRegionSelectProps } from '@/components/ui/UserRegionSelect'
import { EUserRegionSelectVariation, UserRegionSelect } from '@/components/ui/UserRegionSelect'
import { getLocation } from '@/logic/app/reducer'
import { injectMessages } from '@/logic/translation/utils'
import type { RootState } from '@/redux/interfaces'
import { EAgriculturalProductsRoute } from '@/types'
import messages from './messages.json'
import './styles.scss'

injectMessages(messages)

const mapState = (state: RootState) => ({
  location: getLocation()(state),
})

const RegionChooserBottom: React.FC = () => {
  const { formatMessage } = useIntl()
  const { location } = useSelector(mapState)

  const isDeliverySelectShown = useMemo(() => {
    const whitelist: string[] = [EAgriculturalProductsRoute.main, EAgriculturalProductsRoute.category, EAgriculturalProductsRoute.product]

    return !!(location.pathname && matchPath(location.pathname, whitelist))
  }, [location])

  const regionSelectProps: IUserRegionSelectProps = useMemo(() => {
    const picker: IUserRegionSelectProps['picker'] = {
      desktop: false,
      tablet: EUserRegionSelectVariation.MOBILE,
      mobile: EUserRegionSelectVariation.MOBILE,
    }

    const confirm: IUserRegionSelectProps['confirm'] = {
      desktop: false,
      tablet: EUserRegionSelectVariation.MOBILE,
      mobile: EUserRegionSelectVariation.MOBILE,
    }

    const selector: IUserRegionSelectProps['selector'] = {
      desktop: false,
      tablet: EUserRegionSelectVariation.MOBILE,
      mobile: EUserRegionSelectVariation.MOBILE,
    }

    if (isDeliverySelectShown) {
      return {
        label: formatMessage({ id: 'header.regionChooserBottom.label' }),
        picker,
        confirm,
        selector,
      }
    }

    return {
      picker: false,
      confirm,
      selector,
    }
  }, [formatMessage, isDeliverySelectShown])

  return (
    <div className={classNames('page-wrapper', 'regionChooserBottom')}>
      <UserRegionSelect {...regionSelectProps} />
    </div>
  )
}

export default memo(RegionChooserBottom)
