import type { FC } from 'react'
import React, { memo } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { ErrorMessage } from '@/components/ui'
import UploadButton from '@/components/ui/UploadButton/UploadButton'
import { getMappedFieldMetaState } from '@/utils/fields'
import type { IFormUploadButtonProps } from './types'
import './styles.scss'

const FormUploadButton: FC<IFormUploadButtonProps> = props => {
  const { name, rules, withErrorMessage } = props
  const { control } = useFormContext()

  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      render={({ field: { ref: _, ...fieldWithoutRef }, fieldState }) => {
        return (
          <div className="FormUploadButton">
            <UploadButton {...fieldWithoutRef} {...props} meta={getMappedFieldMetaState(fieldState)} />
            <ErrorMessage isVisible={!!withErrorMessage && !!fieldState.error} text={fieldState.error?.message} />
          </div>
        )
      }}
    />
  )
}

export default memo(FormUploadButton)
