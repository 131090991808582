import React from 'react'
import type { FormRenderProps, FormSpyProps } from 'react-final-form'
import { FormSpy } from 'react-final-form'
import { defaultComparator } from './helpers'
import type { IAutoSaveComparator } from './interfaces'

//eslint-disable-next-line @typescript-eslint/no-explicit-any
interface IAutoSaveProps<GFormValues = Record<string, any>> {
  values: GFormValues
  save: (values: GFormValues) => Promise<void>
  debounce: number
  comparator?: IAutoSaveComparator<GFormValues>
}

//eslint-disable-next-line @typescript-eslint/no-explicit-any
interface IAutoSaveState<GFormValues = Record<string, any>> {
  values: GFormValues
}

class AutoSave extends React.PureComponent<IAutoSaveProps, IAutoSaveState> {
  timeout: NodeJS.Timeout | undefined
  promise: Promise<void> | undefined
  constructor(props: IAutoSaveProps) {
    super(props)
    this.state = { values: props.values }
  }

  componentDidUpdate() {
    if (this.timeout) {
      clearTimeout(this.timeout)
    }
    this.timeout = setTimeout(this.handleSave, this.props.debounce)
  }

  componentWillUnmount() {
    if (this.timeout) clearTimeout(this.timeout)
  }

  handleSave = async () => {
    if (this.promise) {
      await this.promise
    }

    const { values, save, comparator = defaultComparator } = this.props

    if (comparator(this.state.values, values)) {
      this.promise = save(values)
      await this.promise
      delete this.promise
    }
    this.setState({ values })
  }

  render() {
    return null
  }
}

function AutoSaveContainer<GFormValues>(
  props: Omit<IAutoSaveProps<GFormValues>, 'values'> & { props: FormRenderProps<GFormValues, Partial<GFormValues>> },
) {
  return (
    <FormSpy<GFormValues>
      {...props}
      subscription={{ values: true }}
      component={AutoSave as unknown as FormSpyProps<GFormValues>['component']}
    />
  )
}

export default AutoSaveContainer
