import React from 'react'
import { formatDate } from '@/utils/formatUtils'
import type { IHistoryListProps } from './interfaces'
import './styles.scss'

const HistoryList: React.FC<IHistoryListProps> = ({ history = [] }) => (
  <ul className="history-list">
    {history.map((item, key) => {
      const { message } = item
      if (!message || message.includes('TEMPLATE NOT FOUND - ')) return null

      return (
        <li className="history-item" key={key}>
          <div className="history-item__text text_small-from-sm space-holder16-from-sm space-holder8-sm">{message}</div>
          <div className="history-item__date text_super-small color_pale-black">{formatDate(item.date, 'HH:mm  dd.MM.yyyy')}</div>
        </li>
      )
    })}
  </ul>
)

export default HistoryList
