import type { FC } from 'react'
import React from 'react'
import classNames from 'classnames'
import type { ISquareBlockProps } from './interfaces'
import './styles.scss'

const SquareBlock: FC<ISquareBlockProps> = props => {
  const { title, className, animation } = props

  return (
    <div className={classNames(`square-block`, className)} {...animation}>
      <h5 className={'font-weight_normal square-block__title'}>{title}</h5>
    </div>
  )
}

export default SquareBlock
