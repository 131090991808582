import client from "@/api/client/axios";
import type { ResponseConfig } from "@/api/client/axios";
import type { GetCatalogMainPageQueryResponse, GetCatalogMainPageQueryParams } from "../../types/GetCatalogMainPage";

/**
     * @summary Получение списка товаров
     * @link /api/catalog/mainPage/category
     */
export async function getCatalogMainPage (params?: GetCatalogMainPageQueryParams, options: Partial<Parameters<typeof client>[0]> = {}): Promise<ResponseConfig<GetCatalogMainPageQueryResponse>["data"]> {
    const { data: resData } = await client<GetCatalogMainPageQueryResponse>({
        method: "get",
        url: `/api/catalog/mainPage/category`,
        params,
        ...options
    });
    
    return resData;
};