import client from "@/api/client/axios";
import type { ResponseConfig } from "@/api/client/axios";
import type { ChangeUserOrganizationMutationResponse, ChangeUserOrganizationPathParams } from "../../types/ChangeUserOrganization";

/**
     * @summary Смена текущей организации пользователя
     * @link /api/auth/change/organization/:organizationId
     */
export async function changeUserOrganization (organizationId: ChangeUserOrganizationPathParams["organizationId"], options: Partial<Parameters<typeof client>[0]> = {}): Promise<ResponseConfig<ChangeUserOrganizationMutationResponse>["data"]> {
    const { data: resData } = await client<ChangeUserOrganizationMutationResponse>({
        method: "post",
        url: `/api/auth/change/organization/${organizationId}`,
        ...options
    });
    
    return resData;
};