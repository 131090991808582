import { useMemo, useState } from 'react'
import { useEvent, useIsomorphicLayoutEffect } from '@/utils/hooks'

export enum EStickyObserverMethods {
  SIMPLE = 'simple',
  DETAILED = 'detailed',
}

export type IStickyObserverResultSimple = boolean

export interface IStickyObserverResultDetailed {
  top: boolean
  right: boolean
  bottom: boolean
  left: boolean
  x: boolean
  y: boolean
}

export type IStickyObserverResult = IStickyObserverResultSimple | IStickyObserverResultDetailed

const THRESHOLD_STEP = 0.01

export function usePositionStickyObserver(
  el: Maybe<HTMLElement>,
  recalcTrigger?: Maybe<number>,
  method?: EStickyObserverMethods.SIMPLE,
  options?: IntersectionObserverInit,
): IStickyObserverResultSimple
// eslint-disable-next-line no-redeclare
export function usePositionStickyObserver(
  el: Maybe<HTMLElement>,
  recalcTrigger?: Maybe<number>,
  method?: EStickyObserverMethods.DETAILED,
  options?: IntersectionObserverInit,
): IStickyObserverResultDetailed
// eslint-disable-next-line no-redeclare
export function usePositionStickyObserver(
  el: Maybe<HTMLElement>,
  recalcTrigger?: Maybe<number>,
  method: EStickyObserverMethods = EStickyObserverMethods.SIMPLE,
  options?: IntersectionObserverInit,
): IStickyObserverResult {
  const [result, setResultState] = useState(() => getInitialValue(method))

  const setResult = useEvent((newResult: IStickyObserverResult) => {
    if (JSON.stringify(result) !== JSON.stringify(newResult)) {
      setResultState(newResult)
    }
  })

  useIsomorphicLayoutEffect(() => setResult(getInitialValue(method)), [method])

  const threshold = useMemo(() => {
    if (options?.threshold) {
      return options.threshold
    }

    if (method === EStickyObserverMethods.SIMPLE) {
      return 1
    }

    let step = THRESHOLD_STEP
    step = Math.min(0.001, THRESHOLD_STEP)
    step = Math.max(THRESHOLD_STEP, 0.001)

    let count = Math.round(100 / (step * 100))

    // prettier-ignore
    count = count * step >= 1
      ? count - 1
      : count

    const steps = new Array(count).fill(null).map((_, idx) => +((idx + 1) * step).toFixed(3))

    return [0, ...steps, 1]
  }, [method, options?.threshold])

  const [rootMargin, setRootMargin] = useState<IntersectionObserverInit['rootMargin']>()

  useIsomorphicLayoutEffect(() => {
    if (options?.rootMargin) {
      setRootMargin(options.rootMargin)
    } else if (el) {
      const { top, right, bottom, left } = window.getComputedStyle(el)
      const value = [top, right, bottom, left].map(val => getRootMarginFromStyle(val)).join(' ')
      setRootMargin(value)
    } else {
      setRootMargin(undefined)
    }
  }, [el, recalcTrigger, options?.rootMargin])

  const observerOptions = useMemo(
    () => ({
      ...options,
      rootMargin,
      threshold,
    }),
    [options, rootMargin, threshold],
  )

  useIsomorphicLayoutEffect(() => {
    if (!el) return () => {}

    const observer = new IntersectionObserver(
      entries => entries.forEach(entry => setResult(getResultFromEntry(entry, method))),
      observerOptions,
    )

    observer.observe(el)

    return () => observer.disconnect()
  }, [el, method, observerOptions])

  return result
}

function getInitialValue(method: EStickyObserverMethods): IStickyObserverResult {
  if (method === EStickyObserverMethods.SIMPLE) {
    return false
  }

  return {
    top: false,
    right: false,
    bottom: false,
    left: false,
    x: false,
    y: false,
  }
}

function getResultFromEntry(entry: IntersectionObserverEntry, method: EStickyObserverMethods): IStickyObserverResult {
  if (method === EStickyObserverMethods.SIMPLE) {
    return entry.intersectionRatio < 1
  }

  const { boundingClientRect, intersectionRect } = entry

  const top = intersectionRect.top > boundingClientRect.top
  const right = intersectionRect.right < boundingClientRect.right
  const bottom = intersectionRect.bottom < boundingClientRect.bottom
  const left = intersectionRect.left > boundingClientRect.left

  const x = right || left
  const y = top || bottom

  return {
    top,
    right,
    bottom,
    left,
    x,
    y,
  }
}

const PARSE_UNIT_RE = /([^\d]+)$/
const ALLOWED_UNITS = ['px', '%']

function getRootMarginFromStyle(value: string): string {
  let numberValue: Maybe<number> = parseFloat(value)

  // prettier-ignore
  numberValue = Number.isNaN(numberValue) // 'auto' value
    ? null
    : numberValue

  // https://davidwalsh.name/detect-sticky#comment-518440
  // prettier-ignore
  const margin = numberValue === null
    ? 0
    : (numberValue + 1) * -1

  let unit = PARSE_UNIT_RE.exec(value)?.[0] ?? 'px'

  // prettier-ignore
  unit = unit !== value // 'auto' value
    ? unit
    : 'px'

  if (process.env.NODE_ENV === 'development' && !ALLOWED_UNITS.includes(unit)) {
    // eslint-disable-next-line no-console
    console.warn(`Invalid css value: "${value}". "rootMargin" cannot be calculated from "${unit}"`)
    unit = 'px'
  }

  return [margin, unit].join('')
}
