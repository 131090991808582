import type { FC } from 'react'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import { CommonLink } from '@frontend/pole-ui/lib/components/CommonLink'
import { Button } from '@/components/ui'
import { Checkbox } from '@/components/ui/fields'
import { getLegalDocumentUrl } from '@/logic/legalDocuments/helpers'
import type { IRegistrationDocumentProps } from './interfaces'
import './styles.scss'

const RegistrationDocument: FC<IRegistrationDocumentProps> = props => {
  const { name, isRequired, descriptionParts, documents, onChange, onClick, onFocus } = props

  return (
    <div className="space-holder16-sm auth-registration-document">
      <Checkbox onFocus={onFocus} onChange={onChange} name={name} id={`authModal_${name}`} isRequired={isRequired} sizeMobile="large">
        <p className="auth-registration-document__text text_super-small">
          {descriptionParts.map(({ text, link }) => {
            if (text) return <FormattedMessage key={text} id={text} />

            if (link) {
              const { textId, type } = link
              const url = getLegalDocumentUrl(documents, type)
              if (!url) return null
              if (typeof onClick === 'function')
                return (
                  <Button
                    textId={textId}
                    className="underline underline_green text_super-small color_green unstyled-button"
                    onClick={() => onClick(url)}
                    key={url}
                  />
                )

              return (
                <CommonLink shouldOpenInNewWindow key={url} url={url} className="underline underline_green color_green">
                  <FormattedMessage id={textId} />
                </CommonLink>
              )
            }

            return null
          })}
        </p>
      </Checkbox>
    </div>
  )
}

export default RegistrationDocument
