import type { PayloadAction } from '@reduxjs/toolkit'
import { push } from 'connected-react-router'
import { combineEpics } from 'redux-observable'
import { catchError, concat, EMPTY, filter, from, mergeMap, of, switchMap, throwError } from 'rxjs'
import { isStatusOk } from '@/api/helpers'
import type { IResponse } from '@/api/interfaces'
import { fetchHistory } from '@/components/composed/History/actions'
import { closeModal, openModal } from '@/components/composed/Modal/actions'
import type { ICartProductsWithDifferentRegionModalOptions } from '@/components/composed/modals/CartProductsWithDifferentRegionModal/interfaces'
import type { IMessageModalOptions } from '@/components/composed/modals/MessageModal/interfaces'
import { fetchMultiCart } from '@/logic/cart/actions'
import { getCartData, getCartItems, getMultiCartResponse } from '@/logic/cart/reducer'
import { clearData } from '@/logic/data/actions'
import type { Epic } from '@/logic/interfaces'
import {
  addImpersonalProductToExistedOrder,
  addProductToExistingOrder,
  changeOrder,
  changeOrderStatus,
  deleteProductFromOrder,
  fetchAgrarianOrder,
  makeMultiOrder,
  makeOrder,
  recalculateAgrarianOrder,
  rejectOrder,
  setAgrarianOrder,
  setEditorModeState,
} from '@/logic/order/actions'
import { EOrderRequestName } from '@/logic/order/constants'
import type {
  IAddImpersonalProductToExistingOrderPayload,
  IAddProductToExistingOrderPayload,
  IChangeOrderPayload,
  IChangeOrderStatusPayload,
  IDeleteProductFromOrderPayload,
  IFetchAgrarianOrderPayload,
  IMakeMultiOrderPayload,
  IMakeOrderPayload,
  IRecalculateAgrarianOrderPayload,
  IRejectOrderPayload,
} from '@/logic/order/interfaces'
import { getAgrarianOrder } from '@/logic/order/reducer'
import { openNotUploadedDocumentsModal, openRequiredFieldsModal } from '@/logic/profile/helpers'
import { requestFailed, requestFinished, requestStarted } from '@/logic/requestStatus/actions'
import type { IRequestStatusPayload } from '@/logic/requestStatus/interfaces'
import { injectMessages } from '@/logic/translation/utils'
import type { ISkuSuccessCartOrderModalContentOptions } from '@/pages/AgriculturalProduct/components/SkuSuccessMultiOrderModal/interfaces'
import OrderService from '@/services/OrderService'
import { EOrderMetaStatus } from '@/services/OrderService/constants'
import type {
  IMakeMultiOrderParameters,
  IMakeOrderParameters,
  TMakingRequestErrors,
  TOrderRequiredFields,
} from '@/services/OrderService/interfaces'
import { isAgrarianOrder, isProductsWithDifferentRegion } from '@/typeguards/order'
import { ECartRoute, EUserProfileRoute } from '@/types'
import { ESkuOrderType } from '@/types/Catalog/ESkuOrderType'
import { EHistoryEntityType } from '@/types/History'
import type { IAgrarianOrder } from '@/types/Order'
import { EOrderDeliveryType } from '@/types/Order'
import messages from './messages.json'

injectMessages(messages)

const orderService = new OrderService()

const onMakeOrderDoSendRequest: Epic<IMakeOrderPayload, typeof makeOrder> = (action$, state$) =>
  action$.pipe(
    filter(makeOrder.match),
    mergeMap(({ payload }) => {
      const state = state$.value
      const { deliveryType, storeId, paymentMethod, agentId } = payload.formValues
      const cartId = getCartData(state)?.id

      const parameters: IMakeOrderParameters = {
        cartId,
        agentId,
        paymentType: payload.formValues.paymentMethod?.type,
        items: getCartItems(state).map(({ quantity, productOffer }) => ({ quantity, productOfferId: productOffer.id })),
        prepaymentPercentage: paymentMethod?.percentPrepayment,
        type: ESkuOrderType.SINGLE_ORDER,
        deliveryOptions: {
          deliveryType,
          storeId: deliveryType === EOrderDeliveryType.DELIVERY ? storeId : undefined,
        },
      }

      const requestStatus: IRequestStatusPayload = { name: EOrderRequestName.MAKE_ORDER }

      return concat(
        of(requestStarted(requestStatus)),
        from(orderService.createOrder(parameters)).pipe(
          mergeMap(response => {
            const { meta, data } = response

            if (response.meta.status === EOrderMetaStatus.DIFFERENT_REGION_IN_ITEMS && isProductsWithDifferentRegion(data)) {
              return [
                requestFailed({ ...requestStatus, meta }),
                openModal({
                  options: {
                    dialogId: 'CartProductsWithDifferentRegionModal',
                  },
                  contentOptions: {
                    products: data.items,
                  } as ICartProductsWithDifferentRegionModalOptions,
                }),
              ]
            }

            if (!isStatusOk(response)) return throwError(response)

            return [
              requestFinished({ ...requestStatus, data: data as IAgrarianOrder }),
              fetchMultiCart(),
              clearData({ name: 'cart' }),
              openModal({
                options: {
                  dialogId: 'SuccessCartOrderModal',
                  onCloseAction: () =>
                    push((getMultiCartResponse()(state)?.cartsNumber || 1) - 1 > 0 ? ECartRoute.multiCart : EUserProfileRoute.orders),
                },
                contentOptions: {
                  paymentMethodType: payload.formValues.paymentMethod.type,
                },
              }),
            ]
          }),
          catchError(({ data, meta }: IResponse<TMakingRequestErrors>) =>
            of(
              requestFailed({ ...requestStatus, meta, data }),
              openModal({
                options: {
                  dialogId: 'MessageModal',
                  headerId: 'agrarianOrder.makingOrderError.title',
                },
                contentOptions: {
                  messages: [{ id: 'agrarianOrder.makingOrderError.text', className: 'space-holder32' }],
                  buttons: [{ textId: 'agrarianOrder.makingOrderError.button', modifiers: ['outline'] }],
                } as IMessageModalOptions,
              }),
            ),
          ),
        ),
      )
    }),
  )

const onMakeMultiOrderDoSendRequest: Epic<IMakeMultiOrderPayload, typeof makeMultiOrder> = action$ =>
  action$.pipe(
    filter(makeMultiOrder.match),
    mergeMap(({ payload }) => {
      const { quantity, storeId, agentId } = payload.formValues

      const parameters: IMakeMultiOrderParameters = {
        items: [{ quantity, skuId: payload.skuId }],
        prepaymentPercentage: 100,
        type: ESkuOrderType.MULTI_ORDER,
        deliveryOptions: {
          storeId,
          deliveryType: EOrderDeliveryType.DELIVERY,
        },
        agentId,
      }
      const requestStatus: IRequestStatusPayload = { name: EOrderRequestName.MAKE_MULTI_ORDER }

      return concat(
        of(requestStarted(requestStatus)),
        from(orderService.createMultiOrder(parameters)).pipe(
          mergeMap(response => {
            const { data } = response

            if (!isStatusOk(response)) return throwError(response)

            return [
              requestFinished({ ...requestStatus, data: data as IAgrarianOrder }),
              openModal({
                options: {
                  dialogId: 'SkuSuccessMultiOrderModal',
                  onCloseAction: () => push(EUserProfileRoute.orders),
                },
                contentOptions: {
                  categoryId: payload.categoryId,
                  payload,
                } as ISkuSuccessCartOrderModalContentOptions,
              }),
            ]
          }),
          catchError(({ data, meta }: IResponse<TMakingRequestErrors>) =>
            of(
              requestFailed({ ...requestStatus, meta, data }),
              openModal({
                options: {
                  dialogId: 'MessageModal',
                  headerId: 'agrarianOrder.makingOrderError.title',
                },
                contentOptions: {
                  messages: [{ id: 'agrarianOrder.makingOrderError.text', className: 'space-holder32' }],
                  buttons: [{ textId: 'agrarianOrder.makingOrderError.button', modifiers: ['outline'] }],
                } as IMessageModalOptions,
              }),
            ),
          ),
        ),
      )
    }),
  )

const onAddProductToExistedOrderDoMakeRequest: Epic<IAddProductToExistingOrderPayload, typeof addProductToExistingOrder> = (
  action$,
  state$,
) =>
  action$.pipe(
    filter(addProductToExistingOrder.match),
    switchMap(({ payload }) => {
      const requestStatus = { name: EOrderRequestName.ADD_PRODUCT_TO_EXISTING_ORDER }
      const { updateDate: version, id: orderId } = getAgrarianOrder()(state$.value) || {}

      if (!version || !orderId) {
        return of(requestFailed(requestStatus))
      }

      return concat(
        of(requestStarted(requestStatus)),
        from(
          orderService.addProductToOrder({
            quantity: 1,
            orderId,
            version,
            productId: payload.productId,
          }),
        ).pipe(
          mergeMap(response => {
            if (!isStatusOk(response)) return throwError(response)

            return [requestFinished(requestStatus), setAgrarianOrder({ order: response.data })]
          }),
          catchError(({ meta }) => of(requestFailed({ ...requestStatus, meta }))),
        ),
      )
    }),
  )

const onAddImpersonalProductToExistedOrderDoMakeRequest: Epic<
  IAddImpersonalProductToExistingOrderPayload,
  typeof addImpersonalProductToExistedOrder
> = (action$, state$) =>
  action$.pipe(
    filter(addImpersonalProductToExistedOrder.match),
    switchMap(({ payload }) => {
      const { id, updateDate: version } = getAgrarianOrder()(state$.value) || {}
      const requestStatus = { name: EOrderRequestName.ADD_IMPERSONAL_PRODUCT_TO_EXISTING_ORDER }

      if (!version || !id) {
        return of(requestFailed(requestStatus))
      }

      return concat(
        of(requestStarted(requestStatus)),
        from(
          orderService.addImpersonalProductToOrder({
            orderId: id,
            sku: payload,
            version,
          }),
        ).pipe(
          mergeMap(response => {
            if (!isStatusOk(response)) return throwError(response)

            return [
              requestFinished(requestStatus),
              setAgrarianOrder({ order: { ...response.data, updateDate: version } }),
              setEditorModeState(true),
            ]
          }),
          catchError(({ meta }) => of(requestFailed({ ...requestStatus, meta }))),
        ),
      )
    }),
  )

const onDeleteProductFromOrderDoMakeRequest: Epic<IDeleteProductFromOrderPayload, typeof deleteProductFromOrder> = (action$, state$) =>
  action$.pipe(
    filter(deleteProductFromOrder.match),
    switchMap(({ payload }) => {
      const requestStatus = { name: EOrderRequestName.DELETE_PRODUCT_FROM_ORDER }
      const { updateDate: version, id: orderId } = getAgrarianOrder()(state$.value) || {}

      if (!version || !orderId) {
        return of(requestFailed(requestStatus))
      }

      return concat(
        of(requestStarted(requestStatus)),
        from(
          orderService.deleteProductFromOrder({
            orderId,
            version,
            productId: payload.productId,
          }),
        ).pipe(
          mergeMap(response => {
            if (!isStatusOk(response)) return throwError(response)

            return [requestFinished(requestStatus), setAgrarianOrder({ order: response.data })]
          }),
          catchError(({ meta }) => of(requestFailed({ ...requestStatus, meta }))),
        ),
      )
    }),
  )

const openNotificationByUpdatingRequest = () =>
  openModal({
    options: {
      dialogId: 'MessageModal',
      headerId: 'agrarianOrder.changingStatusFailure.title',
    },
    contentOptions: {
      messages: [{ id: 'agrarianOrder.changingStatusFailure.text' }],
      buttons: [
        {
          textId: 'agrarianOrder.changingStatusFailure.acceptButton',
          modifiers: ['green', 'full-width'],
          className: 'space-holder-top32',
        },
      ],
    } as IMessageModalOptions,
  })
const isRequestAlreadyChanged = (response: IResponse<unknown>) => response.meta.status === EOrderMetaStatus.ORDER_CHANGED

const onRecalculateAgrarianOrderDoMakeRequest: Epic<IRecalculateAgrarianOrderPayload, typeof recalculateAgrarianOrder> = (
  action$,
  state$,
) =>
  action$.pipe(
    filter(recalculateAgrarianOrder.match),
    switchMap(({ payload }) => {
      const { changedValues } = payload
      const { id, updateDate: version } = getAgrarianOrder()(state$.value) || {}
      const requestStatus = { name: EOrderRequestName.RECALCULATE_ORDER }

      if (!version || !id) {
        return of(requestFailed(requestStatus))
      }

      return concat(
        of(requestStarted(requestStatus)),
        from(
          orderService.recalculateOrder({
            orderId: id,
            changedValues,
            version,
          }),
        ).pipe(
          mergeMap(response => {
            if (isRequestAlreadyChanged(response)) return [openNotificationByUpdatingRequest(), fetchAgrarianOrder({ id })]

            if (!isStatusOk(response)) return throwError(response)

            return [requestFinished(requestStatus), setAgrarianOrder({ order: { ...response.data, updateDate: version } })]
          }),
          catchError(({ meta }) => of(requestFailed({ ...requestStatus, meta }))),
        ),
      )
    }),
  )

const onFetchAgrarianOrderDoMakeRequest: Epic<IFetchAgrarianOrderPayload, typeof fetchAgrarianOrder> = (action$, state$) =>
  action$.pipe(
    filter(fetchAgrarianOrder.match),
    switchMap(({ payload }) =>
      concat(
        payload.shouldNotShowLoadingState ? EMPTY : of(requestStarted({ name: EOrderRequestName.FETCH_ORDER })),
        from(orderService.getOrder(payload.id)).pipe(
          mergeMap(response => {
            if (!isStatusOk(response)) return throwError(response)

            return concat(
              of(setAgrarianOrder({ order: response.data })).pipe(
                filter(() => payload.shouldLoadForcibly || response.data.updateDate !== getAgrarianOrder()(state$.value)?.updateDate),
              ),
              of(requestFinished({ name: EOrderRequestName.FETCH_ORDER })),
            )
          }),
          catchError(() => of(requestFailed({ name: EOrderRequestName.FETCH_ORDER }))),
        ),
      ),
    ),
  )

const onChangeAgrarianOrderStatusDoMakeRequest: Epic<IChangeOrderStatusPayload, typeof changeOrderStatus> = (action$, state$) =>
  action$.pipe(
    filter(changeOrderStatus.match),
    mergeMap(({ payload }) => {
      //@ts-ignore
      const { statusComment, rejectHeaderId } = payload
      const { id, updateDate } = getAgrarianOrder()(state$.value) || {}
      const requestStatusPayload: IRequestStatusPayload = { name: EOrderRequestName.CHANGE_ORDER_STATUS }

      if (!updateDate || !id) {
        return of(requestFailed(requestStatusPayload))
      }

      return concat(
        of(requestStarted(requestStatusPayload)),
        from(orderService.changeOrderStatus({ orderId: id, version: updateDate, statusComment })).pipe(
          mergeMap(response => {
            if (isRequestAlreadyChanged(response)) {
              return [requestFinished(requestStatusPayload), fetchAgrarianOrder({ id }), openNotificationByUpdatingRequest()]
            }

            if (isStatusOk(response)) {
              return [
                requestFinished(requestStatusPayload),
                setAgrarianOrder({ order: response.data }),
                fetchHistory({ entityId: Number(id), entity: EHistoryEntityType.ORDERS }),
              ]
            }

            return throwError(response)
          }),
          catchError(({ meta, data }) => {
            //eslint-disable-next-line @typescript-eslint/no-explicit-any
            const actions: PayloadAction<any>[] = [requestFailed(requestStatusPayload)]

            switch (meta?.status) {
              case EOrderMetaStatus.REQUIRED_DOCUMENTS_NOT_UPLOAD:
                actions.push(openNotUploadedDocumentsModal(data))
                break
              case EOrderMetaStatus.REQUEST_FIELDS_REQUIRED:
                actions.push(openRequiredFieldsModal(data, rejectHeaderId))
                break
              default:
            }

            return actions
          }),
        ),
      )
    }),
  )

const onChangeAgrarianOrderDoMakeRequest: Epic<IChangeOrderPayload, typeof changeOrder> = (action$, state$) =>
  action$.pipe(
    filter(changeOrder.match),
    switchMap(({ payload }) => {
      const { changedValues, statusComment, onSuccess } = payload
      const { id, updateDate } = getAgrarianOrder()(state$.value) || {}
      const requestStatusPayload: IRequestStatusPayload = { name: EOrderRequestName.CHANGE_ORDER }

      if (!updateDate || !id) {
        return of(requestFailed(requestStatusPayload))
      }

      return concat(
        of(requestStarted(requestStatusPayload)),
        from(orderService.changeOrder({ changedValues, orderId: id, version: updateDate, statusComment })).pipe(
          mergeMap(response => {
            if (isRequestAlreadyChanged(response))
              return [requestFinished(requestStatusPayload), openNotificationByUpdatingRequest(), fetchAgrarianOrder({ id })]

            if (isStatusOk(response) && isAgrarianOrder(response.data)) {
              onSuccess?.()

              return [requestFinished(requestStatusPayload), setAgrarianOrder({ order: response.data })]
            }

            return throwError(response)
          }),
          catchError(({ meta, data }: IResponse<TOrderRequiredFields>) => {
            //eslint-disable-next-line @typescript-eslint/no-explicit-any
            const actions: PayloadAction<any>[] = [requestFailed(requestStatusPayload)]

            if (meta?.status === EOrderMetaStatus.REQUEST_FIELDS_REQUIRED) {
              actions.push(openRequiredFieldsModal(data))
            }

            return actions
          }),
        ),
      )
    }),
  )

const onRejectAgrarianOrderDoMakeRequest: Epic<IRejectOrderPayload, typeof rejectOrder> = (action$, state$) =>
  action$.pipe(
    filter(rejectOrder.match),
    mergeMap(({ payload }) => {
      const { redirectUrl, statusComment } = payload
      const { id, updateDate } = getAgrarianOrder()(state$.value) || {}
      const requestStatusPayload: IRequestStatusPayload = { name: EOrderRequestName.REJECT_ORDER }

      if (!updateDate || !id) {
        return of(requestFailed(requestStatusPayload))
      }

      return concat(
        of(requestStarted(requestStatusPayload)),
        from(orderService.deleteOrder({ version: updateDate, orderId: id, statusComment })).pipe(
          mergeMap(response => {
            if (isRequestAlreadyChanged(response))
              return [requestFinished(requestStatusPayload), openNotificationByUpdatingRequest(), fetchAgrarianOrder({ id })]

            if (isStatusOk(response)) return [requestFinished(requestStatusPayload), push(redirectUrl), closeModal()]

            return throwError(response)
          }),
          catchError(() => of(requestFailed(requestStatusPayload))),
        ),
      )
    }),
  )

//eslint-disable-next-line @typescript-eslint/no-explicit-any
export default combineEpics<any, any, any>(
  onMakeOrderDoSendRequest,
  onMakeMultiOrderDoSendRequest,
  onAddProductToExistedOrderDoMakeRequest,
  onAddImpersonalProductToExistedOrderDoMakeRequest,
  onDeleteProductFromOrderDoMakeRequest,
  onRecalculateAgrarianOrderDoMakeRequest,
  onFetchAgrarianOrderDoMakeRequest,
  onChangeAgrarianOrderStatusDoMakeRequest,
  onChangeAgrarianOrderDoMakeRequest,
  onRejectAgrarianOrderDoMakeRequest,
)
