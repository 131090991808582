import type { FC } from 'react'
import React from 'react'
import { CommonLink } from '@frontend/pole-ui/lib/components/CommonLink'
import parse from 'html-react-parser'
import { EJournalRoute } from '@/types'
import { createRouterLink } from '@/utils/url'
import type { IJournalSearchSuggestionCardProps } from './types'
import './styles.scss'

export const JournalSearchSuggestionCard: FC<IJournalSearchSuggestionCardProps> = props => {
  const { suggestion } = props
  // TODO: rubricName пока что нету в АПИ оно будет добавлено позже
  //@ts-ignore
  const { highlight, slug, title, rubricName } = suggestion

  const suggestionLink = createRouterLink(EJournalRoute.PUBLICATION_ITEM, { publicationSlug: slug })

  return (
    <CommonLink url={suggestionLink} className="link-no-hover journalSearchSuggestionCard">
      <p>{highlight ? parse(highlight) : title}</p>
      {rubricName && <p className="text_small journalSearchSuggestionCard__rubricName">{rubricName}</p>}
    </CommonLink>
  )
}
