
export enum SupplyActionDtoType {
    "CHANGE_STATUS" = "CHANGE_STATUS",
    "DOWNLOAD" = "DOWNLOAD",
    "EDIT_SUPPLY" = "EDIT_SUPPLY",
    "DELETE_SUPPLY" = "DELETE_SUPPLY"
}
export enum SupplyActionDtoNextStatus {
    "SCHEDULED" = "SCHEDULED",
    "COMPLETED" = "COMPLETED",
    "PAYMENT_RECEIVED" = "PAYMENT_RECEIVED"
}
export type SupplyActionDto = {
    /**
     * @type string
    */
    name: string;
    /**
     * @type string
    */
    type: SupplyActionDtoType;
    /**
     * @type string | undefined
    */
    url?: string;
    /**
     * @type string
    */
    nextStatus: SupplyActionDtoNextStatus;
};
