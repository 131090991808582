import type { QueryKey, UseQueryResult, UseQueryOptions, QueryOptions } from "@tanstack/react-query";
import { useQuery } from "@tanstack/react-query";
import client from "@/api/client/axios";
import type { GetMobileBannerQueryResponse, GetMobileBannerPathParams } from "../types/GetMobileBanner";

export const getMobileBannerQueryKey = (bannerId: GetMobileBannerPathParams["bannerId"]) =>     [{url:`/api/ms-content-infoportal-backend/v1/web/banners/${bannerId}`,params:{bannerId:bannerId}}] as const;
      export function getMobileBannerQueryOptions <TData = GetMobileBannerQueryResponse, TError = unknown>(bannerId: GetMobileBannerPathParams["bannerId"], options: Partial<Parameters<typeof client>[0]> = {}): UseQueryOptions<TData, TError> {
        const queryKey = getMobileBannerQueryKey(bannerId, );

        return {
          queryKey,
          queryFn: () => {
            
            return client<TData, TError>({
              method: "get",
              url: `/api/ms-content-infoportal-backend/v1/web/banners/${bannerId}`,
              
              
              ...options,
            }).then(res => res.data);
          },
        };
      };
      
/**
 * @link /api/ms-content-infoportal-backend/v1/web/banners/:bannerId
 */

export function useGetMobileBanner <TData = GetMobileBannerQueryResponse, TError = unknown>(bannerId: GetMobileBannerPathParams["bannerId"], options: {
          query?: UseQueryOptions<TData, TError>,
          client?: Partial<Parameters<typeof client<TData, TError>>[0]>,
        } = {}): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const { query: queryOptions, client: clientOptions = {} } = options ?? {};
  const queryKey = queryOptions?.queryKey ?? getMobileBannerQueryKey(bannerId, );

  const query = useQuery<TData, TError>({
    ...getMobileBannerQueryOptions<TData, TError>(bannerId, clientOptions),
    ...queryOptions
  }) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey as QueryKey;

  return query;
};
