import React from 'react'
import { FormattedMessage } from 'react-intl'
import { EColor } from '@frontend/pole-ui/lib/common/constants'
import type { IIconProps } from '@frontend/pole-ui/lib/components/Icon'
import { EIconName, EIconSize, Icon } from '@frontend/pole-ui/lib/components/Icon'
import { useDeviceType } from '@/utils/hooks/useDeviceType'
import type { IStatusMarkerProps } from './interfaces'
import { EStatus } from './interfaces'

const IconProps: Record<EStatus, IIconProps> = {
  [EStatus.REJECTED]: {
    name: EIconName.Error,
    style: { color: EColor.RED },
  },
  [EStatus.LOADED]: {
    name: EIconName.OkFill,
    style: { color: EColor.GREEN },
  },
  [EStatus.NOT_LOADED]: {
    name: EIconName.EmptyFill,
    style: { color: EColor.LIGHT_GREEN },
  },
}

const StatusMarker: React.FC<IStatusMarkerProps> = ({ status, mobileLabelId }) => {
  const { isMobile } = useDeviceType()

  return (
    <span className="row row_nowrap row-align_center status-marker">
      <Icon {...{ ...IconProps[status], size: isMobile ? EIconSize.XS : EIconSize.M }} />
      {mobileLabelId && (
        <p className="hidden-from-m color_pale-black text_small space-holder-left8-sm">
          <FormattedMessage id={mobileLabelId} />
        </p>
      )}
    </span>
  )
}

export default StatusMarker
