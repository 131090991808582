import React from 'react'
import { EIconName } from '@frontend/pole-ui/lib/components/Icon'
import classNames from 'classnames'
import { Button } from '@/components/ui'
import type { IDeleteButtonProps } from './interfaces'
import './styles.scss'

const DeleteButton: React.FC<IDeleteButtonProps> = ({ onClick, style, className }) => (
  <Button
    iconProps={{ name: EIconName.Delete }}
    className={classNames('delete-button', className)}
    onClick={onClick}
    style={style}
    isUnstyled
  />
)

export default DeleteButton
