
export enum OrderSupplyUpdateStatusRequestNextStatus {
    "SCHEDULED" = "SCHEDULED",
    "COMPLETED" = "COMPLETED",
    "PAYMENT_RECEIVED" = "PAYMENT_RECEIVED"
}
export type OrderSupplyUpdateStatusRequest = {
    /**
     * @type string
    */
    nextStatus: OrderSupplyUpdateStatusRequestNextStatus;
};
