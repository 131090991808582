import type { PayloadAction } from '@reduxjs/toolkit'
import { combineEpics } from 'redux-observable'
import { EMPTY, merge, of, throwError } from 'rxjs'
import { catchError, filter, mergeMap, takeUntil } from 'rxjs/operators'
import { isStatusOk } from '@/api/helpers'
import request from '@/api/request'
import type { RootState } from '@/redux/interfaces'
import type { Epic, EpicState } from '../interfaces'
import type { NDataAction } from './actions'
import { loadData, loadingDataFailed, loadingDataFinished, loadingDataStarted } from './actions'
import { apiUrls } from './apiUrls'
import { getTransformedResponse } from './helpers'
import type { TRequestParams } from './interfaces'
import { getRequestData } from './reducer'

const filterRequest = (state$: EpicState<RootState>) =>
  filter<PayloadAction<NDataAction.ILoadData>>(({ payload }) => {
    const requestedData = getRequestData(payload.name)(state$.value)

    return Boolean(payload.shouldLoadForcibly) || (!requestedData?.data && !requestedData?.isLoading)
  })

const onLoadDataDoMakeRequest: Epic<NDataAction.ILoadData, typeof loadData> = (action$, state$) =>
  action$.pipe(
    filter(loadData.match),
    filterRequest(state$),
    mergeMap(({ payload }) => {
      const { name, params, shouldNotShowLoadingState, shouldAccumulate, isLoadingOnMount } = payload
      const { value: state } = state$
      const { isLoadedWithSsr } = getRequestData(name)(state)

      const lastData = getRequestData<unknown>(name)(state)?.data
      if (isLoadedWithSsr && isLoadingOnMount) return [loadingDataFinished({ data: lastData, name })]

      return merge(
        shouldNotShowLoadingState ? EMPTY : of(loadingDataStarted({ name })),
        request<unknown>({
          url: apiUrls[name](params as TRequestParams, state),
          method: 'GET',
        }).pipe(
          takeUntil(
            action$.pipe(
              filter(loadData.match),
              filterRequest(state$),
              filter(action => action.payload.name === payload.name && !getRequestData(payload.name)(state$.value).isLoading),
            ),
          ),
          mergeMap(response => {
            if (!isStatusOk(response)) return throwError(response)

            return [loadingDataFinished({ data: getTransformedResponse(name, response.data)(state), name, shouldAccumulate })]
          }),
          catchError(response => [loadingDataFailed({ name, meta: response.meta })]),
        ),
      )
    }),
  )
//eslint-disable-next-line @typescript-eslint/no-explicit-any
export default (<any>combineEpics)(onLoadDataDoMakeRequest)
