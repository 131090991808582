import React from 'react'
import { Breadcrumbs } from '@/components/ui'
import { useDeviceType } from '@/utils/hooks/useDeviceType'
import { breadcrumbs } from '../../constants'
import { getArticleBreadcrumbs } from '../../helpers'
import type { IFaqBreadcrumbsProps } from './interfaces'
import './styles.scss'

const FaqBreadcrumbs: React.FC<IFaqBreadcrumbsProps> = ({ category }) => {
  const { isMobile } = useDeviceType()

  if (!isMobile) return null

  return (
    <div className="FaqMobileBreadcrumbsWrapper">
      <Breadcrumbs isMobile={isMobile} customBreadcrumbs={[...breadcrumbs, ...getArticleBreadcrumbs(category)]} />
    </div>
  )
}

export default FaqBreadcrumbs
