import React, { useContext } from 'react'
import { FormattedMessage } from 'react-intl'
import { useSelector } from 'react-redux'
import { EIconName, Icon } from '@frontend/pole-ui/lib/components/Icon'
import filter from 'lodash/filter'
import { Button, ErrorMessage } from '@/components/ui'
import { AccreditationContext } from '@/logic/accreditation/constants'
import { getRequestState } from '@/logic/requestStatus/reducer'
import { injectMessages } from '@/logic/translation/utils'
import type { RootState } from '@/redux/interfaces'
import { EDocumentStatus } from '@/types/Documents'
import { useDeviceType } from '@/utils/hooks/useDeviceType'
import messages from './messages.json'
import './styles.scss'

injectMessages(messages)

const mapState = (state: RootState) => ({
  requestState: getRequestState('makeAccreditationProcess_edit')(state),
})

const DocumentsResubmissionBanner = () => {
  const { documents, accreditationActions, editAccreditation, isManager, statusParameters } = useContext(AccreditationContext)
  const { edit: editButton } = accreditationActions
  const { needUpdate } = statusParameters
  const { requestState } = useSelector(mapState)

  const { isTablet } = useDeviceType()

  if (!editButton || isManager || !needUpdate) return null

  const rejectedDocuments = filter(documents, { status: EDocumentStatus.REJECTED })
  const { isLoading, isFailure } = requestState

  return (
    <section className="user-accreditation-resubmission-banner row row_nowrap space-holder-top56-from-sm">
      <span className="space-holder-right8-from-sm">
        <Icon name={EIconName.Warning} className="user-accreditation-resubmission-banner__icon" />
      </span>
      <div>
        <FormattedMessage id="userAccreditation.resubmissionBanner.title" tagName="h5" />

        <ul className="user-accreditation-resubmission-banner-documents list-with-marks list-with-marks_black text_small-from-sm">
          {rejectedDocuments.map(({ name, comment }) => (
            <li key={name} className="space-holder-top8">
              {name}
              <br />
              <strong>
                <FormattedMessage id="userAccreditation.resubmissionBanner.reason" />
              </strong>
              &nbsp;
              {comment}
            </li>
          ))}
        </ul>
      </div>

      <div className="space-holder-left-auto space-holder-top4-from-sm row row_column user-accreditation-resubmission-banner__button">
        <Button
          onClick={editAccreditation}
          modifiers={isTablet ? ['outline', 'small'] : ['outline']}
          loaderProps={{ isVisible: isLoading }}
        >
          {editButton.name}
        </Button>
        <ErrorMessage isVisible={isFailure} textId="userAccreditation.resubmissionBanner.error" className="space-holder-top16" />
      </div>
    </section>
  )
}

export default DocumentsResubmissionBanner
