import { isMobile, isTablet } from '@/logic/app/reducer'
import type { RootState } from '@/redux/interfaces'
import { useDeviceType } from '@/utils/hooks/useDeviceType'
import type { TResponsiveValue } from './interfaces'

export const getResponsiveValue =
  <GValue>(ResponsiveValue: TResponsiveValue<GValue>) =>
  (state: RootState): GValue => {
    const isTabletDevice = isTablet()(state)
    const isMobileDevice = isMobile()(state)
    let value: GValue = ResponsiveValue.desktop
    if (isMobileDevice) value = ResponsiveValue.mobile
    else if (isTabletDevice) value = ResponsiveValue.tablet

    return value
  }

export const useResponsiveValue = <GValue>(ResponsiveValue: TResponsiveValue<GValue>): GValue => {
  const { isMobile: isMobileDevice, isTablet: isTabletDevice } = useDeviceType()

  let value: GValue = ResponsiveValue.desktop
  if (isMobileDevice) value = ResponsiveValue.mobile
  else if (isTabletDevice) value = ResponsiveValue.tablet

  return value
}
