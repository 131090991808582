import { createAction } from '@reduxjs/toolkit'
import type { IAuthModalFormValues } from '@/components/composed/modals/AuthModal/interfaces'
import type { IError } from './interfaces'

export declare namespace NAuthAction {
  interface ISubmitLoginFormPayload {
    phone: string
    code: string
  }

  interface ISetJwtPayload {
    jwt: string
  }

  interface ISendingSmsRequestFinishedPayload {
    isSendingAgain?: boolean
    tokenId: string
  }

  interface ISendingSmsRequestFailedPayload {
    shouldRegisterUser: boolean
  }

  interface ISendCodeBySmsPayload {
    phone: string
    isSendingAgain?: boolean
  }

  interface ISetAuthErrorStatus {
    errorStatus: string
  }

  interface ISetSendingSmsCodeStatePayload {
    canSendCodeAgain: boolean
    timeUntilSendingCode?: number
  }

  interface ISendRegistrationRequestPayload {
    formValues: IAuthModalFormValues
    isSendingAgain?: boolean
  }

  interface IRegistrationSuccessPayload {
    tokenId: string
  }

  interface IAuthImpersonationRequestFailedPayload {
    status: string
  }

  interface IRegistrationRequestFailedPayload extends IError {}

  interface IIAuthImpersonationRequest {
    phone: string
  }

  interface IAuthLogoutPayload {
    redirectUrl: string
  }
}

export const clearSession = createAction('@@auth/CLEAR_SESSION')

export const logoutUser = createAction<NAuthAction.IAuthLogoutPayload>('@@auth/LOGOUT_USER')

export const authRequestStarted = createAction('@@auth/REQUEST_STARTED')

export const authRequestFinished = createAction('@@auth/REQUEST_FINISHED')

export const setJwt = createAction<NAuthAction.ISetJwtPayload>('@@auth/SET_JWT')

// TODO Не используется, либо заюзать, либо удалить
export const startRefreshJwt = createAction('@@auth/START_REFRESH_JWT')

export const authRefreshSuccess = createAction('@@auth/REFRESH_SUCCESS')

export const authRequestFailed = createAction<NAuthAction.ISetAuthErrorStatus>('@@auth/AUTH_REQUEST_FAILED')

export const sendCodeBySms = createAction<NAuthAction.ISendCodeBySmsPayload>('@@auth/SEND_SMS_CODE')

export const sendingSmsRequestFinished = createAction<NAuthAction.ISendingSmsRequestFinishedPayload>('@@auth/SENDING_SMS_REQUEST_FINISHED')

export const sendingSmsRequestFailed = createAction<NAuthAction.ISendingSmsRequestFailedPayload>('@@auth/SENDING_SMS_REQUEST_FAILED')

export const submitLoginForm = createAction<NAuthAction.ISubmitLoginFormPayload>('@@auth/SUBMIT_LOGIN_FORM')

export const setSendingCodeState = createAction<NAuthAction.ISetSendingSmsCodeStatePayload>('@@auth/SET_SENDING_SMS_CODE_STATE')

export const sendRegistrationRequest = createAction<NAuthAction.ISendRegistrationRequestPayload>('@@auth/SEND_REGISTRATION_REQUEST')

export const registrationRequestSuccess = createAction<NAuthAction.IRegistrationSuccessPayload>('@@auth/REGISTRATION_SUCCESS')

export const registrationRequestFailed = createAction<NAuthAction.IRegistrationRequestFailedPayload>('@@auth/REGISTRATION_FAILED')

export const authDialogClosed = createAction('@@auth/AUTH_DIALOG_CLOSED')

export const resetAuthState = createAction('@@auth/RESET_AUTH_STATE')

export const authImpersonationRequest = createAction<NAuthAction.IIAuthImpersonationRequest>('@@auth/IMPERSONATION_REQUEST')

export const authImpersonationRequestFinished = createAction('@@auth/IMPERSONATION_REQUEST_FINISHED')

export const authImpersonationRequestFailed = createAction<NAuthAction.IAuthImpersonationRequestFailedPayload>(
  '@@auth/IMPERSONATION_REQUEST_FAILED',
)

export const logoutImpersonationUser = createAction('@@auth/LOGOUT_IMPERSONATION_USER')

export const clearError = createAction('@@auth/CLEAR_ERROR')
