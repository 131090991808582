import type { RegionData } from "./RegionData";

export enum MainPageBannerDataSpecialAction {
    "REGISTRATION_FORM" = "REGISTRATION_FORM",
    "URL" = "URL"
}
export type MainPageBannerData = {
    /**
     * @type string | undefined
    */
    url?: string;
    /**
     * @type array
    */
    regions: RegionData[];
    /**
     * @type string
    */
    webImage: string;
    /**
     * @type string
    */
    mobileImage: string;
    /**
     * @type string
    */
    specialAction: MainPageBannerDataSpecialAction;
};
