import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { openModal } from '@/components/composed/Modal/actions'
import type { IAuthModalOptions } from '@/components/composed/modals/AuthModal/interfaces'
import { logoutUser, resetAuthState } from '@/logic/auth/actions'
import { getProfilePath, isAuthenticated } from '@/logic/auth/reducer'
import type { RootState } from '@/redux/interfaces'

const mapState = (state: RootState) => ({
  isAuthenticated: isAuthenticated()(state),
  profilePath: getProfilePath()(state),
})

export const useAuthenticateUser = () => {
  const dispatch = useDispatch()

  return {
    ...useSelector(mapState),
    logoutUser: useCallback((redirectUrl = '/') => dispatch(logoutUser({ redirectUrl })), [dispatch]),
    openAuthModal: useCallback(
      (contentOptions?: IAuthModalOptions) => {
        if (!contentOptions?.codeAlreadySent) {
          dispatch(resetAuthState())
        }

        dispatch(
          openModal({
            options: { dialogId: 'AuthModal', shouldCloseOnMissingClick: false, onCloseModal: contentOptions?.onCloseModal },
            contentOptions,
          }),
        )
      },
      [dispatch],
    ),
  }
}
