import React, { useContext } from 'react'
import { useDispatch } from 'react-redux'
import { openModal } from '@/components/composed/Modal/actions'
import { DocumentCard } from '@/components/resolvers'
import type { TUpdateDocumentAction } from '@/components/resolvers/DocumentCard/interfaces'
import { downloadAccreditationDocument, updateAccreditationDocument } from '@/logic/accreditation/actions'
import { AccreditationContext } from '@/logic/accreditation/constants'
import type { IAccreditationDocumentCardProps } from '@/logic/accreditation/interfaces'
import { EAccreditationDocumentAction } from '@/logic/accreditation/interfaces'
import { EDocumentStatus } from '@/types/Documents'
import type { IRejectingDocumentModalOptions } from './RejectingDocumentModal/interfaces'

const AccreditationDocumentCard: React.FC<IAccreditationDocumentCardProps> = ({ document }) => {
  const { name, status: documentStatus, url, type, fileName, id: documentId, isUKEP } = document
  const { accreditationId, accreditationActions, version } = useContext(AccreditationContext)

  const {
    rejectDocument: rejectingDocumentButton,
    deleteDocument: canDeleteDocument,
    updateDocument: canUpdateDocument,
  } = accreditationActions
  const dispatch = useDispatch()

  const updateDocument = (action: TUpdateDocumentAction, file?: File) => {
    switch (action) {
      case 'delete':
        dispatch(
          updateAccreditationDocument({
            accreditationId,
            documentId,
            documentType: type,
            action: EAccreditationDocumentAction.DELETE,
            version,
          }),
        )

        break
      case 'upload':
        if (!file) {
          // TODO split update/upload and delete actions to 2 different functions with different signatures
          // eslint-disable-next-line no-console
          console.error('file field is required for "upload document" action')

          return
        }

        dispatch(
          updateAccreditationDocument({
            accreditationId,
            file,
            documentType: type,
            action: EAccreditationDocumentAction.UPLOAD,
            version,
            documentName: name,
          }),
        )

        break
      case 'update':
      default:
        if (!file) {
          // TODO split update/upload and delete actions to 2 different functions with different signatures
          // eslint-disable-next-line no-console
          console.error('file field is required for "update document" action')

          return
        }

        dispatch(
          updateAccreditationDocument({
            documentId,
            accreditationId,
            file,
            documentType: type,
            action: EAccreditationDocumentAction.UPDATE,
            version,
            documentName: name,
          }),
        )

        break
    }
  }

  const downloadDocument = () => dispatch(downloadAccreditationDocument({ url, fileName }))

  const openRejectingDocumentModal = (): void => {
    dispatch(
      openModal({
        options: {
          dialogId: 'RejectingDocumentModal',
        },
        contentOptions: { entityId: accreditationId, documentId, version, type: 'accreditations' } as IRejectingDocumentModalOptions,
      }),
    )
  }

  return (
    <DocumentCard
      document={document}
      updateDocumentRequestName={`updateAccreditationDocument_${type}`}
      downloadDocumentRequestName={`downloadAccreditationDocument_${url}`}
      deleteDocumentRequestName={`deleteAccreditationDocument_${type}`}
      openRejectingDocumentModal={openRejectingDocumentModal}
      isDocumentRejected={documentStatus === EDocumentStatus.REJECTED}
      onDownloadDocument={downloadDocument}
      onUpdateDocument={updateDocument}
      canDeleteDocument={Boolean(canDeleteDocument)}
      canUpdateDocument={Boolean(canUpdateDocument && !isUKEP)}
      rejectingDocumentButtonText={rejectingDocumentButton?.name}
      documentStatus={EDocumentStatus[documentStatus]}
    />
  )
}

export default AccreditationDocumentCard
