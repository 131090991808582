import type { FC } from 'react'
import React, { memo } from 'react'
import type { IAccreditationUkepRequestBannerProps } from './interfaces'
import './styles.scss'

const AccreditationUkepRequestBanner: FC<IAccreditationUkepRequestBannerProps> = props => {
  const { text, description, button } = props

  return (
    <section className="accreditationUkepRequestBanner__wrapper">
      <div className="accreditationUkepRequestBanner__card">
        <div className="accreditationUkepRequestBanner__info">
          <div className="h4">{text}</div>
          {description && <div>{description}</div>}
        </div>
        {button && <div className="accreditationUkepRequestBanner__button">{button}</div>}
      </div>
    </section>
  )
}

export default memo(AccreditationUkepRequestBanner)
