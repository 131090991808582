import client from "@/api/client/axios";
import type { ResponseConfig } from "@/api/client/axios";
import type { GetMultiRequestChatMessagesQueryResponse, GetMultiRequestChatMessagesPathParams } from "../../types/GetMultiRequestChatMessages";

/**
     * @summary Получение списка сообщений чата в мультизаявке
     * @link /api/multirequests/:requestId/chat
     */
export async function getMultiRequestChatMessages (requestId: GetMultiRequestChatMessagesPathParams["requestId"], options: Partial<Parameters<typeof client>[0]> = {}): Promise<ResponseConfig<GetMultiRequestChatMessagesQueryResponse>["data"]> {
    const { data: resData } = await client<GetMultiRequestChatMessagesQueryResponse>({
        method: "get",
        url: `/api/multirequests/${requestId}/chat`,
        ...options
    });
    
    return resData;
};