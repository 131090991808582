import type { UseMutationOptions, UseMutationResult } from "@tanstack/react-query";
import { useMutation } from "@tanstack/react-query";
import client from "@/api/client/axios";
import type { ResponseConfig } from "@/api/client/axios";
import type { FilterFundingRequestsMutationRequest, FilterFundingRequestsMutationResponse, FilterFundingRequests400, FilterFundingRequests401, FilterFundingRequests403, FilterFundingRequests422, FilterFundingRequests429, FilterFundingRequests500 } from "../types/FilterFundingRequests";

/**
 * @link /api/credit-flow-backend/v1/fundings
 */

export function useFilterFundingRequests <TData = FilterFundingRequestsMutationResponse, TError = FilterFundingRequests400 | FilterFundingRequests401 | FilterFundingRequests403 | FilterFundingRequests422 | FilterFundingRequests429 | FilterFundingRequests500, TVariables = FilterFundingRequestsMutationRequest>(options: {
          mutation?: UseMutationOptions<ResponseConfig<TData>, TError, TVariables>,
          client?: Partial<Parameters<typeof client<TData, TError, TVariables>>[0]>,
      } = {}): UseMutationResult<ResponseConfig<TData>, TError, TVariables> {
  const { mutation: mutationOptions, client: clientOptions = {} } = options ?? {};

  return useMutation<ResponseConfig<TData>, TError, TVariables>({
    mutationFn: (data) => {
      
      return client<TData, TError, TVariables>({
        method: "post",
        url: `/api/credit-flow-backend/v1/fundings`,
        data,
        
        
        ...clientOptions
      });
    },
    ...mutationOptions
  });
};
