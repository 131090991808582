import type { SuggestionSelectedEventData } from 'react-autosuggest'
import cloneDeep from 'lodash/cloneDeep'
import reduce from 'lodash/reduce'
import type { CompanySuggestion, FioSuggestion, State } from '@/api/kubik/dadata'
import { findCompanyByInn, suggestCompany, suggestFio } from '@/api/kubik/dadata'
import type { TOnSuggestionsFetchRequested } from '@/components/ui/fields/Autosuggest/interfaces'
import type { IOrganizationState } from '@/logic/auth/interfaces'
import { convertTimestampToISO } from '@/utils/date'
import { emptyOrganizationProfile } from '../constants'
import { DADATA_FORM_FIELD_NAME } from './constants'
import type { TCommonDadataFormFieldName } from './types'

export const getSuggestionValue = ({ value }: CompanySuggestion) => value || ''
export const getSuggestionFioValue = ({ value }: FioSuggestion) => value || ''

export const onSuggestionsCompanyFetchRequested =
  <GFieldName extends Record<TCommonDadataFormFieldName, unknown>>(
    //eslint-disable-next-line @typescript-eslint/no-explicit-any
    change: (name: keyof GFieldName, value: any) => void,
    isAutoSelectDisabled?: boolean,
  ): TOnSuggestionsFetchRequested<CompanySuggestion> =>
  async ({ selectSuggestion, value }) => {
    try {
      const { suggestions } = await suggestCompany({ query: value, count: 20 })
      if (!suggestions?.length) return Promise.reject()

      change(DADATA_FORM_FIELD_NAME, value.length > 0 && suggestions.length === 0)

      if (suggestions.length === 1 && !isAutoSelectDisabled) {
        const suggestion = suggestions[0]

        if (suggestion && selectSuggestion) {
          selectSuggestion({ suggestion, sectionIndex: 0, method: 'enter', suggestionIndex: 0, suggestionValue: suggestion.value || '' })
        }

        return Promise.resolve([])
      }

      return Promise.resolve(suggestions)
    } catch (err) {
      change(DADATA_FORM_FIELD_NAME, true)

      return Promise.resolve([])
    }
  }

export const onSuggestionsFioFetchRequested =
  (): TOnSuggestionsFetchRequested<FioSuggestion> =>
  async ({ value }) => {
    try {
      const { suggestions } = await suggestFio({ query: value })

      if (!suggestions) return Promise.reject()

      return Promise.resolve(suggestions)
    } catch (err) {
      return Promise.resolve([])
    }
  }

const getOrganizationStateFromDadataResponse = (value?: State): IOrganizationState | null => {
  return value
    ? {
        status: value?.status,
        code: value?.code,
        actualityDate: convertTimestampToISO(value?.actuality_date),
        liquidationDate: convertTimestampToISO(value?.liquidation_date),
        registrationDate: convertTimestampToISO(value?.registration_date),
      }
    : null
}

export const onSuggestionCompanySelected = async ({ suggestion: baseSuggestion }: SuggestionSelectedEventData<CompanySuggestion>) => {
  const { data: baseData } = baseSuggestion

  try {
    const { suggestions } = await findCompanyByInn({ query: baseData?.inn || '', status: 'ACTIVE', count: 20 })
    if (!suggestions?.length) return false

    const suggest = suggestions[0]
    const { data } = suggest || {}

    if (!data) return false

    const profile = cloneDeep(emptyOrganizationProfile)

    profile.organization.additionalOkveds = reduce(
      data.okveds,
      (okveds: string[], { main, code }) => {
        if (main && okveds) {
          return okveds
        }

        if (code) {
          return [...okveds, code]
        }

        return []
      },
      [],
    )

    const mainOkved = data.okveds?.find(({ main }) => main)

    if (mainOkved) {
      profile.organization.okved = mainOkved.code
      profile.additionalInformation.activity = mainOkved.name
    }
    profile.additionalInformation.employees = data.employee_count
    profile.organization.address = data.address?.unrestricted_value ?? null
    profile.organization.director = data.management?.name ?? null
    profile.organization.inn = data.inn
    profile.organization.ogrn = data.ogrn
    profile.organization.okpo = data.okpo
    profile.organization.kpp = data.kpp
    profile.organization.state = getOrganizationStateFromDadataResponse(data.state)
    profile.organization.name = suggest?.value || null
    profile.organization.capitalValue = data.capital?.value || null
    profile.organization.financeIncome = data.finance?.income || null
    profile.organization.financeYear = data.finance?.year || null

    return profile
  } catch (err) {
    return {
      ...emptyOrganizationProfile,
      organization: { ...emptyOrganizationProfile.organization, name: baseSuggestion.value, inn: baseData?.inn },
    }
  }
}
