import type { QueryKey, UseQueryResult, UseQueryOptions, QueryOptions } from "@tanstack/react-query";
import { useQuery } from "@tanstack/react-query";
import client from "@/api/client/axios";
import type { GetSaleRequestShippingSurchargesQueryResponse, GetSaleRequestShippingSurchargesPathParams, GetSaleRequestShippingSurchargesQueryParams, GetSaleRequestShippingSurcharges404, GetSaleRequestShippingSurcharges500 } from "../types/GetSaleRequestShippingSurcharges";

export const getSaleRequestShippingSurchargesQueryKey = (requestId: GetSaleRequestShippingSurchargesPathParams["requestId"], params?: GetSaleRequestShippingSurchargesQueryParams) =>     [{url:`/api/salerequests/${requestId}/shippings/surcharges`,params:{requestId:requestId}},...(params ? [params] : [])] as const;
      export function getSaleRequestShippingSurchargesQueryOptions <TData = GetSaleRequestShippingSurchargesQueryResponse, TError = GetSaleRequestShippingSurcharges404 | GetSaleRequestShippingSurcharges500>(requestId: GetSaleRequestShippingSurchargesPathParams["requestId"], params?: GetSaleRequestShippingSurchargesQueryParams, options: Partial<Parameters<typeof client>[0]> = {}): UseQueryOptions<TData, TError> {
        const queryKey = getSaleRequestShippingSurchargesQueryKey(requestId, params);

        return {
          queryKey,
          queryFn: () => {
            
            return client<TData, TError>({
              method: "get",
              url: `/api/salerequests/${requestId}/shippings/surcharges`,
              params,
              
              ...options,
            }).then(res => res.data);
          },
        };
      };
      
/**
 * @summary Запрос на получение доплат
 * @link /api/salerequests/:requestId/shippings/surcharges
 */

export function useGetSaleRequestShippingSurcharges <TData = GetSaleRequestShippingSurchargesQueryResponse, TError = GetSaleRequestShippingSurcharges404 | GetSaleRequestShippingSurcharges500>(requestId: GetSaleRequestShippingSurchargesPathParams["requestId"], params?: GetSaleRequestShippingSurchargesQueryParams, options: {
          query?: UseQueryOptions<TData, TError>,
          client?: Partial<Parameters<typeof client<TData, TError>>[0]>,
        } = {}): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const { query: queryOptions, client: clientOptions = {} } = options ?? {};
  const queryKey = queryOptions?.queryKey ?? getSaleRequestShippingSurchargesQueryKey(requestId, params);

  const query = useQuery<TData, TError>({
    ...getSaleRequestShippingSurchargesQueryOptions<TData, TError>(requestId, params, clientOptions),
    ...queryOptions
  }) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey as QueryKey;

  return query;
};
