import type { TMessages } from '@/types/TMessages'

export const messages = {
  header: {
    title: {
      part1: 'Номер ',
      part2: ' не зарегистрован.',
    },
    text: 'Пожалуйста, заполните дополнительные поля для получения консультации ',
  },
  form: {
    fullName: 'Фамилия Имя Отчество',
    organization: 'Название организации или ИНН',
    email: 'Эл. почта (необязательно)',
  },
  tooltip: {
    title: 'Зачем нужен мой ИНН?',
    text: 'Мы собираем закрытый клуб аграриев, предоставляя всем нашим пользователям доступ к дружному сообществу профессионалов',
  },

  button: {
    goBack: 'Изменить номер',
    submit: 'Получить консультацию',
  },

  errors: {
    required: 'Необходимо заполнить',
    inn: 'Выберите вашу организацию из списка предложенных вариантов',
  },
} satisfies TMessages
