import client from "@/api/client/axios";
import type { ResponseConfig } from "@/api/client/axios";
import type { GetSaleTemplateQueryResponse, GetSaleTemplatePathParams } from "../../types/GetSaleTemplate";

/**
     * @summary Получение заявки на закупку
     * @link /api/requests/templates/:templateId
     */
export async function getSaleTemplate (templateId: GetSaleTemplatePathParams["templateId"], options: Partial<Parameters<typeof client>[0]> = {}): Promise<ResponseConfig<GetSaleTemplateQueryResponse>["data"]> {
    const { data: resData } = await client<GetSaleTemplateQueryResponse>({
        method: "get",
        url: `/api/requests/templates/${templateId}`,
        ...options
    });
    
    return resData;
};