import { createAction } from '@reduxjs/toolkit'
import type { IModalOptions } from './interfaces'

export declare namespace NAction {
  export interface IOpenModalPayload {
    options: IModalOptions
    //eslint-disable-next-line @typescript-eslint/no-explicit-any
    contentOptions?: Record<string, any>
  }
}

export const openModal = createAction<NAction.IOpenModalPayload>('@@modal/OPEN-MODAL')

export const clearModalOptions = createAction('@@modal/CLEAR-MODAL-OPTIONS')

export const closeModal = createAction('@@modal/CLOSE-MODAL')
