import type { PixelCrop } from 'react-image-crop'

export const getCroppedImage = async (image: HTMLImageElement, crop: PixelCrop) => {
  const scaleX = image.naturalWidth / image.width
  const scaleY = image.naturalHeight / image.height

  const offscreen = new OffscreenCanvas(crop.width * scaleX, crop.height * scaleY)
  const ctx = offscreen.getContext('2d')

  if (!ctx) {
    throw new Error('No 2d context')
  }

  // OffscreenCanvas типизирован в TS > 5
  // @ts-ignore
  ctx.drawImage(image, crop.x * scaleX, crop.y * scaleY, crop.width * scaleX, crop.height * scaleY, 0, 0, offscreen.width, offscreen.height)

  // @ts-ignore
  const blob = await offscreen.convertToBlob({ type: 'image/webp', quality: 0.7 })

  return new File([blob], 'croppedImage.webp', { type: blob.type })
}
