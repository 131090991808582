import React, { memo, useMemo, useState } from 'react'
import { EIconName, Icon } from '@frontend/pole-ui/lib/components/Icon'
import type { ITextInputProps } from '@frontend/pole-ui/lib/components/TextInput'
import { TextInputView } from '@frontend/pole-ui/lib/components/TextInput'
import classNames from 'classnames'
import type { ICheckboxesSearchInputProps } from '@/components/composed/CheckboxesFilter/interfaces'
import { Button } from '@/components/ui'
import { Fading } from '@/components/ui/animations'
import { useDeviceType } from '@/utils/hooks/useDeviceType'

const CheckboxesSearchInput: React.FC<ICheckboxesSearchInputProps> = props => {
  const { isDesktop } = useDeviceType()
  const clearString = () => props.onChange('')
  const setString: ITextInputProps['onChange'] = event => props.onChange(event.target.value)
  const isClearable = useMemo(() => props.value.trim() !== '', [props.value])
  const [isFocused, setFocused] = useState(false)
  const onFocus = () => setFocused(true)
  const onBlur = () => setFocused(false)
  const wrapperClass = classNames('search-input', 'checkboxes-filter-search-wrapper', { 'search-input_right': isDesktop }, props.className)
  const inputClass = classNames('checkboxes-filter-search-input', 'input-wrapper_search', { 'input-wrapper_active': isFocused })
  const searchIconClass = classNames('search-input__icon', { 'search-input__icon_right': isDesktop })

  return (
    <div className={wrapperClass}>
      <span className={searchIconClass}>
        <Icon name={EIconName.Search} />
      </span>
      <TextInputView
        name="filterSearchQuery"
        value={props.value}
        onChange={setString}
        placeholder={props.placeholder}
        className={inputClass}
        onFocus={onFocus}
        onBlur={onBlur}
      />
      <Fading isVisible={isClearable}>
        <Button isUnstyled onClick={clearString} className="search-input__clear-button" iconProps={{ name: EIconName.Exit }} />
      </Fading>
    </div>
  )
}

export default memo(CheckboxesSearchInput)
