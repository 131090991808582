import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { getInitialState } from '@/redux/helpers'
import type { RootState } from '@/redux/interfaces'
import DocumentService from '@/services/DocumentServiece'
import type { IRequestBodyCreateAssignmentDocument } from '@/services/DocumentServiece/interfaces'
import type { IDealAgreementFormStore } from './interfaces'

const initialState: IDealAgreementFormStore = {
  document: null,
}

const documentService = new DocumentService()

const reducerName = 'dealAgreementForm'

export const createDocumentAssignment = createAsyncThunk(
  'dealAgreementForm/createDocumentAssignment',
  async (dataForAssignmentDocument: IRequestBodyCreateAssignmentDocument) => {
    const response = await documentService.createAssignmentDocument(dataForAssignmentDocument)

    return response
  },
)

const dealAgreementForm = createSlice({
  name: reducerName,
  initialState: getInitialState(reducerName, initialState),
  reducers: {},
  extraReducers: builder => {
    builder.addCase(createDocumentAssignment.fulfilled, (state, action) => {
      state.document = action.payload
    })
  },
})

export const getDealAgreementFormStore = () => (state: RootState) => state.dealAgreementForm

export default dealAgreementForm.reducer
