import client from "@/api/client/axios";
import type { ResponseConfig } from "@/api/client/axios";
import type { DeleteOrderSupplyMutationResponse, DeleteOrderSupplyPathParams } from "../../types/DeleteOrderSupply";

/**
     * @summary Удаление поставки
     * @link /api/orders/:orderId/supplies/:supplyId
     */
export async function deleteOrderSupply (orderId: DeleteOrderSupplyPathParams["orderId"], supplyId: DeleteOrderSupplyPathParams["supplyId"], options: Partial<Parameters<typeof client>[0]> = {}): Promise<ResponseConfig<DeleteOrderSupplyMutationResponse>["data"]> {
    const { data: resData } = await client<DeleteOrderSupplyMutationResponse>({
        method: "delete",
        url: `/api/orders/${orderId}/supplies/${supplyId}`,
        ...options
    });
    
    return resData;
};