import client from "@/api/client/axios";
import type { ResponseConfig } from "@/api/client/axios";
import type { DeleteFundingDocumentMutationResponse, DeleteFundingDocumentQueryParams } from "../../types/DeleteFundingDocument";

/**
     * @link /api/credit-flow-backend/v1/document
     */
export async function deleteFundingDocument (params: DeleteFundingDocumentQueryParams, options: Partial<Parameters<typeof client>[0]> = {}): Promise<ResponseConfig<DeleteFundingDocumentMutationResponse>["data"]> {
    const { data: resData } = await client<DeleteFundingDocumentMutationResponse>({
        method: "delete",
        url: `/api/credit-flow-backend/v1/document`,
        params,
        ...options
    });
    
    return resData;
};