import loadable from '@loadable/component'

// yet-another-react-lightbox - pure ESM module, loadable нужен вместо динамического импорта

export const ReactLightbox = loadable.lib(
  /* webpackChunkName: "YARLightbox" */
  /* webpackPrefetch: "true" */
  /* #__LOADABLE__ */
  () => import('yet-another-react-lightbox'),

  { ssr: false },
)

export const ReactLightboxThumbnailsPlugin = loadable.lib(
  /* webpackChunkName: "ReactLightboxThumbnailsPlugin" */
  /* webpackPrefetch: "true" */
  /* #__LOADABLE__ */
  () => import('yet-another-react-lightbox/plugins/thumbnails'),
  { ssr: false },
)
