import React from 'react'
import { InjectHtml } from '@/components/ui'
import type { IExactFarmingChecksPhoneSectionProps } from './interfaces'
import './styles.scss'

const ExactFarmingChecksPhoneSection: React.FC<IExactFarmingChecksPhoneSectionProps> = props => {
  const { titleId, listItemTextIds, imageSrc, blockId } = props

  return (
    <section className="exact-farming-checks-phone-section">
      <div className="page-wrapper page-wrapper_no-mobile">
        <div className="exact-farming-checks-phone-section__inner" style={{ backgroundImage: `url("${imageSrc}")` }}>
          <InjectHtml
            TagName="h2"
            textId={titleId}
            className="exact-farming-checks-phone-section__title space-holder24-from-md space-holder16-sm"
          />

          <div className="exact-farming-checks-phone-section__checks-wrapper" id={blockId}>
            {listItemTextIds.map((listItemTextId, i) => (
              <InjectHtml
                key={i}
                TagName="div"
                textId={listItemTextId}
                className="exact-farming-checks-phone-section__check text_super-small-sm h4-from-sm"
              />
            ))}
          </div>
        </div>
      </div>
    </section>
  )
}

export default ExactFarmingChecksPhoneSection
