import type { PropsWithChildren } from 'react'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setInitialStatus } from '@/logic/requestStatus/actions'
import { getRequestData } from '@/logic/requestStatus/reducer'
import type { RootState } from '@/redux/interfaces'
import type { IStore } from '@/types/IStore'
import { EChoosingLocationRequestName } from '../../constants'
import type { ISavingAddressListenerProps } from './interfaces'

const mapState = (state: RootState) => ({
  storeId: getRequestData<IStore>(EChoosingLocationRequestName.SAVE)(state)?.id,
})

//eslint-disable-next-line @typescript-eslint/no-explicit-any
function SavingAddressListener<FormValues = Record<string, any>>(props: PropsWithChildren<ISavingAddressListenerProps<FormValues>>) {
  const { form, shouldEditForm, fieldName } = props
  const { storeId } = useSelector(mapState)
  const dispatch = useDispatch()

  useEffect(() => {
    if (storeId && shouldEditForm) {
      //eslint-disable-next-line @typescript-eslint/no-explicit-any
      form.change(fieldName, storeId as any)
      dispatch(setInitialStatus({ name: EChoosingLocationRequestName.SAVE }))
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [storeId, shouldEditForm, fieldName])

  return null
}

export default SavingAddressListener
