import type { ICartItem, IOrderPaymentVariant } from '@/types/Cart'
import { EOrderPaymentVariantType } from '@/types/Cart'
import type { IAgrarianOrder, IOrderItem } from '@/types/Order'
import { EAgrarianOrderStatus } from '@/types/Order'
import { ECartRequestName } from './constants'

export const isCartProductsSameVatRate = (items?: ICartItem[]): boolean =>
  Boolean(items?.every(({ productOffer }) => productOffer.vatRate === items[0]?.productOffer.vatRate))

export const isOrderItemsSameVatRate = (items: IOrderItem[]): boolean => items.every(({ vatRate }) => vatRate === items[0]?.vatRate)

export const getClearingCartRequestName = (id: string) => `${ECartRequestName.CLEAR_CART}_${id}` as const

export const getChangingProductQuantityRequestName = (id: string) => `${ECartRequestName.CHANGE_PRODUCT_QUANTITY}_${id}` as const

export const isOrderWithPromo = (items?: ICartItem[]): boolean =>
  Boolean(items?.some(({ productOffer }) => productOffer.promotions?.length > 0))

export const canCartPricesBeNegotiated = (items?: ICartItem[]): boolean =>
  Boolean(items?.some(({ productOffer }) => productOffer.isPriceNegotiable))

export const OrderProposalStatus: Partial<Record<EAgrarianOrderStatus, true>> = {
  [EAgrarianOrderStatus.AGRARIAN_PROPOSAL]: true,
  [EAgrarianOrderStatus.SUPPLIER_PROPOSAL]: true,
  [EAgrarianOrderStatus.CANCELED]: true,
}

export const canOrderPricesBeNegotiated = (order?: IAgrarianOrder): boolean =>
  Boolean(
    order?.items.some(({ isPriceNegotiable, price }) => isPriceNegotiable && price.value === 0) && OrderProposalStatus[order.status.id],
  )

export const isFullPrepayment = ({ type, percentPrepayment }: IOrderPaymentVariant): boolean =>
  type === EOrderPaymentVariantType.PREPAYMENT && percentPrepayment === 100
