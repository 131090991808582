import type { ISkeletonLoaderProps } from '@frontend/pole-ui/lib/components/Skeleton'

export const articlePreviewWithoutImageSkeleton: ISkeletonLoaderProps = {
  className: 'journal-articles-item article-preview-without-image',
  style: { alignItems: 'center' },
  width: null,
  height: null,
  styleName: 'white',
  skeletons: [
    { className: 'article-preview-without-image__title space-holder24-from-md space-holder16-md h2' },
    { className: 'text_large' },
  ],
}
