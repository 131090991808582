import type { ReducersMapObject } from '@reduxjs/toolkit'
import { configureStore } from '@reduxjs/toolkit'
import { routerMiddleware } from 'connected-react-router'
import { createEpicMiddleware } from 'redux-observable'
import { history } from '../browserHistory'
import type { RootState, TAppStore } from './interfaces'
import { preloadedState } from './preloadedState'
import { reducerManager } from './reducerManager'
import rootEpicHandler from './rootEpic'

const epicMiddleware = createEpicMiddleware()

const store = configureStore({
  reducer: reducerManager.reduce as ReducersMapObject<Partial<RootState>>,
  middleware: getDefaultMiddleware => getDefaultMiddleware().concat(epicMiddleware, routerMiddleware(history)),
  devTools: process.env.NODE_ENV !== 'production',
  preloadedState,
}) as TAppStore

reducerManager.store = store

//@ts-expect-error
epicMiddleware.run(rootEpicHandler)

export default store
