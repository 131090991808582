import { useEffect, useState } from 'react'
// eslint-disable-next-line camelcase
import { unstable_batchedUpdates } from 'react-dom'
import { useStore } from 'react-redux'
import { isStatusOk } from '../helpers'
import type { IResponseMeta } from '../interfaces'
import { requestData } from '../request'

export interface IUseGetApi<T> {
  data?: T
  isLoading: boolean
  isFailure: boolean
  isLoaded: boolean
  meta?: IResponseMeta
}

export interface IUseGetApiOptions<T> {
  defaultValue?: T
  shouldMakeRequest?: boolean
}

//eslint-disable-next-line @typescript-eslint/no-explicit-any
export const useGetApi = <T>(url: string, deps: any[] = [], options: IUseGetApiOptions<T> = {}): IUseGetApi<T> => {
  const { defaultValue, shouldMakeRequest = true } = options
  const [data, setData] = useState<T | undefined>(defaultValue)
  const [isLoading, setLoadingState] = useState(true)
  const [isLoaded, setLoadedState] = useState(false)
  const [isFailure, setFailureState] = useState(false)
  const state = useStore().getState()
  const [meta, setMeta] = useState<IResponseMeta>()

  useEffect(() => {
    if (!shouldMakeRequest) return

    requestData<T>({ url, state, method: 'GET' })
      .then(response => {
        unstable_batchedUpdates(() => {
          setLoadingState(false)

          if (isStatusOk(response)) {
            setData(response.data)
            setLoadedState(true)
          } else {
            setFailureState(true)
            setData(defaultValue)
            setMeta(response.meta)
          }
        })
      })
      .catch(() => {
        unstable_batchedUpdates(() => {
          setLoadingState(false)
          setFailureState(true)
          setData(defaultValue)
        })
      })
    //eslint-disable-next-line
  }, [...deps, shouldMakeRequest])

  return {
    data,
    isLoading,
    isFailure,
    isLoaded,
    meta,
  }
}
