import React, { useContext } from 'react'
import { useDispatch } from 'react-redux'
import { TypedDocumentCard } from '@/components/resolvers'
import { downloadAccreditationDocument, updateAccreditationDocument } from '@/logic/accreditation/actions'
import { AccreditationContext } from '@/logic/accreditation/constants'
import type { IAccreditationTypedDocumentCardProps } from '@/logic/accreditation/interfaces'
import { EAccreditationDocumentAction } from '@/logic/accreditation/interfaces'

const AccreditationTypedDocumentCard: React.FC<IAccreditationTypedDocumentCardProps> = ({ typedDocument }) => {
  const { accreditationId, version, accreditationActions } = useContext(AccreditationContext)
  const { name, id: type, templateUrl, fileName } = typedDocument
  const { addDocument: canAddDocument } = accreditationActions
  const dispatch = useDispatch()

  const onDownloadDocument = templateUrl
    ? () => dispatch(downloadAccreditationDocument({ url: templateUrl, fileName: fileName as string }))
    : undefined

  return (
    <TypedDocumentCard
      onDownloadDocument={onDownloadDocument}
      canAddDocument={Boolean(canAddDocument)}
      onUploadDocument={file =>
        file &&
        dispatch(
          updateAccreditationDocument({
            version,
            action: EAccreditationDocumentAction.UPLOAD,
            file,
            documentType: type,
            accreditationId,
            documentName: name,
          }),
        )
      }
      typedDocument={typedDocument}
      uploadDocumentRequestName={`uploadAccreditationDocument_${type}`}
      shouldUploadTemplateAsAction
    />
  )
}

export default AccreditationTypedDocumentCard
