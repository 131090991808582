import { from, Observable } from 'rxjs'
import { ECommonRoute } from '@/types'
import { history } from '../browserHistory'
import ajaxOptions from './ajaxOptions'
import type { IAjaxOptions, IAjaxResponse, IHandleResponse, IResponse, IUploadResponse } from './interfaces'

/* eslint-disable */
let fetch: any
if (process.browser) {
  require('whatwg-fetch')
  fetch = window.fetch
} else {
  const nodeFetch = require('node-fetch')
  fetch = nodeFetch.default || nodeFetch
}
/* eslint-enable */

//eslint-disable-next-line @typescript-eslint/no-explicit-any
export const handleError = async <T = any>(error: Promise<IResponse<T>> | Error) => {
  if (error instanceof Promise) {
    const response = await error

    throw response
  }

  // eslint-disable-next-line no-console
  if (error.name !== 'AbortError') console.error(error)

  return { data: null, meta: { status: 'ERROR', message: error } }
}

const isRespondedOk = <T, GResponse = IResponse<T>>({ response, shouldRedirectTo500Page }: IHandleResponse<T, GResponse>): boolean => {
  const { status: code } = response

  if (code < 500 && code >= 200) {
    if (code === 401 || code === 404 || code === 400) return false

    return true
  }

  if (code === 500 && process.browser && shouldRedirectTo500Page) history.push(ECommonRoute.error500)

  return false
}

export const timeoutRequest = <T>(ms: number, makeRequest: (controller: Maybe<AbortController>) => Promise<T>): Promise<T> =>
  new Promise((resolve, reject) => {
    const controller = process.browser ? null : new AbortController()
    makeRequest(controller).then(resolve, reject)

    setTimeout(() => {
      controller?.abort()
      reject()
    }, ms)
  })

export const requestData = <T, GResponse = IResponse<T>>(options: IAjaxOptions): Promise<GResponse> => {
  const { url, ...restOptions } = ajaxOptions(options)

  return fetch(url, restOptions)
    .then((response: IAjaxResponse<T, GResponse>): Promise<GResponse> => {
      const isSuccess = isRespondedOk<T, GResponse>({ response })
      const jsonPromise = response.json()
      if (isSuccess) return jsonPromise

      return Promise.reject(jsonPromise)
    })
    .catch(handleError)
}

export const requestDataWithRawResponse = <T>(options: IAjaxOptions): Promise<IAjaxResponse<T>> => {
  const { url, ...restOptions } = ajaxOptions(options)

  return fetch(url, restOptions).catch(handleError)
}

export const requestDataWithBlobResponse = (options: IAjaxOptions): Promise<Blob> => {
  const { url, ...restOptions } = ajaxOptions(options)

  return fetch(url, restOptions)
    .then((response: IAjaxResponse<Blob>): Promise<Blob> => {
      const isSuccess = isRespondedOk<Blob>({ response })
      const blobPromise = response.blob()
      if (isSuccess) return blobPromise

      return Promise.reject(blobPromise)
    })
    .catch(handleError)
}

export const request = <T>(options: IAjaxOptions): Observable<IResponse<T>> => from(requestData<T>(options))

export const requestWithBlobResponse = (options: IAjaxOptions): Observable<Blob> => from(requestDataWithBlobResponse(options))

export const requestWithRawResponse = <T>(options: IAjaxOptions): Observable<IAjaxResponse<T>> =>
  from(requestDataWithRawResponse<T>(options))

export const upload = <T>(data: IAjaxOptions): Observable<IUploadResponse<T>> => {
  const { url, method, body, headers } = ajaxOptions(data)

  return new Observable(observer => {
    const xhr = new XMLHttpRequest()

    xhr.upload.onprogress = event => {
      observer.next({ event })
    }

    xhr.onerror = error => {
      return observer.error(error)
    }

    xhr.onload = event => {
      if (xhr.status === 200) {
        observer.next({ event, response: JSON.parse(xhr.response) })

        return observer.complete()
      }

      return observer.error()
    }

    xhr.open(method, url, true)
    Object.entries(headers).forEach(([name, value]) => xhr.setRequestHeader(name, value))

    xhr.send(body)

    return () => xhr.abort()
  })
}

export { fetch, isRespondedOk, request as default }
