import client from "@/api/client/axios";
import type { ResponseConfig } from "@/api/client/axios";
import type { UpdateSaleRequestTemplateMutationRequest, UpdateSaleRequestTemplateMutationResponse, UpdateSaleRequestTemplatePathParams } from "../../types/UpdateSaleRequestTemplate";

/**
     * @summary Обновление заявки на закупку
     * @link /api/requests/templates/:templateId
     */
export async function updateSaleRequestTemplate (templateId: UpdateSaleRequestTemplatePathParams["templateId"], data: UpdateSaleRequestTemplateMutationRequest, options: Partial<Parameters<typeof client>[0]> = {}): Promise<ResponseConfig<UpdateSaleRequestTemplateMutationResponse>["data"]> {
    const { data: resData } = await client<UpdateSaleRequestTemplateMutationResponse, UpdateSaleRequestTemplateMutationRequest>({
        method: "put",
        url: `/api/requests/templates/${templateId}`,
        data,
        ...options
    });
    
    return resData;
};