export enum EFinancingOfferRoute {
  main = '/finance',
  experimentalMain = '/finances',
  expressCredit = '/financingOffer/express',
  expressCreditAlfaBank = '/financingOffer/expressCreditAlfaBank',
  investmentCredit = '/financingOffer/investment',
  deprecatedAdvancePaymentOnFutureHarvest = '/financingOffer/advancePaymentOnFutureHarvest',
  advancePaymentOnFutureHarvest = '/financingOffer/avans',
  agriculturalMachinesAndEquipmentLeasing = '/financingOffer/agriculturalMachinesAndEquipmentLeasing',
  stoneXxiLeasing = '/financingOffer/stoneXxiLeasing',
  revolving = '/financingOffer/revolving',
  oborotSber = '/financingOffer/oborot_sber',
  revolvingTinkoff = '/financingOffer/revolvingTinkoff',
  transportAndSpecialEquipmentLeasing = '/financingOffer/transportAndSpecialEquipmentLeasing',
  interleasing = '/financingOffer/interleasing',
  baltiyskiyLeasing = '/financingOffer/baltiyskiyLeasing',
  alfaLeasing = '/financingOffer/alfaLeasing',
  fleetFinanceLeasing = '/financingOffer/FleetFinance',
  lombardCreditAlfaBank = '/financingOffer/lombardCreditAlfaBank',
  MFOPage = '/financingOffer/mfo',
  sberRassrochka = '/financingOffer/sberRassrochka',
  sberLeasing = '/financingOffer/sberLeasing',
  investSber = '/financingOffer/invest_sber',
  centerInvestLoan = '/financingOffer/CentrInvest',
  deprecatedMain = '/financing',
  experimentalOldUrl = '/financing/experimental',
}
