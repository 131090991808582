import { baseClickGtmEvent } from '@/logic/metrika/constants'
import { pushFormattedGtmEvent } from '@/logic/metrika/helpers'
import type { TAgricultureCatalogFilter } from '../components/AgricultureCatalogFilter'

export const pushBannerForComplexOrderClickToGtm = () =>
  pushFormattedGtmEvent({
    ...baseClickGtmEvent,
    blockName: 'blockListingInputs',
    eventLabel: 'bannerComplexApplication',
  })

export const onSearchFocusFilterWithGtm = (filter: TAgricultureCatalogFilter) =>
  filter.name === 'activeSubstance' &&
  pushFormattedGtmEvent({
    ...baseClickGtmEvent,
    blockName: 'blockFilter',
    eventLabel: 'filterSearch',
  })

// todo: надо передавать строку поиска и `item?.label`?
// export const onChangeFilterWithGtm = (payload?: IFilterPayload) =>
export const onChangeFilterWithGtm = (filter: TAgricultureCatalogFilter) =>
  filter.name === 'activeSubstance' &&
  pushFormattedGtmEvent({
    ...baseClickGtmEvent,
    blockName: 'blockFilter',
    eventLabel: 'activeIngredients',
    // eventContext: prepareEventContext(payload?.item?.label, payload.searchString),
  })
// const prepareEventContext = (value?: string, searchString?: string) => `fltrValue:${value};fltrSearch:${searchString || null}`
