import React from 'react'
import type { FieldValues } from 'react-hook-form'
import { useController } from 'react-hook-form'
import type { ITextInputProps } from '@frontend/pole-ui/lib/components/TextInput'
import isObject from 'lodash/isObject'
import omit from 'lodash/omit'
import AutosuggestView from '@/components/ui/fields/Autosuggest/AutosuggestView'
import type { TFieldRules } from '@/types/TFieldRules'
import { getMappedFieldMetaState } from '@/utils/fields'
import type { IAutosuggestProps } from './types'

function Autosuggest<GSuggest, TForm extends FieldValues>(props: IAutosuggestProps<GSuggest, TForm>) {
  const { isRequired, errorCustomMessage, isRequiredWithoutSign, className, allowRawValue, onSuggestChange, onInputChange, name, control } =
    props

  const rules: TFieldRules = {
    required: isRequired || isRequiredWithoutSign ? errorCustomMessage : undefined,
    ...props.rules,
  }

  const { field, fieldState } = useController({
    name,
    control,
    rules,
  })

  const meta = getMappedFieldMetaState(fieldState)
  const defaultInputValue = isObject(field.value) ? props.getSuggestionValue(field.value) : field.value

  return (
    <AutosuggestView<GSuggest>
      className={className}
      {...props}
      {...field}
      meta={meta}
      defaultInputValue={defaultInputValue ?? ''}
      customInputProps={
        {
          ...props.customInputProps,
          customErrorText: meta.error,
        } as ITextInputProps
      }
      onSuggestChange={value => {
        onSuggestChange?.(value)
        if (!allowRawValue || value !== null) field.onChange(value)
      }}
      onInputBlur={() => field.onBlur()}
      onInputChange={(value, event) => {
        onInputChange?.(value, event)
        if (allowRawValue) field.onChange(value)
      }}
    />
  )
}

export const HookFormAutosuggest = React.memo(Autosuggest, (prev, next) => {
  const prevValue = omit(prev, 'customInputProps.bubblingTooltipProps')
  const nextValue = omit(next, 'customInputProps.bubblingTooltipProps')

  return JSON.stringify(prevValue) === JSON.stringify(nextValue)
}) as typeof Autosuggest
