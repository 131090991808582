import type { FC } from 'react'
import React from 'react'
import { CommonLink } from '@frontend/pole-ui/lib/components/CommonLink'
import { Checkbox } from '@/components/ui/ReactHookFormFields'
import { getLegalDocumentUrl } from '@/logic/legalDocuments/helpers'
import { messages } from './messages'
import type { IDescription, IRegistrationDocumentProps } from './types'
import './styles.scss'

export const RegistrationDocument: FC<IRegistrationDocumentProps> = props => {
  const { name, control, isRequired, descriptionParts, documents } = props

  const partsMapper = ({ text, link }: IDescription) => {
    if (text) return <span key={text}>{text}</span>

    if (link) {
      const { text: linkText, type } = link
      const url = getLegalDocumentUrl(documents, type)
      if (!url) return null

      return (
        <CommonLink shouldOpenInNewWindow key={url} url={url} className="underline underline_green color_green">
          {linkText}
        </CommonLink>
      )
    }

    return null
  }

  return (
    <div className="space-holder16-sm authRegistrationDocument">
      <Checkbox
        control={control}
        name={name}
        id={`registrationForm_${name}`}
        sizeMobile="large"
        rules={{
          ...(isRequired && { required: messages.errors.required }),
        }}
      >
        <p className="authRegistrationDocument__text text_super-small">{descriptionParts.map(partsMapper)}</p>
      </Checkbox>
    </div>
  )
}
