import client from "@/api/client/inputs-catalog";
import type { ResponseConfig } from "@/api/client/inputs-catalog";
import type { GetCatalogQueryResponse, GetCatalogQueryParams } from "../../types/GetCatalog";

/**
     * @link /v1/catalog
     */
export async function getCatalog (params?: GetCatalogQueryParams, options: Partial<Parameters<typeof client>[0]> = {}): Promise<ResponseConfig<GetCatalogQueryResponse>["data"]> {
    const { data: resData } = await client<GetCatalogQueryResponse>({
        method: "get",
        url: `/v1/catalog`,
        params,
        ...options
    });
    
    return resData;
};