import client from "@/api/client/axios";
import type { ResponseConfig } from "@/api/client/axios";
import type { GetPublicationByIdQueryResponse, GetPublicationByIdPathParams } from "../../types/GetPublicationById";

/**
     * @link /api/ms-content-infoportal-backend/v1/publications/:slug
     */
export async function getPublicationById (slug: GetPublicationByIdPathParams["slug"], options: Partial<Parameters<typeof client>[0]> = {}): Promise<ResponseConfig<GetPublicationByIdQueryResponse>["data"]> {
    const { data: resData } = await client<GetPublicationByIdQueryResponse>({
        method: "get",
        url: `/api/ms-content-infoportal-backend/v1/publications/${slug}`,
        ...options
    });
    
    return resData;
};