export enum EDocumentTypeId {
  // Общие
  COMMON = 'COMMON',

  // Заказ inputs
  ORDER_VALIDATION_COMMON = 'ORDER_VALIDATION_COMMON',

  // Аккредитация
  ACCREDITATION_DOCUMENTS_ARCHIVE = 'ACCREDITATION_DOCUMENTS_ARCHIVE',

  // Рамочный договор outputs
  SALE_REQUEST_CONTRACT = 'SALE_REQUEST_CONTRACT',

  //Заявление о присоединении к платформе “Поле.рф"
  ACCESSION_DECLARATION = 'ACCESSION_DECLARATION',

  //Доверенность на совершение действий на платформе “Поле.рф”, если управлять личным кабинетом будет не руководитель организации"
  LETTER_OF_ATTORNEY = 'LETTER_OF_ATTORNEY',
}
