import type { GetManagerPublicationsQueryParamsStatus } from '@/api/kubik/infoportal'

export enum EInfoportalAdministrationRoute {
  MAIN = '/infoportal',
  PUBLISHED = '/infoportal/published',
  DRAFTS = '/infoportal/drafts',
  ARCHIVE = '/infoportal/archive',
  RUBRICS = '/infoportal/rubrics',
  TAGS = '/infoportal/tags',
  EDITOR_FROM_ARCHIVE = '/infoportal/ARCHIVE/editor',
  EDITOR_FROM_PUBLISHED = '/infoportal/PUBLISHED/editor',
  EDITOR_FROM_DRAFTS = '/infoportal/DRAFTS/editor',
}

export type TPublicationSourcePage = GetManagerPublicationsQueryParamsStatus
