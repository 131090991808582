import type { FC } from 'react'
import React from 'react'
import { useSelector } from 'react-redux'
import classNames from 'classnames'
import { ChangeOrgButton } from '@/components/ui'
import { canUseChat, canUseNotifications } from '@/logic/app/reducer'
import { isAuthenticated } from '@/logic/auth/reducer'
import type { RootState } from '@/redux/interfaces'
import { AdditionalMenuLinks, CartAction, MobileNavigationContainer, NavLinks, NoticesLink, ProfileAction, RegionChooserTop } from '.'

const mapState = (state: RootState) => ({
  isUserAuthenticated: isAuthenticated()(state),
  canUseChatOrNotifications: canUseChat()(state) || canUseNotifications()(state),
})

const HeaderActions: FC = () => {
  const { isUserAuthenticated, canUseChatOrNotifications } = useSelector(mapState)

  return (
    <ul
      className={classNames('header-actions-list', {
        'header-actions-list_authed': isUserAuthenticated,
        'header-actions-list_not-authed': !isUserAuthenticated,
      })}
    >
      <li className="header-action_profile-action">
        <ProfileAction />
      </li>
      {isUserAuthenticated && (
        <>
          {canUseChatOrNotifications && (
            <li className="header-action_notices-link">
              <NoticesLink />
            </li>
          )}
          <li className="header-action_cart-action">
            <CartAction />
          </li>
        </>
      )}
      <li className="header-action_menu hidden-from-sm">
        <MobileNavigationContainer>
          <RegionChooserTop />
          <ChangeOrgButton
            className="headerChangeOrgButton"
            buttonClassName="headerChangeOrgButton__button"
            labelClassName="headerChangeOrgButton__label"
          />
          <NavLinks />
          <AdditionalMenuLinks />
        </MobileNavigationContainer>
      </li>
    </ul>
  )
}

export default HeaderActions
