import type { QueryKey, UseQueryResult, UseQueryOptions, QueryOptions } from "@tanstack/react-query";
import { useQuery } from "@tanstack/react-query";
import client from "@/api/client/axios";
import type { GetArticleBannerQueryResponse, GetArticleBannerPathParams } from "../types/GetArticleBanner";

export const getArticleBannerQueryKey = (bannerId: GetArticleBannerPathParams["bannerId"]) =>     [{url:`/api/ms-content-infoportal-backend/v1/admin/article/banners/${bannerId}`,params:{bannerId:bannerId}}] as const;
      export function getArticleBannerQueryOptions <TData = GetArticleBannerQueryResponse, TError = unknown>(bannerId: GetArticleBannerPathParams["bannerId"], options: Partial<Parameters<typeof client>[0]> = {}): UseQueryOptions<TData, TError> {
        const queryKey = getArticleBannerQueryKey(bannerId, );

        return {
          queryKey,
          queryFn: () => {
            
            return client<TData, TError>({
              method: "get",
              url: `/api/ms-content-infoportal-backend/v1/admin/article/banners/${bannerId}`,
              
              
              ...options,
            }).then(res => res.data);
          },
        };
      };
      
/**
 * @link /api/ms-content-infoportal-backend/v1/admin/article/banners/:bannerId
 */

export function useGetArticleBanner <TData = GetArticleBannerQueryResponse, TError = unknown>(bannerId: GetArticleBannerPathParams["bannerId"], options: {
          query?: UseQueryOptions<TData, TError>,
          client?: Partial<Parameters<typeof client<TData, TError>>[0]>,
        } = {}): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const { query: queryOptions, client: clientOptions = {} } = options ?? {};
  const queryKey = queryOptions?.queryKey ?? getArticleBannerQueryKey(bannerId, );

  const query = useQuery<TData, TError>({
    ...getArticleBannerQueryOptions<TData, TError>(bannerId, clientOptions),
    ...queryOptions
  }) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey as QueryKey;

  return query;
};
