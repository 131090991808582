import type { PayloadAction } from '@reduxjs/toolkit'
import { push } from 'connected-react-router'
import { combineEpics } from 'redux-observable'
import { concat, from, of, throwError, timer } from 'rxjs'
import { catchError, delayWhen, filter, map, mergeMap } from 'rxjs/operators'
import { isStatusOk } from '@/api/helpers'
import { closeModal, openModal } from '@/components/composed/Modal/actions'
import type { ISuccessOrderModalOptions } from '@/components/composed/modals/SuccessOrderModal/interfaces'
import { getLocation } from '@/logic/app/reducer'
import { getProfilePath } from '@/logic/auth/reducer'
import { loadData } from '@/logic/data/actions'
import type { Epic } from '@/logic/interfaces'
import { requestFailed, requestFinished, requestStarted } from '@/logic/requestStatus/actions'
import { disableSendingCode, saveTokenId } from '@/logic/smsCode/actions'
import { reducerManager } from '@/redux/reducerManager'
import { AccreditationDocumentsService, AccreditationService } from '@/services/accreditation'
import type { IMyAccreditation } from '@/types/Accreditation'
import type { ISendingSmsCodeResponse } from '@/types/Verification'
import { scrollTo } from '@/utils/helpers'
import {
  createAccreditation,
  downloadAccreditationDocument,
  makeAccreditationProcess,
  rejectAccreditationDocument,
  reloadAccreditation,
  updateAccreditationDocument,
} from './actions'
import type { NAccreditationAction } from './interfaces'
import { EAccreditationAction, EAccreditationDocumentAction } from './interfaces'

const accreditationDocumentsService = new AccreditationDocumentsService()
const accreditationService = new AccreditationService()

const makeAccreditationDocumentAction = (payload: NAccreditationAction.IUpdateAccreditationDocument) => {
  const { accreditationId: id, documentType, version } = payload

  switch (payload.action) {
    case EAccreditationDocumentAction.DELETE: {
      const { documentId } = payload

      return accreditationDocumentsService.deleteDocument({ version, documentId, id })
    }
    case EAccreditationDocumentAction.UPDATE: {
      const { documentId, file, documentName } = payload

      return accreditationDocumentsService.updateDocument({ version, documentId, file, documentType, documentName, id })
    }
    case EAccreditationDocumentAction.UPLOAD_FROM_MODAL: {
      const { file, documentName } = payload

      return accreditationDocumentsService.uploadDocumentFromModal({ version, file, documentType, documentName, id })
    }
    case EAccreditationDocumentAction.UPDATE_FROM_MODAL: {
      const { documentId, file, documentName } = payload

      return accreditationDocumentsService.updateDocumentFromModal({ version, documentId, file, documentType, documentName, id })
    }
    case EAccreditationDocumentAction.UPLOAD:
    default: {
      const { file, documentName } = payload

      return accreditationDocumentsService.uploadDocument({ version, file, documentType, documentName, id })
    }
  }
}

const onUpdateAccreditationDocumentDoMakeRequest: Epic<
  NAccreditationAction.IUpdateAccreditationDocument,
  typeof updateAccreditationDocument
> = action$ =>
  action$.pipe(
    filter(updateAccreditationDocument.match),
    mergeMap(({ payload }) => {
      const { action, accreditationId: id, shouldCloseModal, documentType } = payload
      const requestStatus = { name: `${action}AccreditationDocument_${documentType}` }

      return concat(
        of(requestStarted(requestStatus)),
        from(makeAccreditationDocumentAction(payload)).pipe(
          mergeMap(response => {
            if (!isStatusOk(response)) return throwError(response)

            //eslint-disable-next-line @typescript-eslint/no-explicit-any
            const actions: PayloadAction<any>[] = [
              loadData({ name: 'accreditation', params: { id }, shouldLoadForcibly: true }),
              loadData({ name: 'accreditationDocuments', params: { id }, shouldLoadForcibly: true }),
              loadData({ name: 'accreditationHistory', params: { id }, shouldLoadForcibly: true }),
              requestFinished(requestStatus),
            ]
            if (shouldCloseModal) actions.push(closeModal())

            return actions
          }),
          catchError(() => of(requestFailed(requestStatus))),
        ),
      )
    }),
  )

const onDownloadAccreditationDocumentDoMakeRequest: Epic<
  NAccreditationAction.IDownloadAccreditationDocument,
  typeof downloadAccreditationDocument
> = action$ =>
  action$.pipe(
    filter(downloadAccreditationDocument.match),
    mergeMap(({ payload }) => {
      const { url, fileName } = payload
      const requestStatus = { name: `downloadAccreditationDocument_${url}` }

      return concat(
        of(requestStarted(requestStatus)),
        from(accreditationDocumentsService.downloadDocument(url, fileName)).pipe(
          map(() => requestFinished(requestStatus)),
          catchError(() => of(requestFailed(requestStatus))),
        ),
      )
    }),
  )

const onCreateAccreditationDoMakeRequest: Epic<NAccreditationAction.ICreateAccreditation, typeof createAccreditation> = (action$, state$) =>
  action$.pipe(
    filter(createAccreditation.match),
    mergeMap(({ payload }) => {
      const { organizationId, accreditationType } = payload
      const requestStatus = { name: `createAccreditation_${organizationId}` }
      const { value: state } = state$

      return concat(
        of(requestStarted(requestStatus)),
        from(accreditationService.createAccreditation(organizationId, accreditationType)).pipe(
          mergeMap(response => {
            if (!isStatusOk(response)) return throwError(response)

            const { id } = response.data

            return [push(`${getProfilePath()(state)}/accreditation/${id}`), requestFinished(requestStatus)]
          }),
          catchError(() => of(requestFailed(requestStatus))),
        ),
      )
    }),
  )

const makeAccreditationAction = (payload: NAccreditationAction.IMakeAccreditationProcess) => {
  const { accreditationId, version } = payload

  switch (payload.action) {
    case EAccreditationAction.CODE:
      return accreditationService.sendCode(payload.parameters, accreditationId, version)
    case EAccreditationAction.SIGN:
      return accreditationService.signAccreditation(payload.parameters, accreditationId, version)
    case EAccreditationAction.CONFIRM:
      return accreditationService.confirmAccreditation(payload.parameters, accreditationId, version)
    case EAccreditationAction.DISAGREEMENT:
      return accreditationService.editDisagreementProtocol(payload.parameters, accreditationId, version)
    case EAccreditationAction.EDIT:
      return accreditationService.editAccreditation(accreditationId, version)
    case EAccreditationAction.REJECT:
    default:
      return accreditationService.rejectAccreditation(payload.parameters, accreditationId, version)
  }
}

const onMakeAccreditationProcessDoMakeRequest: Epic<NAccreditationAction.IMakeAccreditationProcess, typeof makeAccreditationProcess> = (
  action$,
  state$,
) =>
  action$.pipe(
    filter(makeAccreditationProcess.match),
    mergeMap(({ payload }) => {
      const { accreditationId, action, shouldOpenSuccessConfirmationModal, shouldRedirectToMyAccreditation } = payload
      const requestStatus = { name: `makeAccreditationProcess_${action}` }
      const { value: state } = state$

      return concat(
        of(requestStarted(requestStatus)),
        from(makeAccreditationAction(payload)).pipe(
          mergeMap(response => {
            if (!isStatusOk<ISendingSmsCodeResponse | IMyAccreditation>(response)) return throwError(response)

            scrollTo('.user-accreditation-header')

            const { data } = response
            const requestFinishedAction = requestFinished(requestStatus)

            //eslint-disable-next-line @typescript-eslint/no-explicit-any
            const actions: PayloadAction<any>[] = [
              requestFinishedAction,
              loadData({ name: 'accreditation', shouldLoadForcibly: true, params: { id: accreditationId } }),
            ]
            const redirectingToMyAccreditationAction = push(`${getProfilePath()(state)}/accreditation`)

            switch (action) {
              case EAccreditationAction.CODE:
                actions.push(saveTokenId((data as ISendingSmsCodeResponse)?.tokenId), disableSendingCode())
                break
              case EAccreditationAction.SIGN:
                actions.push(redirectingToMyAccreditationAction)
                break
              case EAccreditationAction.DISAGREEMENT: {
                actions.push(openModal({ options: { dialogId: 'SigningAccreditationModal', isDraggable: true } }))
                break
              }
              case EAccreditationAction.REJECT:
                return [redirectingToMyAccreditationAction, requestFinishedAction]
              case EAccreditationAction.CONFIRM:
                actions.push(
                  shouldOpenSuccessConfirmationModal
                    ? openModal({
                        options: {
                          dialogId: 'SuccessOrderModal',
                          onCloseModal: () => {
                            reducerManager.store?.dispatch(redirectingToMyAccreditationAction)
                          },
                        },
                        contentOptions: {
                          titleId: 'userAccreditation.successDocumentUploaded.title',
                          messageId: 'userAccreditation.successDocumentUploaded.text',
                          buttons: [
                            {
                              textId: 'userAccreditation.successDocumentUploaded.button',
                              modifiers: ['green'],
                              className: 'success-order-modal__button',
                            },
                          ],
                          titleClass: 'space-holder16',
                          modalContentClassName: 'user-accreditation-success-modal',
                        } as ISuccessOrderModalOptions,
                      })
                    : closeModal(),
                )

                if (shouldRedirectToMyAccreditation) actions.push(redirectingToMyAccreditationAction)
                break
              default:
            }

            return actions
          }),
          catchError(() => {
            //eslint-disable-next-line @typescript-eslint/no-explicit-any
            const actions: PayloadAction<any>[] = [requestFailed(requestStatus)]
            if (action === EAccreditationAction.CODE) actions.push(disableSendingCode())

            return actions
          }),
        ),
      )
    }),
  )

const onRejectAccreditationDocumentProcessDoMakeRequest: Epic<
  NAccreditationAction.IRejectAccreditationDocument,
  typeof rejectAccreditationDocument
> = action$ =>
  action$.pipe(
    filter(rejectAccreditationDocument.match),
    mergeMap(({ payload }) => {
      const { accreditationId, version, comment, documentId } = payload
      const requestStatus = { name: 'rejectAccreditationDocument' }

      return concat(
        of(requestStarted(requestStatus)),
        from(accreditationDocumentsService.rejectDocument({ version, documentId, comment, id: accreditationId })).pipe(
          mergeMap(response => {
            if (!isStatusOk(response)) return throwError(response)

            return [
              requestFinished(requestStatus),
              loadData({ name: 'accreditation', shouldLoadForcibly: true, params: { id: accreditationId } }),
              loadData({ name: 'accreditationDocuments', shouldLoadForcibly: true, params: { id: accreditationId } }),
              loadData({ name: 'accreditationHistory', shouldLoadForcibly: true, params: { id: accreditationId } }),
              closeModal(),
            ]
          }),
          catchError(() => of(requestFailed(requestStatus))),
        ),
      )
    }),
  )

const onReloadAccreditationDoSetReloadingByInterval: Epic<NAccreditationAction.IReloadAccreditation, typeof reloadAccreditation> = (
  action$,
  state$,
) =>
  action$.pipe(
    filter(reloadAccreditation.match),
    delayWhen(() => timer(10000)),
    filter(({ payload }) => (getLocation()(state$.value).pathname || '').includes(String(payload.id))),
    mergeMap(({ payload }) => [
      loadData({ name: 'accreditation', shouldLoadForcibly: true, params: payload, shouldNotShowLoadingState: true }),
      loadData({ name: 'accreditationDocuments', shouldLoadForcibly: true, params: payload, shouldNotShowLoadingState: true }),
      loadData({ name: 'accreditationHistory', shouldLoadForcibly: true, params: payload, shouldNotShowLoadingState: true }),
      reloadAccreditation(payload),
    ]),
  )

//eslint-disable-next-line @typescript-eslint/no-explicit-any
export default (<any>combineEpics)(
  onReloadAccreditationDoSetReloadingByInterval,
  onUpdateAccreditationDocumentDoMakeRequest,
  onDownloadAccreditationDocumentDoMakeRequest,
  onCreateAccreditationDoMakeRequest,
  onMakeAccreditationProcessDoMakeRequest,
  onRejectAccreditationDocumentProcessDoMakeRequest,
)
