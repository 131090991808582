import type { QueryKey, UseQueryResult, UseQueryOptions, QueryOptions } from "@tanstack/react-query";
import { useQuery } from "@tanstack/react-query";
import client from "@/api/client/axios";
import type { GetMainPageSaleTemplatesCulturesInfoQueryResponse, GetMainPageSaleTemplatesCulturesInfo400, GetMainPageSaleTemplatesCulturesInfo404, GetMainPageSaleTemplatesCulturesInfo500 } from "../types/GetMainPageSaleTemplatesCulturesInfo";

export const getMainPageSaleTemplatesCulturesInfoQueryKey = () =>     [{url:`/api/requests/list/main-page-templates-culture-info`}] as const;
      export function getMainPageSaleTemplatesCulturesInfoQueryOptions <TData = GetMainPageSaleTemplatesCulturesInfoQueryResponse, TError = GetMainPageSaleTemplatesCulturesInfo400 | GetMainPageSaleTemplatesCulturesInfo404 | GetMainPageSaleTemplatesCulturesInfo500>(options: Partial<Parameters<typeof client>[0]> = {}): UseQueryOptions<TData, TError> {
        const queryKey = getMainPageSaleTemplatesCulturesInfoQueryKey();

        return {
          queryKey,
          queryFn: () => {
            
            return client<TData, TError>({
              method: "get",
              url: `/api/requests/list/main-page-templates-culture-info`,
              
              
              ...options,
            }).then(res => res.data);
          },
        };
      };
      
/**
 * @summary Информации о культурах
 * @link /api/requests/list/main-page-templates-culture-info
 */

export function useGetMainPageSaleTemplatesCulturesInfo <TData = GetMainPageSaleTemplatesCulturesInfoQueryResponse, TError = GetMainPageSaleTemplatesCulturesInfo400 | GetMainPageSaleTemplatesCulturesInfo404 | GetMainPageSaleTemplatesCulturesInfo500>(options: {
          query?: UseQueryOptions<TData, TError>,
          client?: Partial<Parameters<typeof client<TData, TError>>[0]>,
        } = {}): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const { query: queryOptions, client: clientOptions = {} } = options ?? {};
  const queryKey = queryOptions?.queryKey ?? getMainPageSaleTemplatesCulturesInfoQueryKey();

  const query = useQuery<TData, TError>({
    ...getMainPageSaleTemplatesCulturesInfoQueryOptions<TData, TError>(clientOptions),
    ...queryOptions
  }) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey as QueryKey;

  return query;
};
