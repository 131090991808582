import { useGetRubrics } from '@/api/kubik/infoportal'

export const useJournalRubrics = (rubricId?: number) => {
  const { data, isFetching, isError } = useGetRubrics(
    { showInMenu: true },
    {
      query: {
        staleTime: Infinity,
        select: fetchedData => ({
          data: {
            rubrics: fetchedData.data?.rubrics.sort((a, b) => a.priority - b.priority) || [],
            pagination: fetchedData.data?.pagination || { offset: 0, limit: 0, total: 0 },
          },
          meta: fetchedData.meta,
        }),
      },
    },
  )

  const currentRubric = rubricId ? data?.data?.rubrics?.find(rubric => rubric.id === rubricId) : undefined

  return { rubrics: data?.data?.rubrics, isJournalRubricsMenuLoading: isFetching, isJournalRubricsMenuError: isError, currentRubric }
}
