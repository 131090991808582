import type { ICheckboxProps } from '@/components/ui/fields/CheckboxInput/interfaces'
import type { EPurchaseStatus } from '@/types/EPurchaseStatus'
import type { IOrganization, IPagination, IPrice, IRegion, IStoreLocation } from '.'

export enum EQualityIndicatorId {
  nature = 'nature',
  humidity = 'humidity',
  infection = 'infection',
  weedAdmixture = 'weedAdmixture',
  grainAdmixture = 'grainAdmixture',
  fusarios = 'fusarios',
  proteinFrom = 'proteinFrom',
  cultureClass = 'cultureClass',
  broken = 'broken',
  damaged = 'damaged',
  ambrosia = 'ambrosia',
  acidNumber = 'acidNumber',
  oilContent = 'oilContent',
  protein = 'protein',
  oilAdmixture = 'oilAdmixture',
  oilСontent = 'oilСontent',
  remainder38 = 'remainder38',
  remainder40 = 'remainder40',
  remainder45 = 'remainder45',
}

export enum EOrderType {
  multiple = 'multiple',
  solo = 'solo',
}

export type TOrderType = `${EOrderType}`

export type TVariableDeliveryBases = `${EDeliveryBasisVariantId}`

export interface IDeliveryBasesData {
  id: TVariableDeliveryBases
  name: string
  abbreviation?: string
}

export interface IHarvestSaleCultureClassVariant {
  id: number | string
  name: string
}

export interface IHarvestSaleProteinVariant {
  value: number
  name: string
}

export enum EOutputsOrganizationsId {
  DEMETRA = 'b1bb7cae-211e-4a0f-b3b2-6789bb98b989',
}

export enum EFcaPriceStatus {
  OK = 'OK',
  ROUTE_NOT_FOUND = 'ROUTE_NOT_FOUND',
  DISTANCE_TOO_LONG = 'DISTANCE_TOO_LONG',
  PRICE_IS_EMPTY = 'PRICE_IS_EMPTY',
}

export enum EPriceVariant {
  ELEVATOR = 'price',
  EXW = 'exw',
  FOB = 'fob',
  STORE = 'fca',
}

export interface IHarvestSaleCulture {
  mainImage?: string
  name: string
  image: string
  id: string
}

export interface IHarvestSaleElevator {
  id: string
  name: string
  location: IStoreLocation
}

export interface IFcaPrice {
  price?: IPrice
  status?: EFcaPriceStatus
}

export type ITemplatePrice = IPrice | IFcaPrice | undefined

export interface IHarvestSaleQuality extends Record<TQualityName, string> {}

export interface IIndicator {
  name: string
  required?: boolean
  possibleValues: string[]
  unit?: string
  order?: string
  maxRange?: number
  minRange?: number
}

export enum EQualityIndicatorValueType {
  double = 'DOUBLE',
  integer = 'INTEGER',
  list = 'LIST',
}

export interface IQualityIndicator<GId = string> extends IIndicator {
  id: GId
  maxRange?: number
  minRange?: number
  unit?: string
  dataType?: EQualityIndicatorValueType
  isDisplayedOnCreation?: boolean
}

export interface IRequestQualityIndicator {
  cultureName?: string
  indicators: Partial<Record<TQualityName, IIndicator>>
}

export interface IRequestQualityIndicators {
  optionalQualityIndicators: IQualityIndicator[]
  requiredQualityIndicators: IQualityIndicator[]
}

export interface ITemplate {
  id: string
  minVolume: number
  name: string
  price?: IPrice
  exw?: IPrice
  fca: IFcaPrice
  fob?: IPrice
  buyer: IOrganization
  qualityIndicators: IRequestQualityIndicator
  culture: IHarvestSaleCulture
  elevator?: IHarvestSaleElevator
  fcaRegion?: IRegion
  updateDate: string
  creationDate: string
  validTo?: string | Date
  status?: EPurchaseStatus
}

export interface IFilterOption {
  name: string
  value: string
  dependencies?:
    | Array<{
        value: string | number
        name: string
      }>
    | undefined
}

export type TTemplatesFilterName =
  | 'cultures'
  | 'cultureClasses'
  | 'protein'
  | 'elevators'
  | 'regionCodes'
  | 'deliveryBasis'
  | 'store'
  | 'buyers'

export interface ITemplatesFilter {
  name: string
  value: TTemplatesFilterName
  options: IFilterOption[]
}

export interface ILotsFilter {
  title: string
  category: TTemplatesFilterName
  options: ICheckboxProps[]
  zeroOptionsMessageId?: string | undefined
  zeroOptionsMessage?: string | undefined
}

/**
 * Перечисляемый тип базиса поставки
 * @enum {string}
 */
export enum EDeliveryBasisVariantId {
  /** Элеватор/порт (CPT) */
  elevator = 'elevator',
  /** Со склада хозяйства (FCA) */
  pickup = 'pickup',
  /** Перепись (EXW) */
  exw = 'exw',
  wagon = 'wagon',
  /**На борту судна (FOB) */
  fob = 'fob',
}

export interface IDeliveryBasisVariant {
  name: string
  id: EDeliveryBasisVariantId
  abbreviation?: string
}

export enum EDeliveryBasis {
  fca = 'fca',
  cpt = 'cpt',
  exw = 'exw',
  wagon = 'wagon',
  fob = 'fob',
}

export interface ITemplatesResponse {
  templates: ITemplate[]
  filters: ITemplatesFilter[]
  pagination: IPagination
  deliveryBasisVariants: IDeliveryBasisVariant[]
}

export type TQualityName =
  | 'cultureClass'
  | 'protein'
  | 'proteinFrom'
  | 'proteinTo'
  | 'humidity'
  | 'gluten'
  | 'nature'
  | 'grainAdmixture'
  | 'weedAdmixture'
  | 'fallingNumber'
  | 'harvestYear'
  | 'ergot'
  | 'damagedByTurtleBugSeedsContent'

export interface IHarvestSalePageRequestCulture extends IHarvestSaleCulture {
  qualityIndicators: Record<TQualityName, IIndicator>
}

export interface IHarvestSalePageRequest {
  count: number
  cultureData: IHarvestSalePageRequestCulture
  saleTemplatesByCulture: ITemplate[]
}

export interface ISaleRequestByCultureDataResponse {
  deliveryBasisVariants: IDeliveryBasisVariant[]
  saleRequestTemplateByCultureData: IHarvestSalePageRequest[]
}
