
export enum NotificationTypeDataType {
    "UNREAD_MESSAGES_COUNT" = "UNREAD_MESSAGES_COUNT",
    "UNREAD_WEB_NOTIFICATION_COUNT" = "UNREAD_WEB_NOTIFICATION_COUNT",
    "UNREAD_FUNDING_MESSAGES_COUNT" = "UNREAD_FUNDING_MESSAGES_COUNT",
    "UNWATCHED_FUNDING_DOCUMENTS_COUNT" = "UNWATCHED_FUNDING_DOCUMENTS_COUNT"
}
export type NotificationTypeData = {
    /**
     * @type array
    */
    type: NotificationTypeDataType[];
};
