import type { FC } from 'react'
import React, { memo, useMemo } from 'react'
import classNames from 'classnames'
import type { ILotQualitiesProps } from './interfaces'
import './styles.scss'

const LotQualities: FC<ILotQualitiesProps> = ({ quality, className }) => {
  const { indicators } = quality || {}

  const sortedIndicators = useMemo(() => {
    return indicators ? Object.entries(indicators).sort((a, b) => Number(a[1].order) - Number(b[1].order)) : null
  }, [indicators])

  return sortedIndicators ? (
    <div className={classNames('lot-qualities', className)}>
      {sortedIndicators.map(([key, indicator]) => (
        <p className="lot-quality" key={key}>
          <span className="lot-quality__label color_pale-black">{indicator.name?.toLowerCase()}</span>
          <span className="lot-quality__value">{indicator.possibleValues}</span>
        </p>
      ))}
    </div>
  ) : null
}

export default memo(LotQualities)
