import type { QueryKey, UseQueryResult, UseQueryOptions, QueryOptions } from "@tanstack/react-query";
import { useQuery } from "@tanstack/react-query";
import client from "@/api/client/axios";
import type { GetSaleRequestShippingsDocumentsQueryResponse, GetSaleRequestShippingsDocumentsPathParams, GetSaleRequestShippingsDocumentsQueryParams, GetSaleRequestShippingsDocuments404, GetSaleRequestShippingsDocuments500 } from "../types/GetSaleRequestShippingsDocuments";

export const getSaleRequestShippingsDocumentsQueryKey = (requestId: GetSaleRequestShippingsDocumentsPathParams["requestId"], shippingId: GetSaleRequestShippingsDocumentsPathParams["shippingId"], params?: GetSaleRequestShippingsDocumentsQueryParams) =>     [{url:`/api/salerequests/${requestId}/shippings/${shippingId}/documents`,params:{requestId:requestId,shippingId:shippingId}},...(params ? [params] : [])] as const;
      export function getSaleRequestShippingsDocumentsQueryOptions <TData = GetSaleRequestShippingsDocumentsQueryResponse, TError = GetSaleRequestShippingsDocuments404 | GetSaleRequestShippingsDocuments500>(requestId: GetSaleRequestShippingsDocumentsPathParams["requestId"], shippingId: GetSaleRequestShippingsDocumentsPathParams["shippingId"], params?: GetSaleRequestShippingsDocumentsQueryParams, options: Partial<Parameters<typeof client>[0]> = {}): UseQueryOptions<TData, TError> {
        const queryKey = getSaleRequestShippingsDocumentsQueryKey(requestId, shippingId, params);

        return {
          queryKey,
          queryFn: () => {
            
            return client<TData, TError>({
              method: "get",
              url: `/api/salerequests/${requestId}/shippings/${shippingId}/documents`,
              params,
              
              ...options,
            }).then(res => res.data);
          },
        };
      };
      
/**
 * @summary Запрос на получение списка документов отгрузки
 * @link /api/salerequests/:requestId/shippings/:shippingId/documents
 */

export function useGetSaleRequestShippingsDocuments <TData = GetSaleRequestShippingsDocumentsQueryResponse, TError = GetSaleRequestShippingsDocuments404 | GetSaleRequestShippingsDocuments500>(requestId: GetSaleRequestShippingsDocumentsPathParams["requestId"], shippingId: GetSaleRequestShippingsDocumentsPathParams["shippingId"], params?: GetSaleRequestShippingsDocumentsQueryParams, options: {
          query?: UseQueryOptions<TData, TError>,
          client?: Partial<Parameters<typeof client<TData, TError>>[0]>,
        } = {}): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const { query: queryOptions, client: clientOptions = {} } = options ?? {};
  const queryKey = queryOptions?.queryKey ?? getSaleRequestShippingsDocumentsQueryKey(requestId, shippingId, params);

  const query = useQuery<TData, TError>({
    ...getSaleRequestShippingsDocumentsQueryOptions<TData, TError>(requestId, shippingId, params, clientOptions),
    ...queryOptions
  }) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey as QueryKey;

  return query;
};
