import client from "@/api/client/axios";
import type { ResponseConfig } from "@/api/client/axios";
import type { GetLegalDocumentsQueryResponse } from "../../types/GetLegalDocuments";

/**
     * @summary Запрос на получение публичных документов
     * @link /api/documents/legal
     */
export async function getLegalDocuments (options: Partial<Parameters<typeof client>[0]> = {}): Promise<ResponseConfig<GetLegalDocumentsQueryResponse>["data"]> {
    const { data: resData } = await client<GetLegalDocumentsQueryResponse>({
        method: "get",
        url: `/api/documents/legal`,
        ...options
    });
    
    return resData;
};