import Loadable from '@loadable/component'

export * from './types'

export const UserRegionSelectPickerDesktop = Loadable(
  /* webpackChunkName: "UserRegionSelectPickerDesktop" */
  /* webpackPrefetch: true */
  /* #__LOADABLE__ */
  () => import('./UserRegionSelectPickerDesktop'),
)

export const UserRegionSelectPickerMobile = Loadable(
  /* webpackChunkName: "UserRegionSelectPickerMobile" */
  /* webpackPrefetch: true */
  /* #__LOADABLE__ */
  () => import('./UserRegionSelectPickerMobile'),
)
