import client from "@/api/client/axios";
import type { ResponseConfig } from "@/api/client/axios";
import type { GetBadgesV2MutationRequest, GetBadgesV2MutationResponse } from "../../types/GetBadgesV2";

/**
     * @summary Запрос на получение бэйджей
     * @link /api/v2/badges
     */
export async function getBadgesV2 (data: GetBadgesV2MutationRequest, options: Partial<Parameters<typeof client>[0]> = {}): Promise<ResponseConfig<GetBadgesV2MutationResponse>["data"]> {
    const { data: resData } = await client<GetBadgesV2MutationResponse, GetBadgesV2MutationRequest>({
        method: "post",
        url: `/api/v2/badges`,
        data,
        ...options
    });
    
    return resData;
};