import type { IDropdownOption } from '@frontend/pole-ui/lib/components/Dropdown'
import { createAction } from '@reduxjs/toolkit'
import type { NHarvestSaleAction, TFilterOptions } from '@/logic/harvestSale/interfaces'
import type { TCreatingHarvestSaleRequestFinishedPayload } from '@/pages/HarvestSaleLots/interfaces'

export const clearHarvestSale = createAction('@@harvestSale/CLEAR_HARVEST_SALE')

export const saveSaleRequestFormValues = createAction<NHarvestSaleAction.ISaveSaleRequestFormValues>(
  '@@harvestSale/SAVE_SALE_REQUEST_FORM_VALUES',
)

export const clearSavedSaleRequestFormValues = createAction('@@harvestSale/CLEAR_SAVED_SALE_REQUEST_FORM_VALUES')

export const requestHarvestSale = createAction<NHarvestSaleAction.IRequestHarvestSalePayload>('@@harvestSale/REQUEST_HARVEST_SALE')

export const requestingHarvestSaleFinished = createAction<TCreatingHarvestSaleRequestFinishedPayload>(
  '@@harvestSale/REQUESTING_HARVEST_SALE_FINISHED',
)
export const resetRequestingHarvestSaleRequestState = createAction('@@harvestSale/RESET_REQUESTING_HARVEST_SALE_REQUEST_STATE')

export const clearHarvestSaleRequestState = createAction('@@harvestSale/CLEAR_HARVEST_SALE_REQUEST_STATE')

export const requestingHarvestSaleFailed = createAction<NHarvestSaleAction.IFailureResponseHarvestSalePayload>(
  '@@harvestSale/REQUESTING_HARVEST_SALE_FAILED',
)

export const setHarvestSaleFilterOptions = createAction<TFilterOptions>('@@harvestSale/SET_HARVEST_SALE_FILTER_OPTIONS')

export const setHarvestSaleDeliveryBasis = createAction<IDropdownOption[]>('@@harvestSale/SET_HARVEST_SALE_DELIVERY_BASIS')

export const saveSaleRequestBuyerOptions = createAction<IDropdownOption[]>('@@harvestSale/SAVE_SALE_REQUEST_BUYER_OPTIONS')

export const clearSaleRequestBuyerOptions = createAction('@@harvestSale/CLEAR_SALE_REQUEST_BUYER_OPTIONS')
