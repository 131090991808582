import { isSameDay, isSameYear, isYesterday } from 'date-fns'
import { PublicationDataDtoV1Type } from '@/api/kubik/infoportal'
import { messages } from './messages'
import { useFormatDate } from './useFormatDate'

export const useFormatPublicationDate = (publicationType?: PublicationDataDtoV1Type) => {
  const { formatDate } = useFormatDate()
  const isNews = publicationType === PublicationDataDtoV1Type.NEWS

  return {
    formatPublicationDate: (publicationDate: string | Date) => {
      if (!isSameYear(new Date(), new Date(publicationDate))) return formatDate(publicationDate, 'dd MMMM yyyy')
      if (isSameDay(new Date(), new Date(publicationDate)))
        return isNews ? `${messages.today}, ${formatDate(publicationDate, 'HH:mm')}` : messages.today
      if (isYesterday(new Date(publicationDate))) return messages.yesterday

      return formatDate(publicationDate, 'dd MMMM')
    },
  }
}
