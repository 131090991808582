import type { ReactNode } from 'react'
import React, { forwardRef, memo, useCallback, useImperativeHandle, useMemo, useReducer, useState } from 'react'
import type { SuggestionSelectedEventData } from 'react-autosuggest'
import classNames from 'classnames'
import AutosuggestView from '@/components/ui/fields/Autosuggest/AutosuggestView'
import { injectMessages } from '@/logic/translation/utils'
import { useIsomorphicLayoutEffect } from '@/utils/hooks'
import type { ISearchInputWithSuggestionsProps, ISearchInputWithSuggestionsRef } from './interfaces'
import messages from './messages.json'
import SearchInputWithSuggestionsWrapper from './SearchInputWithSuggestionsWrapper'
import SearchInputWrapper from './SearchInputWrapper'
import './SearchInputWithSuggestions.scss'

injectMessages(messages)

const DEFAULT_META = {}

const SearchInputWithSuggestions = <GSuggestion,>(
  props: ISearchInputWithSuggestionsProps<GSuggestion>,
  forwardedRef: React.Ref<ISearchInputWithSuggestionsRef>,
) => {
  const {
    name,
    className,
    customInputProps,
    shouldOpenInModal = false,
    customInputWrapper,
    value = '',
    meta = DEFAULT_META,
    onSuggestChange,
    onChange,
    onInput,
    onSuggestionSelected,
    suggestionComponent,
    getSuggestionValue,
    onSuggestionsFetchRequested,
    shouldRenderSuggestions,
    resetValueAfterSelect = true,
    focusInputOnSuggestionClick,
  } = props

  const [internalValue, setInternalValue] = useState<string>(value ?? '')

  useIsomorphicLayoutEffect(() => setInternalValue(value ?? ''), [value])
  // TODO: Возможно и без этого будет работать
  useIsomorphicLayoutEffect(() => onChange?.(internalValue ?? ''), [internalValue, onChange])

  const [dropdownKey, setDropdownKey] = useReducer(prevState => prevState + 1, 0)
  const [shouldOpenModal, setModalVisibility] = useState<boolean>(false)

  const { showModal, hideModal } = useMemo(
    () => ({
      showModal: () => setModalVisibility(shouldOpenInModal),
      hideModal: () => setModalVisibility(false),
    }),
    [setModalVisibility, shouldOpenInModal],
  )

  const resetForm = useCallback(() => {
    setDropdownKey()
    setInternalValue('')
  }, [setDropdownKey, setInternalValue])

  // TODO: Придумать нормальное решение и выпилить
  const suggestionSelected = (event: SuggestionSelectedEventData<GSuggestion>) => {
    // По выбору сущности подсказки поиск сбрасывается, чтобы при выборе Категории
    // не подставлялся текст категории
    setTimeout(() => {
      if (resetValueAfterSelect) {
        setInternalValue('')
      }

      hideModal()
    }, 0)

    return onSuggestionSelected && onSuggestionSelected(event)
  }

  useImperativeHandle(forwardedRef, () => ({ hideModal }), [hideModal])

  const inputProps = useMemo(
    () => ({ placeholderId: customInputProps?.placeholder ?? 'searchInput.placeholder', autoFocus: shouldOpenModal, ...customInputProps }),
    [customInputProps, shouldOpenModal],
  )

  const withActions = (shouldOpenInModal ? shouldOpenModal : true) && Boolean(internalValue)

  const renderCustomInput = useCallback(
    (TextInput: ReactNode) => {
      if (customInputWrapper) {
        const InputWrapper = customInputWrapper

        return (
          <InputWrapper onReset={resetForm} withActions={withActions}>
            {TextInput}
          </InputWrapper>
        )
      }

      return (
        <SearchInputWrapper onReset={resetForm} withActions={withActions}>
          {TextInput}
        </SearchInputWrapper>
      )
    },
    [resetForm, withActions, customInputWrapper],
  )

  return (
    <form
      className={classNames('search-input-with-suggestions', className, {
        'search-input-with-suggestions_has-actions': withActions,
        'search-input-with-suggestions_in-modal': shouldOpenModal,
      })}
      onSubmit={event => {
        event.preventDefault()
        onInput?.(internalValue)
      }}
    >
      <SearchInputWithSuggestionsWrapper shouldOpenModal={shouldOpenModal} onCloseModal={hideModal}>
        <AutosuggestView<GSuggestion>
          name={name}
          defaultInputValue={internalValue}
          onSuggestChange={onSuggestChange}
          renderCustomInput={renderCustomInput}
          meta={meta}
          onSuggestionSelected={suggestionSelected}
          suggestionComponent={suggestionComponent}
          getSuggestionValue={getSuggestionValue}
          onSuggestionsFetchRequested={onSuggestionsFetchRequested}
          onInputChange={setInternalValue}
          onInputFocus={showModal}
          customInputProps={inputProps}
          shouldAlwaysRenderSuggestions={shouldOpenInModal}
          key={dropdownKey}
          shouldRenderSuggestions={shouldRenderSuggestions}
          focusInputOnSuggestionClick={focusInputOnSuggestionClick}
        />
      </SearchInputWithSuggestionsWrapper>
    </form>
  )
}

export default memo(forwardRef(SearchInputWithSuggestions)) as <GSuggestion>(
  props: ISearchInputWithSuggestionsProps<GSuggestion> & { ref?: React.Ref<ISearchInputWithSuggestionsRef> },
) => ReturnType<typeof SearchInputWithSuggestions>
