import client from "@/api/client/axios";
import type { ResponseConfig } from "@/api/client/axios";
import type { DownloadSaleRequestCryptoSignedContentQueryResponse, DownloadSaleRequestCryptoSignedContentPathParams, DownloadSaleRequestCryptoSignedContentQueryParams } from "../../types/DownloadSaleRequestCryptoSignedContent";

/**
     * @summary Запрос содержимого документа для подписи УКЭП в виде выгружаемого PDF файла
     * @link /api/salerequests/:requestId/sign/crypto/content/download
     */
export async function downloadSaleRequestCryptoSignedContent (requestId: DownloadSaleRequestCryptoSignedContentPathParams["requestId"], params: DownloadSaleRequestCryptoSignedContentQueryParams, options: Partial<Parameters<typeof client>[0]> = {}): Promise<ResponseConfig<DownloadSaleRequestCryptoSignedContentQueryResponse>["data"]> {
    const { data: resData } = await client<DownloadSaleRequestCryptoSignedContentQueryResponse>({
        method: "get",
        url: `/api/salerequests/${requestId}/sign/crypto/content/download`,
        params,
        ...options
    });
    
    return resData;
};