import client from "@/api/client/axios";
import type { ResponseConfig } from "@/api/client/axios";
import type { GetOrderChatMessagesQueryResponse, GetOrderChatMessagesPathParams } from "../../types/GetOrderChatMessages";

/**
     * @summary Получить сообщения
     * @link /api/orders/:orderId/chat
     */
export async function getOrderChatMessages (orderId: GetOrderChatMessagesPathParams["orderId"], options: Partial<Parameters<typeof client>[0]> = {}): Promise<ResponseConfig<GetOrderChatMessagesQueryResponse>["data"]> {
    const { data: resData } = await client<GetOrderChatMessagesQueryResponse>({
        method: "get",
        url: `/api/orders/${orderId}/chat`,
        ...options
    });
    
    return resData;
};