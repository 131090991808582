import { combineEpics } from 'redux-observable'
import { EMPTY } from 'rxjs'
import { catchError, filter, skipWhile, switchMap, tap } from 'rxjs/operators'
import { requestWithRawResponse } from '@/api/request'
import type { Epic } from '@/logic/interfaces'
import { downloadFileFromResponse } from '@/utils/files'
import { downloadDocument } from './actions'
import type { IDealAgreementFileDownload } from './interfaces'

const onDownloadDocumentDoMakeRequest: Epic<IDealAgreementFileDownload, typeof downloadDocument> = action$ =>
  action$.pipe(
    filter(downloadDocument.match),
    switchMap(({ payload }) => {
      const { url, tab } = payload

      return requestWithRawResponse<Blob>({
        url,
        method: 'GET',
      }).pipe(
        tap(response => downloadFileFromResponse(response, 'Doc.pdf', tab)),
        skipWhile(() => true),
        catchError(() => EMPTY),
      )
    }),
  )
//eslint-disable-next-line @typescript-eslint/no-explicit-any
export default (<any>combineEpics)(onDownloadDocumentDoMakeRequest)
