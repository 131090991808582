import type { TMessages } from '@/types/TMessages'

export const messages = {
  label: {
    fca: 'хозяйство (FCA)',
    price: 'элеватор (CPT)',
    exw: 'перепись (EXW)',
    fob: 'на борту судна (FOB)',
  },
  emptyPriceTip: {
    hint: {
      title: {
        ROUTE_NOT_FOUND: 'Не рассчитана цена',
        DISTANCE_TOO_LONG: 'Не рассчитана цена',
        PRICE_IS_EMPTY: 'Не указана цена на складе (FCA) покупателем',
        storeNotChosen: 'Не указан адрес склада',
        cptIsEmpty: 'Не указана цена на элеваторе (CPT) покупателем',
        exwIsEmpty: 'Не указана цена на элеваторе (EXW) покупателем',
        fobIsEmpty: 'Не указана цена на элеваторе (FOB) покупателем',
        fcaIsEmpty: 'Не указана цена на складе (FCA) покупателем',
        notWork: 'Покупатель не работает по данному базису',
        rub: '₽/т',
      },
      description: {
        ROUTE_NOT_FOUND: 'Указанный склад находится дальше 200 км от элеватора.',
        DISTANCE_TOO_LONG: 'Указанный склад находится дальше 200 км от элеватора.',
        PRICE_IS_EMPTY: 'Предложите свою цену, отправив заявку с базисом поставки «Со склада хозяйства (FCA)».',
        storeNotChosen: 'Укажите адрес склада, чтобы увидеть цену на складе.',
        cptIsEmpty: 'Предложите свою цену, отправив заявку с базисом поставки «Элеватор/порт (СРТ)».',
        exwIsEmpty: 'Предложите свою цену, отправив заявку с базисом поставки «Перепись (EXW)».',
        fobIsEmpty: 'Предложите свою цену, отправив заявку с базисом поставки На борту судна (FOB)».',
        fcaIsEmpty: 'Предложите свою цену, отправив заявку с базисом поставки «Склад хозяйства (FCA)».',
        notWork: ' ',
      },
    },
  },
} satisfies TMessages
