import client from "@/api/client/axios";
import type { ResponseConfig } from "@/api/client/axios";
import type { AddRequestChatMessageMutationRequest, AddRequestChatMessageMutationResponse, AddRequestChatMessagePathParams } from "../../types/AddRequestChatMessage";

/**
     * @summary Добавить сообщение в чат в заявке на продажу
     * @link /api/salerequests/:requestId/chat
     */
export async function addRequestChatMessage (requestId: AddRequestChatMessagePathParams["requestId"], data: AddRequestChatMessageMutationRequest, options: Partial<Parameters<typeof client>[0]> = {}): Promise<ResponseConfig<AddRequestChatMessageMutationResponse>["data"]> {
    const { data: resData } = await client<AddRequestChatMessageMutationResponse, AddRequestChatMessageMutationRequest>({
        method: "post",
        url: `/api/salerequests/${requestId}/chat`,
        data,
        ...options
    });
    
    return resData;
};