import type { PropsWithChildren } from 'react'
import React, { useCallback } from 'react'
import type { FieldPath, FieldValues } from 'react-hook-form'
import { useController } from 'react-hook-form'
import { Checkbox } from '@frontend/pole-ui/lib/components/Checkbox'
import ErrorMessage from '@/components/ui/ErrorMessage'
import { splitHookFormControllerProps } from '@/utils/fields'
import type { IHookFormCheckboxProps } from './types'

export function HookFormCheckbox<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>(props: PropsWithChildren<IHookFormCheckboxProps<TFieldValues, TName>>) {
  const [controllerProps, { errorClassName, isDisabled, onBlur, onChange, ...componentProps }] = splitHookFormControllerProps(props)

  const disabled = controllerProps.disabled || isDisabled

  const { field, fieldState } = useController({ ...controllerProps, disabled })

  const { message: errorMessage } = fieldState.error ?? {}

  const onInputBlur: NonNullable<typeof onBlur> = useCallback(
    event => {
      field.onBlur()
      onBlur?.(event)
    },
    [field, onBlur],
  )

  const onInputChange: NonNullable<typeof onChange> = useCallback(
    (checked, event) => {
      field.onChange(event)
      onChange?.(checked, event)
    },
    [field, onChange],
  )

  return (
    <Checkbox
      error={errorMessage && <ErrorMessage textId={errorMessage} isVisible className={errorClassName} />}
      {...field}
      {...componentProps}
      isDisabled={field.disabled}
      onBlur={onInputBlur}
      onChange={onInputChange}
    />
  )
}
