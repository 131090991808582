import type { FC } from 'react'
import React, { memo } from 'react'
import { Button } from '@/components/ui'
import type { IStatusModelActionsProps } from '../../interfaces'
import { useStatusModelButtons } from '../../useStatusModelButtons'
import './styles.scss'

const Buttons: FC<IStatusModelActionsProps> = props => {
  const buttonsProps = useStatusModelButtons(props)

  return (
    <div className="orderDetailsNotificationButtonContainer">
      <Button
        modifiers={['white-black', 'small', 'full-width-mobile']}
        className="orderDetailsNotificationButton"
        {...buttonsProps.accreditationWithUKEP}
      />
      <Button
        modifiers={['outline-white', 'small', 'full-width-mobile']}
        className="orderDetailsNotificationButton"
        {...buttonsProps.cancel}
      />
      <Button
        modifiers={['outline-white', 'small', 'full-width-mobile']}
        className="orderDetailsNotificationButton"
        {...buttonsProps.feedback}
      />
      <Button modifiers={['white', 'small', 'full-width-mobile']} className="orderDetailsNotificationButton" {...buttonsProps.sberPay} />
      <Button
        modifiers={['outline-white', 'small', 'full-width-mobile']}
        className="orderDetailsNotificationButton"
        {...buttonsProps.edit}
      />
      <Button
        modifiers={['white-black', 'small', 'full-width-mobile']}
        className="orderDetailsNotificationButton"
        {...buttonsProps.download}
      />
      <Button
        modifiers={['outline-white', 'small', 'full-width-mobile']}
        className="orderDetailsNotificationButton"
        {...buttonsProps.supplySchedule}
      />
      <Button
        modifiers={['white-black', 'small', 'full-width-mobile']}
        className="orderDetailsNotificationButton"
        {...buttonsProps.addSupply}
      />
      <Button
        modifiers={['white-black', 'small', 'full-width-mobile']}
        className="orderDetailsNotificationButton"
        {...buttonsProps.confirm}
      />
      <Button modifiers={['white-black', 'small', 'full-width-mobile']} className="orderDetailsNotificationButton" {...buttonsProps.sign} />
      <Button
        modifiers={['outline-white', 'small', 'full-width-mobile']}
        className="orderDetailsNotificationButton"
        {...buttonsProps.accreditation}
      />

      <Button modifiers={['white', 'small', 'full-width-mobile']} className="orderDetailsNotificationButton" {...buttonsProps.signCrypto} />

      <Button modifiers={['white', 'small', 'full-width-mobile']} className="orderDetailsNotificationButton" {...buttonsProps.payOnline} />
    </div>
  )
}

export default memo(Buttons)
