export const ONLY_DIGITS_RE = /^[\d]+$/

export const EMAIL_RE = /^[^\s@]+@[^\s@]+\.[^\s@]+$/

export const NON_NUMBER_SYMBOLS_RE = /[^\d-.]/g
export const LAST_DOT_RE = /\.$/
export const LEAD_ZEROS_RE = /^0*/
export const LAST_ZEROS_RE = /0*$/

export const INN_RE = /^(\d{10}|\d{12})$/
