import type { ReactNode } from 'react'

export enum EAccessControlValue {
  VISIBLE = 'VISIBLE',
  HIDDEN = 'HIDDEN',
}

export type TAccessControlRule<TContext, TValue extends string = EAccessControlValue> = (context: TContext, extra: unknown) => TValue

export type TAccessControlRules<TContext, TValue extends string = EAccessControlValue> = {
  [key: string]: TAccessControlRule<TContext, TValue> | TAccessControlRules<TContext, TValue>
}

export type TAccessControlProviderProps<TContext> = {
  context: TContext
  children: ReactNode
}

export type TAccessControlResolver<
  TRules extends TAccessControlRules<TContext, TValue>,
  TContext,
  TValue extends string = EAccessControlValue,
> = (key: TAccessControlRuleKey<TRules, TContext, TValue>, extra?: unknown) => TValue

export type TAccessControlProviderContent<
  TRules extends TAccessControlRules<TContext, TValue>,
  TContext,
  TValue extends string = EAccessControlValue,
> = {
  resolve: TAccessControlResolver<TRules, TContext, TValue>
}

export type TAccessControlRuleKey<TRules, TContext, TValue extends string = EAccessControlValue> =
  TRules extends TAccessControlRules<TContext, TValue>
    ? {
        [TKey in keyof TRules & string]: TRules[TKey] extends Function
          ? `${TKey}`
          : TRules[TKey] extends TAccessControlRules<TContext, TValue>
            ? `${TKey}.${TAccessControlRuleKey<TRules[TKey], TContext, TValue>}`
            : '.'
      }[keyof TRules & string]
    : TRules extends Function
      ? ''
      : never
