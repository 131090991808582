import React, { memo, useCallback } from 'react'
import { useField } from 'react-final-form'
import isEqual from 'lodash/isEqual'
import QuantityPicker from '@/components/composed/QuantityPicker'
import type { IQuantityPickerInputProps } from './interfaces'

const QuantityPickerInput: React.FC<IQuantityPickerInputProps> = props => {
  const { name, onChange, pickerProps } = props
  const { input } = useField(name)

  const handleChange = useCallback(
    (quantity: number) => {
      onChange?.(quantity)
      input.onChange(quantity)
    },
    [onChange, input],
  )

  return <QuantityPicker {...pickerProps} defaultQuantity={input.value} onChange={handleChange} />
}

export default memo(QuantityPickerInput, isEqual)
