import type { TSkeletons } from '@frontend/pole-ui/lib/components/Skeleton'

export const SKELETONS: TSkeletons = Array.from({ length: 5 }, () => ({
  className: 'journalSearch__skeletons',
}))

export const TOTAL_ITEMS_PER_PAGE = 15

export const MAX_QUERIES = 10
export const STORAGE_KEY = 'recentJournalSearchQueries'
