import client from "@/api/client/axios";
import type { ResponseConfig } from "@/api/client/axios";
import type { GetRequestChatMessagesQueryResponse, GetRequestChatMessagesPathParams } from "../../types/GetRequestChatMessages";

/**
     * @summary Получение списка сообщений чата в заявке на продажу
     * @link /api/salerequests/:requestId/chat
     */
export async function getRequestChatMessages (requestId: GetRequestChatMessagesPathParams["requestId"], options: Partial<Parameters<typeof client>[0]> = {}): Promise<ResponseConfig<GetRequestChatMessagesQueryResponse>["data"]> {
    const { data: resData } = await client<GetRequestChatMessagesQueryResponse>({
        method: "get",
        url: `/api/salerequests/${requestId}/chat`,
        ...options
    });
    
    return resData;
};