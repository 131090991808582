import type { QueryKey, UseQueryResult, UseQueryOptions, QueryOptions } from "@tanstack/react-query";
import { useQuery } from "@tanstack/react-query";
import client from "@/api/client/auctions";
import type { GetBetsV2QueryResponse, GetBetsV2QueryParams, GetBetsV2400, GetBetsV2404 } from "../types/GetBetsV2";

export const getBetsV2QueryKey = (params: GetBetsV2QueryParams) =>     [{url:`/v2/auction/view_bets`},...(params ? [params] : [])] as const;
      export function getBetsV2QueryOptions <TData = GetBetsV2QueryResponse, TError = GetBetsV2400 | GetBetsV2404>(params: GetBetsV2QueryParams, options: Partial<Parameters<typeof client>[0]> = {}): UseQueryOptions<TData, TError> {
        const queryKey = getBetsV2QueryKey(params);

        return {
          queryKey,
          queryFn: () => {
            
            return client<TData, TError>({
              method: "get",
              url: `/v2/auction/view_bets`,
              params,
              
              ...options,
            }).then(res => res.data);
          },
        };
      };
      
/**
 * @description Метод предназначен для получения данных по ставкам аукциона по ID аукциона. https://confluence.poleinvest.ru/pages/viewpage.action?pageId=448006637

 * @link /v2/auction/view_bets
 */

export function useGetBetsV2 <TData = GetBetsV2QueryResponse, TError = GetBetsV2400 | GetBetsV2404>(params: GetBetsV2QueryParams, options: {
          query?: UseQueryOptions<TData, TError>,
          client?: Partial<Parameters<typeof client<TData, TError>>[0]>,
        } = {}): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const { query: queryOptions, client: clientOptions = {} } = options ?? {};
  const queryKey = queryOptions?.queryKey ?? getBetsV2QueryKey(params);

  const query = useQuery<TData, TError>({
    ...getBetsV2QueryOptions<TData, TError>(params, clientOptions),
    ...queryOptions
  }) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey as QueryKey;

  return query;
};
