import { NotificationDataType } from '@/api/kubik/monolith'
import type { INotifications } from '@/logic/notifications/types'

export const POLLING_INTERVAL = 60000

export const INITIAL_BADGES: Readonly<INotifications> = {
  unreadChatMessagesCount: 0,
  unreadNotificationsCount: 0,
  totalUnreadNotificationsCount: 0,
  unreadFundingMessagesCount: 0,
  unwatchedFundingDocumentsCount: 0,
}

export const STATE_KEY_BY_NOTIFICATION_DATA_TYPE: Readonly<Record<NotificationDataType, keyof INotifications>> = {
  [NotificationDataType.UNREAD_MESSAGES_COUNT]: 'unreadChatMessagesCount',
  [NotificationDataType.UNREAD_WEB_NOTIFICATION_COUNT]: 'unreadNotificationsCount',
  [NotificationDataType.UNREAD_FUNDING_MESSAGES_COUNT]: 'unreadFundingMessagesCount',
  [NotificationDataType.UNWATCHED_FUNDING_DOCUMENTS_COUNT]: 'unwatchedFundingDocumentsCount',
}
