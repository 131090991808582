import React, { useEffect } from 'react'
import { useLocation } from '@/router/hooks'
import { useScrollDirection } from '@/utils/hooks'

const ScrollableHeader: React.FC = ({ children }) => {
  const { scrollClassName, setDirection } = useScrollDirection({ className: 'header', defaultDirection: 'down', minThrottle: 120 })
  const { pathname } = useLocation()

  useEffect(() => {
    const timeout = setTimeout(() => setDirection('up'), 250)

    return () => clearTimeout(timeout)
    //eslint-disable-next-line
  }, [pathname])

  return <header className={`header ${scrollClassName}`}>{children}</header>
}

export default ScrollableHeader
