export const baseTheme = {
  container: 'base-autosuggest__container',
  inputOpen: 'base-autosuggest__input--open',
  inputFocused: 'base-autosuggest__input--focused',
  suggestionsContainer: 'base-autosuggest__suggestions-container',
  suggestionsContainerOpen: 'base-autosuggest__suggestions-container--open',
  suggestionsList: 'base-autosuggest__suggestions-list',
  suggestion: 'base-autosuggest__suggestion',
  suggestionHighlighted: 'base-autosuggest__suggestion--highlighted',
}
