import React from 'react'
import { useSelector } from 'react-redux'
import { ResponsiveView } from '@/components/ui'
import { shouldHideHeader } from '@/logic/app/reducer'
import { injectMessages } from '@/logic/translation/utils'
import type { RootState } from '@/redux/interfaces'
import HeaderDesktop from './HeaderDesktop'
import HeaderMobile from './HeaderMobile'
import HeaderTablet from './HeaderTablet'
import messages from './messages.json'

injectMessages(messages)

const mapState = (state: RootState) => ({
  shouldHide: shouldHideHeader()(state),
})

const HeaderContainer: React.FC = () => {
  const { shouldHide } = useSelector(mapState)

  if (shouldHide) return null

  return <ResponsiveView Desktop={HeaderDesktop} Mobile={HeaderMobile} Tablet={HeaderTablet} />
}

export default HeaderContainer
