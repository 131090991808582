export const BREAKPOINTS = {
  mobile: 360,
  tablet: 768,
  desktop: 1280,
}

export const MATCH_MEDIA_QUERIES = {
  mobile: `(max-width: ${BREAKPOINTS.tablet - 1}px)`,
  desktop: `(min-width: ${BREAKPOINTS.desktop}px)`,
  tablet: `(min-width: ${BREAKPOINTS.tablet}px) and (max-width: ${BREAKPOINTS.desktop - 1}px)`,
  small: `(max-width: ${BREAKPOINTS.mobile - 1}px)`,
}

export const SERVER_DATE_FORMAT = `yyyy-MM-dd'T'HH:mm:ss.SSSXXX`

export const DEFAULT_ITEMS_PER_PAGE = 6

export enum AcceptedFileType {
  ARCHIVE = 'application/x-tar, application/x-7z-compressed, application/x-rar-compressed, application/zip',
  //https://stackoverflow.com/questions/11832930/html-input-file-accept-attribute-file-type-csv#answer-11834872
  EXCEL = '.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel',
  XLSXONLY = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  DOCS = '.txt, .doc, .docx, .rtf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  PRESENTATION = '.ppt, .pptx',
  PDF = 'application/pdf, .pdf',
  EMAIL = '.eml, .mbox',
  HTML = '.html, .mht, .mhtml',
  IMAGE = '.jpg, .png, .gif, .bmp, .tiff',
  AUDIO = '.mp3, .wav, .flac, .m4a, .wma, .aac, .ra, .ram, .aif, .aiff, .ogg',
  VIDEO = '.mp4, .mpeg, .mov, .wmv, .avi, .3gpp, .webm, .flv, .mkv, .m4v, .mp2t, .ogv, .mp4',
}

export const VAT_RATE_DEFAULT = 20
