import { requestData, requestDataWithRawResponse } from '@/api/request'
import { EInputsOrderApiUrl } from '@/api/urls'
import type {
  IAddImpersonalProductOptionsInRequestBody,
  IAgrarianOrderChangingRequestBody,
  IMakeMultiOrderParameters,
  IMakeOrderParameters,
  IProductsWithDifferentRegionResponse,
  TMakingRequestErrors,
} from '@/services/OrderService/interfaces'
import type { IStore } from '@/types'
import type { IAgrarianOrder } from '@/types/Order'
import type { TEntityId } from '@/types/TEntityId'
import { downloadFileFromResponse } from '@/utils/files'
import { createRouterLink, getUrlWithQuery } from '@/utils/url'

interface IAddProductToOrder {
  quantity: number
  orderId: TEntityId
  productId: string
  version: string
}

interface IAddImpersonalProductToOrder {
  orderId: TEntityId
  sku: IAddImpersonalProductOptionsInRequestBody
  version: string
}

interface IDeleteProductFromOrder {
  orderId: TEntityId
  productId: string
  version: string
}

interface IRecalculateOrder {
  orderId: TEntityId
  changedValues: IAgrarianOrderChangingRequestBody
  version: string
}

export interface IChangeOrderStatusOptions {
  version: string
  orderId: TEntityId
  statusComment?: string
}

export interface IChangeOrderOptions {
  changedValues: IAgrarianOrderChangingRequestBody
  orderId: TEntityId
  version: string
  statusComment?: string
}

export interface IDeleteOrderOptions {
  version: string
  orderId: TEntityId
  statusComment?: string
}

/** Сервис работы с заказом inputs */
export default class OrderService {
  /**
   * Создание заказа
   * @param {Object} parameters - информация для создания заказа
   * @prop {Object[]} parameters.items - товары
   * @prop {string} parameters.items[].productOfferId - идентификатор товара
   * @prop {number} parameters.items[].quantity - количество товара
   * @prop {Object} parameters.deliveryOptions - опции доставки
   * @prop {string} parameters.deliveryOptions.storeId? - идентификатор склада
   * @prop {string} parameters.deliveryOptions.endDate - конечная дата доставки
   * @prop {string} parameters.deliveryOptions.startDate - начальная дата доставки
   * @prop {string} parameters.cartId - идентификатор корзины
   * @prop {number} parameters.prepaymentPercentage - процент предоплаты
   * @prop {ESkuOrderType} parameters.type - тип заказа (ESkuOrderType.SINGLE_ORDER)
   * @prop {EOrderPaymentVariantType} parameters.paymentType - тип оплаты
   */
  createOrder = (parameters: IMakeOrderParameters) =>
    requestData<TMakingRequestErrors | IProductsWithDifferentRegionResponse | IAgrarianOrder>({
      url: EInputsOrderApiUrl.orders,
      method: 'POST',
      body: parameters,
    })

  /**
   * Создание мультизаказа
   * @param {Object} parameters - информация для создания мультизаказа
   * @prop {Object[]} parameters.items - товары
   * @prop {string} parameters.items[].skuId - идентификатор товара
   * @prop {number} parameters.items[].quantity - количество товара
   * @prop {number} parameters.prepaymentPercentage - процент предоплаты (для мультизаказа всегда 100)
   * @prop {ESkuOrderType} parameters.type - тип заказа (ESkuOrderType.MULTI_ORDER)
   * @prop {number} parameters.deliveryRegionCode - код региона
   */
  createMultiOrder = (parameters: IMakeMultiOrderParameters) =>
    requestData<TMakingRequestErrors | IAgrarianOrder>({
      url: EInputsOrderApiUrl.orders,
      method: 'POST',
      body: parameters,
    })

  /**
   * Добавление продукта в заказ
   * @param {Object} options
   * @prop {number} options.quantity - количество продукта
   * @prop {number} options.orderId - идентификатор заказа
   * @prop {string} options.productId - идентификатор оффера поставщика
   * @prop {string} options.version - версия заказа, updateDate
   */
  addProductToOrder = ({ quantity, orderId, productId, version }: IAddProductToOrder) =>
    requestData<IAgrarianOrder>({
      url: getUrlWithQuery(`${EInputsOrderApiUrl.orders}/${orderId}/items/${productId}`, { version }),
      method: 'POST',
      body: { quantity },
    })

  /**
   * Добавление имперсонализированного продукта в заказ
   * @param {Object} options
   * @prop {number} options.orderId - идентификатор заказа
   * @prop {string} options.version - версия заказа, updateDate
   * @prop {IAddImpersonalProductOptionsInRequestBody} options.sku - имперсовнализированный sku
   */
  addImpersonalProductToOrder = ({ orderId, sku, version }: IAddImpersonalProductToOrder) =>
    requestData<IAgrarianOrder>({
      url: getUrlWithQuery(createRouterLink(EInputsOrderApiUrl.addImpersonalOrder, { orderId }), { version }),
      method: 'PUT',
      body: sku,
    })

  /**
   * Удаление продукта из заказа
   * @param {Object} options
   * @prop {number} options.orderId - идентификатор заказа
   * @prop {string} options.productId - идентификатор оффера поставщика
   * @prop {string} options.version - версия заказа, updateDate
   */
  deleteProductFromOrder = ({ orderId, productId, version }: IDeleteProductFromOrder) =>
    requestData<IAgrarianOrder>({
      url: getUrlWithQuery(`${EInputsOrderApiUrl.orders}/${orderId}/items/${productId}`, { version }),
      method: 'DELETE',
    })

  /**
   * Предварительный расчёт заказа при изменение информации поставщиком
   * @param {Object} options
   * @prop {number} options.orderId - идентификатор заказа
   * @prop {string} options.version - версия заказа, updateDate
   * @prop {string} options.changedValues - изменяемые значения. См. интерфейс
   */
  recalculateOrder = ({ orderId, changedValues, version }: IRecalculateOrder) =>
    requestData<IAgrarianOrder>({
      url: getUrlWithQuery(`${EInputsOrderApiUrl.orders}/${orderId}/recalculate`, { version }),
      method: 'POST',
      body: changedValues,
    })

  /**
   * Получение информации о заказе
   * @param {number} id - идентификатор заказа
   */
  getOrder = (id: TEntityId) =>
    requestData<IAgrarianOrder>({
      url: `${EInputsOrderApiUrl.orders}/${id}`,
      method: 'GET',
    })

  /**
   * Создание склада агрария
   * @param {number} id - идентификатор заказа
   * @param {string} address - адрес склада
   */
  createAgrarianStore = (id: TEntityId, address: string) =>
    requestData<IStore>({
      url: createRouterLink(EInputsOrderApiUrl.stores, { id }),
      method: 'POST',
      body: { address },
    })

  /**
   * Изменение статуса заказа
   * @param {Object} options
   * @prop {number} options.orderId - идентификатор заказа
   * @prop {string} options.version - версия заказа, updateDate
   */
  changeOrderStatus = ({ orderId, version, statusComment }: IChangeOrderStatusOptions) => {
    const queryParams: { version: string; statusComment?: string } = { version }

    if (statusComment) {
      queryParams.statusComment = statusComment
    }

    return requestData<IAgrarianOrder>({
      url: getUrlWithQuery(`${EInputsOrderApiUrl.orders}/${orderId}/status/next`, queryParams),
      method: 'POST',
    })
  }

  /**
   * Изменение данных заказа
   * @param {Object} options
   * @prop {number} options.orderId - идентификатор заказа
   * @prop {string} options.version - версия заказа, updateDate
   * @prop {string} options.statusComment - комментарий
   * @prop {IAgrarianOrderChangingRequestBody} options.changedValues - изменяемые значения
   */
  changeOrder = ({ orderId, version, statusComment, changedValues }: IChangeOrderOptions) => {
    const queryParams: { version: string; statusComment?: string } = { version }

    if (statusComment) {
      queryParams.statusComment = statusComment
    }

    return requestData<IAgrarianOrder | Array<{ name: string; id: string }>>({
      url: getUrlWithQuery(`${EInputsOrderApiUrl.orders}/${orderId}`, queryParams),
      method: 'PATCH',
      body: changedValues,
    })
  }

  /**
   * Удаление заказа
   * @param {Object} options
   * @prop {number} options.orderId - идентификатор заказа
   * @prop {string} options.version - версия заказа, updateDate
   * @prop {string} options.statusComment - комментарий
   */
  deleteOrder = ({ orderId, version, statusComment }: IDeleteOrderOptions) => {
    const queryParams: { version: string; statusComment?: string } = { version }

    if (statusComment) {
      queryParams.statusComment = statusComment
    }

    return requestData<IAgrarianOrder>({
      url: getUrlWithQuery(`${EInputsOrderApiUrl.orders}/${orderId}`, queryParams),
      method: 'DELETE',
    })
  }

  downloadOrderSupplyDocument = (url: string, fileName?: string) =>
    requestDataWithRawResponse<Blob>({ url, method: 'GET' }).then(response => {
      downloadFileFromResponse(response, fileName ?? 'doc.pdf')
    })
}
