import React from 'react'
import { EColor } from '@frontend/pole-ui/lib/common/constants'
import { EIconName, EIconSize } from '@frontend/pole-ui/lib/components/Icon'
import classNames from 'classnames'
import TextWithIcon from '../TextWithIcon/TextWithIcon'
import type { IInfoTextProps } from './interfaces'
import './styles.scss'

const InfoText: React.FC<IInfoTextProps> = props => {
  const {
    color,
    text,
    infoTextId,
    iconProps,
    className,
    textModifier,
    textClassName = 'color_pale-black',
    textValues,
    isDeviceMobile,
  } = props

  return (
    <TextWithIcon
      className={classNames('info-text', className)}
      iconProps={{
        name: EIconName.InfoFill,
        className: 'info-text__icon',
        style: { color: color || EColor.BLACK },
        size: isDeviceMobile ? EIconSize.XS : EIconSize.M,
        ...iconProps,
      }}
      text={text}
      textModifier={textModifier}
      textId={infoTextId}
      textClassName={classNames('info-text__text', textClassName)}
      textValues={textValues}
    />
  )
}

export default InfoText
