import type { FC } from 'react'
import React from 'react'
import { CommonLink } from '@frontend/pole-ui/lib/components/CommonLink'
import { EIconName, EIconSize } from '@frontend/pole-ui/lib/components/Icon'
import { Button, Icon } from '@/components/ui'
import { EJournalRoute } from '@/types'
import { getUrlWithQuery } from '@/utils/url'
import type { IJournalSearchDefaultSuggestionCardProps } from './types'
import './styles.scss'

export const JournalSearchDefaultSuggestionCard: FC<IJournalSearchDefaultSuggestionCardProps> = props => {
  const { suggestion, onDelete } = props

  const suggestionLink = getUrlWithQuery(EJournalRoute.SEARCH, { searchText: suggestion })

  const onDeleteHandle = (event: MouseEvent) => {
    event.preventDefault()
    event.stopPropagation()
    onDelete(suggestion)
  }

  return (
    <CommonLink url={suggestionLink} className="link-no-hover journalSearchDefaultSuggestionCard">
      <Icon name={EIconName.Clock} size={EIconSize.S} className="journalSearchDefaultSuggestionCard__icon" />

      <p className="journalSearchDefaultSuggestionCard__title">{suggestion}</p>

      <Button
        isUnstyled
        onClick={onDeleteHandle}
        iconProps={{ name: EIconName.Exit, className: 'journalSearchDefaultSuggestionCard__close-icon' }}
      />
    </CommonLink>
  )
}
