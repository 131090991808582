export interface ILoanPurposeSelectProps {
  className?: string
  errorClassName?: string
  isRequired?: boolean
  isHangingError?: boolean
}

export interface IFormWithLoanPurposeSelect {
  loanPurpose?: ELoanPurpose[]
  otherLoanPurpose?: string
}

export enum ELoanPurpose {
  PURCHASE_OF_MACHINERY_EQUIPMENT = 'PURCHASE_OF_MACHINERY_EQUIPMENT',
  REPLENISHMENT_OF_WORKING_CAPITAL = 'REPLENISHMENT_OF_WORKING_CAPITAL',
  PURCHASE_OF_FIXED_ASSETS = 'PURCHASE_OF_FIXED_ASSETS',
  PURCHASE_OF_GOODS_AND_MATERIALS = 'PURCHASE_OF_GOODS_AND_MATERIALS',
  REFINANCING_CURRENT_LOANS = 'REFINANCING_CURRENT_LOANS',
  BUSINESS_DEVELOPMENT = 'BUSINESS_DEVELOPMENT',
  OTHER = 'OTHER',
}
