import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Tab as PoleUiTab } from '@frontend/pole-ui/lib/components/Tab'
import classnames from 'classnames'
import type { ITab } from './interfaces'
import './styles.scss'

const Tab: React.FC<ITab> = ({ className, LabelComponent, isActive, label, onClick, isDisabled }) => (
  <PoleUiTab
    value={label}
    text={LabelComponent ? <LabelComponent isActive={isActive} /> : <FormattedMessage id={label} />}
    className={classnames('tab-item', 'text_nowrap', className)}
    isActive={isActive}
    onClick={() => onClick(label)}
    isDisabled={isDisabled}
  />
)

export default Tab
