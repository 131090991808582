import type { UseMutationOptions, UseMutationResult } from "@tanstack/react-query";
import { useMutation } from "@tanstack/react-query";
import client from "@/api/client/axios";
import type { ResponseConfig } from "@/api/client/axios";
import type { AddSaleRequestShippingDocument2MutationRequest, AddSaleRequestShippingDocument2MutationResponse, AddSaleRequestShippingDocument2PathParams, AddSaleRequestShippingDocument2QueryParams, AddSaleRequestShippingDocument2404, AddSaleRequestShippingDocument2500 } from "../types/AddSaleRequestShippingDocument2";

/**
 * @summary Запрос на создание счета об отгрузке
 * @link /api/v2/salerequests/:requestId/shippings/:shippingId/document
 */

export function useAddSaleRequestShippingDocument2 <TData = AddSaleRequestShippingDocument2MutationResponse, TError = AddSaleRequestShippingDocument2404 | AddSaleRequestShippingDocument2500, TVariables = AddSaleRequestShippingDocument2MutationRequest>(requestId: AddSaleRequestShippingDocument2PathParams["requestId"], shippingId: AddSaleRequestShippingDocument2PathParams["shippingId"], params?: AddSaleRequestShippingDocument2QueryParams, options: {
          mutation?: UseMutationOptions<ResponseConfig<TData>, TError, TVariables>,
          client?: Partial<Parameters<typeof client<TData, TError, TVariables>>[0]>,
      } = {}): UseMutationResult<ResponseConfig<TData>, TError, TVariables> {
  const { mutation: mutationOptions, client: clientOptions = {} } = options ?? {};

  return useMutation<ResponseConfig<TData>, TError, TVariables>({
    mutationFn: (data) => {
      
      return client<TData, TError, TVariables>({
        method: "post",
        url: `/api/v2/salerequests/${requestId}/shippings/${shippingId}/document`,
        data,
        params,
        
        ...clientOptions
      });
    },
    ...mutationOptions
  });
};
