import { useState } from 'react'
import type { MutationObserverBaseResult } from '@tanstack/query-core'

export type TMutationsStatusesKeys = {
  [k in keyof MutationObserverBaseResult]: k extends `is${string}` ? (MutationObserverBaseResult[k] extends boolean ? k : never) : never
}[keyof MutationObserverBaseResult]

export type TMutationsStatuses = Pick<MutationObserverBaseResult, TMutationsStatusesKeys>

// for debug use the spread syntax: `{ ...statuses }`
export function useMutationsStatuses(mutations: TMutationsStatuses[]): TMutationsStatuses {
  const [statuses] = useState<TMutationsStatuses>(() => ({
    isError: false,
    isIdle: false,
    isLoading: false,
    isSuccess: false,
    isPaused: false,
  }))

  // https://tkdodo.eu/blog/react-query-render-optimizations#tracked-queries
  return new Proxy(statuses, {
    get(target, key: TMutationsStatusesKeys) {
      return mutations.some(q => q[key] ?? false)
    },
  })
}
