import client from "@/api/client/axios";
import type { ResponseConfig } from "@/api/client/axios";
import type { DownloadAccreditationCryptoSignedContentQueryResponse, DownloadAccreditationCryptoSignedContentPathParams, DownloadAccreditationCryptoSignedContentQueryParams } from "../../types/DownloadAccreditationCryptoSignedContent";

/**
     * @summary Запрос на скачивание подписанной УКЭП аккредитации
     * @link /api/accreditations/:accreditationId/actions/sign/crypto/content/download
     */
export async function downloadAccreditationCryptoSignedContent (accreditationId: DownloadAccreditationCryptoSignedContentPathParams["accreditationId"], params: DownloadAccreditationCryptoSignedContentQueryParams, options: Partial<Parameters<typeof client>[0]> = {}): Promise<ResponseConfig<DownloadAccreditationCryptoSignedContentQueryResponse>["data"]> {
    const { data: resData } = await client<DownloadAccreditationCryptoSignedContentQueryResponse>({
        method: "get",
        url: `/api/accreditations/${accreditationId}/actions/sign/crypto/content/download`,
        params,
        ...options
    });
    
    return resData;
};