import { MATCH_MEDIA_QUERIES } from '@/constants/common'

export function detectDevice() {
  const { matchMedia } = window
  const isSmallMobile = matchMedia(MATCH_MEDIA_QUERIES.small).matches
  const isMobile = matchMedia(MATCH_MEDIA_QUERIES.mobile).matches
  const isTablet = matchMedia(MATCH_MEDIA_QUERIES.tablet).matches
  const isDesktop = matchMedia(MATCH_MEDIA_QUERIES.desktop).matches

  return {
    isSmallMobile,
    isMobile,
    isTablet,
    isDesktop,
  }
}
