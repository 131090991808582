import type { FC } from 'react'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useHistory, useLocation } from 'react-router-dom'
import { EColor } from '@frontend/pole-ui/lib/common/constants'
import { EIconName } from '@frontend/pole-ui/lib/components/Icon'
import { Pagination } from '@frontend/pole-ui/lib/components/Pagination'
import classNames from 'classnames'
import keyBy from 'lodash/keyBy'
import type { IUploadMultipleFilesModalOptions } from '@/components/composed/Documents/components/UploadMultipleDocumentsModal/interfaces'
import type { TUpdateDocumentAction } from '@/components/resolvers/DocumentCard/interfaces'
import { Button, Loader, TextWithIcon } from '@/components/ui'
import { injectMessages } from '@/logic/translation/utils'
import { RejectingDocumentModal } from '@/pages/Profiles/UserProfile/components/Accreditation/components'
import type { RootState } from '@/redux/interfaces'
import { DEFAULT_LIMIT_DOCUMENTS } from '@/services/documents/constants'
import { type EDocumentCategory, EDocumentEntityType, type IDocument, type IDocumentType } from '@/types/Documents'
import { addNewEpicsLazily } from '@/utils/addNewEpicsLazily'
import { openModal } from '../Modal/actions'
import { addModalToWellKnown } from '../Modal/constants'
import DocumentGroup from './components/DocumentGroup/DocumentGroup'
import type { IUploadDocumentModalOptions } from './components/UploadDocumentModal/interfaces'
import { deleteDocument, downloadDocument, fetchDocuments, updateDocument, uploadDocument } from './actions'
import { DocumentView, UploadDocumentModal, UploadMultipleDocumentsModal } from './components'
import { actionPermissions, CommonDocumentType } from './constants'
import asyncEpics from './epics'
import { canMakeAction, pickFilteredOutTypes } from './helpers'
import { useGetDocumentsByType } from './hooks'
import type { IDocumentPermissions, IDocumentsProps, IFetchDocumentsPayload, TFilterDocumentTypes } from './interfaces'
import messages from './messages.json'
import { getDocuments, getPagination, getTypes, isFetching } from './reducer'
import './styles.scss'

const itemsPerPage = DEFAULT_LIMIT_DOCUMENTS

addModalToWellKnown({
  UploadDocumentModal,
  UploadMultipleDocumentsModal,
  RejectingDocumentModal,
})

addNewEpicsLazily([asyncEpics])
injectMessages(messages)

const getDefaultFilteredTypes: TFilterDocumentTypes = types => types

const mapState = (state: RootState) => ({
  documents: getDocuments()(state),
  types: getTypes()(state),
  isLoading: isFetching()(state),
  pagination: getPagination()(state),
})

const getDefaultSeparatedTypesByGroup: IDocumentsProps['separateTypesByGroup'] = types => [{ title: '', types }]

const Documents: FC<IDocumentsProps> = props => {
  const {
    entity,
    entityId,
    className,
    actions,
    shouldHideUpdatingDocumentButton = false,
    shouldDisplayDocumentStatusElement = false,
    shouldHideAddingDocumentButton = false,
    filterTypes = getDefaultFilteredTypes,
    separateTypesByGroup = getDefaultSeparatedTypesByGroup,
    openRejectingDocumentModal,
    orderIdForUpdateOrderAfterUpdateDoc,
    shouldCheckPermissionsFromActionsByCategories,
    uploadingThroughModalDocumentType,
    version,
    isMultipleUpload,
  } = props
  const dispatch = useDispatch()
  const history = useHistory()
  const location = useLocation()

  const [pageNumber, setPageNumber] = useState(() => {
    const params = new URLSearchParams(location.search)

    return parseInt(params.get('page') || '1', 10)
  })

  const getUrl = useCallback(
    (newPageNumber?: number) => {
      const params = new URLSearchParams(location.search)

      if (newPageNumber && newPageNumber > 1) {
        params.set('noscrolltop', 'true')
        params.set('page', newPageNumber.toString())
      } else {
        params.delete('noscrolltop')
        params.delete('page')
      }

      return `${location.pathname}${params.toString() ? `?${params.toString()}` : ''}`
    },
    [location.pathname, location.search],
  )

  const updateUrl = useCallback(
    (newPageNumber: number) => {
      const url = getUrl(newPageNumber)
      history.replace(url)
    },
    [getUrl, history],
  )

  useEffect(() => {
    const fetchingDocumentPayload: IFetchDocumentsPayload = {
      entity,
      entityId,
      offset: (pageNumber - 1) * itemsPerPage,
      limit: itemsPerPage,
      commonTail: entity === EDocumentEntityType.REQUESTS || entity === EDocumentEntityType.ORDERS,
    }
    dispatch(fetchDocuments(fetchingDocumentPayload))
  }, [pageNumber, entity, entityId, dispatch])

  useEffect(() => {
    const params = new URLSearchParams(location.search)
    const urlPageNumber = parseInt(params.get('page') || '1', 10)

    if (urlPageNumber !== pageNumber) {
      setPageNumber(urlPageNumber)
    }
  }, [location.search, pageNumber])

  const { documents, types, isLoading, pagination } = useSelector(mapState)
  const documentsByType = useGetDocumentsByType(documents)

  const { typesByGroup, typesById, otherDocuments, filteredOutTypes } = useMemo(() => {
    const typesMap = keyBy(types, 'id')
    const filteredTypes = filterTypes(types)
    const pickedFilteredOutTypes = pickFilteredOutTypes(types)

    const finalFilteredTypes = filteredTypes.filter(type => !pickedFilteredOutTypes.includes(type))

    return {
      typesByGroup: separateTypesByGroup(finalFilteredTypes),
      typesById: typesMap,
      otherDocuments: documents.filter(({ type }) => !typesMap[type] && !CommonDocumentType[type]),
      filteredOutTypes: pickedFilteredOutTypes,
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [types, documents, filterTypes, pickFilteredOutTypes])

  const openUploadingDocumentModal = () => {
    dispatch(
      openModal({
        options: { dialogId: 'UploadDocumentModal' },
        contentOptions: { entity, entityId, documentType: uploadingThroughModalDocumentType } as IUploadDocumentModalOptions,
      }),
    )
  }

  const openUploadingMultipleDocumentsModal = () => {
    dispatch(
      openModal({
        options: { dialogId: 'UploadMultipleDocumentsModal' },
        contentOptions: {
          entityType: entity,
          entityId,
          documentType: uploadingThroughModalDocumentType,
          version,
        } as IUploadMultipleFilesModalOptions,
      }),
    )
  }

  const uploadDoc = (documentFile: File, typedDocument: IDocumentType) => {
    const { id: documentType, name: documentName } = typedDocument

    dispatch(uploadDocument({ documentFile, documentType, entity, entityId, documentName }))
  }

  const updateDoc = (document: IDocument, documentFile: File) =>
    dispatch(
      updateDocument({
        documentFile,
        document,
        entity,
        entityId,
        documentName: document?.name,
        orderId: orderIdForUpdateOrderAfterUpdateDoc,
      }),
    )
  const deleteDoc = (document: IDocument) => dispatch(deleteDocument({ ...document, entity, entityId }))
  const downloadDoc = (document: IDocument) => dispatch(downloadDocument({ ...document, entity }))

  const downloadDocTemplate = ({ templateUrl, fileName, id }: IDocumentType) =>
    dispatch(downloadDocument({ url: templateUrl as string, fileName: fileName as string, type: id, id, entity }))

  const onUpdateDocument = (action: TUpdateDocumentAction, document: IDocument, file?: File) => {
    switch (action) {
      case 'delete':
        deleteDoc(document)
        break
      case 'update':
        updateDoc(document, file as File)
        break
      default:
    }
  }

  const documentPermissions = useMemo(() => {
    const memoDocumentPermissions: Record<string, IDocumentPermissions> = {}

    return (id: string, categories: EDocumentCategory[]): IDocumentPermissions => {
      const permission = memoDocumentPermissions[id]
      if (permission) return permission

      const memoConditions = {
        canUpdateDoc: canMakeAction('updateDocument', categories, actions),
        canDeleteDoc: canMakeAction('deleteDocument', categories, actions),
        canRejectDoc: canMakeAction('rejectDocument', categories, actions),
        canAddDoc: canMakeAction('addDocument', categories, actions),
      }
      memoDocumentPermissions[id] = memoConditions

      return memoConditions
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(actions)])

  if (actions) {
    actionPermissions.canUpdateDoc = Boolean(actions.updateDocument)
    actionPermissions.canDeleteDoc = Boolean(actions.deleteDocument)
    actionPermissions.canAddDoc = Boolean(actions.addDocument)
    actionPermissions.canRejectDoc = Boolean(actions?.rejectDocument)
  }

  const handlePageChange = (newPageNumber: number) => {
    setPageNumber(newPageNumber)
    updateUrl(newPageNumber)
  }

  const scrollRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (scrollRef.current) {
      scrollRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' })
    }
  }, [pageNumber])

  return (
    <div className={classNames('documents', className)}>
      <div ref={scrollRef} />

      <Loader isVisible={isLoading} />

      {actionPermissions.canAddDoc && !shouldHideAddingDocumentButton && (
        <div className="documents-upload-button-container">
          {isMultipleUpload ? (
            <Button
              onClick={openUploadingMultipleDocumentsModal}
              modifiers={['outline', 'small']}
              textId="documents.button.uploadDocs"
              className="text_small-from-sm"
            />
          ) : (
            <Button
              onClick={openUploadingDocumentModal}
              modifiers={['outline', 'small']}
              textId="documents.button.upload"
              className="text_small-from-sm"
            />
          )}
          <div className="documents-upload-button-container__infoIconWrapper">
            <TextWithIcon
              iconProps={{ name: EIconName.InfoFill, style: { color: EColor.LIGHT_GRAY } }}
              textId="documents.button.uploadDocsInfo"
              textClassName="text_small color_pale-black"
            />
          </div>
        </div>
      )}

      {pageNumber === 1 &&
        typesByGroup.map(({ types: visibleTypes, title }, index) => (
          <DocumentGroup
            key={`${title}_${index}`}
            title={title}
            visibleTypes={visibleTypes}
            documentsByType={documentsByType}
            typesById={typesById}
            onUpdateDocument={onUpdateDocument}
            downloadDoc={downloadDoc}
            uploadDoc={uploadDoc}
            downloadDocTemplate={downloadDocTemplate}
            shouldDisplayDocumentStatusElement={shouldDisplayDocumentStatusElement}
            shouldHideUpdatingDocumentButton={shouldHideUpdatingDocumentButton}
            documentPermissions={documentPermissions}
            shouldCheckPermissionsFromActionsByCategories={shouldCheckPermissionsFromActionsByCategories}
            openRejectingDocumentModal={openRejectingDocumentModal}
            actions={actions}
          />
        ))}
      <DocumentGroup
        visibleTypes={filteredOutTypes}
        documentsByType={documentsByType}
        typesById={typesById}
        onUpdateDocument={onUpdateDocument}
        downloadDoc={downloadDoc}
        uploadDoc={uploadDoc}
        downloadDocTemplate={downloadDocTemplate}
        shouldDisplayDocumentStatusElement={shouldDisplayDocumentStatusElement}
        shouldHideUpdatingDocumentButton={shouldHideUpdatingDocumentButton}
        documentPermissions={documentPermissions}
        shouldCheckPermissionsFromActionsByCategories={shouldCheckPermissionsFromActionsByCategories}
        openRejectingDocumentModal={openRejectingDocumentModal}
        actions={actions}
      />
      {otherDocuments?.length > 0 && (
        <div className="documents__otherDocumentsGroup">
          {otherDocuments.map(document => (
            <DocumentView
              document={document}
              downloadDoc={downloadDoc}
              onUpdateDocument={onUpdateDocument}
              key={document.creationDate}
              canUpdateDocument={actionPermissions.canUpdateDoc}
              canDeleteDocument={actionPermissions.canDeleteDoc}
              shouldDisplayDocumentStatusElement={shouldDisplayDocumentStatusElement}
              typesById={typesById}
            />
          ))}
        </div>
      )}
      {pagination?.total > itemsPerPage && (
        <div className="documents__paginationWrapper">
          <Pagination
            hidePrevButton
            onChange={handlePageChange}
            page={pageNumber}
            count={Math.ceil(pagination.total / itemsPerPage)}
            renderItem={(page: number, isActive: boolean) => (
              <Link key={page} to={getUrl(page)} className={classNames('button button_pagination', isActive && 'button_pagination-active')}>
                {page}
              </Link>
            )}
          />
        </div>
      )}
    </div>
  )
}

export default Documents
