import type { UseMutationOptions, UseMutationResult } from "@tanstack/react-query";
import { useMutation } from "@tanstack/react-query";
import client from "@/api/client/axios";
import type { ResponseConfig } from "@/api/client/axios";
import type { RegistrationPartnerMutationRequest, RegistrationPartnerMutationResponse, RegistrationPartnerHeaderParams, RegistrationPartner400, RegistrationPartner500 } from "../types/RegistrationPartner";

/**
 * @summary Регистрация поставщика с подписанием аккредитации укэп
 * @link /api/auth/registration/partners
 */

export function useRegistrationPartner <TData = RegistrationPartnerMutationResponse, TError = RegistrationPartner400 | RegistrationPartner500, TVariables = RegistrationPartnerMutationRequest>(headers?: RegistrationPartnerHeaderParams, options: {
          mutation?: UseMutationOptions<ResponseConfig<TData>, TError, TVariables>,
          client?: Partial<Parameters<typeof client<TData, TError, TVariables>>[0]>,
      } = {}): UseMutationResult<ResponseConfig<TData>, TError, TVariables> {
  const { mutation: mutationOptions, client: clientOptions = {} } = options ?? {};

  return useMutation<ResponseConfig<TData>, TError, TVariables>({
    mutationFn: (data) => {
      
      return client<TData, TError, TVariables>({
        method: "post",
        url: `/api/auth/registration/partners`,
        data,
        
        headers: { ...headers, ...clientOptions.headers },
        ...clientOptions
      });
    },
    ...mutationOptions
  });
};
