import type { TMessages } from '@/types/TMessages'

export const messages = {
  header: {
    title: 'Кредитная линия для аграриев на весь год',
    description: 'Откройте возобновляемую кредитную линию на покупки на поле.рф',
    button: 'Подать заявку',
  },
  benefits: {
    title: 'Преимущества кредита',
    description1: 'Выдача средств',
    text1: 'осуществляется в виде траншей. Первый транш уже через 1-2 дня после открытия кредитной линии',
    description2: 'Погашение',
    text2: 'Погашайте кредит частично или полностью. После погашения ваш кредитный лимит возобновляется',
    description3: 'Берите частями',
    text3: 'и платите проценты только за использованный лимит',
    banner: {
      title: 'Простая подача <br/>заявки',
    },
  },
  steps: {
    '1': 'Заполните заявку онлайн. Пройдите предварительную проверку и получите консультацию менеджера банка',
    '2': 'Заполните анкету данными о компании, и банк примет решение в кратчайшие сроки',
    '3': 'Готово. Подпишите документы, и банк переведет деньги на ваш расчетный счет в Альфа-Банке',
    title: 'Как получить кредит?',
    button: 'Подать заявку',
    sign: 'Возможно получение льготного кредита до 30 млн ₽ с сокращенным пакетом документов и ускоренной выдачей',
  },
  form: { title: 'Оставьте заявку на кредит от «Альфа-банка»' },
  conditions: { title: 'Условия кредитования' },
  banner: {
    sale: {
      title: 'скидка <span class="landing-header-banner__title-accent">5%</span> на покупки на поле.рф',
      description:
        'При оформлении кредита получите скидку 5% на покупку<br /> сельхозтоваров на поле.рф. Предложение действует только до конца августа',
      conditions: {
        title: 'Условия акции',
        list: '<ol class="landing-header-banner__conditions-list"><li class="landing-header-banner__conditions-list__item">Оставьте заявку на кредит от Альфа-Банка на поле.рф до 31 августа 2022 года.</li><li class="landing-header-banner__conditions-list__item">Оформите кредитный договор и получите персональный промокод на скидку 5% на товары на поле.рф.&nbsp;*</li></ol>',
        cite: 'В акции участвует ассортимент от: «СТК-АГРО», «Южный дом», «АгроВелес», «ОПТИМУМ», «КАШЕМИР КАПИТАЛ», «ИНОВАТОРС», «ТЕРМИНАЛ-ЛЫСЬВА», «АГРОДОМ-ЛИПЕЦК». Срок действия промокода – 6 месяцев. Все консультации по условиям акции осуществляет служба поддержки клиентов',
      },
    },
  },
  breadcrumb: 'Кредиты для бизнеса',
  detail: {
    value: {
      until18Months: "до <span class='h2 h3-sm with-counter-animation' data-value='18'></span> мес.",
      untilTenMillions: "до <span class='h2 h3-sm with-counter-animation' data-value='10'>0</span> млн руб.",
      creditAmountPerMonth: "от <span class='h2 h3-sm with-counter-animation' data-value='1.5' data-round='true'>0</span> %",
    },
    sign: {
      creditPeriod: 'срок кредита',
      creditAmount: 'сумма кредита',
      perMonth: 'в месяц',
    },
  },
  forAgrariansOnly: 'Доступно только для аграриев',
} satisfies TMessages
