import client from "@/api/client/dadata";
import type { ResponseConfig } from "@/api/client/dadata";
import type { SuggestFioMutationRequest, SuggestFioMutationResponse } from "../../types/SuggestFio";

/**
     * @link /v1/suggest_fio
     */
export async function suggestFio (data: SuggestFioMutationRequest, options: Partial<Parameters<typeof client>[0]> = {}): Promise<ResponseConfig<SuggestFioMutationResponse>["data"]> {
    const { data: resData } = await client<SuggestFioMutationResponse, SuggestFioMutationRequest>({
        method: "post",
        url: `/v1/suggest_fio`,
        data,
        ...options
    });
    
    return resData;
};