/* eslint-disable camelcase */
import { useCallback, useState } from 'react'
import { unstable_batchedUpdates } from 'react-dom'
import { useRequestStateManager } from '@/api/hooks'
import { isStatusOk } from '../helpers'
import type { IAjaxOptions, IResponse, TMethod } from '../interfaces'
import { requestData } from '../request'

export interface IUseMakeRequest<Response, Payload> {
  data: Response
  makeRequest: (body?: Payload, customUrl?: string) => void
  isLoading: boolean
  isFailure: boolean
  isLoaded: boolean
  isIdle: boolean
}

export interface IUseMakeRequestOptions<GResponse extends Partial<IResponse<unknown>>> {
  defaultValue?: GResponse['data']
  method?: TMethod
}

export const useMakeRequest = <GResponse extends Partial<IResponse<unknown>>, GPayload extends IAjaxOptions['body'] = undefined>(
  url: string,
  options?: IUseMakeRequestOptions<GResponse>,
  customCheckStatus?: (response?: GResponse) => boolean,
): IUseMakeRequest<GResponse['data'], GPayload> => {
  const { method = 'POST', defaultValue } = options || {}
  const [data, setData] = useState<GResponse['data']>(defaultValue)
  const { actions, state } = useRequestStateManager()

  const makeRequest = useCallback(
    async (body?: GPayload, customUrl?: string) => {
      actions.requestStarted()

      try {
        const response = await requestData<GResponse, GResponse>({ url: customUrl || url, method, body })

        unstable_batchedUpdates(() => {
          if (customCheckStatus?.(response) || isStatusOk(response as IResponse<GResponse['data']>)) {
            actions.requestEnded()
            setData(response.data)
          } else {
            actions.requestFailed()
            setData(defaultValue)
          }
        })
      } catch (e) {
        unstable_batchedUpdates(() => {
          actions.requestFailed()
          setData(defaultValue)
        })
      }
    },
    [setData, actions, defaultValue, method, customCheckStatus, url],
  )

  return {
    makeRequest,
    data,
    ...state,
  }
}
