import type { FC } from 'react'
import React from 'react'
import { CommonLink } from '@frontend/pole-ui/lib/components/CommonLink'
import classNames from 'classnames'
import format from 'date-fns/format'
import { Image } from '@/components/ui'
import { useFormatPublicationDate } from '@/utils/hooks/format/useFormatPublicationDate'
import { getJournalPublicationUrl } from '@/utils/infoportal/helpers'
import type { IArticlePreviewProps } from './interfaces'
import './styles.scss'

const ArticlePreview: FC<IArticlePreviewProps> = ({ className, article, isImageInStatic }) => {
  const { coverImage, title, slug, showCover: shouldShowCover, publicationDate, type } = article
  const { formatPublicationDate } = useFormatPublicationDate(type)

  return (
    <article className={classNames(className, 'article-preview')}>
      <CommonLink url={getJournalPublicationUrl(slug)} className="article-preview-link row-justify_between-from-sm">
        <div>
          {shouldShowCover && (
            <Image
              src={coverImage?.squareCover}
              className="article-preview__image space-holder32-from-md space-holder24-md"
              isInStatic={isImageInStatic}
              isBackground
            />
          )}
          <h2 className="h5 font_base font-weight_normal space-holder16-sm">
            <span className="text_base">{title}</span>
          </h2>
        </div>

        {publicationDate && (
          <div>
            <p className="text_super-small color_pale-black">
              <time itemProp="publishedDate" dateTime={format(new Date(publicationDate), 'yyyy-MM-dd')}>
                {formatPublicationDate(publicationDate)}
              </time>
            </p>
          </div>
        )}
      </CommonLink>
    </article>
  )
}

export default ArticlePreview
