import type { QueryKey, UseQueryResult, UseQueryOptions, QueryOptions } from "@tanstack/react-query";
import { useQuery } from "@tanstack/react-query";
import client from "@/api/client/axios";
import type { GetCatalogMainPageQueryResponse, GetCatalogMainPageQueryParams, GetCatalogMainPage400, GetCatalogMainPage500 } from "../types/GetCatalogMainPage";

export const getCatalogMainPageQueryKey = (params?: GetCatalogMainPageQueryParams) =>     [{url:`/api/catalog/mainPage/category`},...(params ? [params] : [])] as const;
      export function getCatalogMainPageQueryOptions <TData = GetCatalogMainPageQueryResponse, TError = GetCatalogMainPage400 | GetCatalogMainPage500>(params?: GetCatalogMainPageQueryParams, options: Partial<Parameters<typeof client>[0]> = {}): UseQueryOptions<TData, TError> {
        const queryKey = getCatalogMainPageQueryKey(params);

        return {
          queryKey,
          queryFn: () => {
            
            return client<TData, TError>({
              method: "get",
              url: `/api/catalog/mainPage/category`,
              params,
              
              ...options,
            }).then(res => res.data);
          },
        };
      };
      
/**
 * @summary Получение списка товаров
 * @link /api/catalog/mainPage/category
 */

export function useGetCatalogMainPage <TData = GetCatalogMainPageQueryResponse, TError = GetCatalogMainPage400 | GetCatalogMainPage500>(params?: GetCatalogMainPageQueryParams, options: {
          query?: UseQueryOptions<TData, TError>,
          client?: Partial<Parameters<typeof client<TData, TError>>[0]>,
        } = {}): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const { query: queryOptions, client: clientOptions = {} } = options ?? {};
  const queryKey = queryOptions?.queryKey ?? getCatalogMainPageQueryKey(params);

  const query = useQuery<TData, TError>({
    ...getCatalogMainPageQueryOptions<TData, TError>(params, clientOptions),
    ...queryOptions
  }) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey as QueryKey;

  return query;
};
