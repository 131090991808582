import client from "@/api/client/axios";
import type { ResponseConfig } from "@/api/client/axios";
import type { CheckOrganizationsQueryResponse, CheckOrganizationsPathParams } from "../../types/CheckOrganizations";

/**
     * @summary Запрос на проверку организации по ИНН
     * @link /api/auth/check/organizations/:inn
     */
export async function checkOrganizations (inn: CheckOrganizationsPathParams["inn"], options: Partial<Parameters<typeof client>[0]> = {}): Promise<ResponseConfig<CheckOrganizationsQueryResponse>["data"]> {
    const { data: resData } = await client<CheckOrganizationsQueryResponse>({
        method: "get",
        url: `/api/auth/check/organizations/${inn}`,
        ...options
    });
    
    return resData;
};