import client from "@/api/client/axios";
import type { ResponseConfig } from "@/api/client/axios";
import type { SearchQueryResponse, SearchQueryParams } from "../../types/Search";

/**
     * @link /api/ms-content-infoportal-backend/v1/mobile/publications_search
     */
export async function search (params: SearchQueryParams, options: Partial<Parameters<typeof client>[0]> = {}): Promise<ResponseConfig<SearchQueryResponse>["data"]> {
    const { data: resData } = await client<SearchQueryResponse>({
        method: "get",
        url: `/api/ms-content-infoportal-backend/v1/mobile/publications_search`,
        params,
        ...options
    });
    
    return resData;
};