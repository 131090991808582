import type { GetAuctionsForVitrinaQueryParams } from '@/api/kubik/auctions'
import {
  getAuction,
  getAuctionBets,
  getAuctionBetsQueryKey,
  getAuctionQueryKey,
  getAuctionsForVitrina,
  getAuctionsForVitrinaQueryKey,
} from '@/api/kubik/auctions'
import type { TSsrPrefetchQueries } from '@/interfaces'

export const betsVitrinaPrefetchSsrQueries: TSsrPrefetchQueries = (routeParams: Record<string, string | undefined>) => {
  const params: GetAuctionsForVitrinaQueryParams = {
    offset: routeParams?.offset ? Number(routeParams?.offset) : 0,
  }

  return [{ queryFn: () => getAuctionsForVitrina(params), queryKey: getAuctionsForVitrinaQueryKey(params) }]
}

export const betsAuctionPrefetchQueries: TSsrPrefetchQueries = (routeParams: Record<'id', string | undefined>) => {
  const params = routeParams.id ? { auctionId: routeParams.id } : { auctionId: '' }

  return [
    { queryFn: () => getAuction(params), queryKey: getAuctionQueryKey(params) },
    { queryFn: () => getAuctionBets(params.auctionId), queryKey: getAuctionBetsQueryKey(params.auctionId) },
  ]
}
