import type { FC } from 'react'
import React, { memo, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { EIconName } from '@frontend/pole-ui/lib/components/Icon'
import classNames from 'classnames'
import { Button, ToggleDisplayingItemsButton } from '@/components/ui'
import { pushGtmEventOnChangeFilterFormClick } from '@/logic/metrika/harvest/helpers'
import { injectMessages } from '@/logic/translation/utils'
import { useIsomorphicLayoutEffect } from '@/utils/hooks'
import { useDeviceType } from '@/utils/hooks/useDeviceType'
import { CheckboxesFilterCommon, CheckboxesFilterSearchable } from './components'
import { MAX_CHECKBOXES_WHILE_COLLAPSED } from './constants'
import type { ICheckboxFilterProps } from './interfaces'
import messages from './messages.json'
import './styles.scss'

injectMessages(messages)

const CheckboxesFilter: FC<ICheckboxFilterProps> = props => {
  const {
    title,
    titleId,
    checkboxProps,
    checkboxes,
    onChange,
    zeroOptionsMessageId,
    zeroOptionsMessage,
    controlStyle,
    shouldUseSearch,
    showExpandedInPopup,
    userGtmInfo,
    userRegion,
    eventLabel = 'buyers',
  } = props

  const { isDesktop } = useDeviceType()
  const [isExpanded, setExpanded] = useState(false)
  const isSearchMode = isExpanded && shouldUseSearch?.(checkboxes)
  const isPopupOpened = Boolean(showExpandedInPopup && isSearchMode && isExpanded)
  const shouldShowZeroOptionsMessage = checkboxes.length === 0 && (zeroOptionsMessageId || zeroOptionsMessage)
  const isShownExpandToggle = checkboxes.length > MAX_CHECKBOXES_WHILE_COLLAPSED && (isDesktop || !isSearchMode)
  const clearExpanded = () => setExpanded(false)
  const isSearching = isSearchMode && isExpanded

  useIsomorphicLayoutEffect(() => setExpanded(false), [checkboxes])

  return (
    <section className={classNames('checkboxes-filter', { 'checkboxes-filter_in-modal': isPopupOpened })}>
      <h3 className={`space-holder24-from-sm space-holder16-sm row row-align_center row_nowrap ${isPopupOpened ? 'h2-from-sm' : 'h5-sm'}`}>
        {isPopupOpened && (
          <Button isUnstyled onClick={clearExpanded} className="row popup-back-icon" iconProps={{ name: EIconName.ArrowLeft2 }} />
        )}
        {title || (titleId && <FormattedMessage id={titleId} />)}
      </h3>

      <div className="checkboxes-filter__items">
        {isSearching && (
          <CheckboxesFilterSearchable
            checkboxProps={checkboxProps}
            checkboxes={checkboxes}
            shouldExpand={isExpanded}
            controlStyle={controlStyle}
            onChange={onChange}
            isPopupOpened={isPopupOpened}
            clearExpanded={clearExpanded}
            isExpanded={isExpanded}
          />
        )}
        {!isSearching && (
          <CheckboxesFilterCommon
            checkboxProps={checkboxProps}
            checkboxes={checkboxes}
            shouldExpand={isExpanded}
            controlStyle={controlStyle}
            onChange={onChange}
          />
        )}
        {isShownExpandToggle && (
          <ToggleDisplayingItemsButton
            onActionClick={() => {
              setExpanded(!isExpanded)
              if (userGtmInfo && userRegion)
                pushGtmEventOnChangeFilterFormClick(userGtmInfo, userRegion, eventLabel, isExpanded ? 'Свернуть' : 'Показать все')
            }}
            shouldExpand={isExpanded}
          />
        )}
      </div>
      {shouldShowZeroOptionsMessage && (zeroOptionsMessage || <FormattedMessage id={zeroOptionsMessageId} tagName="p" />)}
    </section>
  )
}

export default memo(CheckboxesFilter)
