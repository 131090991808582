import type { ReactNode } from 'react'
import type { IAsyncThunkPayload } from '@/logic/data/interfaces'
import type { IRegion } from '@/types'
import type { IAgriculturalProductTreeCategory, IDetailedSku } from '@/types/Catalog'

export enum EAgriculturalProductRequestName {
  FETCH = 'agriculturalProduct_',
}

export interface IAgriculturalProductParams {
  productId: string
  category: number
  token?: string
}

export interface IRegionWithCount {
  countSuppliers: number
  region: IRegion
}

export interface IFetchAgriculturalProductPayload extends Omit<IAsyncThunkPayload, 'params' | 'search'> {
  params: IAgriculturalProductParams
}

export type IProductDetailedResponse = {
  numberOfInterestedSuppliers: number
  product: IDetailedSku
  categories: IAgriculturalProductTreeCategory
  regionWithCountSuppliersData: IRegionWithCount[]
  multiOrderEnabled: boolean
}

export interface IAgriculturalProductDetailsProps {
  product: IDetailedSku
  className: string
}

export interface IAgriculturalProductDetailWithTitleProps {
  titleId: string
  children: ReactNode
}

export interface IBestOffer {
  id: string
  price: number
}
