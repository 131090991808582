import { useRef } from 'react'
import { useIsomorphicLayoutEffect } from './useIsomorphicLayoutEffect'

export const usePreviousValue = <T>(value: T): T | undefined => {
  const ref = useRef<T>()

  useIsomorphicLayoutEffect(() => {
    ref.current = value
  })

  return ref.current
}
