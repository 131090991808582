import type { UseMutationOptions, UseMutationResult } from "@tanstack/react-query";
import { useMutation } from "@tanstack/react-query";
import client from "@/api/client/axios";
import type { ResponseConfig } from "@/api/client/axios";
import type { DeleteRubricMutationResponse, DeleteRubricPathParams } from "../types/DeleteRubric";

/**
 * @link /api/ms-content-infoportal-backend/v1/admin/rubrics/:rubricId
 */

export function useDeleteRubric <TData = DeleteRubricMutationResponse, TError = unknown>(rubricId: DeleteRubricPathParams["rubricId"], options: {
          mutation?: UseMutationOptions<ResponseConfig<TData>, TError, void>,
          client?: Partial<Parameters<typeof client<TData, TError, void>>[0]>,
      } = {}): UseMutationResult<ResponseConfig<TData>, TError, void> {
  const { mutation: mutationOptions, client: clientOptions = {} } = options ?? {};

  return useMutation<ResponseConfig<TData>, TError, void>({
    mutationFn: () => {
      
      return client<TData, TError, void>({
        method: "delete",
        url: `/api/ms-content-infoportal-backend/v1/admin/rubrics/${rubricId}`,
        
        
        
        ...clientOptions
      });
    },
    ...mutationOptions
  });
};
