import type { CSSProperties, FC } from 'react'
import React, { useContext, useMemo, useRef } from 'react'
import { EColor } from '@frontend/pole-ui/lib/common/constants'
import type { FaqCategoryData } from '@/api/kubik/monolith'
import type { TUuid } from '@/interfaces'
import { useData } from '@/logic/data'
import { injectMessages } from '@/logic/translation/utils'
import { FaqContext } from '@/pages/QuestionsAndAnswers/constants'
import { FaqTreeCategory } from '..'
import { useGetSpecializationQuestions } from './hooks'
import messages from './messages.json'
import './styles.scss'

injectMessages(messages)

const FaqCategoriesTree: FC = () => {
  const { params } = useContext(FaqContext)
  const { data: categories } = useData<FaqCategoryData>('faqCategories')
  const initialParams = useMemo(() => params, [params])
  const { questions, loadQuestions } = useGetSpecializationQuestions()

  const activeLink = useRef(params.specializationId || '')

  const selectLink = (link: TUuid[]) => {
    activeLink.current = link.toString()
  }

  return (
    <aside className="faq-tree-categories" style={{ '--spinner-background': EColor.LIGHT_GRAY } as CSSProperties}>
      <FaqTreeCategory
        items={categories?.children}
        questions={questions}
        categoryId={params.categoryId}
        initialParams={initialParams}
        loadQuestions={loadQuestions}
        hasChosenQuestion={Boolean(params.questionId)}
        activeLink={activeLink.current}
        selectLink={selectLink}
      />
    </aside>
  )
}

export default FaqCategoriesTree
