import type { QueryKey, UseQueryResult, UseQueryOptions, QueryOptions } from "@tanstack/react-query";
import { useQuery } from "@tanstack/react-query";
import client from "@/api/client/axios";
import type { GetMenuItemsListQueryResponse, GetMenuItemsList400, GetMenuItemsList500 } from "../types/GetMenuItemsList";

export const getMenuItemsListQueryKey = () =>     [{url:`/api/catalog/mainPage/menuitems`}] as const;
      export function getMenuItemsListQueryOptions <TData = GetMenuItemsListQueryResponse, TError = GetMenuItemsList400 | GetMenuItemsList500>(options: Partial<Parameters<typeof client>[0]> = {}): UseQueryOptions<TData, TError> {
        const queryKey = getMenuItemsListQueryKey();

        return {
          queryKey,
          queryFn: () => {
            
            return client<TData, TError>({
              method: "get",
              url: `/api/catalog/mainPage/menuitems`,
              
              
              ...options,
            }).then(res => res.data);
          },
        };
      };
      
/**
 * @summary Получение списка меню
 * @link /api/catalog/mainPage/menuitems
 */

export function useGetMenuItemsList <TData = GetMenuItemsListQueryResponse, TError = GetMenuItemsList400 | GetMenuItemsList500>(options: {
          query?: UseQueryOptions<TData, TError>,
          client?: Partial<Parameters<typeof client<TData, TError>>[0]>,
        } = {}): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const { query: queryOptions, client: clientOptions = {} } = options ?? {};
  const queryKey = queryOptions?.queryKey ?? getMenuItemsListQueryKey();

  const query = useQuery<TData, TError>({
    ...getMenuItemsListQueryOptions<TData, TError>(clientOptions),
    ...queryOptions
  }) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey as QueryKey;

  return query;
};
