import type { TMessages } from '@/types/TMessages'

export const messages = {
  blockHeader: 'Ваш личный кабинет всегда под рукой',
  mobileHeader: 'Приложение ПОЛЕ.РФ',
  blockSubHeader: 'Наведите камеру и скачайте приложение поле.рф',
  mobileSubHeader: 'Ваш личный кабинет всегда под рукой',
  qrCodeAlt: 'Скачайте мобильное приложение Поле.рф в Appstore и Google Play',
  mobilePhoneAlt: 'IPhone',
  googlePlayAlt: 'Мобильное приложение Поле.рф в Google Play',
  appStoreAlt: 'Мобильное приложение Поле.рф в App store',
} satisfies TMessages
