import client from "@/api/client/axios";
import type { ResponseConfig } from "@/api/client/axios";
import type { UpdateOrderSupplyStatusMutationRequest, UpdateOrderSupplyStatusMutationResponse, UpdateOrderSupplyStatusPathParams } from "../../types/UpdateOrderSupplyStatus";

/**
     * @summary Редактирование статуса доставки
     * @link /api/orders/:orderId/supplies/:supplyId/status
     */
export async function updateOrderSupplyStatus (orderId: UpdateOrderSupplyStatusPathParams["orderId"], supplyId: UpdateOrderSupplyStatusPathParams["supplyId"], data: UpdateOrderSupplyStatusMutationRequest, options: Partial<Parameters<typeof client>[0]> = {}): Promise<ResponseConfig<UpdateOrderSupplyStatusMutationResponse>["data"]> {
    const { data: resData } = await client<UpdateOrderSupplyStatusMutationResponse, UpdateOrderSupplyStatusMutationRequest>({
        method: "patch",
        url: `/api/orders/${orderId}/supplies/${supplyId}/status`,
        data,
        ...options
    });
    
    return resData;
};