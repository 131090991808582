import clamp from 'lodash/clamp'
import type { IInterval } from '@/types'

export function normalizeInterval(value: Maybe<IInterval>, bounds: IInterval): Maybe<IInterval> {
  if (!value) return null

  let { from, to } = value

  from = clamp(from, bounds.from, bounds.to)
  to = clamp(to, bounds.from, bounds.to)

  from = Math.min(from, to)
  to = Math.max(from, to)

  // prettier-ignore
  return from !== bounds.from || to !== bounds.to
    ? { from, to }
    : null
}
