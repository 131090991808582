import type React from 'react'
import { useEffect, useState } from 'react'
import { fromEvent } from 'rxjs'
import { debounceTime, distinctUntilChanged, filter, map, pairwise } from 'rxjs/operators'

export type TDirection = 'throttle' | 'down' | 'up' | 'initial'

export interface IUseScrollDirectionOptions {
  className: string
  defaultDirection?: TDirection
  minThrottle?: number
  maxThrottle?: number
}

export interface IUseScrollDirectionPayload {
  scrollClassName: string
  setDirection: React.Dispatch<React.SetStateAction<TDirection>>
  scrollDirection: TDirection
}

const useScrollDirection = (options: IUseScrollDirectionOptions): IUseScrollDirectionPayload => {
  const { className, defaultDirection = 'throttle', minThrottle = 0, maxThrottle = NaN } = options
  const [direction, setDirection] = useState<TDirection>(defaultDirection)

  useEffect(() => {
    const wheelEvent = fromEvent(window, 'scroll', { passive: false })
      .pipe(
        map(() => window.scrollY),
        filter(position => position > 0),
        pairwise<number>(),
        distinctUntilChanged(),
        map(([previousScrollY, scrollY]) => {
          if (minThrottle > scrollY || maxThrottle < scrollY) return 'throttle'

          return previousScrollY < scrollY ? 'down' : 'up'
        }),
        distinctUntilChanged(),
        debounceTime(200),
      )
      .subscribe(updatedDirection => setDirection(updatedDirection as TDirection))

    return () => wheelEvent.unsubscribe()
  }, [minThrottle, maxThrottle])

  return {
    scrollClassName: `${className}_${direction}`,
    setDirection,
    scrollDirection: direction,
  }
}

export { useScrollDirection }
