import type { UseMutationOptions, UseMutationResult } from "@tanstack/react-query";
import { useMutation } from "@tanstack/react-query";
import client from "@/api/client/axios";
import type { ResponseConfig } from "@/api/client/axios";
import type { AddOrRemoveTopNewsSetProductsMutationResponse, AddOrRemoveTopNewsSetProductsQueryParams } from "../types/AddOrRemoveTopNewsSetProducts";

/**
 * @link /api/ms-content-infoportal-backend/v1/admin/publications/topNews/products
 */

export function useAddOrRemoveTopNewsSetProducts <TData = AddOrRemoveTopNewsSetProductsMutationResponse, TError = unknown>(params: AddOrRemoveTopNewsSetProductsQueryParams, options: {
          mutation?: UseMutationOptions<ResponseConfig<TData>, TError, void>,
          client?: Partial<Parameters<typeof client<TData, TError, void>>[0]>,
      } = {}): UseMutationResult<ResponseConfig<TData>, TError, void> {
  const { mutation: mutationOptions, client: clientOptions = {} } = options ?? {};

  return useMutation<ResponseConfig<TData>, TError, void>({
    mutationFn: () => {
      
      return client<TData, TError, void>({
        method: "post",
        url: `/api/ms-content-infoportal-backend/v1/admin/publications/topNews/products`,
        
        params,
        
        ...clientOptions
      });
    },
    ...mutationOptions
  });
};
