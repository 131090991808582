import { format } from 'date-fns'

export const convertTimestampToISO = (value?: number | null) => (value ? new Date(value).toISOString() : '')

export const convertTimestampFromISO = (value: string, schema: string = "yyyy-MM-dd'T'HH:mm:ss.SSSXXX") => {
  const date = new Date(value)

  const offset = date.getTimezoneOffset() * 60 * 1000
  const localTime = new Date(date.getTime() - offset)

  return format(localTime, schema)
}

export const dateAfterDays = (days: number) => new Date().getTime() + days * 24 * 60 * 60 * 1000
