export enum EAuthApiUrl {
  user = '/api/user',
  apiKey = '/api/auth/api_key',
  logout = '/api/auth/logout',
  impersonation = '/api/auth/impersonation',
  documents = '/api/auth/documents',
  refreshToken = '/api/user/refresh',
  getToken = '/api/auth/token',
  sendCode = '/api/auth/code',
  changesUserData = '/api/auth/user',
}
