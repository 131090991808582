import Loadable from '@loadable/component'

export { default as Modal } from './Modal'
export { default as Header } from './Header'
export { default as Footer } from './Footer'
export { default as CookieAgreement } from './CookieAgreement'
export { default as ToggleButton } from './ToggleButton'
export { default as CheckboxesFilter } from './CheckboxesFilter'
export { default as SearchInput } from './SearchInput'
export { default as QuantityPicker } from './QuantityPicker'
export { default as AgriculturalProductAvailableVolume } from './AgriculturalProductAvailableVolume'
export { default as ProductSupplier } from './ProductSupplier'
export { default as PriceInfo } from './PriceInfo'
export { default as LabeledProductInfo } from './LabeledProductInfo'
export { default as DeleteButton } from './DeleteButton'
export { default as ProductPrice } from './ProductPrice'
export { default as Order } from './Order'
export { default as Documents } from './Documents'
export { default as StatusModelActions } from './StatusModelActions'
export { default as OrderHeader } from './OrderHeader'
export { default as HarvestQualityInputs } from './HarvestQualityInputs'
export { default as History } from './History'
export { default as HistoryList } from './HistoryList'
export { default as WideBreadcrumbs } from './WideBreadcrumbs'
export { default as Counterparty } from './Counterparty'
export { default as ProductsList } from './ProductsList'
export { default as ClientCard } from './ClientCard'
export { default as StoreInput } from './StoreInput'
export { default as ExactFarmingHeader } from './ExactFarmingHeader'
export { default as ExactFarmingTextPhoneSection } from './ExactFarmingTextPhoneSection'
export { default as ExactFarmingScreenSection } from './ExactFarmingScreenSection'
export { default as Steps } from './Steps'
export { default as ExactFarmingOtherOffersSection } from './ExactFarmingOtherOffersSection'
export { default as ExactFarmingChecksPhoneSection } from './ExactFarmingChecksPhoneSection'

export { default as ArticlePreviewBanner } from './ArticlePreviewBanner'
export { default as ArticlePreview } from './ArticlePreview'
export { default as InteractiveSteps } from './InteractiveSteps'
export { default as MobileAuthButton } from './MobileAuthButton'
export { default as RecursiveCategoriesTree } from './RecursiveCategoriesTree'
export { default as ConsultingForm } from './ConsultingForm'
export { default as Faq } from './Faq'
export { default as MobileNavBar } from './MobileNavBar'
export { Reviews } from './Reviews'
export { default as RubricsMenu } from './RubricsMenu/RubricsMenu'
export { default as TagsMenu } from './TagsMenu/TagsMenu'
export { JournalSearchInput } from './JournalSearchInput'
export * from './InstallmentCalculator'
export * from './UserRegionDefiner'
export { FileActionPanel } from './FileActionPanel'
export { InputsCard } from './InputsCard'

export const InfoportalSearchForm = Loadable(
  /* webpackChunkName: "InfoportalSearchForm" */
  /* webpackPrefetch: "true" */
  /* #__LOADABLE__ */
  () => import('./InfoportalSearchForm'),
)

export const AgroservicesForm = Loadable(
  /* webpackChunkName: "AgroservicesForm" */
  /* webpackPrefetch: "true" */
  /* #__LOADABLE__ */
  () => import('./AgroservicesForm'),
)

export const Chat = Loadable(
  /* webpackChunkName: "Chat" */
  /* webpackPrefetch: "true" */
  /* #__LOADABLE__ */
  () => import('./Chat'),
)

export const BannerWithLink = Loadable(
  /* webpackChunkName: "BannerWithLink" */
  /* webpackPrefetch: "true" */
  /* #__LOADABLE__ */
  () => import('./BannerWithLink'),
)

export const FaqAgroservicesBanner = Loadable(
  /* webpackChunkName: "FaqAgroservicesBanner" */
  /* webpackPrefetch: "true" */
  /* #__LOADABLE__ */
  () => import('./FaqAgroservicesBanner'),
)

export const AddProductToCartButton = Loadable(
  /* webpackChunkName: "AddProductToCartButton" */
  /* webpackPrefetch: "true" */
  /* #__LOADABLE__ */
  () => import('./AddProductToCartButton'),
)

export const ExpressCreditApplication = Loadable(
  /* webpackChunkName: "ExpressCreditApplication" */
  /* webpackPrefetch: "true" */
  /* #__LOADABLE__ */
  () => import('./ExpressCreditApplication'),
)

export const LeasingPartnersShortLogos = Loadable(
  /* webpackChunkName: "LeasingPartnersShortLogos" */
  /* webpackPrefetch: "true" */
  /* #__LOADABLE__ */
  () => import('./LeasingPartnersShortLogos'),
)

export const LeasingForm = Loadable(
  /* webpackChunkName: "LeasingForm" */
  /* webpackPrefetch: "true" */
  /* #__LOADABLE__ */
  () => import('./LeasingForm'),
)

/* FIXME: поправить webpackChunkName на AgrarianContractCard */
export const AgrarianContractCard = Loadable(
  /* webpackChunkName: "LeasingForm" */
  /* webpackPrefetch: "true" */
  /* #__LOADABLE__ */
  () => import('./AgrarianContractCard'),
)

export const InstallmentCalculator = Loadable(
  /* webpackChunkName: "InstallmentCalculator" */
  /* webpackPrefetch: "true" */
  /* #__LOADABLE__ */
  () => import('./InstallmentCalculator'),
)
