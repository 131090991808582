import type { ApprovedRegions } from '@/api/kubik/monolith'
import { requestData } from '@/api/request'
import { ECommonApiUrl } from '@/api/urls'
import type { EFinancingProductId } from '@/types'
import { getUrlWithQuery } from '@/utils/url'

export default class MicrocreditOrganizationsService {
  getRegionsForMicrocreditOrganization = (financingProductId: EFinancingProductId) => {
    return requestData<ApprovedRegions>({
      url: getUrlWithQuery(ECommonApiUrl.fundingRegionRequests, { productId: financingProductId }),
      method: 'GET',
    }).then(response => response.data.approvedRegions)
  }
}
