import type { SyntheticEvent } from 'react'
import React, { useRef, useState } from 'react'
import type { Crop, PixelCrop } from 'react-image-crop'
import ReactCrop, { centerCrop, makeAspectCrop } from 'react-image-crop'
import { CSSTransition } from 'react-transition-group'
import { Button } from '@frontend/pole-ui/lib/components/Button'
import { EIconName, EIconSize, Icon } from '@frontend/pole-ui/lib/components/Icon'
import { Stack } from '@frontend/pole-ui/lib/components/Stack'
import Overlay from '@/components/composed/Overlay/Overlay'
import Portal from '@/components/composed/Portal/Portal'
import { getCroppedImage } from './helpers'
import { messages } from './messages'
import type { IImageCropperProps } from './types'
import './styles.scss'
import 'react-image-crop/dist/ReactCrop.css'

function ImageCropper(props: IImageCropperProps) {
  const { imageSrc, isCropperOpened, closeCropper, aspectRatio, onCropFinished, minWidth } = props
  const [crop, setCrop] = useState<Crop>()
  const [finalCrop, setFinalCrop] = useState<PixelCrop>()
  const imageRef = useRef<HTMLImageElement>(null)
  const [minCropWidth, setMinCropWidth] = useState<number | undefined>(undefined)

  const onImageLoad = (e: SyntheticEvent<HTMLImageElement>) => {
    const { naturalWidth: width, naturalHeight: height, width: containerWidth } = e.currentTarget
    const scaleX = containerWidth / width

    setMinCropWidth(scaleX * minWidth)

    const centeredAspectCrop = centerCrop(
      makeAspectCrop(
        {
          unit: '%',
          width: 100,
        },
        aspectRatio,
        width,
        height,
      ),
      width,
      height,
    )

    setCrop(centeredAspectCrop)
  }

  const onFinishCropping = async () => {
    if (!finalCrop || !imageRef.current) {
      closeCropper()

      return
    }

    const croppedFile = await getCroppedImage(imageRef.current, finalCrop)
    onCropFinished(croppedFile)

    closeCropper()
  }

  return (
    <Portal>
      <CSSTransition appear mountOnEnter unmountOnExit in={!!isCropperOpened} timeout={200} classNames="fading">
        <div className="imageCropper">
          <Overlay onClick={closeCropper} />
          <div className="imageCropper__modal">
            <div className="imageCropper__header">
              <p className="h5">{messages.header}</p>
              <div onClick={closeCropper} className="imageCropper__closeBtn">
                <Icon name={EIconName.Exit} size={EIconSize.M} />
              </div>
            </div>

            <ReactCrop
              minWidth={minCropWidth}
              crop={crop}
              onChange={(_c, percentCrop) => setCrop(percentCrop)}
              aspect={aspectRatio}
              onComplete={setFinalCrop}
            >
              <img ref={imageRef} src={imageSrc} onLoad={onImageLoad} />
            </ReactCrop>
            <Stack direction="row" spacing={6}>
              <Button modifiers={['green']} onClick={onFinishCropping}>
                {messages.addImage}
              </Button>
              <Button modifiers={['outline']} onClick={closeCropper}>
                {messages.cancel}
              </Button>
            </Stack>
          </div>
        </div>
      </CSSTransition>
    </Portal>
  )
}

export default ImageCropper
