import compact from 'lodash/compact'
import type { IUserGtmInfoPayload } from '@/interfaces'
import { getRequestData } from '@/logic/data/reducer'
import { getSavedSaleRequestBuyerOptions } from '@/logic/harvestSale/reducer'
import type {
  IBlockHarvestSaleCultureGtmEventOptions,
  IBlockOutputsCultureApplicationForm,
  IGetHarvestSalePriceProps,
  IGtmFormattedTemplate,
  IPushSubmitModalGtm,
  TGtmFormattedFormStep1,
  TGtmFormattedRequestSale,
  THarvestVariantPage,
} from '@/logic/metrika/harvest/interfaces'
import { pushGtmEvent } from '@/logic/metrika/helpers'
import { reducerManager } from '@/redux/reducerManager'
import type { IStore } from '@/types'
import type { IIndicator, ITemplate } from '@/types/HarvestSaleLots'
import { EOrderType } from '@/types/HarvestSaleLots'
import type { IHarvestSaleRequest } from '@/types/HarvestSaleRequest'
import { getTranslitedTextInCamelCase } from '@/utils/helpers'

export const calculateHarvestSalePrice = ({ price, volume, vatUsed }: IGetHarvestSalePriceProps) =>
  +(Number(price) * Number(volume) * (vatUsed ? 1.1 : 1)).toFixed(2)

//eslint-disable-next-line @typescript-eslint/no-explicit-any
const getTransformedQualityToString = (quality: Record<string, any>) =>
  Object.entries(quality).reduce((acc, [key, value], index) => {
    return `${acc}${index > 0 ? '&' : ''}${key}:${getTranslitedTextInCamelCase(value as string)}`
  }, '')

export const getFormattedTemplate = (template: ITemplate): IGtmFormattedTemplate => {
  const { culture, id, buyer, price, exw, fca, fob, qualityIndicators } = template
  const { id: cultureId } = culture
  const { id: buyerId, name: buyerName, address: buyerAdr } = buyer
  const { indicators } = qualityIndicators

  const transformedIndicatorsToString = Object.entries<IIndicator>(indicators).reduce(
    (acc, [key, { possibleValues }], index) => `${acc}${index > 0 ? '&' : ''}${key}:${possibleValues?.[0] || ''}`,
    '',
  )

  const formattedTemplate = {
    id,
    name: `${cultureId}Card`,
    priceCpt: price?.value ?? null,
    priceExw: exw?.value ?? null,
    priceFob: fob?.value ?? null,
    priceFca: fca?.price?.value ?? null,
    brand: getTranslitedTextInCamelCase(buyerName),
    category: cultureId,
    variant: transformedIndicatorsToString,
    dimension14: `${buyerId ? `buyerId:${buyerId}&` : ''}${buyerAdr ? `adr:${getTranslitedTextInCamelCase(buyerAdr)}` : ''}` || null,
  }

  return formattedTemplate
}

export const getFormattedRequestSale = (
  sale: IHarvestSaleRequest,
  templateId?: string,
  isMultiRequest?: boolean,
): TGtmFormattedRequestSale => {
  const { volume, deliveryOptions, price, culture, quality, buyer, vatUsed } = sale

  const { deliveryBasis, store } = deliveryOptions || {}
  const { id: idDeliveryBasis } = deliveryBasis || {}

  const withRegion = store?.location?.region.code
  const withAddress = store?.location?.address

  const dimension14Data = isMultiRequest
    ? [
        idDeliveryBasis ? `basis:${idDeliveryBasis}` : null,
        store?.id ? `storeId:${store?.id}` : null,
        withRegion ? `regionCode:${store?.location?.region.code}` : null,
      ]
    : [
        idDeliveryBasis ? `basis:${idDeliveryBasis}` : null,
        buyer?.id ? `buyerId:${buyer?.id}` : null,
        withAddress ? `adr:${store?.location?.address}` : null,
      ]

  const dimension14 = compact(dimension14Data).join('&')

  const formattedSale = {
    id: templateId || null,
    name: culture?.id ? `${culture.id}Card` : '',
    price: price.value,
    quantity: volume,
    brand: isMultiRequest ? null : getTranslitedTextInCamelCase(buyer?.name),
    category: culture.id,
    variant: getTransformedQualityToString(quality),
    metric1: calculateHarvestSalePrice({ price: price.value, volume, vatUsed }),
    dimension14,
  }

  return formattedSale
}

//eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getFormattedFormStep1 = (values: Record<string, any>): TGtmFormattedFormStep1 => {
  const { orderType, cultureId, desiredPrice, volume, buyerId, quality, deliveryOptions, vatUsed, id } = values
  const { deliveryBasisId, storeId } = deliveryOptions
  // TODO https://jira.poleinvest.ru/browse/OUT-1908
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const storeState = reducerManager.store?.getState()!
  const stores = getRequestData<IStore[]>('stores')(storeState)
  const buyerOptions = getSavedSaleRequestBuyerOptions()(storeState)
  const address = stores.data?.find(storeOption => storeOption.id === storeId)?.location.address
  const buyerName = buyerOptions.find(buyerOption => buyerOption.value === buyerId)?.label as string

  const formattedSale = {
    id,
    name: `${cultureId}Card`,
    price: desiredPrice,
    quantity: volume,
    brand: buyerName && orderType !== EOrderType.multiple ? getTranslitedTextInCamelCase(buyerName) : null,
    category: cultureId,
    variant: getTransformedQualityToString(quality),
    metric1: calculateHarvestSalePrice({ price: desiredPrice, volume, vatUsed }),
    dimension14:
      `${deliveryBasisId ? `basis:${deliveryBasisId}` : ''}${buyerId && orderType !== EOrderType.multiple ? `&buyerId:${buyerId}` : ''}${
        address ? `&adr:${address}` : ''
      }` || null,
  }

  return formattedSale
}

export const pushGtmEventOnBlockHarvestSaleCultureClick = (
  userGtmInfo: IUserGtmInfoPayload,
  userRegionName: string,
  eventLabel: string,
) => {
  pushGtmEvent<IBlockHarvestSaleCultureGtmEventOptions>({
    event: 'userEvent',
    regionSelected: userRegionName,
    eventCategory: 'interactions',
    eventAction: 'click',
    eventNonInteraction: 0,
    blockName: 'blockHarvestSaleCulture',
    eventLabel,
    eventContext: null,
    ...userGtmInfo,
  })
}

export const pushGtmEventOnBlockHarvestSaleCultureLotLinkClick = (
  userGtmInfo: IUserGtmInfoPayload,
  userRegionName: string,
  eventLabel: string,
) => {
  pushGtmEvent<IBlockHarvestSaleCultureGtmEventOptions>({
    event: 'userEvent',
    regionSelected: userRegionName,
    eventCategory: 'interactions',
    eventAction: 'click',
    eventNonInteraction: 0,
    blockName: 'blockHarvestSaleCultureLotLink',
    eventLabel,
    eventContext: null,
    ...userGtmInfo,
  })
}

export const pushGtmEventOnFocusCultureApplicationFormClick = (
  userGtmInfo: IUserGtmInfoPayload,
  userRegionName: string,
  eventLabel: string,
) => {
  pushGtmEvent<IBlockOutputsCultureApplicationForm>({
    event: 'userEvent',
    regionSelected: userRegionName,
    eventCategory: 'interactions',
    eventAction: 'input',
    eventNonInteraction: 0,
    blockName: 'blockOutputsCultureApplicationForm',
    eventLabel,
    eventContext: null,
    ...userGtmInfo,
  })
}

export const pushGtmEventOnFocusBlockOutputsApplicationFormClick = (
  userGtmInfo: IUserGtmInfoPayload,
  userRegionName: string,
  eventLabel: string,
  template?: ITemplate,
) => {
  const harvestSale = () => {
    if (template) {
      return {
        items: {
          itemId: template.id,
          itemBuyer: template.buyer.id,
          itemCategory: template.culture.id,
          itemListName: 'harvestSale',
        },
      }
    }

    return undefined
  }

  pushGtmEvent<IBlockOutputsCultureApplicationForm>({
    event: 'userEvent',
    regionSelected: userRegionName,
    eventCategory: 'interactions',
    eventAction: 'input',
    eventNonInteraction: 0,
    harvestSale: harvestSale(),
    blockName: 'blockOutputsCultureApplicationForm',
    eventLabel,
    eventContext: null,
    ...userGtmInfo,
  })
}
export const pushOpenModalFirstStepHarvestGtmEvent = (userGtmInfo: IUserGtmInfoPayload, userRegionName: string, template?: ITemplate) => {
  const harvestSale = () => {
    if (template) {
      return {
        items: {
          itemId: template.id,
          itemBuyer: template.buyer.id,
          itemCategory: template.culture.id,
          itemListName: 'harvestSale',
        },
      }
    }

    return undefined
  }

  pushGtmEvent<IBlockOutputsCultureApplicationForm>({
    event: 'userEvent',
    regionSelected: userRegionName,
    eventCategory: 'interactions',
    eventAction: 'viewing',
    eventNonInteraction: 0,
    harvestSale: harvestSale(),
    blockName: 'blockOutputsApplicationForm',
    eventLabel: 'step_1',
    eventContext: null,
    ...userGtmInfo,
  })
}

export const pushSubmitModalHarvestGtmEvent = (args: IPushSubmitModalGtm) => {
  const { userGtmInfo, userRegionName, eventLabel, template, sourceOfOpening, targetRequestID, requestID } = args

  const harvestSale = () => {
    if (template && sourceOfOpening === 'previewLotsOutputs') {
      return {
        items: {
          itemId: template.id,
          itemBuyer: template.buyer.id,
          itemCategory: template.culture.id,
          itemListName: 'harvestSale',
        },
      }
    }

    return undefined
  }

  pushGtmEvent<IBlockOutputsCultureApplicationForm>({
    event: 'userEvent',
    regionSelected: userRegionName,
    eventCategory: 'interactions',
    eventNonInteraction: 0,
    harvestSale: harvestSale(),
    blockName: 'blockOutputsApplicationForm',
    eventContext: null,
    ...userGtmInfo,
    targetRequestID: targetRequestID && targetRequestID?.length > 0 ? targetRequestID : null,
    requestID: requestID && requestID?.length > 0 ? requestID : null,
    eventAction: 'click',
    eventLabel,
  })
}

export const pushCloseBackModalHarvestFirstStepGtmEvent = (
  userGtmInfo: IUserGtmInfoPayload,
  userRegionName: string,
  template?: ITemplate,
) => {
  const harvestSale = () => {
    if (template) {
      return {
        items: {
          itemId: template.id,
          itemBuyer: template.buyer.id,
          itemCategory: template.culture.id,
          itemListName: 'harvestSale',
        },
      }
    }

    return undefined
  }

  pushGtmEvent<IBlockOutputsCultureApplicationForm>({
    event: 'userEvent',
    regionSelected: userRegionName,
    eventCategory: 'interactions',
    eventAction: 'click',
    eventNonInteraction: 0,
    harvestSale: harvestSale(),
    blockName: 'blockOutputsApplicationForm',
    eventContext: 'cancel',
    ...userGtmInfo,
  })
}

export const pushCloseBackModalHarvestGtmEvent = (
  userGtmInfo: IUserGtmInfoPayload,
  userRegionName: string,
  template?: ITemplate,
  sourceOfOpening?: THarvestVariantPage,
) => {
  const harvestSale = () => {
    if (template && sourceOfOpening === 'previewLotsOutputs') {
      return {
        items: {
          itemId: template.id,
          itemBuyer: template.buyer.id,
          itemCategory: template.culture.id,
          itemListName: 'harvestSale',
        },
      }
    }

    return undefined
  }

  pushGtmEvent<IBlockOutputsCultureApplicationForm>({
    event: 'userEvent',
    regionSelected: userRegionName,
    eventCategory: 'interactions',
    eventAction: 'click',
    eventNonInteraction: 0,
    harvestSale: harvestSale(),
    blockName: 'blockOutputsApplicationForm',
    eventContext: 'back',
    ...userGtmInfo,
  })
}

export const pushGtmEventOnChangeFilterFormClick = (
  userGtmInfo: IUserGtmInfoPayload,
  userRegionName: string,
  eventLabel: string,
  eventContext?: string,
) => {
  pushGtmEvent<IBlockOutputsCultureApplicationForm>({
    event: 'userEvent',
    regionSelected: userRegionName,
    eventCategory: 'interactions',
    eventAction: 'click',
    eventNonInteraction: 0,
    blockName: 'blockListingOutputsFilter',
    eventLabel,
    eventContext: eventContext || null,
    ...userGtmInfo,
  })
}

export const pushGotoHarvestSalesGtmEvent = (userGtmInfo: IUserGtmInfoPayload, userRegionName: string) => {
  pushGtmEvent<IBlockOutputsCultureApplicationForm>({
    event: 'userEvent',
    regionSelected: userRegionName,
    eventCategory: 'interactions',
    eventLabel: 'success',
    eventAction: 'click',
    eventNonInteraction: 0,
    blockName: 'blockOutputsApplicationForm',
    eventContext: 'viewRequest',
    ...userGtmInfo,
  })
}
