import type { TResponsiveValue } from '../responsive/interfaces'

export const ResponsiveThreshold: TResponsiveValue<number> = {
  desktop: 0.6,
  tablet: 0.1,
  mobile: 0.2,
}

export const ResponsiveThresholdFullIntersection: TResponsiveValue<number> = {
  desktop: 1,
  tablet: 0.6,
  mobile: 0.6,
}
