import React, { useEffect, useState } from 'react'
import { CommonLink } from '@frontend/pole-ui/lib/components/CommonLink'
import { EIconName, EIconSize, Icon } from '@frontend/pole-ui/lib/components/Icon'
import classNames from 'classnames'
// TODO: переиспользуемый хук, вынести выше при выносе футера
// https://jira.poleinvest.ru/browse/BETS-320
import { useDeviceType } from '@/utils/hooks/useDeviceType'
import type { IFooterSectionProps } from '../interfaces'
import { EFooterSection } from '../interfaces'
import { messages } from '../messages'

const FooterSection: React.FC<IFooterSectionProps> = props => {
  const { section, links, isExpandable, isHidden } = props

  const { isDesktop, isMobile } = useDeviceType()
  const [isExpanded, setIsExpanded] = useState(true)
  const toggleExpanded = () => isMobile && setIsExpanded(!isExpanded)

  useEffect(() => {
    setIsExpanded(!isMobile || !isExpandable)
  }, [isMobile, isExpandable])

  if (isHidden) return null

  return (
    <div>
      <div
        className={classNames('text_label footer__title', {
          'footer__title--expandable': isMobile && isExpandable,
          'footer__title--expanded': isExpanded,
        })}
        onClick={toggleExpanded}
      >
        <p>{messages[section].title}</p>
        {isMobile && isExpandable && <Icon name={EIconName.ShevronDown} size={EIconSize.S} style={{ marginTop: '-2px' }} />}
      </div>

      {isExpanded && (
        <div className="footer__links">
          {links.map(link => {
            const { name, url, email, withLabel, icon, isFilled, isUnderlined, isLarge, shouldOpenInNewWindow } = link
            const footerSection = messages[section]

            const label = withLabel ? footerSection[name as keyof typeof footerSection] : undefined
            const linkUrl = email ? `mailto:${email}` : url

            const className = classNames('footer__link', {
              'footer__link--filled': isFilled,
              'footer__link--underlined': isUnderlined,
            })

            const message = email || footerSection[name as keyof typeof footerSection]

            return (
              <div key={name}>
                {withLabel && (
                  <>
                    <span>{label}</span>
                    {!isDesktop && <br />}
                  </>
                )}
                <CommonLink url={linkUrl} className={className} shouldOpenInNewWindow={shouldOpenInNewWindow}>
                  {icon && (
                    <Icon
                      name={icon}
                      size={EIconSize.M}
                      className={section === EFooterSection.SOCIAL || section === EFooterSection.CONTACTS ? 'footerIconsGray' : undefined}
                    />
                  )}
                  {isLarge ? <h4>{message}</h4> : <p>{message}</p>}
                </CommonLink>
              </div>
            )
          })}
        </div>
      )}
    </div>
  )
}

export default FooterSection
