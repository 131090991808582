import React, { useContext } from 'react'
import { useDispatch } from 'react-redux'
import { openModal } from '@/components/composed/Modal/actions'
import { AccreditationContext } from '@/logic/accreditation/constants'
import { AccreditationBanner } from '.'

const bannerTextId = 'userAccreditation.uploadArchive.text'

const UploadArchiveBanner: React.FC = () => {
  const { accreditationId, archive, version, accreditationActions, isMobile } = useContext(AccreditationContext)
  const { addDocument: canAddDocument } = accreditationActions
  const dispatch = useDispatch()
  if (archive || !canAddDocument) return null

  let titleId
  let textId
  if (isMobile) textId = bannerTextId
  else titleId = bannerTextId

  return (
    <AccreditationBanner
      iconUrl={`${isMobile ? '' : 'black/'}docs`}
      buttonTextId="userAccreditation.uploadArchive.button"
      titleId={titleId}
      textId={textId}
      className="space-holder-top56-from-sm upload-archive-banner"
      onButtonClick={() =>
        dispatch(openModal({ options: { dialogId: 'UploadingArchiveModal' }, contentOptions: { accreditationId, version } }))
      }
      buttonStyle={isMobile ? 'green' : 'white-black'}
    />
  )
}

export default UploadArchiveBanner
