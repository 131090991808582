import { useDispatch, useSelector } from 'react-redux'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import { getOrganizationProfileV2, getOrganizationProfileV2QueryKey } from '@/api/kubik/monolith'
import { changeActiveOrganisation } from '@/components/composed/modals/ChangeActiveOrganisationModal/reducer'
import { getUser, isAuthenticated as isUserAuthenticated } from '@/logic/auth/reducer'
import type { RootState } from '@/redux/interfaces'

export const useOrganizationCheck = () => {
  const dispatch = useDispatch()
  const queryClient = useQueryClient()

  const { user, isAuthenticated } = useSelector((state: RootState) => ({
    user: getUser()(state),
    isAuthenticated: isUserAuthenticated()(state),
  }))

  const { data: fetchedOrganization } = useQuery({
    queryKey: getOrganizationProfileV2QueryKey(),
    queryFn: async () => {
      const resp = await getOrganizationProfileV2()
      const newOrgId = resp?.data?.organization?.id
      const tokenOrgId = user?.organizationId

      if (isAuthenticated && newOrgId && newOrgId !== tokenOrgId) {
        /* TODO when endpoint for /api/auth/refresh become available - replace
         TODO backend task https://jira.poleinvest.ru/browse/OUT-1867 */
        dispatch(changeActiveOrganisation({ id: newOrgId }))
        queryClient.setQueryData(getOrganizationProfileV2QueryKey(), null)
      }
    },
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    enabled: isAuthenticated,
  })

  return { fetchedOrganization }
}
