export enum EDocumentServiceApiUrl {
  CREATE_ACCESSION_DOCUMENT = '/api/document/template/v1/create_accession-document',
  CREATE_POWER_ATTORNEY = '/api/document/template/v1/create_power-of-attorney-document',
  UPLOAD_ACCESSION_DOCUMENT_CRYPTO_SIGNED = '/api/document/v1/upload_accession-document-crypto-signed',
  UPLOAD_FERTILIZER_OFFER_DOCUMENT_CRYPTO_SIGNED = '/api/document/v1/upload_fertilizer-order-offer-sign',
  UPLOAD_FERTILIZER_DOCUMENT_CRYPTO_SIGNED = '/api/document/v1/upload/crypto-signature',
  ADD_SEAL_TO_DOCUMENT = '/api/document/template/v1/update_add-seal-to-document',
  CREATE_ASSIGNMENT_DOCUMENT = '/api/document/template/v1/create_assignment-document',
  GET_FERTILIZER_OFFER_DOCUMENT_TEMPLATE = '/api/document/v1/download_fertilizer-offer-document-template',
  GET_FERTILIZER_OFFER_DOCUMENT_SIGNED = '/api/document/v1/download_fertilizer-offer-document-signed',
  UPLOAD_DOCUMENT_FOR_REGISTRATION_PARTNER = '/api/document/v1/upload_documents-for-registration-partner',
  CREATE_REGISTRATION_DOCUMENTS = '/api/document/template/v1/create_self-registration-documents',
  GET_PARTNER_DOCUMENTS = '/api/document/v1/get_accreditation-documents-list/:userId',
  DOWNLOAD_DOCUMENT_BY_ID = '/api/document/v1/download_by_id-document/:documentId',
}
