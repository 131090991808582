import type { QueryKey, UseQueryResult, UseQueryOptions, QueryOptions } from "@tanstack/react-query";
import { useQuery } from "@tanstack/react-query";
import client from "@/api/client/inputs-catalog";
import type { GetCategoryFiltersQueryResponse, GetCategoryFilters400, GetCategoryFilters404, GetCategoryFilters500 } from "../types/GetCategoryFilters";

export const getCategoryFiltersQueryKey = () =>     [{url:`/v1/category/filters`}] as const;
      export function getCategoryFiltersQueryOptions <TData = GetCategoryFiltersQueryResponse, TError = GetCategoryFilters400 | GetCategoryFilters404 | GetCategoryFilters500>(options: Partial<Parameters<typeof client>[0]> = {}): UseQueryOptions<TData, TError> {
        const queryKey = getCategoryFiltersQueryKey();

        return {
          queryKey,
          queryFn: () => {
            
            return client<TData, TError>({
              method: "get",
              url: `/v1/category/filters`,
              
              
              ...options,
            }).then(res => res.data);
          },
        };
      };
      
/**
 * @link /v1/category/filters
 */

export function useGetCategoryFilters <TData = GetCategoryFiltersQueryResponse, TError = GetCategoryFilters400 | GetCategoryFilters404 | GetCategoryFilters500>(options: {
          query?: UseQueryOptions<TData, TError>,
          client?: Partial<Parameters<typeof client<TData, TError>>[0]>,
        } = {}): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const { query: queryOptions, client: clientOptions = {} } = options ?? {};
  const queryKey = queryOptions?.queryKey ?? getCategoryFiltersQueryKey();

  const query = useQuery<TData, TError>({
    ...getCategoryFiltersQueryOptions<TData, TError>(clientOptions),
    ...queryOptions
  }) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey as QueryKey;

  return query;
};
