import { EAgriculturalProductsRoute, EAgroservicesRoute, ECartRoute, EFinancingOfferRoute } from '@/types'
import type { IBreadcrumb } from './Breadcrumb/interfaces'

export const homeBreadcrumb: IBreadcrumb = { captionId: 'breadcrumbs.home', link: '/' }
export const agriculturalProductsBreadcrumb: IBreadcrumb = {
  captionId: 'breadcrumbs.agriculturalProducts',
  link: EAgriculturalProductsRoute.main,
}
export const agroservicesBreadcrumb: IBreadcrumb = { captionId: 'breadcrumbs.agroservices', link: EAgroservicesRoute.main }

export const financingPageBreadcrumb: IBreadcrumb = { captionId: 'breadcrumbs.financing', link: EFinancingOfferRoute.main }

export const cartBreadcrumbs: IBreadcrumb[] = [
  agriculturalProductsBreadcrumb,
  { captionId: 'breadcrumbs.multiCart', link: ECartRoute.multiCart },
  { captionId: 'breadcrumbs.cart' },
]

export const multiCartBreadcrumbs: IBreadcrumb[] = [agriculturalProductsBreadcrumb, { captionId: 'breadcrumbs.multiCart' }]
