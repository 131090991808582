import { createAction } from '@reduxjs/toolkit'
import type { IUserOrganizationData } from '@/types'
import type { IAgrarianNotificationSettings, IProfileAgrarian, IUserProfileFormValues } from './interface'

export declare namespace NAction {
  interface ISetUserProfilePayload {
    profile: IProfileAgrarian
    deals: IAgrarianNotificationSettings
    newMessages: IAgrarianNotificationSettings
    specialOffers: IAgrarianNotificationSettings
  }

  interface IChangeUserProfilePayload {
    formValues: IUserProfileFormValues
  }

  interface IChangeOrganizationDataPayload {
    formValues: IUserOrganizationData
  }
}

export const profileRequestFinished = createAction('@@profile/PROFILE_REQUEST_FINISHED')
export const profileRequestStarted = createAction('@@profile/PROFILE_REQUEST_STARTED')
export const profileRequestFailed = createAction('@@profile/PROFILE_REQUEST_FAILED')
export const clearProfileState = createAction('@@profile/CLEAR_PROFILE_STATE')
export const fetchUserProfile = createAction('@@profile/FETCH_USER_PROFILE')
export const setUserProfile = createAction<NAction.ISetUserProfilePayload>('@@profile/SET_USER_PROFILE')
export const clearUserProfile = createAction('@@profile/CLEAR_USER_PROFILE')
export const changeUserProfile = createAction<NAction.IChangeUserProfilePayload>('@@profile/CHANGE_USER_PROFILE')
export const changeOrganizationData = createAction<NAction.IChangeOrganizationDataPayload>('@@profile/CHANGE_ORGANIZATION_DATA')
export const uploadPhoto = createAction<File>('@@profile/UPLOAD_PHOTO')
