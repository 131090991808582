
export enum BetCostLastDataArrow {
    "UP" = "UP",
    "DOWN" = "DOWN"
}
export type BetCostLastData = {
    /**
     * @type integer
    */
    cost: number;
    /**
     * @type string
    */
    arrow: BetCostLastDataArrow;
};
