import debounce from 'lodash/debounce'
import isEqual from 'lodash/isEqual'
import { useSyncExternalStore } from 'use-sync-external-store/shim'
import { MATCH_MEDIA_QUERIES } from '@/constants/common'
import { capitalize } from '@/utils/capitalize'
import { getObjectKeys } from '@/utils/getObjectKeys'

type EventHandler = (event: MediaQueryListEvent) => void

type TStringWithPrefix<prefix extends string, s extends string> = `${prefix}${Capitalize<s>}`

type TDeviceType = keyof typeof MATCH_MEDIA_QUERIES
type TPrefixedDeviceType = TStringWithPrefix<'is', TDeviceType>
type TPrefixedDeviceTypes = Record<TPrefixedDeviceType, boolean>

const getPrefixedDeviceType = <s extends TDeviceType>(deviceType: s): TPrefixedDeviceType => `is${capitalize(deviceType)}`

let deviceTypes = getInitialDeviceTypes()

let listeners: Array<() => void> = []

function emitChange() {
  for (const listener of listeners) {
    listener()
  }
}

const emitChangeDebounced = debounce(emitChange, 50)

const mqHandlers = new Map<MediaQueryList, EventHandler>()

function setupMqHandlers() {
  getObjectKeys(MATCH_MEDIA_QUERIES).forEach(deviceType => {
    const deviceTypePrefixed = getPrefixedDeviceType(deviceType)

    const mqList = matchMedia(MATCH_MEDIA_QUERIES[deviceType])
    deviceTypes[deviceTypePrefixed] = mqList.matches

    const handler: EventHandler = ({ matches }) => {
      deviceTypes = { ...deviceTypes, [deviceTypePrefixed]: matches }
      emitChangeDebounced()
    }
    mqHandlers.set(mqList, handler)

    if (mqList.addListener) {
      mqList.addListener(handler)
    } else {
      mqList.addEventListener('change', handler)
    }
  })

  const initialDeviceTypes = getInitialDeviceTypes()
  const isSomeMediaQueryChangedDuringSetupOnClient = !isEqual(initialDeviceTypes, deviceTypes)

  if (isSomeMediaQueryChangedDuringSetupOnClient) {
    updateReferenceToForceRerendering()
  }
}

function updateReferenceToForceRerendering() {
  deviceTypes = { ...deviceTypes }
}

function removeMqHandlers() {
  mqHandlers.forEach((handler, mqList) => {
    if (mqList.removeListener) {
      mqList.removeListener(handler)
    } else {
      mqList.removeEventListener('change', handler)
    }
  })
}

function subscribe(listener: () => void) {
  listeners = [...listeners, listener]
  if (listeners.length === 1) {
    setupMqHandlers()
  }

  return () => {
    listeners = listeners.filter(l => l !== listener)
    if (!listeners.length) {
      removeMqHandlers()
    }
  }
}

function getInitialDeviceTypes(): TPrefixedDeviceTypes {
  const deviceType = getObjectKeys(MATCH_MEDIA_QUERIES).reduce(
    (acc, type) => ({
      ...acc,
      [getPrefixedDeviceType(type)]: false,
    }),
    {} as TPrefixedDeviceTypes,
  )

  return deviceType
}

function getServerSnapshot(): TPrefixedDeviceTypes {
  return getInitialDeviceTypes()
}

function getSnapshot() {
  return deviceTypes
}

export function useDeviceType(): TPrefixedDeviceTypes {
  const retVal = useSyncExternalStore(subscribe, getSnapshot, getServerSnapshot)

  return retVal
}
