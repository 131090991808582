import type { UseMutationOptions, UseMutationResult } from "@tanstack/react-query";
import { useMutation } from "@tanstack/react-query";
import client from "@/api/client/axios";
import type { ResponseConfig } from "@/api/client/axios";
import type { ConfirmSaleRequestShippingMutationResponse, ConfirmSaleRequestShippingPathParams, ConfirmSaleRequestShipping404, ConfirmSaleRequestShipping500 } from "../types/ConfirmSaleRequestShipping";

/**
 * @summary Запрос на подтверждение оплаты
 * @link /api/salerequests/:requestId/shippings/:shippingId/confirm
 */

export function useConfirmSaleRequestShipping <TData = ConfirmSaleRequestShippingMutationResponse, TError = ConfirmSaleRequestShipping404 | ConfirmSaleRequestShipping500>(requestId: ConfirmSaleRequestShippingPathParams["requestId"], shippingId: ConfirmSaleRequestShippingPathParams["shippingId"], options: {
          mutation?: UseMutationOptions<ResponseConfig<TData>, TError, void>,
          client?: Partial<Parameters<typeof client<TData, TError, void>>[0]>,
      } = {}): UseMutationResult<ResponseConfig<TData>, TError, void> {
  const { mutation: mutationOptions, client: clientOptions = {} } = options ?? {};

  return useMutation<ResponseConfig<TData>, TError, void>({
    mutationFn: () => {
      
      return client<TData, TError, void>({
        method: "post",
        url: `/api/salerequests/${requestId}/shippings/${shippingId}/confirm`,
        
        
        
        ...clientOptions
      });
    },
    ...mutationOptions
  });
};
