import React, { useEffect } from 'react'
import { FormattedMessage } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { EColor } from '@frontend/pole-ui/lib/common/constants'
import { EIconName, EIconSize } from '@frontend/pole-ui/lib/components/Icon'
import compact from 'lodash/compact'
import { AuthButton, Avatar, ChangeOrgButton, TextWithIcon } from '@/components/ui'
import { isMobile } from '@/logic/app/reducer'
import { isAuthenticated, isImpersonationUser } from '@/logic/auth/reducer'
import { fetchUserProfile } from '@/logic/profile/actions'
import { getProfile } from '@/logic/profile/reducer'
import { injectMessages } from '@/logic/translation/utils'
import type { RootState } from '@/redux/interfaces'
import messages from './messages.json'
import './styles.scss'

injectMessages(messages)

const mapState = (state: RootState) => ({
  isUserAuthenticated: isAuthenticated()(state),
  isImpersonation: isImpersonationUser()(state),
  profile: getProfile()(state),
  isMobileDevice: isMobile()(state),
})

const ProfileAction: React.FC = () => {
  const { isUserAuthenticated, isImpersonation, profile, isMobileDevice } = useSelector(mapState)

  const dispatch = useDispatch()
  const { firstName, lastName = '', middleName = '', photo, updateDate } = profile || {}
  const getTextWithDot = (text?: string): string | undefined => text && `${text}.`

  useEffect(() => {
    if (isUserAuthenticated) dispatch(fetchUserProfile())
    //eslint-disable-next-line
  }, [isUserAuthenticated])

  const userName = compact([lastName, getTextWithDot(firstName[0]), getTextWithDot(middleName[0])]).join(' ')

  return (
    <div className="profileAction">
      <ChangeOrgButton className="profileAction__changeOrgButton--hideUntilDesktop" />
      <AuthButton buttonProps={{ className: 'profileActionButton text_small' }} shouldRedirectToProfileIfAuth>
        {isMobileDevice ? (
          <>
            {!isUserAuthenticated ? (
              <TextWithIcon
                iconProps={{ name: EIconName.LogIn, size: EIconSize.S }}
                textId="profileAction.unauthorized.greeting.mobile"
                className="profileActionButton__loginButton"
              />
            ) : (
              <Avatar avatar={photo} version={updateDate} size="36px" patchBackground={EColor.WHITE} />
            )}
          </>
        ) : (
          <span className="profileAction__username">
            {isImpersonation ? (
              <FormattedMessage id="profileAction.authorized.greeting.impersonation" values={{ name: userName }} />
            ) : (
              <div>
                {isUserAuthenticated ? (
                  <FormattedMessage id={`profileAction.authorized.greeting`} />
                ) : (
                  <TextWithIcon
                    iconProps={{ name: EIconName.LogIn, size: EIconSize.S, style: { color: EColor.WHITE } }}
                    iconClassName="profileActionButton__loginIcon"
                    textId="profileAction.unauthorized.greeting"
                    className="profileActionButton__loginButton"
                  />
                )}
              </div>
            )}
          </span>
        )}
      </AuthButton>
    </div>
  )
}

export default ProfileAction
