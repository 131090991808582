import type { ReactNode } from 'react'
import type { ISimpleDetail } from '@/components/ui/SimpleDetails/interfaces'
import type { TApiUrlName } from '@/logic/data/interfaces'
import type { IDiscountData } from '@/types'
import type { ESaleRequestType } from '@/types/ESaleRequestType'
import type { IHarvestSaleQuality } from '@/types/HarvestSaleLots'

export interface IAgrarianSale {
  requestId: string
  saleRequestType: ESaleRequestType
  saleRequestApiName: TApiUrlName
  isMultiRequest: boolean
}

export type TQualityFieldControl = (detail: ISimpleDetail) => ReactNode

export interface IAgrarianSaleRequestFormValues {
  deliveryBasisId: string
  elevatorId: string
  price: string
  quality: IHarvestSaleQuality
  storeId: string
  volume: string
  deliveryRange: [string, string]
  prepaymentPercentage?: number
  paymentType: string
  discount?: string
  transportType?: string
  changeType?: string
  requestDiscountData?: {
    discountData: IDiscountData[]
    cultureId: string
    limitQualityIndicators: {
      proteinFrom: string
      fallingNumber: string
      nature: string
      weedAdmixture: string
      humidity: string
      grainAdmixture: string
      ergot: string
      gluten: string
      damagedByTurtleBugSeedsContent: string
    }
  }
}

export type TTransportTypeOptions = 'UNDEFINED' | 'AUTOMOBILE' | 'RAILWAY'

export type TAgrarianSaleRequestFormValues =
  | 'deliveryBasisId'
  | 'elevatorId'
  | 'price'
  | 'quality'
  | 'storeId'
  | 'volume'
  | 'deliveryRange'
  | 'prepaymentPercentage'
  | 'transportType'

export const RequestActiveTabIndex = {
  details: 0,
  chat: 1,
  shipping: 2,
  documents: 3,
  history: 4,
} as const

export const MultiRequestActiveTabIndex = {
  details: 0,
  chat: 1,
  history: 2,
} as const

export type TRequestTab = 'details' | 'documents' | 'shipping' | 'chat' | 'history'
export type TMultiRequestTab = 'details' | 'chat' | 'history'

export type TDeliveryBasisId = 'WAGON' | 'EXW' | 'PICKUP' | 'ELEVATOR'

export const DeliveryBasisName = {
  WAGON: 'Франко-вагон (FCA)',
  EXW: 'Перепись (EXW)',
  PICKUP: 'Со склада хозяйства (FCA)',
  ELEVATOR: 'Элеватор/порт (CPT)',
} as const
