import client from "@/api/client/axios";
import type { ResponseConfig } from "@/api/client/axios";
import type { DownloadFundingDocumentQueryResponse, DownloadFundingDocumentQueryParams } from "../../types/DownloadFundingDocument";

/**
     * @link /api/credit-flow-backend/v1/document/view_binary
     */
export async function downloadFundingDocument (params: DownloadFundingDocumentQueryParams, options: Partial<Parameters<typeof client>[0]> = {}): Promise<ResponseConfig<DownloadFundingDocumentQueryResponse>["data"]> {
    const { data: resData } = await client<DownloadFundingDocumentQueryResponse>({
        method: "get",
        url: `/api/credit-flow-backend/v1/document/view_binary`,
        params,
        ...options
    });
    
    return resData;
};