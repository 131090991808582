import type { CSSProperties } from 'react'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import { EIconName, Icon } from '@frontend/pole-ui/lib/components/Icon'
import classNames from 'classnames'
import { Toggle } from 'rsuite'
import { BubblingTooltip } from '@/components/ui'
import type { ISwitchButtonProps } from './interfaces'
import './styles.scss'

const SwitchButton: React.FC<ISwitchButtonProps> = props => {
  const {
    onChange,
    labelId,
    label,
    switcherProps,
    value,
    isDefaultChecked,
    switcherStyle,
    switcherThumbStyle,
    labelPosition = 'right',
    className,
    labelClassName = 'text_small',
    tooltipText,
  } = props
  const labelText = label || (labelId && <FormattedMessage id={labelId} />)

  return (
    <div
      className={classNames('toggle-block', className, { row_column: ['bottom', 'top'].includes(labelPosition) })}
      style={{ '--switcher-style': switcherStyle, '--switcher-thumb-style': switcherThumbStyle } as CSSProperties}
    >
      {labelText && (
        <div className={`toggle-block__label toggle-block__label_${labelPosition}`}>
          <p className={labelClassName}>{labelText}</p>
          {tooltipText && (
            <BubblingTooltip
              content={tooltipText}
              tooltipProps={{ className: 'toggle-block__tooltip', placement: 'top-start', allowHTML: true }}
            >
              <div className="toggle-block__tooltipContent">
                <Icon name={EIconName.Question} className="toggle-block__tooltipIcon" />
              </div>
            </BubblingTooltip>
          )}
        </div>
      )}
      <Toggle
        {...switcherProps}
        onChange={onChange}
        checked={value}
        defaultChecked={isDefaultChecked}
        className={`toggle-block__button_${labelPosition}`}
      />
    </div>
  )
}

export default SwitchButton
