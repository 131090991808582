import React, { createContext, useCallback, useContext, useMemo } from 'react'
import { resolveAccessControlRule } from '@/components/composed/AccessControlProvider/helpers'
import type {
  EAccessControlValue,
  TAccessControlProviderContent,
  TAccessControlProviderProps,
  TAccessControlResolver,
  TAccessControlRules,
} from './types'

/**
 * Создает провайдер AccessControlProvider с заданными правилами.
 *
 * @param {TRules} rules - Правила контроля доступа.
 * @returns {Object} Объект, содержащий компонент AccessControlProvider и пользовательский хук для доступа к контексту.
 * @see {@link file://./README.md#Использование|README.md - Использование}
 */
export const createAccessControlProvider = <
  TRules extends TAccessControlRules<TContext, TValue>,
  TContext,
  TValue extends string = EAccessControlValue,
>(
  rules: TRules,
) => {
  const AccessControlContext = createContext<TAccessControlProviderContent<TRules, TContext, TValue>>({
    resolve: () => {
      throw new Error('AccessControlContext should be used inside AccessControlProvider')
    },
  })

  const AccessControlProvider = ({ context, children }: TAccessControlProviderProps<TContext>) => {
    const resolve: TAccessControlResolver<TRules, TContext, TValue> = useCallback(
      (key, extra) => {
        return resolveAccessControlRule(context, rules, extra, key)
      },
      [context],
    )

    const value = useMemo<TAccessControlProviderContent<TRules, TContext, TValue>>(
      () => ({
        resolve,
      }),
      [resolve],
    )

    return <AccessControlContext.Provider value={value}>{children}</AccessControlContext.Provider>
  }

  const useAccessControl = () => useContext(AccessControlContext)

  return {
    AccessControlProvider,
    useAccessControl,
  }
}
