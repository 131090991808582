import Loadable from '@loadable/component'

export * from './types'

export const UserRegionSelectSelectorDesktop = Loadable(
  /* webpackChunkName: "UserRegionSelectSelectorDesktop" */
  /* webpackPrefetch: true */
  /* #__LOADABLE__ */
  () => import('./UserRegionSelectSelectorDesktop'),
)

export const UserRegionSelectSelectorMobile = Loadable(
  /* webpackChunkName: "UserRegionSelectSelectorMobile" */
  /* webpackPrefetch: true */
  /* #__LOADABLE__ */
  () => import('./UserRegionSelectSelectorMobile'),
)
