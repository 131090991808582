import { ExecutionEnvironment } from '@/utils/ExecutionEnvironment'

const cookieAgreementCookieName = 'isCookieAgreed'

export const CookieAgreementStorage = {
  agree() {
    window.localStorage.setItem(cookieAgreementCookieName, 'true')
  },
  get(): boolean | null {
    if (ExecutionEnvironment.isSsr) return null

    return Boolean(window.localStorage.getItem(cookieAgreementCookieName))
  },
}
