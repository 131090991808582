import { generatePath } from 'react-router-dom'
import { history } from '../../browserHistory'

// URL api не работает с относительными путями, требуется baseUrl, поэтому добавляем BASE_HOST и убираем его из URL
const BASE_HOST = 'https://pole-base-host.ru'

export const createRouterLink = generatePath

const getCurrentUrlData = () => {
  const { pathname, search, hash } = window.location

  return {
    relativePath: `${pathname}${search}${hash}`,
    pathname,
    search,
    hash,
  }
}

export const getUrlWithQuery = (url: string, query: Record<string, unknown> | null): string => {
  let urlObj: URL

  try {
    urlObj = new URL(url)
  } catch {
    urlObj = new URL(url, BASE_HOST)
  }

  Object.entries(query ?? {}).forEach(([name, value]) => {
    if (value !== undefined) {
      if (Array.isArray(value)) {
        value.forEach(val => {
          if (val !== undefined) {
            urlObj.searchParams.append(name, String(val))
          }
        })
      } else {
        urlObj.searchParams.append(name, String(value))
      }
    }
  })

  return urlObj.toString().replace(BASE_HOST, '')
}

export const clearQueryParamsInUrl = (name: string | string[]): string => {
  const { pathname, search, hash } = getCurrentUrlData()
  const params = new URLSearchParams(search)
  const paramsForDeleting = typeof name === 'string' ? [name] : name
  paramsForDeleting.forEach(paramName => params.delete(paramName))

  const searchParams = params.toString()

  return `${pathname}${searchParams.length ? `?${searchParams}` : ''}${hash}`
}

export const getLocationPayload = (href: string) => {
  const url = new URL(href)
  const { hash, pathname, search, origin, protocol, hostname } = url

  const query: Record<string, unknown> = {}

  for (const [key, value] of url.searchParams.entries()) {
    if (query[key]) {
      // @ts-ignore
      query[key] = Array.isArray(query[key]) ? [...query[key], value] : [query[key], value]
    } else {
      query[key] = value
    }
  }

  return {
    hash,
    is404Page: pathname === '/error-404',
    pathname,
    search,
    query,
    origin,
    href,
    protocol,
    hostname,
  }
}

export const changePageUrlHash = (hash: string) => {
  const { pathname, search } = getCurrentUrlData()
  history.push(`${pathname}${search}#${hash}`)
}

export const removeQueryParameter = (name: string | string[]) => {
  const url = clearQueryParamsInUrl(name)

  history.push(url)
}

export const changeQueryString = (name: string, value: string | number, shouldRemoveOldValue?: boolean): void => {
  let url = getCurrentUrlData().relativePath

  if (shouldRemoveOldValue) {
    url = clearQueryParamsInUrl(name)
  }

  const updatedUrl = getUrlWithQuery(url, { [name]: value })
  history.push(updatedUrl)
}

export const changeQueryStringForTabs = (
  name: string,
  value: string | number,
  options?: { shouldRemoveOldValue?: boolean; noScrollTop?: boolean },
): void => {
  let url = getCurrentUrlData().relativePath

  if (options?.shouldRemoveOldValue) {
    url = clearQueryParamsInUrl(name)
  }

  const updatedUrl = getUrlWithQuery(url, { [name]: value, ...(options?.noScrollTop && { noscrolltop: true }) })
  history.replace(updatedUrl)
}
