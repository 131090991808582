import { LOCATION_CHANGE } from 'connected-react-router'
import type { AnyAction, Reducer } from 'redux'
import { combineReducers } from 'redux'
import type { RootState, TAppStore, TRootReducer, TRootReducers } from './interfaces'

type TRootStateKeys = keyof RootState

export type TGetReducerWithCleanupOnLocationChange = (
  reducer: TRootReducers[TRootStateKeys],
) => (state: RootState[TRootStateKeys], action: AnyAction) => RootState[TRootStateKeys]

export const getReducerWithCleanupOnLocationChange: TGetReducerWithCleanupOnLocationChange = reducer => {
  let lastLocationPathname: string | undefined

  return (state, action) => {
    let isLocationChanged = false
    if (action.type === LOCATION_CHANGE) {
      const locationPathname = action.payload.location.pathname
      isLocationChanged = lastLocationPathname !== locationPathname
      lastLocationPathname = locationPathname
    }

    //Передавая undefined получаем defaultState
    //eslint-disable-next-line @typescript-eslint/no-explicit-any
    return reducer((isLocationChanged ? undefined : state) as any, action)
  }
}

export interface IReducerManager {
  getReducerMap: () => Partial<TRootReducer>
  reduce: TRootReducer
  add: (key: TRootStateKeys, reducer: TRootReducers[TRootStateKeys], shouldNotCleanReducerStateOnChangeLocation?: boolean) => void
  remove: (key: TRootStateKeys) => void
  store?: TAppStore
}

const createReducerManager = (initialReducers: Partial<TRootReducers>): IReducerManager => {
  const reducers: Partial<TRootReducers> = { ...initialReducers }

  return {
    getReducerMap: () => reducers,

    reduce: combineReducers(reducers) as TRootReducer,

    add(key, reducer, shouldNotCleanReducerStateOnChangeLocation) {
      if (!key || reducers[key]) return

      // Требуется делать typeguard на каждый редусер
      //@ts-ignore
      reducers[key] = shouldNotCleanReducerStateOnChangeLocation ? reducer : getReducerWithCleanupOnLocationChange(reducer)
      this.reduce = combineReducers(reducers) as TRootReducer
      if (this.store) this.store.replaceReducer(this.reduce)
    },

    remove(key) {
      if (!key || !reducers[key]) return

      delete reducers[key]
      this.reduce = combineReducers(reducers) as Reducer<RootState>
      if (this.store) this.store.replaceReducer(this.reduce)
    },
  }
}

export default createReducerManager
