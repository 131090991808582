import { EIconName } from '@frontend/pole-ui/lib/components/Icon'
import {
  EAgriculturalProductsRoute,
  EAgroservicesRoute,
  EFinancingOfferRoute,
  EHarvestSaleRoute,
  EJournalRoute,
  EUserProfileRoute,
} from '@/types'
import { EOfferRequestsRoute } from '@/types/EOfferRequestsRoute'
import { getUrlWithQuery } from '@/utils/url'
import type { NavLink } from './interfaces'

export const links: NavLink[] = [
  {
    textId: 'fertilizers',
    url: EAgriculturalProductsRoute.main,
    icon: EIconName.Farming,
  },
  {
    textId: 'harvestSale',
    url: EHarvestSaleRoute.main,
    icon: EIconName.Harvest,
  },
  {
    textId: 'priceRequests',
    url: getUrlWithQuery(EOfferRequestsRoute.main, { offset: 0 }),
    icon: EIconName.Scale,
  },
  {
    textId: 'financing',
    url: EFinancingOfferRoute.main,
    icon: EIconName.Wallet,
  },
  {
    textId: 'agroservices',
    url: EAgroservicesRoute.main,
    icon: EIconName.Services,
  },
  {
    textId: 'journal',
    url: EJournalRoute.MAIN,
    icon: EIconName.NewsTab,
  },
]

export const mobileLinks: NavLink[] = [
  {
    textId: 'profile',
    url: EUserProfileRoute.main,
    icon: EIconName.Profile,
  },
  ...links,
]
