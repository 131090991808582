import { createAction } from '@reduxjs/toolkit'
import { loadData } from '@/logic/data/actions'
import type {
  IChangeHarvestSaleRequestPayload,
  IChangeHarvestSaleRequestStatusPayload,
  IRejectHarvestSaleRequestPayload,
} from '@/logic/harvestSaleRequest/interfaces'
import type { TEntityId } from '@/types/TEntityId'

export const changeHarvestSaleRequest = createAction<IChangeHarvestSaleRequestPayload>('@@harvestSaleRequest/CHANGE_HARVEST_SALE_REQUEST')
export const rejectHarvestSaleRequest = createAction<IRejectHarvestSaleRequestPayload>('@@harvestSaleRequest/REJECT_HARVEST_SALE_REQUEST')
export const changeHarvestSaleRequestStatus = createAction<IChangeHarvestSaleRequestStatusPayload>(
  '@@harvestSaleRequest/CHANGE_HARVEST_SALE_REQUEST_STATUS',
)
export const loadHarvestSaleRequest = (id: TEntityId) => loadData({ name: 'saleRequest', params: { id }, shouldLoadForcibly: true })
export const loadHarvestSaleMultiRequest = (id: TEntityId) =>
  loadData({ name: 'saleMultiRequest', params: { id }, shouldLoadForcibly: true })
