import type { ControllerFieldState, FieldPath, FieldValues, UseControllerProps, UseFormReturn } from 'react-hook-form'
import type { IFieldMetaState } from '@frontend/pole-ui/lib/components/BaseFormFieldContainer'

export const getMappedFieldMetaState = (fieldState: ControllerFieldState, overrides?: Partial<IFieldMetaState>): IFieldMetaState => {
  const { isTouched, error, invalid, isDirty } = fieldState

  return { touched: isTouched, error: error?.message, invalid, valid: !invalid, dirty: isDirty, ...overrides }
}

// Т.к. форма не помечает поля как touched при submit'е автоматически, приходится делать это вручную
// https://github.com/react-hook-form/react-hook-form/issues/1418
export const makeAllFieldsTouched = <TForm extends FieldValues>({
  getValues,
  setValue,
}: Pick<UseFormReturn<TForm>, 'getValues' | 'setValue'>) => {
  const values = getValues()

  Object.entries(values).forEach(([fieldName, fieldValue]) => {
    setValue(fieldName as FieldPath<TForm>, fieldValue, { shouldTouch: true })
  })
}

// если подставлять `UseControllerProps` напрямую в дженерик, то снаружи функции ts ломается
export type TControllerPropsName = keyof UseControllerProps
export type TControllerProps = {
  [k in keyof UseControllerProps]: unknown
}

export function splitHookFormControllerProps<T extends TControllerProps>(
  props: T,
): [Pick<T, TControllerPropsName>, Omit<T, TControllerPropsName>] {
  const { name, control, rules, disabled, shouldUnregister, defaultValue, ...restProps } = props

  return [{ name, control, rules, disabled, shouldUnregister, defaultValue }, restProps]
}
