import type { IRequestResponse } from '@/api/interfaces'
import type { ProductData } from '@/api/kubik/monolith'
import { requestFailed, requestFinished, requestStarted } from '@/logic/requestStatus/actions'
import { EAgriculturalProductRequestName } from './types'

export const agriculturalProductRequestFinished = (data: ProductData) =>
  requestFinished({
    name: `${EAgriculturalProductRequestName.FETCH}${data.product?.id}`,
    data,
  })
export const agriculturalProductRequestFailed = ({ meta }: IRequestResponse<ProductData>, productId: string | undefined) =>
  requestFailed({ name: `${EAgriculturalProductRequestName.FETCH}${productId}`, meta })
export const agriculturalProductRequestStarted = (productId: string | undefined) =>
  requestStarted({ name: `${EAgriculturalProductRequestName.FETCH}${productId}` })
