import type { TFetchCatalogRequestParams } from '@/services/CatalogService/interfaces'
import { isNonNullable } from '@/typeguards/common'
import type { IAsyncThunkFetchProductCatalogPayload } from '@/types/Catalog'
import { EAgriculturalProductCategoryId } from '@/types/Catalog'
import { getObjectKeys } from '@/utils/getObjectKeys'
import { PRODUCTS_PER_PAGE } from '../../constants'
import type { IAgriculturalProductCatalogFiltersForm } from '../../types'
import { isAgriculturalProductFilterName } from '../typeguards'

interface IPaginationOptions {
  perPage?: number
}

export function mapCatalogPayloadToFetchRequestParams(
  { params, search }: IAsyncThunkFetchProductCatalogPayload,
  options?: Maybe<IPaginationOptions>,
  defaults?: Maybe<Partial<TFetchCatalogRequestParams>>,
): TFetchCatalogRequestParams {
  const { perPage = PRODUCTS_PER_PAGE } = options ?? {}

  const payload = { ...params, ...search }

  const initialParams: TFetchCatalogRequestParams = {
    ...defaults,
    ...getPagination(1, { perPage }),
  }

  return getObjectKeys(payload).reduce((acc, key) => {
    if (isAgriculturalProductFilterName(key)) {
      const value = [search[key]].flat().filter(isNonNullable)

      // prettier-ignore
      acc[key] = value.length
        ? value
        : undefined

      return acc
    }

    switch (key) {
      case 'categoryId':
        if (payload[key] !== EAgriculturalProductCategoryId.SEARCH) {
          acc.category = Number(params[key])
          break
        }
        break

      case 'searchText':
        acc.text = payload[key]
        break

      case 'promotionId':
        acc.promotionId = payload[key]
        break

      case 'pageNumber': {
        const pageNumber = Number(payload[key]) || 1
        Object.assign(acc, getPagination(pageNumber, { perPage }))
        break
      }

      case 'cfilters': {
        let val: IAgriculturalProductCatalogFiltersForm['filters'] = {}

        try {
          const rawVal = payload[key]
          val = rawVal ? (JSON.parse(decodeURIComponent(rawVal)) ?? null) : val
        } catch (err) {
          // eslint-disable-next-line no-console
          console.error(err)
        }

        acc.characteristicsFilters = Object.entries(val).map(([id, value]) => JSON.stringify({ id, value }))
        break
      }

      default: {
        // eslint-disable-next-line unused-imports/no-unused-vars
        const exhaustiveCheck: never = key
      }
    }

    return acc
  }, initialParams)
}

export function getPagination(
  pageNumber: number,
  options: Required<IPaginationOptions>,
): Pick<TFetchCatalogRequestParams, 'offset' | 'limit'> {
  const page = Math.max(pageNumber, 1)
  const perPage = Math.max(options.perPage, 0)

  return {
    limit: perPage,
    offset: perPage * (page - 1),
  }
}
