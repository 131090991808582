import type { QueryKey, UseQueryResult, UseQueryOptions, QueryOptions } from "@tanstack/react-query";
import { useQuery } from "@tanstack/react-query";
import client from "@/api/client/axios";
import type { GetSaleRequestShippingDocumentContentQueryResponse, GetSaleRequestShippingDocumentContentPathParams, GetSaleRequestShippingDocumentContentQueryParams, GetSaleRequestShippingDocumentContent404, GetSaleRequestShippingDocumentContent500 } from "../types/GetSaleRequestShippingDocumentContent";

export const getSaleRequestShippingDocumentContentQueryKey = (requestId: GetSaleRequestShippingDocumentContentPathParams["requestId"], shippingId: GetSaleRequestShippingDocumentContentPathParams["shippingId"], params: GetSaleRequestShippingDocumentContentQueryParams) =>     [{url:`/api/salerequests/${requestId}/shippings/${shippingId}/document/content`,params:{requestId:requestId,shippingId:shippingId}},...(params ? [params] : [])] as const;
      export function getSaleRequestShippingDocumentContentQueryOptions <TData = GetSaleRequestShippingDocumentContentQueryResponse, TError = GetSaleRequestShippingDocumentContent404 | GetSaleRequestShippingDocumentContent500>(requestId: GetSaleRequestShippingDocumentContentPathParams["requestId"], shippingId: GetSaleRequestShippingDocumentContentPathParams["shippingId"], params: GetSaleRequestShippingDocumentContentQueryParams, options: Partial<Parameters<typeof client>[0]> = {}): UseQueryOptions<TData, TError> {
        const queryKey = getSaleRequestShippingDocumentContentQueryKey(requestId, shippingId, params);

        return {
          queryKey,
          queryFn: () => {
            
            return client<TData, TError>({
              method: "get",
              url: `/api/salerequests/${requestId}/shippings/${shippingId}/document/content`,
              params,
              
              ...options,
            }).then(res => res.data);
          },
        };
      };
      
/**
 * @summary Предпросмотр счета на отгрузку
 * @link /api/salerequests/:requestId/shippings/:shippingId/document/content
 */

export function useGetSaleRequestShippingDocumentContent <TData = GetSaleRequestShippingDocumentContentQueryResponse, TError = GetSaleRequestShippingDocumentContent404 | GetSaleRequestShippingDocumentContent500>(requestId: GetSaleRequestShippingDocumentContentPathParams["requestId"], shippingId: GetSaleRequestShippingDocumentContentPathParams["shippingId"], params: GetSaleRequestShippingDocumentContentQueryParams, options: {
          query?: UseQueryOptions<TData, TError>,
          client?: Partial<Parameters<typeof client<TData, TError>>[0]>,
        } = {}): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const { query: queryOptions, client: clientOptions = {} } = options ?? {};
  const queryKey = queryOptions?.queryKey ?? getSaleRequestShippingDocumentContentQueryKey(requestId, shippingId, params);

  const query = useQuery<TData, TError>({
    ...getSaleRequestShippingDocumentContentQueryOptions<TData, TError>(requestId, shippingId, params, clientOptions),
    ...queryOptions
  }) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey as QueryKey;

  return query;
};
