export enum ELoanProductName {
  BNPL_AGROINTEGRATOR = 'BNPL_AGROINTEGRATOR',
  AGROINTEGRATOR = 'AGROINTEGRATOR',
  MONEY_KAPITAL = 'MONEY_KAPITAL',
  EXPRESS_MFO = 'EXPRESS_MFO',
  ADVANCE_FUNDING = 'ADVANCE_FUNDING',
  SBERPAY = 'SBERPAY',
  SBER_OBOROTKA_LOAN = 'SBER_OBOROTKA_LOAN',
  SBER_INVESTMENT_LOAN = 'SBER_INVESTMENT_LOAN',
  LOMBARD_LOAN_ALFA_BANK = 'LOMBARD_LOAN_ALFA_BANK',
  CENTER_INVEST_LOAN = 'CENTER_INVEST_BANK',
  BNPL_MANDARIN = 'BNPL_MANDARIN',
  BNPL_POLECREDIT = 'BNPL_POLECREDIT',
}
