import client from "@/api/client/axios";
import type { ResponseConfig } from "@/api/client/axios";
import type { GetMainPagePublicationQueryResponse } from "../../types/GetMainPagePublication";

/**
     * @link /api/ms-content-infoportal-backend/v1/publications/mainPage
     */
export async function getMainPagePublication (options: Partial<Parameters<typeof client>[0]> = {}): Promise<ResponseConfig<GetMainPagePublicationQueryResponse>["data"]> {
    const { data: resData } = await client<GetMainPagePublicationQueryResponse>({
        method: "get",
        url: `/api/ms-content-infoportal-backend/v1/publications/mainPage`,
        ...options
    });
    
    return resData;
};