import React, { useCallback, useContext, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { EColor } from '@frontend/pole-ui/lib/common/constants'
import type { IIconProps } from '@frontend/pole-ui/lib/components/Icon'
import { EIconName } from '@frontend/pole-ui/lib/components/Icon'
import some from 'lodash/some'
import { closeModal, openModal } from '@/components/composed/Modal/actions'
import type { ICustomizedFormModalOptions } from '@/components/composed/modals/CustomizedFormModal/interfaces'
import { Button, ErrorMessage, TextWithIcon } from '@/components/ui'
import { isBeforeToday } from '@/components/ui/fields/DatePicker/helpers'
import { SERVER_DATE_FORMAT } from '@/constants/common'
import { makeAccreditationProcess } from '@/logic/accreditation/actions'
import { AccreditationContext } from '@/logic/accreditation/constants'
import { EAccreditationAction } from '@/logic/accreditation/interfaces'
import { useData } from '@/logic/data'
import { pushGtmEventOfSendingDocumentsToVerification } from '@/logic/metrika/helpers'
import type { RootState } from '@/redux/interfaces'
import type { IConfirmAccreditationParameters, IRejectAccreditationParameters } from '@/services/accreditation/interfaces'
import type { IUserOrganizationData } from '@/types'
import { EDocumentStatus } from '@/types/Documents'
import { formatDate } from '@/utils/formatUtils'
import { unpackingArchiveStatuses } from '../../MyAccreditation/constants'
import { getConfirmationState } from '../helpers'
import type { IConfirmationBlockProps, TConfirmationNoticeType } from '../interfaces'

const mapState = (state: RootState) => ({ requestState: getConfirmationState()(state) })

const NoticeIcon: Record<TConfirmationNoticeType, IIconProps> = {
  documentsUploaded: { name: EIconName.OkFill, style: { color: EColor.GREEN } },
  documentsNotUploaded: { style: { color: EColor.RED }, name: EIconName.Warning },
}

const ConfirmationBlock: React.FC<IConfirmationBlockProps> = ({ shownTypedDocuments }) => {
  const {
    confirmAccreditation,
    accreditationActions,
    documents,
    accreditationId,
    version,
    isManager,
    accreditationType,
    mainStatus,
    validTo: initialValidTo,
    archive,
    statusId,
  } = useContext(AccreditationContext)
  const { confirm: confirmationButton, reject: rejectionButton } = accreditationActions
  const { requestState } = useSelector(mapState)
  const dispatch = useDispatch()
  const canSendArchiveToAdmin = typeof archive !== 'undefined' && !unpackingArchiveStatuses.includes(statusId) && mainStatus === 'waiting'

  const isAllDocumentsNotUploaded = useMemo<boolean>(
    () => some(shownTypedDocuments, { required: true }) || some(documents, { status: EDocumentStatus.REJECTED }),
    //eslint-disable-next-line
    [JSON.stringify(documents), JSON.stringify(shownTypedDocuments)],
  )

  const { data: organizationData, isLoading: organizationDataLoading } = useData<IUserOrganizationData>('userOrganization', {
    shouldLoadForcibly: true,
  })

  const openConfirmationRejectingModal = useCallback((): void => {
    dispatch(
      openModal({
        options: {
          dialogId: 'CustomizedFormModal',
        },
        contentOptions: {
          customizedFormProps: {
            requestStatusName: 'makeAccreditationProcess_reject',
            onSubmit: ({ comment }) => {
              dispatch(makeAccreditationProcess({ action: EAccreditationAction.REJECT, accreditationId, version, parameters: { comment } }))
            },
            steps: [
              {
                titleId: 'userAccreditation.rejectAccreditationModal.title',
                fields: [
                  {
                    type: 'textArea',
                    labelId: 'userAccreditation.rejectAccreditationModal.comment',
                    className: 'space-holder24',
                    isRequired: true,
                    name: 'comment',
                  },
                ],
                errorMessageId: 'userAccreditation.rejectAccreditationModal.error',
                submitButton: {
                  textId: 'userAccreditation.rejectAccreditationModal.confirmRejectingButton',
                  modifiers: ['full-width', 'green'],
                },
                buttons: [
                  {
                    textId: 'userAccreditation.rejectAccreditationModal.cancelRejectingButton',
                    modifiers: ['full-width', 'outline'],
                    className: 'space-holder-top8 space-holder-top8-sm',
                    onClick: () => {
                      dispatch(closeModal())
                    },
                  },
                ],
              },
            ],
          },
        } as ICustomizedFormModalOptions<IRejectAccreditationParameters>,
      }),
    )
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [version])
  if (!confirmationButton && !rejectionButton) return null

  const isAllNecessaryFieldsCompleted = Boolean(
    !organizationDataLoading &&
      organizationData?.organization.bik &&
      organizationData?.organization.email &&
      organizationData?.organization.billingAccount &&
      organizationData?.organization.correspondentAccount &&
      organizationData?.organization.bankName,
  )

  const { isLoading, isFailure } = requestState
  let confirmationNoticeType: TConfirmationNoticeType
  if (isAllDocumentsNotUploaded) confirmationNoticeType = 'documentsNotUploaded'
  else confirmationNoticeType = 'documentsUploaded'

  const confirmWithChosenExpirationDate = () =>
    dispatch(
      openModal({
        options: {
          dialogId: 'CustomizedFormModal',
        },
        contentOptions: {
          customizedFormProps: {
            initialValues: { validTo: initialValidTo ? formatDate(initialValidTo, SERVER_DATE_FORMAT) : undefined },
            requestStatusName: 'makeAccreditationProcess_confirm',
            onSubmit: ({ validTo }) =>
              confirmAccreditation({
                validTo,
                shouldOpenSuccessConfirmationModal: false,
                shouldRedirectToMyAccreditation: accreditationType === 'ORDER',
              }),
            steps: [
              {
                titleId: 'confirmationAccreditationModal.title',
                fields: [
                  {
                    fieldType: 'datePicker',
                    name: 'validTo',
                    pickerProps: { disabledDate: isBeforeToday, format: 'DD.MM.YYYY' },
                    parsingFormat: SERVER_DATE_FORMAT,
                    isRequired: true,
                  },
                ],
                submitButton: {
                  textId: 'confirmationAccreditationModal.submitButton',
                  modifiers: ['green', 'full-width'],
                },
              },
            ],
          },
        } as ICustomizedFormModalOptions<IConfirmAccreditationParameters>,
      }),
    )

  const openAddOrganizationsInfoModal = () =>
    dispatch(
      openModal({
        options: {
          dialogId: 'AddOrganizationInfoModal',
        },
        contentOptions: {
          onSubmit: () => confirmAccreditation({ shouldOpenSuccessConfirmationModal: false }),
          organizationData,
          isFetching: organizationDataLoading,
        },
      }),
    )

  const onSendDocumentToVerificationClick = () => {
    if (isAllNecessaryFieldsCompleted) {
      pushGtmEventOfSendingDocumentsToVerification()
      if (mainStatus === 'processing') confirmWithChosenExpirationDate()
      else if (statusId === 'ADMIN_APPROVES') confirmAccreditation({ shouldOpenSuccessConfirmationModal: false })
      else confirmAccreditation()
    } else {
      openAddOrganizationsInfoModal()
    }
  }

  return (
    //eslint-disable-next-line max-len
    <div className="space-holder-top56-from-sm space-holder-top32-sm row row-justify_between row-align_center user-accreditation-confirmation-block row_nowrap">
      {(!canSendArchiveToAdmin || !isAllDocumentsNotUploaded) && !isManager && (
        <TextWithIcon
          className="user-accreditation-confirmation-block__text"
          iconProps={NoticeIcon[confirmationNoticeType]}
          textId={`userAccreditation.confirmation.notice.${confirmationNoticeType}`}
        />
      )}

      <div className="space-holder-left-auto row row_nowrap user-accreditation-confirmation-block-actions">
        {confirmationButton && (
          <div className="row row_column">
            <Button
              onClick={onSendDocumentToVerificationClick}
              modifiers={['green']}
              loaderProps={{ isVisible: isLoading }}
              isDisabled={canSendArchiveToAdmin ? false : isAllDocumentsNotUploaded}
            >
              {confirmationButton.name}
            </Button>
            <ErrorMessage isVisible={isFailure} textId="userAccreditation.confirmation.error" className="space-holder-top16 text_center" />
          </div>
        )}

        {rejectionButton && (
          <Button onClick={openConfirmationRejectingModal} modifiers={['outline']} className="space-holder-left24-from-sm">
            {rejectionButton.name}
          </Button>
        )}
      </div>
    </div>
  )
}

export default ConfirmationBlock
