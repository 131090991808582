import { visibleForAllRule, visibleForPermissionsRule } from '@/components/composed/AccessControlProvider/helpers'
import type { TAccessControlRules } from '@/components/composed/AccessControlProvider/types'
import { EUserPermission } from '@/types'
import type { TAppAccessControlContext } from './types'

const IS_CART_SHOWN_RULE = visibleForPermissionsRule([EUserPermission.AGRARIAN])

export const APP_ACCESS_CONTROL_LIST = {
  header: {
    cart: IS_CART_SHOWN_RULE,
  },
  routes: {
    index: visibleForAllRule,
    agriculturalProducts: visibleForAllRule,
    agriculturalProductsCatalog: visibleForAllRule,
    agriculturalProduct: visibleForAllRule,
    userProfile: visibleForPermissionsRule([
      EUserPermission.AGRARIAN,
      EUserPermission.BUYER,
      EUserPermission.SUPPLIER,
      EUserPermission.LOGISTICS_OPERATOR,
      EUserPermission.FUNDING_PARTNER,
    ]),
    harvestSaleLotsOld: visibleForAllRule,
    harvestSaleLots: visibleForAllRule,
    culturesSaleLots: visibleForAllRule,
    pooling: visibleForAllRule,
    harvestSale: visibleForAllRule,
    offerRequests: visibleForAllRule,
    offerRequest: visibleForAllRule,
    qrCode: visibleForAllRule,
    payOnline: visibleForAllRule,
    error500: visibleForAllRule,
    error404: visibleForAllRule,
    makingOrderDetails: visibleForAllRule,
    multiCart: IS_CART_SHOWN_RULE,
    impersonation: visibleForPermissionsRule([EUserPermission.ADMIN]),
    temporaryBasicAuthPage: visibleForAllRule,
    financing: visibleForAllRule,
    finances: visibleForAllRule,
    agroservicesCalc: visibleForAllRule,
    agroservicesSeedingRate: visibleForAllRule,
    agroservicesFgisZerno: visibleForAllRule,
    agroservicesPoleexpert: visibleForAllRule,
    agroservicesPolescouting: visibleForAllRule,
    agroservicesPolediff: visibleForAllRule,
    agronoutFarmlandCropValuation: visibleForAllRule,
    agroservicesPolePlus: visibleForAllRule,
    express: visibleForAllRule,
    financingRedirect: visibleForAllRule,
    sberRassrochka: visibleForAllRule,
    investment: visibleForAllRule,
    investSber: visibleForAllRule,
    advancePaymentOnFutureHarvest: visibleForAllRule,
    advancePaymentOnFutureHarvestWithCalculator: visibleForAllRule,
    agriculturalMachinesAndEquipmentLeasing: visibleForAllRule,
    alfabankLeasing: visibleForAllRule,
    lombardCreditAlfaBank: visibleForAllRule,
    interleasing: visibleForAllRule,
    sberLeasing: visibleForAllRule,
    stoneXxiLeasing: visibleForAllRule,
    agroconsulting: visibleForAllRule,
    revolving: visibleForAllRule,
    oborotSber: visibleForAllRule,
    mfo: visibleForAllRule,
    transportAndSpecialEquipmentLeasing: visibleForAllRule,
    centerInvestLoan: visibleForAllRule,
    fleetFinanceLeasing: visibleForAllRule,
    agroservices: visibleForAllRule,
    feedback: visibleForAllRule,
    faqList: visibleForAllRule,
    faqSearch: visibleForAllRule,
    faq: visibleForAllRule,
    loyalty: visibleForAllRule,
    infoportal: visibleForPermissionsRule([EUserPermission.ADMIN, EUserPermission.CONTENT_MANAGER]),
    requisites: visibleForAllRule,
    aboutUs: visibleForAllRule,
    journalPublication: visibleForAllRule,
    journalNews: visibleForAllRule,
    journalNewsLatest: visibleForAllRule,
    journal: visibleForAllRule,
    journalByRubric: visibleForAllRule,
    journalByTag: visibleForAllRule,
    journalSearch: visibleForAllRule,
    legalDocuments: visibleForAllRule,
    partnerLanding: visibleForAllRule,
    partnerLandingRedirect: visibleForAllRule,
    vezdehodPromoLanding: visibleForAllRule,
    businessProcess: visibleForPermissionsRule([EUserPermission.ADMIN]),
    sberAuthRedirect: visibleForAllRule,
    youDo: visibleForAllRule,
    efis: visibleForAllRule,
    tenderPublic: visibleForAllRule,
    tenderPublicComparativeTable: visibleForAllRule,
    tenderPublicOfferFrom: visibleForAllRule,
    mobileRedirect: visibleForAllRule,
  },
} satisfies Readonly<TAccessControlRules<TAppAccessControlContext>>
