import type { TMessages } from '@/types/TMessages'

export const messages = {
  product: {
    expressLoan: 'Экспресс-кредит онлайн',
    revolvingLoan: 'Оборотный кредит',
    vtbLeasing: 'ВТБ Лизинг',
    transportAndSpecialEquipmentLeasing: 'Лизинг автотранспорта и спецтехники',
    investmentLoan: 'Инвестиционный кредит',
    alfaLeasing: 'Альфа-Лизинг',
  },
  offerGroup: {
    express: { title: 'Деньги на сельхозтовары' },
    replenish: { title: 'Пополнение оборотных средств' },
    leasing: { title: 'Лизинг техники и транспорта' },
    capital: { title: 'Деньги на капитальные затраты' },
  },
} satisfies TMessages
