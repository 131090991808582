import type { FC } from 'react'
import React from 'react'
import { useSelector } from 'react-redux'
import classNames from 'classnames'
import { AuthButton } from '@/components/ui'
import { getDevice } from '@/logic/app/reducer'
import { isCookieAgreed } from '@/logic/cookieAgreement/reducer'
import type { RootState } from '@/redux/interfaces'
import { useScrollDirection } from '@/utils/hooks'
import { useDeviceType } from '@/utils/hooks/useDeviceType'
import { getDialogId } from '../Modal/reducer'
import type { IMobileAuthButtonProps } from './interfaces'
import { messages } from './messages'
import './styles.scss'

const mapState = (state: RootState) => ({
  hasCookieAgreement: isCookieAgreed()(state),
  isAuthModalOpened: getDialogId()(state) === 'AuthModal',
  device: getDevice()(state),
})

const MobileAuthButton: FC<IMobileAuthButtonProps> = props => {
  const { visibilityMinOffset } = props
  const { hasCookieAgreement, isAuthModalOpened, device } = useSelector(mapState)
  const { isiPhone } = device

  const { scrollClassName } = useScrollDirection({
    className: 'app-mobile-auth-button',
    minThrottle: visibilityMinOffset,
  })

  const { isMobile } = useDeviceType()

  if (!isMobile) return null

  return (
    <AuthButton
      shouldHideForAuthenticatedUser
      buttonProps={{
        className: classNames('app-mobile-auth-button', scrollClassName, {
          'app-mobile-auth-button_safari': isiPhone,
          'app-mobile-auth-button_with-cookie-banner': !hasCookieAgreement,
          'app-mobile-auth-button_throttle': isAuthModalOpened,
        }),
        isUnstyled: false,
        modifiers: ['green'],
        isDisabled: isAuthModalOpened,
      }}
    >
      {messages.appMobileAuthButton}
    </AuthButton>
  )
}

export default MobileAuthButton
