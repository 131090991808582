import { getUserGtmInfo, pushFormattedGtmEvent } from '@/logic/metrika/helpers'
import type { RootState } from '@/redux/interfaces'

export const pushClickToAuthWithSberBusinessIdGtmEvent = () => {
  pushFormattedGtmEvent({
    event: 'userEvent',
    pagePart: 'popUp',
    blockName: 'formRegistrationStep1',
    eventCategory: 'interactions',
    eventAction: 'click',
    eventLabel: 'sberId',
    eventContext: null,
    eventStatus: 'success',
    eventEcommerce: null,
    eventNonInteraction: '0',
    firingOptions: 'oncePerEvent',
    ecommerce: null,
  })
}

export const pushOpenRegistrationModalGtmEvent = (state: RootState) => {
  pushFormattedGtmEvent({
    event: 'userEvent',
    pagePart: 'popUp',
    blockName: 'modalAuth',
    eventCategory: 'interactions',
    eventAction: 'viewing',
    eventLabel: 'modalAuthView',
    eventContext: null,
    eventEcommerce: null,
    eventNonInteraction: '0',
    firingOptions: 'oncePerEvent',
    ecommerce: null,
    userSegment: null,
    ...getUserGtmInfo()(state),
  })
}

export const pushOpenModalAuthConfirmGtmEvent = (state: RootState) => {
  pushFormattedGtmEvent({
    event: 'userEvent',
    pagePart: 'popUp',
    blockName: 'modalAuth',
    eventCategory: 'interactions',
    eventAction: 'click',
    eventLabel: 'modalAuthConfirm',
    eventContext: null,
    eventEcommerce: null,
    eventNonInteraction: '0',
    firingOptions: 'oncePerEvent',
    ecommerce: null,
    userSegment: null,
    ...getUserGtmInfo()(state),
  })
}
