export const messages = {
  agrarianMultiRequest: {
    title: 'Предложение № {id}',
  },
  agrarianSaleRequest: {
    title: 'Заявка № {id}',
    tabs: {
      documents: {
        text: 'Документы',
      },
      details: {
        text: 'Детали заявки',
      },
      shipping: {
        text: 'Отгрузки',
      },
      chat: {
        text: 'Чат с покупателем',
        textBuyer: 'Чат с клиентом',
      },
      history: {
        text: 'История заявки',
      },
    },
    saveChangesButton: {
      text: 'Сохранить изменения',
      counterOffer: 'Проверить и сделать предложение',
    },
    confirmRejectingPopup: {
      title: 'Вы уверены, что хотите отменить сделку?',
    },
    changingOrder: {
      failure: 'Не получилось обновить сделку',
    },
    button: {
      cancel: 'Отменить',
    },
    deliveryDate: {
      label: 'Интервал поставки',
    },
    commentModal: {
      title: 'Комментарий',
      placeholder: {
        title: 'Причина редактирования',
        notNecessary: 'Причина редактирования (опционально)',
      },
      saveButton: 'Сохранить',
    },
    rejectButton: {
      request: 'Отменить заявку',
      deal: 'Отменить сделку',
      proposal: 'Отменить предложение',
    },
    rejectingOrderModal: {
      title: {
        request: 'Отменить заявку?',
        deal: 'Отменить сделку?',
        proposal: 'Отменить предложение?',
      },
      backButton: {
        text: 'Нет, вернуться',
      },
      rejectButton: {
        text: 'Да, отменить',
      },
      error: 'Не удалось отменить заказ',
    },
    statusComment: {
      title: {
        request: 'Отмена заявки',
        deal: 'Отмена сделки',
        proposal: 'Отмена предложения',
      },
      comment: 'Причина отмены',
    },
    multirequestConfirmationModal: {
      title: 'Принять это предложение?',
      description: 'Предложения других покупателей по вашей заявке будут автоматически отменены',
      button: {
        confirm: 'Да, принять',
        cancel: 'Отмена',
      },
    },
  },
  harvestSaleMassage: {
    title: {
      request: 'Заявка {id} от {date}',
      deal: 'Сделка {id} от {date}',
      proposal: 'Предложение {id} от {date}',
    },
  },
  deliveryBasisId: 'Базис доставки',
  transportType: 'Вид транспорта:',
  elevatorId: 'Элеватор',
  paymentType: 'Вариант оплаты',
  deliveryRange: 'Интервал поставки',
  discount: 'Скидка',
  prepaymentPercentage: 'Процент предоплаты',
  transportTypeOptions: {
    UNDEFINED: 'Не определено',
    AUTOMOBILE: 'Автомобильный',
    RAILWAY: 'Железнодорожный',
  },
  volume: 'Объём',
  price: 'Цена',
  storeId: 'Адрес хозяйства',
  discountData: 'Скидка',
  requestDiscountData: 'Скидка',
  changeType: 'тип изменения',
}
