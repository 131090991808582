import type { QueryKey, UseQueryResult, UseQueryOptions, QueryOptions } from "@tanstack/react-query";
import { useQuery } from "@tanstack/react-query";
import client from "@/api/client/axios";
import type { GetMainPageBannersQueryResponse, GetMainPageBannersQueryParams, GetMainPageBanners400, GetMainPageBanners500 } from "../types/GetMainPageBanners";

export const getMainPageBannersQueryKey = (params?: GetMainPageBannersQueryParams) =>     [{url:`/api/mainPage/banners`},...(params ? [params] : [])] as const;
      export function getMainPageBannersQueryOptions <TData = GetMainPageBannersQueryResponse, TError = GetMainPageBanners400 | GetMainPageBanners500>(params?: GetMainPageBannersQueryParams, options: Partial<Parameters<typeof client>[0]> = {}): UseQueryOptions<TData, TError> {
        const queryKey = getMainPageBannersQueryKey(params);

        return {
          queryKey,
          queryFn: () => {
            
            return client<TData, TError>({
              method: "get",
              url: `/api/mainPage/banners`,
              params,
              
              ...options,
            }).then(res => res.data);
          },
        };
      };
      
/**
 * @summary Получение баннеров для главной страницы в соответствии с регионом и типом
 * @link /api/mainPage/banners
 */

export function useGetMainPageBanners <TData = GetMainPageBannersQueryResponse, TError = GetMainPageBanners400 | GetMainPageBanners500>(params?: GetMainPageBannersQueryParams, options: {
          query?: UseQueryOptions<TData, TError>,
          client?: Partial<Parameters<typeof client<TData, TError>>[0]>,
        } = {}): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const { query: queryOptions, client: clientOptions = {} } = options ?? {};
  const queryKey = queryOptions?.queryKey ?? getMainPageBannersQueryKey(params);

  const query = useQuery<TData, TError>({
    ...getMainPageBannersQueryOptions<TData, TError>(params, clientOptions),
    ...queryOptions
  }) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey as QueryKey;

  return query;
};
