import React from 'react'
import { Field } from 'react-final-form'
import { format } from 'date-fns'
import type { FieldValidator } from 'final-form'
import omit from 'lodash/omit'
import { SERVER_DATE_FORMAT } from '@/constants/common'
import { getValidateFunction, isFieldRequired } from '@/utils/validators'
import DatePickerFieldView from './DatePickerFieldView'
import type { IDatePickerProps, TDatePickerViewProps } from './interfaces'
import { useDatePickerFieldProps } from './useDatePickerProps'

const DatePickerInput: React.FC<IDatePickerProps> = props => {
  const { parseDate } = useDatePickerFieldProps(props)
  const { parsingFormat = SERVER_DATE_FORMAT, isRequired, customValidator, isRequiredWithoutSign } = props
  const { defaultValue } = props?.pickerProps || {}

  let value: string | Date | undefined = defaultValue
  if (defaultValue instanceof Date) value = format(defaultValue, parsingFormat)

  const validate = getValidateFunction(() => {
    const validators: FieldValidator<string>[] = []
    if (isRequired || isRequiredWithoutSign) validators.push(isFieldRequired)
    if (customValidator) validators.push(customValidator)

    return validators
  }) as FieldValidator<string | Date | Date[] | string[]>

  return (
    <Field {...omit(props, ['pickerProps', 'rangePickerProps'])} parse={parseDate} defaultValue={value} validate={validate}>
      {(fieldProps: TDatePickerViewProps) => <DatePickerFieldView {...fieldProps} {...props} />}
    </Field>
  )
}
export default React.memo(DatePickerInput)
