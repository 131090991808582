import client from "@/api/client/axios";
import type { ResponseConfig } from "@/api/client/axios";
import type { CreateSaleRequestTemplateMutationRequest, CreateSaleRequestTemplateMutationResponse } from "../../types/CreateSaleRequestTemplate";

/**
     * @summary Создание заявки на закупку
     * @link /api/requests/templates
     */
export async function createSaleRequestTemplate (data: CreateSaleRequestTemplateMutationRequest, options: Partial<Parameters<typeof client>[0]> = {}): Promise<ResponseConfig<CreateSaleRequestTemplateMutationResponse>["data"]> {
    const { data: resData } = await client<CreateSaleRequestTemplateMutationResponse, CreateSaleRequestTemplateMutationRequest>({
        method: "post",
        url: `/api/requests/templates`,
        data,
        ...options
    });
    
    return resData;
};