import find from 'lodash/find'
import merge from 'lodash/merge'
import partition from 'lodash/partition'
import { TemplatesFilterName } from '@/logic/harvestSale/constants'
import { transformIndicatorsToQualityIndicators } from '@/pages/HarvestSaleLots/components/RequestHarvestSaleByTemplateModal/helpers'
import type { RootState } from '@/redux/interfaces'
import type { IUserOrganizationData } from '@/types'
import type { IRequestQualityIndicator, IRequestQualityIndicators, ITemplatesFilter, ITemplatesResponse } from '@/types/HarvestSaleLots'
import { getInitialOrganizationData } from './constants'
import { getDataByName } from './helpers'
import type { TApiUrlName } from './interfaces'

//eslint-disable-next-line @typescript-eslint/no-explicit-any
export const responseMapper: Partial<Record<TApiUrlName, (data: any, state: RootState) => unknown>> = {
  userOrganization: (data: IUserOrganizationData, state): IUserOrganizationData => {
    const previousData = getDataByName<IUserOrganizationData>('userOrganization')(state.data)?.data || {}

    return merge(getInitialOrganizationData(), previousData, data)
  },
  harvestSaleQualityIndicators: (data: IRequestQualityIndicator[]): IRequestQualityIndicators => {
    const { indicators } = data?.[0] || {}

    if (!indicators) {
      return {
        optionalQualityIndicators: [],
        requiredQualityIndicators: [],
      }
    }

    const [requiredQualityIndicators, optionalQualityIndicators] = partition(transformIndicatorsToQualityIndicators(indicators), 'required')

    return { requiredQualityIndicators, optionalQualityIndicators }
  },
  harvestSaleCultureClasses: (data: ITemplatesResponse): ITemplatesFilter | undefined =>
    find(data.filters, { value: TemplatesFilterName.CULTURE_CLASSES }),
}
