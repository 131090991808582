import React, { forwardRef, memo, useCallback, useEffect, useImperativeHandle, useRef, useState } from 'react'
import cn from 'classnames'
import type { IPopoverWithTriggerProps, IPopoverWithTriggerRef } from './types'
import './styles.scss'

const PopoverWithTrigger = forwardRef<IPopoverWithTriggerRef, IPopoverWithTriggerProps>((props, ref) => {
  const { children, trigger, closeOnScroll = true, closeOnClickOutside = true, wrapperClassName, contentClassName } = props
  const [isOpen, setIsOpen] = useState(false)

  const popoverRef = useRef<HTMLDivElement>(null)

  const onOpen = useCallback(() => {
    setIsOpen(true)
  }, [setIsOpen])

  const onClose = useCallback(() => {
    setIsOpen(false)
  }, [setIsOpen])

  useImperativeHandle(
    ref,
    () => ({
      onClose,
      onOpen,
      isOpen,
    }),
    [isOpen, onClose, onOpen],
  )

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (popoverRef.current && !popoverRef.current.contains(event.target as Node) && isOpen && closeOnClickOutside) {
        onClose()
      }
    }

    const handleScroll = () => {
      if (isOpen && closeOnScroll) {
        onClose()
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    window.addEventListener('scroll', handleScroll)

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
      window.removeEventListener('scroll', handleScroll)
    }
  }, [closeOnClickOutside, closeOnScroll, onClose, isOpen])

  return (
    <div className={cn('popover', wrapperClassName)} ref={popoverRef}>
      {trigger}
      {isOpen && <section className={cn('popover__section', contentClassName)}>{children}</section>}
    </div>
  )
})

export default memo(PopoverWithTrigger)
