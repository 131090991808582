import client from "@/api/client/axios";
import type { ResponseConfig } from "@/api/client/axios";
import type { SetFundingRequestStatusMutationResponse, SetFundingRequestStatusPathParams } from "../../types/SetFundingRequestStatus";

/**
     * @summary Обновление статуса заявки на финансирование
     * @link /cc/operators/funding/:id/status/:newStatus
     */
export async function setFundingRequestStatus (id: SetFundingRequestStatusPathParams["id"], newStatus: SetFundingRequestStatusPathParams["newStatus"], options: Partial<Parameters<typeof client>[0]> = {}): Promise<ResponseConfig<SetFundingRequestStatusMutationResponse>["data"]> {
    const { data: resData } = await client<SetFundingRequestStatusMutationResponse>({
        method: "patch",
        url: `/cc/operators/funding/${id}/status/${newStatus}`,
        ...options
    });
    
    return resData;
};