import client from "@/api/client/axios";
import type { ResponseConfig } from "@/api/client/axios";
import type { GetMainPageSaleTemplatesCulturesInfoQueryResponse } from "../../types/GetMainPageSaleTemplatesCulturesInfo";

/**
     * @summary Информации о культурах
     * @link /api/requests/list/main-page-templates-culture-info
     */
export async function getMainPageSaleTemplatesCulturesInfo (options: Partial<Parameters<typeof client>[0]> = {}): Promise<ResponseConfig<GetMainPageSaleTemplatesCulturesInfoQueryResponse>["data"]> {
    const { data: resData } = await client<GetMainPageSaleTemplatesCulturesInfoQueryResponse>({
        method: "get",
        url: `/api/requests/list/main-page-templates-culture-info`,
        ...options
    });
    
    return resData;
};