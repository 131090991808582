import type { UseMutationOptions, UseMutationResult } from "@tanstack/react-query";
import { useMutation } from "@tanstack/react-query";
import client from "@/api/client/axios";
import type { ResponseConfig } from "@/api/client/axios";
import type { DeleteSaleRequestDiscountMutationResponse, DeleteSaleRequestDiscountPathParams, DeleteSaleRequestDiscount400, DeleteSaleRequestDiscount404, DeleteSaleRequestDiscount500 } from "../types/DeleteSaleRequestDiscount";

/**
 * @summary Удаление скидки
 * @link /api/salerequests/:requestId/discounts/:discountId
 */

export function useDeleteSaleRequestDiscount <TData = DeleteSaleRequestDiscountMutationResponse, TError = DeleteSaleRequestDiscount400 | DeleteSaleRequestDiscount404 | DeleteSaleRequestDiscount500>(requestId: DeleteSaleRequestDiscountPathParams["requestId"], discountId: DeleteSaleRequestDiscountPathParams["discountId"], options: {
          mutation?: UseMutationOptions<ResponseConfig<TData>, TError, void>,
          client?: Partial<Parameters<typeof client<TData, TError, void>>[0]>,
      } = {}): UseMutationResult<ResponseConfig<TData>, TError, void> {
  const { mutation: mutationOptions, client: clientOptions = {} } = options ?? {};

  return useMutation<ResponseConfig<TData>, TError, void>({
    mutationFn: () => {
      
      return client<TData, TError, void>({
        method: "delete",
        url: `/api/salerequests/${requestId}/discounts/${discountId}`,
        
        
        
        ...clientOptions
      });
    },
    ...mutationOptions
  });
};
