import type { FC } from 'react'
import React, { memo } from 'react'
import classNames from 'classnames'
import { CollapsingBlock } from '@/components/ui'
import type { IFaqProps } from './types'
import './styles.scss'

const Faq: FC<IFaqProps> = props => {
  const { title, titleClassName, sectionId, className, items, onExpand } = props

  return (
    <section className={classNames('faqComponent', className)} id={sectionId}>
      {title && <h2 className={classNames('h3-only-md faqComponent__title', titleClassName)}>{title}</h2>}
      <div className="faqComponent__block">
        {items.map(({ question, answer }) => (
          <CollapsingBlock headingClass="h4 faqComponent__item" title={question} onExpand={() => onExpand?.(question)} key={question}>
            <div className="faqComponent__answer">{answer}</div>
          </CollapsingBlock>
        ))}
      </div>
    </section>
  )
}

export default memo(Faq)
