import get from 'lodash/get'
import { getHarvestSaleRequest } from '@/logic/harvestSaleRequest/reducer'
import { getAgrarianOrderVersion } from '@/logic/order/reducer'
import type { RootState } from '@/redux/interfaces'
import type { IDocumentType, TDocumentActions } from '@/types/Documents'
import { EDocumentEntityType, EDocumentTypeId } from '@/types/Documents'

export type TEntityVersionGetter = (state: RootState) => string | undefined

export const EntityVersionGetter: Record<EDocumentEntityType, TEntityVersionGetter | undefined> = {
  [EDocumentEntityType.ORDERS]: getAgrarianOrderVersion,
  [EDocumentEntityType.REQUESTS]: state => getHarvestSaleRequest(state)?.updateDate,
  [EDocumentEntityType.MY_DOCUMENTS]: undefined,
}

export const canMakeAction = (actionName: string, categories: string[], actions?: TDocumentActions) =>
  ((get(actions, `${actionName}.allowedDocumentCategories`) || []) as string[]).some(value => categories.includes(value))

export const pickFilteredOutTypes = (types: IDocumentType[]) => types.filter(type => type.id === EDocumentTypeId.COMMON)
