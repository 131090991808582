import client from "@/api/client/axios";
import type { ResponseConfig } from "@/api/client/axios";
import type { GetRequestChatMessagesV1QueryResponse, GetRequestChatMessagesV1QueryParams } from "../../types/GetRequestChatMessagesV1";

/**
     * @summary Получить сообщения
     * @link /api/monolith/v1/chat
     */
export async function getRequestChatMessagesV1 (params: GetRequestChatMessagesV1QueryParams, options: Partial<Parameters<typeof client>[0]> = {}): Promise<ResponseConfig<GetRequestChatMessagesV1QueryResponse>["data"]> {
    const { data: resData } = await client<GetRequestChatMessagesV1QueryResponse>({
        method: "get",
        url: `/api/monolith/v1/chat`,
        params,
        ...options
    });
    
    return resData;
};