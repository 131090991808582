import client from "@/api/client/axios";
import type { ResponseConfig } from "@/api/client/axios";
import type { GetElevatorsQueryResponse, GetElevatorsQueryParams } from "../../types/GetElevators";

/**
     * @summary Запрос на получение элеваторов
     * @link /api/elevators
     */
export async function getElevators (params?: GetElevatorsQueryParams, options: Partial<Parameters<typeof client>[0]> = {}): Promise<ResponseConfig<GetElevatorsQueryResponse>["data"]> {
    const { data: resData } = await client<GetElevatorsQueryResponse>({
        method: "get",
        url: `/api/elevators`,
        params,
        ...options
    });
    
    return resData;
};