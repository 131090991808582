import type { UseMutationOptions, UseMutationResult } from "@tanstack/react-query";
import { useMutation } from "@tanstack/react-query";
import client from "@/api/client/axios";
import type { ResponseConfig } from "@/api/client/axios";
import type { CreatePublicationMutationRequest, CreatePublicationMutationResponse } from "../types/CreatePublication";

/**
 * @link /api/ms-content-infoportal-backend/v1/admin/publications
 */

export function useCreatePublication <TData = CreatePublicationMutationResponse, TError = unknown, TVariables = CreatePublicationMutationRequest>(options: {
          mutation?: UseMutationOptions<ResponseConfig<TData>, TError, TVariables>,
          client?: Partial<Parameters<typeof client<TData, TError, TVariables>>[0]>,
      } = {}): UseMutationResult<ResponseConfig<TData>, TError, TVariables> {
  const { mutation: mutationOptions, client: clientOptions = {} } = options ?? {};

  return useMutation<ResponseConfig<TData>, TError, TVariables>({
    mutationFn: (data) => {
      
      return client<TData, TError, TVariables>({
        method: "post",
        url: `/api/ms-content-infoportal-backend/v1/admin/publications`,
        data,
        
        
        ...clientOptions
      });
    },
    ...mutationOptions
  });
};
