import { requestData } from '@/api/request'
import { EHarvestSaleMultiLotsApiUrl } from '@/api/urls'
import type { IHistoryItem } from '@/types/History'
import { createRouterLink } from '@/utils/url'

/** Сервис работы с историей заявки на продажу зерна outputs */
export default class SaleMultiRequestHistoryService {
  /**
   * Получение истории заявки
   * @param {number} id - идентификатор заявки
   */
  getHistory = (id: number) =>
    requestData<IHistoryItem[]>({
      url: createRouterLink(EHarvestSaleMultiLotsApiUrl.saleRequestHistory, { requestId: id }),
      method: 'GET',
    })
}
