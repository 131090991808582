import type { QueryKey, UseQueryResult, UseQueryOptions, QueryOptions } from "@tanstack/react-query";
import { useQuery } from "@tanstack/react-query";
import client from "@/api/client/axios";
import type { GetPublicationById1QueryResponse, GetPublicationById1PathParams } from "../types/GetPublicationById1";

export const getPublicationById_1QueryKey = (publicationId: GetPublicationById1PathParams["publicationId"]) =>     [{url:`/api/ms-content-infoportal-backend/v1/admin/publications/${publicationId}`,params:{publicationId:publicationId}}] as const;
      export function getPublicationById_1QueryOptions <TData = GetPublicationById1QueryResponse, TError = unknown>(publicationId: GetPublicationById1PathParams["publicationId"], options: Partial<Parameters<typeof client>[0]> = {}): UseQueryOptions<TData, TError> {
        const queryKey = getPublicationById_1QueryKey(publicationId, );

        return {
          queryKey,
          queryFn: () => {
            
            return client<TData, TError>({
              method: "get",
              url: `/api/ms-content-infoportal-backend/v1/admin/publications/${publicationId}`,
              
              
              ...options,
            }).then(res => res.data);
          },
        };
      };
      
/**
 * @link /api/ms-content-infoportal-backend/v1/admin/publications/:publicationId
 */

export function useGetPublicationById1 <TData = GetPublicationById1QueryResponse, TError = unknown>(publicationId: GetPublicationById1PathParams["publicationId"], options: {
          query?: UseQueryOptions<TData, TError>,
          client?: Partial<Parameters<typeof client<TData, TError>>[0]>,
        } = {}): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const { query: queryOptions, client: clientOptions = {} } = options ?? {};
  const queryKey = queryOptions?.queryKey ?? getPublicationById_1QueryKey(publicationId, );

  const query = useQuery<TData, TError>({
    ...getPublicationById_1QueryOptions<TData, TError>(publicationId, clientOptions),
    ...queryOptions
  }) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey as QueryKey;

  return query;
};
