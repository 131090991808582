import client from "@/api/client/axios";
import type { ResponseConfig } from "@/api/client/axios";
import type { AddOrderChatMessageMutationRequest, AddOrderChatMessageMutationResponse, AddOrderChatMessagePathParams } from "../../types/AddOrderChatMessage";

/**
     * @summary Добавить новое сообщение в чат
     * @link /api/orders/:orderId/chat
     */
export async function addOrderChatMessage (orderId: AddOrderChatMessagePathParams["orderId"], data: AddOrderChatMessageMutationRequest, options: Partial<Parameters<typeof client>[0]> = {}): Promise<ResponseConfig<AddOrderChatMessageMutationResponse>["data"]> {
    const { data: resData } = await client<AddOrderChatMessageMutationResponse, AddOrderChatMessageMutationRequest>({
        method: "post",
        url: `/api/orders/${orderId}/chat`,
        data,
        ...options
    });
    
    return resData;
};