import type { ChangeEventHandler } from 'react'
import React, { forwardRef, memo, useCallback, useEffect, useImperativeHandle, useRef, useState } from 'react'
import { EIconName, EIconSize, Icon } from '@frontend/pole-ui/lib/components/Icon'
import { useIsomorphicLayoutEffect } from '@frontend/pole-ui/lib/hooks/useIsomorphicLayoutEffect'
import classNames from 'classnames'
import isUndefined from 'lodash/isUndefined'
import { Button } from '@/components/ui'
import { numericValueParser } from '@/components/ui/fields/TextInput/helpers'
import { useDeviceType } from '@/utils/hooks/useDeviceType'
import type { IQuantityPickerProps, IQuantityPickerRef } from './interfaces'
import './styles.scss'

const baseButtonClass = 'quantity-picker__button'

/** Компонент - счётчик
 * @param onChange - функция, получающая новое значение счётчика при его изменении
 * @param step - шаг счётчика
 * @param defaultQuantity - начальное значение счётчика
 * @param withNegativeQuantity - флаг, возможность ввода отрицательных значений
 * @param className - передаваемые имена классов
 * @param minQuantity - минимальное возможное значение
 * @param maxQuantity - максимальное возможное значение
 * @param unit - единицы изменения
 */
const QuantityPicker = forwardRef<IQuantityPickerRef, IQuantityPickerProps>((props, forwardedRef) => {
  const {
    onChange,
    step = 1,
    defaultQuantity = 0,
    withNegativeQuantity,
    className,
    minQuantity = 0,
    maxQuantity,
    unit,
    isDisabled = false,
  } = props

  const [quantity, setQuantity] = useState(() => (withNegativeQuantity ? defaultQuantity : Math.max(defaultQuantity, minQuantity)))

  useEffect(() => {
    setQuantity(withNegativeQuantity ? defaultQuantity : Math.max(defaultQuantity, minQuantity))
  }, [withNegativeQuantity, defaultQuantity, minQuantity])

  const [isUserTypingQuantity, setTypingState] = useState(false)
  const [controlledValue, setControlledValue] = useState<string | undefined>()
  const { isMobile } = useDeviceType()
  const inputRef = useRef<HTMLInputElement>(null)
  const digitsAfterDot = step.toString().split('.')[1]?.length || 0

  /**
   * Функция, округляющая число до заданного количества знаков после запятой
   * @param num - текущее число
   * @param afterDot - количество знаков после запятой
   */
  const roundToTenths = useCallback(
    (num: number) => {
      if (digitsAfterDot === 0) return Math.round(num)
      const multiplier = 10 ** digitsAfterDot

      return Math.round(num * multiplier) / multiplier
    },
    [digitsAfterDot],
  )

  /**
   * Функция, контролирующая нахождение текущего значения в заданном диапазоне
   * @param num - текущее значение
   * @param min - минимальное возможное значение
   * @param max - максимальное возможное значение
   */
  const minMaxValueControl = useCallback((num: number, min: number, max: number | undefined): number => {
    if (!isUndefined(min) && isUndefined(max)) return Math.max(num, min)

    if (!isUndefined(max)) {
      if (num >= max) return max
      if (num <= min) return min
    }

    return num
  }, [])

  /**
   * Хэндлер нажатия на кнопки + и -
   * @param newQuantity - новое значение для инпута
   */
  const onChangeQuantity = useCallback(
    (newQuantity: number) => {
      const updatedQuantity = roundToTenths(minMaxValueControl(newQuantity, minQuantity, maxQuantity))
      setQuantity(updatedQuantity)
      setTypingState(false)
      onChange(updatedQuantity)
    },
    [roundToTenths, minMaxValueControl, minQuantity, maxQuantity, onChange],
  )

  /**
   * Хендлер ручного ввода в инпут
   * @param target - событие ввода в input
   */
  const onInputChange: ChangeEventHandler<HTMLInputElement> = ({ target }) => {
    const inputValue = target.value
    const shouldSetValue = inputValue !== '' && !isNaN(Number(inputValue)) && inputValue[inputValue.length - 1] !== '.'
    const value = roundToTenths(minMaxValueControl(Number(numericValueParser(inputValue)), minQuantity, maxQuantity))

    if (shouldSetValue) {
      setQuantity(value)
      onChange(value)
      setTypingState(false)
    } else {
      setTypingState(true)
      setControlledValue(inputValue.replace(/[^(\d|.]/g, ''))
    }
  }

  const onDownClick = useCallback(() => {
    const nextQuantity = quantity - step
    if (nextQuantity < 0 && !withNegativeQuantity) onChangeQuantity(0)
    else onChangeQuantity(nextQuantity)
  }, [quantity, step, withNegativeQuantity, onChangeQuantity])

  const onUpClick = useCallback(() => {
    onChangeQuantity(quantity + step)
  }, [onChangeQuantity, quantity, step])

  const isInputFocused = useRef(false)
  const isInputWasFocusedWhenDisabled = useRef(false)

  useIsomorphicLayoutEffect(() => {
    if (isDisabled) {
      isInputWasFocusedWhenDisabled.current = isInputFocused.current
    } else if (isInputWasFocusedWhenDisabled.current) {
      inputRef.current?.focus()
    }
  }, [isDisabled])

  useImperativeHandle(
    forwardedRef,
    () => ({
      onFocus: () => {
        inputRef.current?.focus()
      },
    }),
    [],
  )

  return (
    <div className={classNames('quantity-picker', isDisabled && 'quantity-picker--disabled', className)}>
      <Button onClick={onDownClick} className={`${baseButtonClass} ${baseButtonClass}_plus`} isUnstyled>
        <Icon name={EIconName.Minus} size={isMobile ? EIconSize.XS : EIconSize.M} />
      </Button>
      <div>
        <input
          ref={inputRef}
          disabled={isDisabled}
          value={isUserTypingQuantity ? controlledValue : numericValueParser(String(quantity))}
          className={classNames('quantity-picker__value', { 'quantity-picker--cursor': isDisabled })}
          onChange={onInputChange}
          onFocus={() => (isInputFocused.current = true)}
          onBlur={() => (isInputFocused.current = false)}
        />
        {unit && <span>{unit}</span>}
      </div>
      <Button onClick={onUpClick} className={`${baseButtonClass} ${baseButtonClass}_plus`} isUnstyled>
        <Icon name={EIconName.Plus} size={isMobile ? EIconSize.XS : EIconSize.M} />
      </Button>
    </div>
  )
})

export default memo(QuantityPicker)
