export enum EAgriculturalProductCategoryId {
  fertilizers = 1,
  seeds,
  remedies,
  fuel,
  agrotechnics,
  spares,

  // Внутренний айдишник приложения. Общая страница выдачи, как отдельная категори каталога
  SEARCH = 'search',
}
