import client from "@/api/client/axios";
import type { ResponseConfig } from "@/api/client/axios";
import type { UpdateSaleRequestTemplateStateMutationRequest, UpdateSaleRequestTemplateStateMutationResponse, UpdateSaleRequestTemplateStatePathParams } from "../../types/UpdateSaleRequestTemplateState";

/**
     * @summary Изменение состояния шаблона заявки ACTIVE/INACTIVE
     * @link /api/requests/templates/:templateId
     */
export async function updateSaleRequestTemplateState (templateId: UpdateSaleRequestTemplateStatePathParams["templateId"], data: UpdateSaleRequestTemplateStateMutationRequest, options: Partial<Parameters<typeof client>[0]> = {}): Promise<ResponseConfig<UpdateSaleRequestTemplateStateMutationResponse>["data"]> {
    const { data: resData } = await client<UpdateSaleRequestTemplateStateMutationResponse, UpdateSaleRequestTemplateStateMutationRequest>({
        method: "patch",
        url: `/api/requests/templates/${templateId}`,
        data,
        ...options
    });
    
    return resData;
};