export const DADATA_FORM_FIELD_NAME = '__shouldShowEmptyDadataResponse__'

export enum ESberCookieNonce {
  SBER_NONCE = 'sber_nonce',
}

export const SCOPE_TEST = [
  'openid',
  'PAY_DOC_RU_INVOICE_ANY',
  'PAY_DOC_RU_INVOICE',
  'PAY_DOC_RU',
  'CREDIT_REQUEST',
  'GET_CREDIT_OFFERS',
  'OrgName',
  'summOfferSmartCredit',
  'orgBusinessSegment',
  'userCryptoType',
  'sub',
  'name',
  'inn',
  'email',
  'phone_number',
  'orgKpp',
  'orgFullName',
  'HashOrgId',
  'offerSmartCredit',
  'buyOnCreditMmb',
  'orgLawForm',
  'orgLawFormShort',
  'hasActiveCreditLine',
  'creditLineAvailableSum',
]

export const SCOPE_PROD = [
  'openid',
  'CREDIT_REQUEST',
  'GET_CREDIT_OFFERS',
  'PAY_DOC_RU_INVOICE_ANY',
  'buyOnCreditMmb',
  'creditLineAvailableSum',
  'hasActiveCreditLine',
  'orgLawFormShort',
  'userCryptoType',
  'aud',
  'iss',
  'inn',
  'sub',
  'orgFullName',
  'email',
  'phone_number',
  'HashOrgId',
  'orgKpp',
]
