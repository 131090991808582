import React from 'react'
import { Icon } from '@frontend/pole-ui/lib/components/Icon'
import classNames from 'classnames'
import { InjectHtml } from '..'
import type { ITextWithIconProps } from './interfaces'
import './styles.scss'

const TextWithIcon: React.FC<ITextWithIconProps> = props => {
  const {
    children,
    textId,
    iconProps,
    className,
    textValues,
    textModifier = 'small',
    text,
    textClassName,
    animation,
    iconClassName,
  } = props

  return (
    <div className={classNames('text-with-icon', className)} {...animation}>
      <span className={classNames('text-with-icon__icon row', iconClassName)}>
        <Icon {...iconProps} />
      </span>
      <span className={classNames(textClassName, `text-with-icon__text text_${textModifier}-from-sm`)}>
        {text || (textId && <InjectHtml textId={textId} textValues={textValues} TagName="span" />)}
        {children}
      </span>
    </div>
  )
}

export default TextWithIcon
