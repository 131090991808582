import client from "@/api/client/axios";
import type { ResponseConfig } from "@/api/client/axios";
import type { GetConsultBannerQueryResponse } from "../../types/GetConsultBanner";

/**
     * @summary Получение баннера формы консультации
     * @link /api/mainPage/consultBanner
     */
export async function getConsultBanner (options: Partial<Parameters<typeof client>[0]> = {}): Promise<ResponseConfig<GetConsultBannerQueryResponse>["data"]> {
    const { data: resData } = await client<GetConsultBannerQueryResponse>({
        method: "get",
        url: `/api/mainPage/consultBanner`,
        ...options
    });
    
    return resData;
};