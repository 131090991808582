import { CanceledError } from 'axios'
import { ExecutionEnvironment } from '@/utils/ExecutionEnvironment'
import { createAxiosClient } from './axiosFactory'

const { isSsr } = ExecutionEnvironment
const { NODE_ENV } = process.env
const IS_DEV = NODE_ENV === 'development'

const ssrUrl = IS_DEV
  ? `http://${process.env.CUSTOM_ENV}-ms-inputs-catalog-backend.dev.poletech.io/api`
  : `http://ms-inputs-catalog-backend/api`

const baseURL = isSsr ? ssrUrl : '/api/ms-inputs-catalog-backend'

export const catalogAxiosClient = createAxiosClient({
  baseURL,
})

export * from './types'
export { CanceledError }
export default catalogAxiosClient
