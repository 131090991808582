import type { TMethod } from '@/api/interfaces'
import { requestData } from '@/api/request'
import { EHarvestSaleMultiLotsApiUrl } from '@/api/urls'
import type { ICreateHarvestSaleRequestData } from '@/logic/harvestSaleRequest/interfaces'
import type { IAgrarianSaleChangingRequestBody } from '@/logic/profile/interface'
import type { TCreateHarvestSaleResponse } from '@/services/HarvestSaleRequestService/interfaces'
import type { IHarvestSaleRequest } from '@/types/HarvestSaleRequest'
import type { TEntityId } from '@/types/TEntityId'
import { createRouterLink, getUrlWithQuery } from '@/utils/url'

export interface IChangeHarvestSaleMultiRequestStatus {
  requestId: TEntityId
  version: string
  statusComment?: string
  organizationBankAccounts?: string[]
}

export interface IChangeHarvestSaleMultiRequest {
  changedValues: IAgrarianSaleChangingRequestBody
  requestId: TEntityId
  version: string
  statusComment?: string
}

export interface IDeleteHarvestSaleMultiRequestPayload {
  version: string
  requestId: TEntityId
  statusComment?: string
}

/** Сервис работы с Мультизаявкой на продажу зерна outputs */
export default class HarvestSaleMultiRequestService {
  private createRequestAbortController: AbortController | null = null
  private deleteRequestAbortController: AbortController | null = null

  /**
   * Создание заказа
   * @param {Object} parameters - информация для создания заявки
   */
  createHarvestSaleRequest = (parameters: ICreateHarvestSaleRequestData) => {
    if (this.createRequestAbortController) {
      this.createRequestAbortController.abort()
    }

    this.createRequestAbortController = new AbortController()

    const options = {
      url: createRouterLink(EHarvestSaleMultiLotsApiUrl.saleRequest, {}),
      method: 'POST' as TMethod,
      body: parameters,
      signal: this.createRequestAbortController.signal,
    }

    return requestData<TCreateHarvestSaleResponse>(options).then(response => {
      this.createRequestAbortController = null

      return response
    })
  }

  /**
   * Изменение статуса заявки
   * @param {Object} options
   * @prop {number} options.orderId - идентификатор заявки
   * @prop {string} options.version - версия заявки, updateDate
   */
  changeHarvestSaleRequestStatus = ({
    requestId,
    version,
    statusComment,
    organizationBankAccounts,
  }: IChangeHarvestSaleMultiRequestStatus) => {
    const queryParams: { version: string; statusComment?: string } = { version }

    if (statusComment) {
      queryParams.statusComment = statusComment
    }

    return requestData<IHarvestSaleRequest>({
      url: getUrlWithQuery(createRouterLink(EHarvestSaleMultiLotsApiUrl.saleRequestNextStatus, { requestId }), queryParams),
      method: 'POST',
      body: { organizationBankAccounts },
    })
  }

  /**
   * Редактирование предзаявки
   * @param {Object} options
   * @prop {number} options.requestId - идентификатор заявки
   * @prop {string} options.version - версия заявки, updateDate
   * @prop {string} options.statusComment - комментарий
   * @prop {IAgrarianSaleChangingMultiRequestBody} options.changedValues - изменяемые значения
   */
  changeHarvestSaleRequest = ({ requestId, version, statusComment, changedValues }: IChangeHarvestSaleMultiRequest) => {
    const queryParams: { version: string; statusComment?: string } = { version }

    if (statusComment) {
      queryParams.statusComment = statusComment
    }

    return requestData<IHarvestSaleRequest>({
      url: getUrlWithQuery(createRouterLink(EHarvestSaleMultiLotsApiUrl.saleRequestWithId, { requestId }), queryParams),
      method: 'PATCH',
      body: changedValues,
    })
  }

  /**
   * Отмена мультизаявки
   * @param {Object} options
   * @prop {number} options.requestId - идентификатор заявки
   * @prop {string} options.version - версия заявки, updateDate
   * @prop {string} options.statusComment - комментарий
   */
  deleteHarvestSaleRequest = ({ requestId, version, statusComment }: IDeleteHarvestSaleMultiRequestPayload) => {
    if (this.deleteRequestAbortController) {
      this.deleteRequestAbortController.abort()
    }
    this.deleteRequestAbortController = new AbortController()

    const queryParams: { version: string; statusComment?: string } = { version }

    if (statusComment) {
      queryParams.statusComment = statusComment
    }

    return requestData<IHarvestSaleRequest>({
      url: getUrlWithQuery(createRouterLink(EHarvestSaleMultiLotsApiUrl.saleRequestWithId, { requestId }), queryParams),
      method: 'DELETE',
      signal: this.deleteRequestAbortController.signal,
    }).then(response => {
      this.deleteRequestAbortController = null

      return response
    })
  }
}
