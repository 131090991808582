import React, { memo, useEffect } from 'react'
import { useIntl } from 'react-intl'
import { Route } from 'react-router'
import isEqual from 'lodash/isEqual'
import omit from 'lodash/omit'
import type { IAppRoute } from '@/App/components/AppAccessControlProvider/types'
import { EAccessControlValue } from '@/components/composed/AccessControlProvider/types'
import { Button, ErrorBoundary, MetaInfo, PageNoticeText } from '@/components/ui'
import { useAuthenticateUser } from '@/components/ui/AuthButton/hooks'
import { EMetaInfoType } from '@/components/ui/MetaInfo/interfaces'
import AppError from '@/pages/errors/AppError'
import { Redirect404 } from '@/router/components'
import { useAppAccessControl } from '../AppAccessControlProvider'
import type { IRoutePageProps, TAppRouteProps } from './interfaces'

const RoutePage: React.FC<TAppRouteProps & IRoutePageProps> = props => {
  const { metaInfo: routeMetaInfo = {}, component: Page, shouldBeAuthenticated, routeProps, layout, accessControlKey } = props
  const { isAuthenticated, openAuthModal } = useAuthenticateUser()
  const { resolve } = useAppAccessControl()
  const shouldShowAuthPage = shouldBeAuthenticated && !isAuthenticated
  const { formatMessage } = useIntl()
  const { titleId, descriptionId } = routeMetaInfo

  useEffect(() => {
    if (shouldShowAuthPage) openAuthModal()
    //eslint-disable-next-line
  }, [shouldShowAuthPage])

  if (shouldShowAuthPage) {
    return (
      <PageNoticeText textId="authPage.authRestriction.text">
        <Button onClick={() => openAuthModal()} modifiers={['as-link']} textId="authPage.loginButton.text" />
      </PageNoticeText>
    )
  }

  if (resolve(accessControlKey) !== EAccessControlValue.VISIBLE) {
    return <Redirect404 />
  }

  const metaInfo = {
    type: EMetaInfoType.WEBSITE,
    ...omit(routeMetaInfo, ['titleId', 'descriptionId']),
    ...(titleId && { title: formatMessage({ id: titleId }) }),
    ...(descriptionId && { description: formatMessage({ id: descriptionId }) }),
  }

  return (
    <ErrorBoundary fallBack={(error: Error) => <AppError errorText={error} />}>
      <MetaInfo metaInfo={metaInfo} />
      <Page {...{ ...routeProps, layout }} />
    </ErrorBoundary>
  )
}

const AppRoute = (props: IAppRoute) => (
  <Route
    {...omit(props, ['asyncThunk', 'component'])}
    render={routeProps => <RoutePage {...omit(props, ['key'])} routeProps={routeProps} />}
  />
)

export default memo(AppRoute, isEqual)
