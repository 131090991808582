import client from "@/api/client/axios";
import type { ResponseConfig } from "@/api/client/axios";
import type { GetMainPublicationQueryResponse } from "../../types/GetMainPublication";

/**
     * @link /api/ms-content-infoportal-backend/v1/publications/main
     */
export async function getMainPublication (options: Partial<Parameters<typeof client>[0]> = {}): Promise<ResponseConfig<GetMainPublicationQueryResponse>["data"]> {
    const { data: resData } = await client<GetMainPublicationQueryResponse>({
        method: "get",
        url: `/api/ms-content-infoportal-backend/v1/publications/main`,
        ...options
    });
    
    return resData;
};