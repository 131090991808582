import type { QueryKey, UseQueryResult, UseQueryOptions, QueryOptions } from "@tanstack/react-query";
import { useQuery } from "@tanstack/react-query";
import client from "@/api/client/axios";
import type { GetSaleRequestShippingsTotalQueryResponse, GetSaleRequestShippingsTotalPathParams, GetSaleRequestShippingsTotal404, GetSaleRequestShippingsTotal500 } from "../types/GetSaleRequestShippingsTotal";

export const getSaleRequestShippingsTotalQueryKey = (requestId: GetSaleRequestShippingsTotalPathParams["requestId"]) =>     [{url:`/api/salerequests/${requestId}/shippings/total`,params:{requestId:requestId}}] as const;
      export function getSaleRequestShippingsTotalQueryOptions <TData = GetSaleRequestShippingsTotalQueryResponse, TError = GetSaleRequestShippingsTotal404 | GetSaleRequestShippingsTotal500>(requestId: GetSaleRequestShippingsTotalPathParams["requestId"], options: Partial<Parameters<typeof client>[0]> = {}): UseQueryOptions<TData, TError> {
        const queryKey = getSaleRequestShippingsTotalQueryKey(requestId, );

        return {
          queryKey,
          queryFn: () => {
            
            return client<TData, TError>({
              method: "get",
              url: `/api/salerequests/${requestId}/shippings/total`,
              
              
              ...options,
            }).then(res => res.data);
          },
        };
      };
      
/**
 * @summary Запрос на получение общей информации об отгрузке
 * @link /api/salerequests/:requestId/shippings/total
 */

export function useGetSaleRequestShippingsTotal <TData = GetSaleRequestShippingsTotalQueryResponse, TError = GetSaleRequestShippingsTotal404 | GetSaleRequestShippingsTotal500>(requestId: GetSaleRequestShippingsTotalPathParams["requestId"], options: {
          query?: UseQueryOptions<TData, TError>,
          client?: Partial<Parameters<typeof client<TData, TError>>[0]>,
        } = {}): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const { query: queryOptions, client: clientOptions = {} } = options ?? {};
  const queryKey = queryOptions?.queryKey ?? getSaleRequestShippingsTotalQueryKey(requestId, );

  const query = useQuery<TData, TError>({
    ...getSaleRequestShippingsTotalQueryOptions<TData, TError>(requestId, clientOptions),
    ...queryOptions
  }) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey as QueryKey;

  return query;
};
