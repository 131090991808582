export const messages = {
  otherProduct: {
    breadcrumb: {
      text: 'Назад',
    },
  },
  orderHeader: {
    seller: 'Продавец {sellingOrganizationName}',
    buyer: 'Покупатель {buyerName}',
    multiOrder: {
      title: 'МУЛЬТИЗАЯВКА',
      hint: {
        title: 'Заявка отправлена нескольким поставщикам',
        message: 'Поспешите сделать выгодное предложение',
      },
    },
  },
}
