import type { TTextInputType } from '@frontend/pole-ui/lib/components/TextInput'
import { normalizeNumber, normalizePhoneNumber } from './transformers'
import type { TTextInputTransformer } from './types'

export const PRICE_MIN_VALUE = 0
export const PRICE_PRECISION_DEFAULT = 2
export const NUMBER_FLOAT_PRECISION_DEFAULT = 3

export const TRANSFORMERS_BY_TEXT_INPUT_TYPE: Partial<Record<TTextInputType, TTextInputTransformer>> = {
  tel: normalizePhoneNumber,
  num: normalizeNumber,
  price: normalizeNumber,
  floatPrice: normalizeNumber,
  numberFloat: normalizeNumber,
}

export const PRICE_INPUT_TYPES = ['price', 'floatPrice'] satisfies ReadonlyArray<TTextInputType>

export const NUMBER_INPUT_TYPES = ['num', 'numberFloat'] satisfies ReadonlyArray<TTextInputType>

export const PHONE_NUMBER_FIELD_PREFIX = '7'
