import client from "@/api/client/axios";
import type { ResponseConfig } from "@/api/client/axios";
import type { GetMenuItemsListQueryResponse } from "../../types/GetMenuItemsList";

/**
     * @summary Получение списка меню
     * @link /api/catalog/mainPage/menuitems
     */
export async function getMenuItemsList (options: Partial<Parameters<typeof client>[0]> = {}): Promise<ResponseConfig<GetMenuItemsListQueryResponse>["data"]> {
    const { data: resData } = await client<GetMenuItemsListQueryResponse>({
        method: "get",
        url: `/api/catalog/mainPage/menuitems`,
        ...options
    });
    
    return resData;
};