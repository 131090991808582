import { CanceledError } from 'axios'
import { ExecutionEnvironment } from '@/utils/ExecutionEnvironment'
import { createAxiosClient } from './axiosFactory'

const { isSsr } = ExecutionEnvironment
const { NODE_ENV } = process.env
const IS_DEV = NODE_ENV !== 'production'
const ssrUrl = isSsr && IS_DEV ? `http://${process.env.CUSTOM_ENV}-auction-backend.dev.poletech.io/api` : `http://auction-backend/api`

const baseURL = isSsr ? ssrUrl : '/api/ms-outputs-auction-backend'
export const auctionAxiosClient = createAxiosClient({
  baseURL,
})

export * from './types'
export { CanceledError }
export default auctionAxiosClient
