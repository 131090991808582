import React, { useReducer } from 'react'
import { EIconName, Icon } from '@frontend/pole-ui/lib/components/Icon'
import classNames from 'classnames'
import { Button } from '@/components/ui'
import { Fading } from '@/components/ui/animations'
import { TextInput } from '@/components/ui/fields'
import type { ISearchInputProps } from './interfaces'
import './styles.scss'

const SearchInput: React.FC<ISearchInputProps> = props => {
  const { textInputProps, className, iconPosition, iconClass, iconProps, onClear, shouldShowClearButton } = props
  const [key, setNewKey] = useReducer(state => state + 1, 0)

  return (
    <div className={classNames('search-input', className, { [`search-input_${iconPosition}`]: iconPosition })}>
      <span className={classNames('search-input__icon', { [`search-input__icon_${iconPosition}`]: iconPosition }, iconClass)}>
        <Icon name={EIconName.Search} {...iconProps} />
      </span>
      <TextInput {...textInputProps} isSearchInput key={key} />
      <Fading isVisible={Boolean(shouldShowClearButton)}>
        <Button
          isUnstyled
          onClick={() => {
            onClear?.()
            setTimeout(setNewKey)
          }}
          className="search-input__clear-button"
          iconProps={{ name: EIconName.Exit }}
        />
      </Fading>
    </div>
  )
}

export default SearchInput
