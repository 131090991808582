import React from 'react'
import type { FormProps } from 'react-final-form'
import { Form } from 'react-final-form'
import { CheckboxesFilter } from '@/components/composed'
import { pushGtmEventOnChangeFilterFormClick } from '@/logic/metrika/harvest/helpers'
import type { IHandelChangeFilter, TFiltersProps } from './interfaces'

const Filters: React.FC<TFiltersProps & Partial<FormProps>> = props => {
  const {
    filters,
    onChange,
    onSearchFocus,
    onSubmit,
    controlStyle,
    shouldUseSearch,
    showExpandedInPopup,
    userGtmInfo,
    userRegion,
    eventLabel,
  } = props

  const handleChangeFilter = (args: IHandelChangeFilter) => {
    const { event, category, title, payload } = args
    onChange(event, { ...payload, category })
    pushGtmEventOnChangeFilterFormClick(userGtmInfo, userRegion.name, title, payload?.label)
  }

  const checkboxes = filters.map(({ title, options, zeroOptionsMessageId, zeroOptionsMessage, category }) => {
    return (
      <CheckboxesFilter
        key={title}
        title={title}
        onChange={(event, payload) => handleChangeFilter({ event, category, title, payload })}
        onSearchFocus={() => onSearchFocus?.(category)}
        checkboxes={options}
        zeroOptionsMessageId={zeroOptionsMessageId}
        zeroOptionsMessage={zeroOptionsMessage}
        controlStyle={controlStyle}
        shouldUseSearch={shouldUseSearch}
        showExpandedInPopup={showExpandedInPopup}
        userGtmInfo={userGtmInfo}
        userRegion={userRegion?.name}
        eventLabel={eventLabel}
      />
    )
  })
  if (!onSubmit) return <>{checkboxes}</>

  return <Form onSubmit={onSubmit}>{() => checkboxes}</Form>
}

export default Filters
