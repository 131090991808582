import type { IOrganization, IRegion, TRegions } from '.'

export enum EVariablesKind {
  warehouse = 'warehouse',
  station = 'station',
}

export interface IStore {
  id: string
  name?: string
  location: IStoreLocation
  organization: IOrganization
  deliveryRegions: TRegions
}

export interface IStoreLocation {
  address: string
  default: boolean
  id: string
  name?: string
  region: IRegion
  latitude: number
  longitude: number
  updateDate?: string
  creationDate: string
  kind: {
    id: EVariablesKind
    name: string
  }
  fiasID: string
}

export interface ILocationParams {
  regionCode?: number
  address?: string
  longitude?: number
  latitude?: number
  default?: boolean
  kindId?: string
  stationCode?: string
}
export interface IUserStoreUpdatingRequestBody {
  name?: string
  locationParams: ILocationParams
  deliveryRegions: number[]
}
