import React from 'react'
import { HIDDEN_PHONE_NUMBERS_FOR_CRAWLERS } from './constants'

export const FooterHiddenPhoneNumbersForCrawlers: React.FC = () => {
  return (
    <ul hidden>
      {HIDDEN_PHONE_NUMBERS_FOR_CRAWLERS.map(phoneNumber => (
        <li key={phoneNumber}>
          <a href={`tel:${phoneNumber}`}>{phoneNumber}</a>
        </li>
      ))}
    </ul>
  )
}
