import type { QueryKey, UseQueryResult, UseQueryOptions, QueryOptions } from "@tanstack/react-query";
import { useQuery } from "@tanstack/react-query";
import client from "@/api/client/axios";
import type { GetCultureMarketInfoQueryResponse, GetCultureMarketInfoPathParams, GetCultureMarketInfoQueryParams, GetCultureMarketInfo400, GetCultureMarketInfo404, GetCultureMarketInfo500 } from "../types/GetCultureMarketInfo";

export const getCultureMarketInfoQueryKey = (cultureId: GetCultureMarketInfoPathParams["cultureId"], params?: GetCultureMarketInfoQueryParams) =>     [{url:`/api/culture/market/info/cultureId/${cultureId}`,params:{cultureId:cultureId}},...(params ? [params] : [])] as const;
      export function getCultureMarketInfoQueryOptions <TData = GetCultureMarketInfoQueryResponse, TError = GetCultureMarketInfo400 | GetCultureMarketInfo404 | GetCultureMarketInfo500>(cultureId: GetCultureMarketInfoPathParams["cultureId"], params?: GetCultureMarketInfoQueryParams, options: Partial<Parameters<typeof client>[0]> = {}): UseQueryOptions<TData, TError> {
        const queryKey = getCultureMarketInfoQueryKey(cultureId, params);

        return {
          queryKey,
          queryFn: () => {
            
            return client<TData, TError>({
              method: "get",
              url: `/api/culture/market/info/cultureId/${cultureId}`,
              params,
              
              ...options,
            }).then(res => res.data);
          },
        };
      };
      
/**
 * @summary Получение информации о рынке культуры
 * @link /api/culture/market/info/cultureId/:cultureId
 */

export function useGetCultureMarketInfo <TData = GetCultureMarketInfoQueryResponse, TError = GetCultureMarketInfo400 | GetCultureMarketInfo404 | GetCultureMarketInfo500>(cultureId: GetCultureMarketInfoPathParams["cultureId"], params?: GetCultureMarketInfoQueryParams, options: {
          query?: UseQueryOptions<TData, TError>,
          client?: Partial<Parameters<typeof client<TData, TError>>[0]>,
        } = {}): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const { query: queryOptions, client: clientOptions = {} } = options ?? {};
  const queryKey = queryOptions?.queryKey ?? getCultureMarketInfoQueryKey(cultureId, params);

  const query = useQuery<TData, TError>({
    ...getCultureMarketInfoQueryOptions<TData, TError>(cultureId, params, clientOptions),
    ...queryOptions
  }) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey as QueryKey;

  return query;
};
