import type { IDeliveryBasesData, IFcaPrice } from '@/types/HarvestSaleLots'
import { EDeliveryBasisVariantId, EOutputsOrganizationsId, EPriceVariant } from '@/types/HarvestSaleLots'
import type { IGetEmptyPriceHintMessageIdProps } from './interfaces'

const getWorkingWithDeliveryBases = (availableDeliveryBases: IDeliveryBasesData[]): Record<string, boolean> => {
  return availableDeliveryBases?.reduce((acc, { id }) => {
    if (EDeliveryBasisVariantId.elevator === id) return { ...acc, isCptWorking: true }
    if (EDeliveryBasisVariantId.exw === id) return { ...acc, isExwWorking: true }
    if (EDeliveryBasisVariantId.pickup === id) return { ...acc, isFcaWorking: true }
    if (EDeliveryBasisVariantId.fob === id) return { ...acc, isFobWorking: true }

    return acc
  }, {})
}

export const getEmptyPriceHintMessageId = (props: IGetEmptyPriceHintMessageIdProps): string | undefined => {
  const { priceVariant, price, isStoreChosen, isAgrarian, buyer } = props
  const { deliveryBasesData, id: buyerId } = buyer

  const {
    isCptWorking = false,
    isExwWorking = false,
    isFcaWorking = false,
    isFobWorking = false,
  } = getWorkingWithDeliveryBases(deliveryBasesData)
  const isDemetra = EOutputsOrganizationsId.DEMETRA === buyerId

  switch (priceVariant) {
    case EPriceVariant.ELEVATOR:
      return isCptWorking ? 'cptIsEmpty' : 'notWork'
    case EPriceVariant.EXW:
      return isExwWorking ? 'exwIsEmpty' : 'notWork'
    case EPriceVariant.FOB:
      return isFobWorking ? 'fobIsEmpty' : 'notWork'
    case EPriceVariant.STORE: {
      if (isAgrarian && isDemetra) return isStoreChosen ? (price as IFcaPrice)?.status : 'storeNotChosen'

      return isFcaWorking ? 'fcaIsEmpty' : 'notWork'
    }
    default:
      return undefined
  }
}
