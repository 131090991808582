import type { QueryKey, UseQueryResult, UseQueryOptions, QueryOptions } from "@tanstack/react-query";
import { useQuery } from "@tanstack/react-query";
import client from "@/api/client/auctions";
import type { GetAuctionQueryResponse, GetAuctionQueryParams, GetAuction400, GetAuction404 } from "../types/GetAuction";

export const getAuctionQueryKey = (params: GetAuctionQueryParams) =>     [{url:`/v1/auction`},...(params ? [params] : [])] as const;
      export function getAuctionQueryOptions <TData = GetAuctionQueryResponse, TError = GetAuction400 | GetAuction404>(params: GetAuctionQueryParams, options: Partial<Parameters<typeof client>[0]> = {}): UseQueryOptions<TData, TError> {
        const queryKey = getAuctionQueryKey(params);

        return {
          queryKey,
          queryFn: () => {
            
            return client<TData, TError>({
              method: "get",
              url: `/v1/auction`,
              params,
              
              ...options,
            }).then(res => res.data);
          },
        };
      };
      
/**
 * @description Метод предназначен для получения данных аукциона по ID аукциона. https://confluence.poleinvest.ru/pages/viewpage.action?pageId=425482468

 * @link /v1/auction
 */

export function useGetAuction <TData = GetAuctionQueryResponse, TError = GetAuction400 | GetAuction404>(params: GetAuctionQueryParams, options: {
          query?: UseQueryOptions<TData, TError>,
          client?: Partial<Parameters<typeof client<TData, TError>>[0]>,
        } = {}): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const { query: queryOptions, client: clientOptions = {} } = options ?? {};
  const queryKey = queryOptions?.queryKey ?? getAuctionQueryKey(params);

  const query = useQuery<TData, TError>({
    ...getAuctionQueryOptions<TData, TError>(params, clientOptions),
    ...queryOptions
  }) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey as QueryKey;

  return query;
};
