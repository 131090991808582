import { useState } from 'react'
import { useEffectOnMissingClickByBlock } from '@/utils/hooks/event'

interface IUseHandleMenuVisibility {
  isMenuShown: boolean
  toggleMenuVisibility: () => void
  hideMenu: () => void
}

export const useHandleMenuVisibility = (): IUseHandleMenuVisibility => {
  const [isMenuShown, setMenuVisibility] = useState<boolean>(false)
  const hideMenu = () => setMenuVisibility(false)
  useEffectOnMissingClickByBlock('mobile-navigation-container', hideMenu)

  return { isMenuShown, toggleMenuVisibility: () => setMenuVisibility(!isMenuShown), hideMenu }
}
