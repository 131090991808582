import type { MonetaryAmountData } from "./MonetaryAmountData";
import type { SupplyItemDto } from "./SupplyItemDto";
import type { SupplyActionDto } from "./SupplyActionDto";

export enum OrderSupplyDtoStatus {
    "SCHEDULED" = "SCHEDULED",
    "COMPLETED" = "COMPLETED",
    "PAYMENT_RECEIVED" = "PAYMENT_RECEIVED"
}
export type OrderSupplyDto = {
    /**
     * @type integer int64
    */
    id: number;
    /**
     * @type string date
    */
    startDate: string;
    /**
     * @type string date
    */
    endDate: string;
    /**
     * @type string
    */
    status: OrderSupplyDtoStatus;
    /**
     * @type string
    */
    statusName: string;
    sum: MonetaryAmountData;
    /**
     * @type array
    */
    items: SupplyItemDto[];
    /**
     * @type array | undefined
    */
    actions?: SupplyActionDto[];
};
