import type { QueryKey, UseQueryResult, UseQueryOptions, QueryOptions } from "@tanstack/react-query";
import { useQuery } from "@tanstack/react-query";
import client from "@/api/client/axios";
import type { GetSaleRequestShippings2QueryResponse, GetSaleRequestShippings2PathParams, GetSaleRequestShippings2QueryParams, GetSaleRequestShippings2404, GetSaleRequestShippings2500 } from "../types/GetSaleRequestShippings2";

export const getSaleRequestShippings2QueryKey = (requestId: GetSaleRequestShippings2PathParams["requestId"], params?: GetSaleRequestShippings2QueryParams) =>     [{url:`/api/v2/salerequests/${requestId}/shippings`,params:{requestId:requestId}},...(params ? [params] : [])] as const;
      export function getSaleRequestShippings2QueryOptions <TData = GetSaleRequestShippings2QueryResponse, TError = GetSaleRequestShippings2404 | GetSaleRequestShippings2500>(requestId: GetSaleRequestShippings2PathParams["requestId"], params?: GetSaleRequestShippings2QueryParams, options: Partial<Parameters<typeof client>[0]> = {}): UseQueryOptions<TData, TError> {
        const queryKey = getSaleRequestShippings2QueryKey(requestId, params);

        return {
          queryKey,
          queryFn: () => {
            
            return client<TData, TError>({
              method: "get",
              url: `/api/v2/salerequests/${requestId}/shippings`,
              params,
              
              ...options,
            }).then(res => res.data);
          },
        };
      };
      
/**
 * @summary Запрос на получение графика открузки с пагинацией
 * @link /api/v2/salerequests/:requestId/shippings
 */

export function useGetSaleRequestShippings2 <TData = GetSaleRequestShippings2QueryResponse, TError = GetSaleRequestShippings2404 | GetSaleRequestShippings2500>(requestId: GetSaleRequestShippings2PathParams["requestId"], params?: GetSaleRequestShippings2QueryParams, options: {
          query?: UseQueryOptions<TData, TError>,
          client?: Partial<Parameters<typeof client<TData, TError>>[0]>,
        } = {}): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const { query: queryOptions, client: clientOptions = {} } = options ?? {};
  const queryKey = queryOptions?.queryKey ?? getSaleRequestShippings2QueryKey(requestId, params);

  const query = useQuery<TData, TError>({
    ...getSaleRequestShippings2QueryOptions<TData, TError>(requestId, params, clientOptions),
    ...queryOptions
  }) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey as QueryKey;

  return query;
};
