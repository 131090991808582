import type { ElementRef } from 'react'
import React, { useEffect, useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'
import type { ITextInputProps } from '@frontend/pole-ui/lib/components/TextInput'
import { useQuery } from '@tanstack/react-query'
import type { PublicationSearchDtoV1 } from '@/api/kubik/infoportal'
import { search, searchQueryKey } from '@/api/kubik/infoportal'
import SearchInputWithSuggestions from '@/components/composed/search/SearchInputWithSuggestions'
import type { TOnSuggestionsFetchRequested } from '@/components/ui/fields/Autosuggest/interfaces'
import { TOTAL_ITEMS_PER_PAGE } from '@/pages/Journal/components/JournalBySearch/constants'
import { useSearchQuery } from '@/router/hooks/useSearchQuery'
import { EJournalRoute } from '@/types'
import { useJournalSearchDefaultValue } from '@/utils/hooks/journal/useJournalSearchDefaultValue'
import { useDeviceType } from '@/utils/hooks/useDeviceType'
import { getUrlWithQuery } from '@/utils/url'
import { JournalSearchDefaultSuggestionCard } from './components/JournalSearchDefaultSuggestionCard/JournalSearchDefaultSuggestionCard'
import { JournalSearchSuggestionCard } from './components/JournalSearchSuggestionCard/JournalSearchSuggestionCard'
import { messages } from './messages'
import './styles.scss'

const getSuggestionValue = (suggestion: PublicationSearchDtoV1 | string) => (typeof suggestion === 'string' ? suggestion : suggestion.title)

export const JournalSearchInput = () => {
  const { isMobile } = useDeviceType()

  const history = useHistory()
  const query = useSearchQuery()
  const searchTextQuery = query.get('searchText')

  const { queries, addSearchQuery, removeSearchQueryById } = useJournalSearchDefaultValue()

  useEffect(() => {
    if (searchTextQuery) {
      addSearchQuery(searchTextQuery)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTextQuery])

  const [searchValue, setSearchValue] = useState(searchTextQuery || '')

  const { data: response } = useQuery(
    [...searchQueryKey({ query: searchValue }), 'journalSearch'],
    () =>
      search({
        query: searchValue,
        limit: TOTAL_ITEMS_PER_PAGE,
        offset: 0,
      }),
    {
      enabled: !!searchValue,
    },
  )

  const inputRef = useRef<ElementRef<typeof SearchInputWithSuggestions>>(null)

  const customInputProps: Partial<ITextInputProps> = {
    type: 'text',
    modifiers: ['white'],
    placeholder: messages.inputPlaceholder,
  }

  const onSuggestionsFetchRequested: TOnSuggestionsFetchRequested<PublicationSearchDtoV1 | string> = async ({ value }) => {
    if (!value) return queries

    setSearchValue(value)

    return response?.data?.publications || []
  }

  const onSearchInput = (newValue: string) => {
    const preparedNewValue = newValue.trim()

    if (!preparedNewValue) return

    inputRef.current?.hideModal()

    const activeElement = document.activeElement as HTMLInputElement
    activeElement?.blur()

    const url = getUrlWithQuery(EJournalRoute.SEARCH, { searchText: preparedNewValue })
    history.push(url)
  }

  const renderSuggestionComponent = (suggestion: PublicationSearchDtoV1 | string) => {
    if (typeof suggestion === 'string') {
      return <JournalSearchDefaultSuggestionCard suggestion={suggestion} onDelete={removeSearchQueryById} />
    }

    return <JournalSearchSuggestionCard suggestion={suggestion} />
  }

  return (
    <div className="page-wrapper journalSearchInput">
      <SearchInputWithSuggestions<PublicationSearchDtoV1 | string>
        value={searchTextQuery}
        ref={inputRef}
        name="journalSearch"
        customInputProps={customInputProps}
        shouldOpenInModal={isMobile}
        onSuggestionsFetchRequested={onSuggestionsFetchRequested}
        onInput={onSearchInput}
        getSuggestionValue={getSuggestionValue}
        suggestionComponent={renderSuggestionComponent}
        shouldRenderSuggestions={() => true}
        resetValueAfterSelect={false}
        focusInputOnSuggestionClick={false}
      />
    </div>
  )
}
