import React, { memo, useMemo } from 'react'
import Helmet from 'react-helmet'
import { useSelector } from 'react-redux'
import escape from 'lodash/escape'
import { getLocation } from '@/logic/app/reducer'
import { injectMessages } from '@/logic/translation/utils'
import type { RootState } from '@/redux/interfaces'
import { getMinioImagePath, getStaticImagePath } from '@/utils/images'
import type { IMetaInfoProps } from './interfaces'
import { EMetaInfoType } from './interfaces'
import messages from './messages.json'

injectMessages(messages)

const mapState = (state: RootState) => ({
  location: getLocation()(state),
})

const MetaInfo: React.FC<IMetaInfoProps> = props => {
  const { metaInfo = {} } = props

  const { location } = useSelector(mapState)
  const { type = EMetaInfoType.ARTICLE, title = '', description = '', image: pageImage, keywords, robots, script } = metaInfo
  const url = `${location.origin}${location.pathname}`

  const meta = useMemo(() => {
    const image = pageImage ? getMinioImagePath({ src: encodeURIComponent(pageImage) }) : getStaticImagePath('ogimage.jpg')
    const escapedDescription = escape(description)

    const metaData: JSX.IntrinsicElements['meta'][] = [
      { property: 'og:site_name', content: 'поле.рф' },
      { property: 'og:type', content: type },
      { property: 'og:url', content: url },
      { property: 'og:image', content: image },
    ]

    if (title) metaData.push({ property: 'og:title', content: escape(title) })

    if (description) {
      metaData.push({ name: 'description', content: escapedDescription })
      metaData.push({ property: 'og:description', content: escapedDescription })
    }

    if (keywords) metaData.push({ name: 'keywords', content: keywords })
    if (robots) metaData.push({ name: 'robots', content: robots })

    return metaData
  }, [description, keywords, pageImage, robots, title, type, url])

  const helmetProps = useMemo(
    () => ({
      title,
      script,
      meta,
      htmlAttributes: { prefix: 'og: http://ogp.me/ns#', lang: 'ru' },
      link: [{ rel: 'canonical', href: url }],
    }),
    [meta, script, title, url],
  )

  return <Helmet {...helmetProps} />
}

export default memo(MetaInfo)
