import type { CategoryTreeData } from '@/api/kubik/inputs-catalog'
import type { IAgriculturalProductTreeCategory } from '@/types/Catalog'

export const getFlattenedCategoriesFromTree = (
  categories?: IAgriculturalProductTreeCategory[] | IAgriculturalProductTreeCategory,
): IAgriculturalProductTreeCategory[] => {
  if (!categories) return []

  const getFlattenCategory = (category: IAgriculturalProductTreeCategory) =>
    [category, ...(category.children || []).map(childCategory => getFlattenedCategoriesFromTree(childCategory))].flat()

  return (Array.isArray(categories) ? categories.map(category => getFlattenCategory(category)) : getFlattenCategory(categories)).flat()
}

export const getFlattenCategoriesTree = (categories?: CategoryTreeData | CategoryTreeData[]): CategoryTreeData[] => {
  if (!categories) return []

  const flatCategory = (category: CategoryTreeData) =>
    [category, ...(category.children || []).map(childCategory => getFlattenCategoriesTree(childCategory))].flat()

  return (Array.isArray(categories) ? categories.map(category => flatCategory(category)) : flatCategory(categories)).flat()
}

export const getFlattenedSubCategoriesTree = (categoriesTree?: IAgriculturalProductTreeCategory[] | IAgriculturalProductTreeCategory) => {
  const flattenedTree = getFlattenedCategoriesFromTree(categoriesTree)
  const activeCategoryIndex = flattenedTree.findIndex(category => category.active)

  return flattenedTree.slice(activeCategoryIndex + 1)
}
