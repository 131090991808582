import type { CoverImageV1 } from "./CoverImageV1";
import type { RubricDataV1 } from "./RubricDataV1";
import type { TagDataV1 } from "./TagDataV1";

export enum PublicationDataDtoV1Status {
    "DRAFT" = "DRAFT",
    "PUBLISHED" = "PUBLISHED",
    "ARCHIVE" = "ARCHIVE"
}
export enum PublicationDataDtoV1Type {
    "ARTICLE" = "ARTICLE",
    "NEWS" = "NEWS"
}
export type PublicationDataDtoV1 = {
    /**
     * @type integer int64
    */
    id: number;
    /**
     * @type string
    */
    slug: string;
    /**
     * @type string date-time
    */
    creationDate: string;
    /**
     * @type string | undefined date-time
    */
    updateDate?: string;
    /**
     * @type string | undefined date-time
    */
    publicationDate?: string;
    /**
     * @type string
    */
    status: PublicationDataDtoV1Status;
    /**
     * @type string
    */
    type: PublicationDataDtoV1Type;
    /**
     * @type string
    */
    title: string;
    /**
     * @type string | undefined
    */
    subtitle?: string;
    /**
     * @type string | undefined
    */
    lead?: string;
    /**
     * @type string
    */
    text: string;
    /**
     * @type string | undefined
    */
    author?: string;
    coverImage?: CoverImageV1;
    /**
     * @type boolean | undefined
    */
    showCover?: boolean;
    /**
     * @type string | undefined
    */
    source?: string;
    /**
     * @type boolean
    */
    main: boolean;
    /**
     * @type array
    */
    rubrics: RubricDataV1[];
    /**
     * @type array
    */
    tags: TagDataV1[];
    /**
     * @type boolean | undefined
    */
    showMainPage?: boolean;
};
