import type { PropsWithChildren } from 'react'
import React from 'react'
import type { FieldRenderProps } from 'react-final-form'
import { Field } from 'react-final-form'
import { useIntl } from 'react-intl'
import type { TOnChangeDropdownItem } from '@frontend/pole-ui/lib/components/Dropdown'
import { Dropdown as PoleUIDropdown } from '@frontend/pole-ui/lib/components/Dropdown'
import omit from 'lodash/omit'
import { formatLocalizedString } from '@/utils/formatUtils'
import { getDropdownPlaceholderToPass, getDropdownValidateFunction } from '../helpers/Dropdown'
import type { IDropdownProps } from './interfaces'

function Dropdown<GValue = string | number>(props: PropsWithChildren<IDropdownProps<GValue>>) {
  const { errorValues, onChange, errorText, customPlaceHolder } = props
  const { formatMessage } = useIntl()
  const labelToPass = getDropdownPlaceholderToPass(props, formatMessage)

  const validate = getDropdownValidateFunction<GValue>(props)

  const initializeDropdown = (fieldProps: FieldRenderProps<GValue>) => {
    const { input, meta } = fieldProps

    const onItemChange: TOnChangeDropdownItem<GValue> = (changedValue, item, event) => {
      input.onChange(changedValue)
      onChange?.(changedValue, item, event)
      input.onBlur()
    }

    const errorTextFromMeta = typeof meta.error === 'string' ? formatLocalizedString(meta.error, errorValues ?? {}) : null
    const errorTextToUse = errorText ?? errorTextFromMeta

    return (
      <PoleUIDropdown<GValue>
        {...omit(props, 'customValidator')}
        {...fieldProps}
        value={input.value}
        onFocus={input.onFocus}
        onBlur={input.onBlur}
        errorText={errorTextToUse}
        onChange={onItemChange}
        label={labelToPass}
        placeholder={customPlaceHolder}
      />
    )
  }

  return (
    <Field<GValue> {...props} validate={validate}>
      {initializeDropdown}
    </Field>
  )
}

export default Dropdown
