import React from 'react'
import { FormattedMessage } from 'react-intl'
import { CommonLink } from '@frontend/pole-ui/lib/components/CommonLink'
import classNames from 'classnames'
import type { TBreadcrumbProps } from './interfaces'

const Breadcrumb: React.FC<TBreadcrumbProps> = ({ breadcrumb, animation, isLast }) => {
  const { captionId, caption, link, captionValues, withBackIcon, className, onClick } = breadcrumb
  const captionText = captionId ? <FormattedMessage id={captionId} values={captionValues} /> : caption

  return (
    <CommonLink
      url={isLast ? undefined : link}
      onClick={onClick}
      spanIfEmpty={!onClick}
      className={classNames('breadcrumb text_small-from-sm', className, { breadcrumb_link: !isLast, breadcrumb_back: withBackIcon })}
    >
      <span {...animation}>{captionText}</span>
    </CommonLink>
  )
}

export default Breadcrumb
