import type { FC } from 'react'
import { useEffect } from 'react'
import { useLocation } from '@/router/hooks'

const ScrollToTop: FC = () => {
  const { pathname, search } = useLocation()

  useEffect(() => {
    if (!search?.includes('noscrolltop')) {
      window.scrollTo(0, 0)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname])

  return null
}

export default ScrollToTop
