import client from "@/api/client/axios";
import type { ResponseConfig } from "@/api/client/axios";
import type { ConfirmOrderMutationResponse, ConfirmOrderPathParams, ConfirmOrderQueryParams } from "../../types/ConfirmOrder";

/**
     * @summary Подтвердить заказ
     * @link /api/orders/:orderId/status/next
     */
export async function confirmOrder (orderId: ConfirmOrderPathParams["orderId"], params: ConfirmOrderQueryParams, options: Partial<Parameters<typeof client>[0]> = {}): Promise<ResponseConfig<ConfirmOrderMutationResponse>["data"]> {
    const { data: resData } = await client<ConfirmOrderMutationResponse>({
        method: "post",
        url: `/api/orders/${orderId}/status/next`,
        params,
        ...options
    });
    
    return resData;
};