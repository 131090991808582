
export enum NotificationDataType {
    "UNREAD_MESSAGES_COUNT" = "UNREAD_MESSAGES_COUNT",
    "UNREAD_WEB_NOTIFICATION_COUNT" = "UNREAD_WEB_NOTIFICATION_COUNT",
    "UNREAD_FUNDING_MESSAGES_COUNT" = "UNREAD_FUNDING_MESSAGES_COUNT",
    "UNWATCHED_FUNDING_DOCUMENTS_COUNT" = "UNWATCHED_FUNDING_DOCUMENTS_COUNT"
}
export type NotificationData = {
    /**
     * @type integer | undefined int32
    */
    count?: number;
    /**
     * @type string | undefined
    */
    type?: NotificationDataType;
};
