import client from "@/api/client/axios";
import type { ResponseConfig } from "@/api/client/axios";
import type { GetSaleTemplatesForEachCulturesQueryResponse, GetSaleTemplatesForEachCulturesQueryParams } from "../../types/GetSaleTemplatesForEachCultures";

/**
     * @summary Получение списка шаблонов заявок для каждой из культур
     * @link /api/requests/list/templates
     */
export async function getSaleTemplatesForEachCultures (params?: GetSaleTemplatesForEachCulturesQueryParams, options: Partial<Parameters<typeof client>[0]> = {}): Promise<ResponseConfig<GetSaleTemplatesForEachCulturesQueryResponse>["data"]> {
    const { data: resData } = await client<GetSaleTemplatesForEachCulturesQueryResponse>({
        method: "get",
        url: `/api/requests/list/templates`,
        params,
        ...options
    });
    
    return resData;
};