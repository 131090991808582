import type { IFormattedGtmEventPayload } from '@/logic/metrika/interfaces'

export enum EClickEventBlockId {
  poleScouting = 'poleScouting',
  differentiation = 'differentiation',
  poleExpert = 'poleExpert',
  polePlus = 'polePlus',
  farmlandCropValuation = 'farmlandCropValuation',
  buttonMorePoleScouting = 'buttonMorePoleScouting',
  buttonMoreDetailsPolePlus = 'buttonMoreDetailsPolePlus',
  buttonMorePoleDifferentiation = 'buttonMorePoleDifferentiation',
  buttonMoreDetailsPoleExpert = 'buttonMoreDetailsPoleExpert',
  buttonMoreDetailsFarmlandCropValuation = 'buttonMoreDetailsFarmlandCropValuation',
}

const baseGtmClickEvent: Omit<IFormattedGtmEventPayload, 'blockName'> = {
  event: 'userEvent',
  pagePart: 'content',
  eventCategory: 'interactions',
  eventAction: 'click',
  eventLabel: 'buttonSendRequest',
  eventContext: null,
  eventStatus: 'success',
  eventEcommerce: null,
  eventNonInteraction: '0',
  firingOptions: 'oncePerEvent',
  ecommerce: null,
}

export const ExactFarmingClickGtmEventPayload: Record<EClickEventBlockId, IFormattedGtmEventPayload> = {
  [EClickEventBlockId.farmlandCropValuation]: {
    ...baseGtmClickEvent,
    blockName: 'blockOtherOffers',
    eventNonInteraction: '1',
  },
  [EClickEventBlockId.differentiation]: {
    ...baseGtmClickEvent,
    blockName: 'blockOtherOffers',
    eventContext: {
      txt: 'Differentiation',
    },
  },
  [EClickEventBlockId.polePlus]: {
    ...baseGtmClickEvent,
    blockName: 'blockOtherOffers',
    eventLabel: 'buttonSendRequest',
    eventContext: {
      txt: 'polePlus',
    },
  },
  [EClickEventBlockId.poleScouting]: {
    ...baseGtmClickEvent,
    blockName: 'blockOtherOffers',
    eventContext: {
      txt: 'FieldScouting',
    },
  },
  [EClickEventBlockId.poleExpert]: {
    ...baseGtmClickEvent,
    blockName: 'blockOtherOffers',
    eventLabel: 'buttonSendRequest',
    eventContext: {
      txt: 'FieldExpert',
    },
  },
  [EClickEventBlockId.buttonMoreDetailsPolePlus]: {
    ...baseGtmClickEvent,
    blockName: 'blockOtherOffers',
    eventLabel: 'buttonMoreDetails',
    eventContext: {
      txt: 'polePlus',
    },
  },
  [EClickEventBlockId.buttonMorePoleScouting]: {
    ...baseGtmClickEvent,
    blockName: 'blockOtherOffers',
    eventLabel: 'buttonMoreDetails',
    eventContext: {
      txt: 'FieldScouting',
    },
  },
  [EClickEventBlockId.buttonMorePoleDifferentiation]: {
    ...baseGtmClickEvent,
    blockName: 'blockOtherOffers',
    eventLabel: 'buttonMoreDetails',
    eventContext: {
      txt: 'Differentiation',
    },
  },
  [EClickEventBlockId.buttonMoreDetailsPoleExpert]: {
    ...baseGtmClickEvent,
    blockName: 'blockOtherOffers',
    eventLabel: 'buttonMoreDetails',
    eventContext: {
      txt: 'FieldExpert',
    },
  },
  [EClickEventBlockId.buttonMoreDetailsFarmlandCropValuation]: {
    ...baseGtmClickEvent,
    blockName: 'blockOtherOffers',
    eventLabel: 'buttonMoreDetails',
    eventNonInteraction: '1',
  },
}
