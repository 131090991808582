import React, { memo } from 'react'
import { useIntl } from 'react-intl'
import classNames from 'classnames'
import { PriceUom } from '@/components/ui'
import { formatMoney } from '@/utils/price'
import type { IPriceInfoProps } from './interfaces'

const PriceInfo: React.FC<IPriceInfoProps> = ({ price, className, shouldShowEmptyPrice, uomClassName }) => {
  const intl = useIntl()

  return (
    <span className={className}>
      <span className="price-info__price">{shouldShowEmptyPrice ? '—' : formatMoney(price.value, intl)}</span>&nbsp;
      <PriceUom className={classNames('price-info__uom', uomClassName)} uom={price.uom} />
    </span>
  )
}

export default memo(PriceInfo) as typeof PriceInfo
