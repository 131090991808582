import { createAction } from '@reduxjs/toolkit'
import type { IDocumentsResponse } from '@/services/documents/interfaces'
import type { IDocument } from '@/types/Documents'
import type { IUpdateDocumentPayload, IUploadDocumentPayload } from './components/UploadDocumentModal/interfaces'
import type { IFetchDocumentsPayload, IFileDownloadPayload } from './interfaces'

export const setPending = createAction<boolean>('@@documents/SET_PENDING')
export const fetchDocuments = createAction<IFetchDocumentsPayload>('@@documents/FETCH_DOCUMENTS')
export const setDocuments = createAction<IDocumentsResponse>('@@documents/SET_DOCUMENTS')
export const uploadDocument = createAction<IUploadDocumentPayload>('@@documents/UPLOAD_DOCUMENT')
export const updateDocument = createAction<IUpdateDocumentPayload>('@@documents/UPDATE_DOCUMENT')
export const downloadDocument = createAction<IFileDownloadPayload>('@@documents/DOWNLOAD_DOCUMENT')
export const deleteDocument = createAction<IDocument & IFetchDocumentsPayload>('@@documents/DELETE_DOCUMENT')
export const reloadDocuments = createAction<IFetchDocumentsPayload>('@@documents/RELOAD_DOCUMENTS')
