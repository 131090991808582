import type { QueryKey, UseQueryResult, UseQueryOptions, QueryOptions } from "@tanstack/react-query";
import { useQuery } from "@tanstack/react-query";
import client from "@/api/client/axios";
import type { GetStoresQueryResponse, GetStores400, GetStores500 } from "../types/GetStores";

export const getStoresQueryKey = () =>     [{url:`/api/stores`}] as const;
      export function getStoresQueryOptions <TData = GetStoresQueryResponse, TError = GetStores400 | GetStores500>(options: Partial<Parameters<typeof client>[0]> = {}): UseQueryOptions<TData, TError> {
        const queryKey = getStoresQueryKey();

        return {
          queryKey,
          queryFn: () => {
            
            return client<TData, TError>({
              method: "get",
              url: `/api/stores`,
              
              
              ...options,
            }).then(res => res.data);
          },
        };
      };
      
/**
 * @summary Получение Складов
 * @link /api/stores
 */

export function useGetStores <TData = GetStoresQueryResponse, TError = GetStores400 | GetStores500>(options: {
          query?: UseQueryOptions<TData, TError>,
          client?: Partial<Parameters<typeof client<TData, TError>>[0]>,
        } = {}): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const { query: queryOptions, client: clientOptions = {} } = options ?? {};
  const queryKey = queryOptions?.queryKey ?? getStoresQueryKey();

  const query = useQuery<TData, TError>({
    ...getStoresQueryOptions<TData, TError>(clientOptions),
    ...queryOptions
  }) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey as QueryKey;

  return query;
};
