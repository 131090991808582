import { combineEpics, ofType } from 'redux-observable'
import { filter, mergeMap, race, skip, take, tap } from 'rxjs'
import type { ProductListData } from '@/api/kubik/inputs-catalog'
import type { NDataAction } from '@/logic/data/actions'
import { loadData, loadingDataFailed, loadingDataFinished } from '@/logic/data/actions'
import { isFailureDataLoadingPayload, isFinishedDataLoadingPayload } from '@/logic/data/typeguard'
import type { Epic, TActionStream } from '@/logic/interfaces'
import { pushProductsGtmEvent } from '@/logic/metrika/catalog/gtmEvents'
import { getMergedOfferWithSku } from '@/logic/metrika/catalog/helpers'
import { getErrorTextFromResponseMeta } from '@/utils/metrika/helpers'

const onSearchIndexPageProductsDoPushGtmEvent: Epic<NDataAction.ILoadData, typeof loadData> = action$ =>
  action$.pipe(
    filter(loadData.match),
    filter(({ payload }) => payload.name === 'indexAgriculturalProducts'),
    mergeMap(() =>
      race(
        (
          action$ as unknown as TActionStream<
            NDataAction.ILoadingDataFinished<ProductListData> | NDataAction.ILoadingDataFailed,
            typeof loadingDataFinished | typeof loadingDataFailed
          >
        ).pipe(
          ofType(loadingDataFinished.type, loadingDataFailed.type),
          take(1),
          tap(({ type, payload }) => {
            pushProductsGtmEvent({
              pagePart: 'content',
              blockName: 'blockHomeInputs',
              eventCategory: 'ecommerce',
              eventLabel: 'productInputs',
              eventStatus:
                loadingDataFailed.type === type && isFailureDataLoadingPayload(payload)
                  ? (`err:${getErrorTextFromResponseMeta(payload.meta)}` as const)
                  : 'success',
              eventEcommerce: 'impressions',
              ecommerceType: 'impressions',
              eventNonInteraction: '1',
              itemPosition: 'homeListingInputs',
              products: isFinishedDataLoadingPayload(payload)
                ? payload.data.products?.flatMap(sku => sku.offers.map(offer => getMergedOfferWithSku(offer, sku))) || []
                : [],
              eventAction: 'viewing',
            })
          }),
          skip(1),
        ),
      ),
    ),
  )

export default combineEpics(onSearchIndexPageProductsDoPushGtmEvent)
