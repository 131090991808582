import type { TMessages } from '@/types/TMessages'

export const messages = {
  shouldBeNonEmptyPhoneNumber: 'Введите свой номер телефона',
  shouldBeInPhoneNumberFormat: 'Некорректный формат номера',
  shouldBeInEmailFormat: 'Некорректный формат E-mail',
  shouldBeInDigitsOnlyNumberFormat: 'Допустимы только цифры',
  shouldBeInNumberFormat: 'Введите число',
  shouldBeInValidNumberFormat: 'Некорректное число',
  shouldBeInIntegerNumberFormat: 'Должно быть целым числом',
  shouldBeMinOrEqual: 'Укажите значение от {min}',
  shouldBeMaxOrEqual: 'Укажите значение до {max}',
  shouldBeGreaterThen: 'Должно быть больше {min}',
  shouldBeLessThen: 'Должно быть меньше {max}',
  shouldBeInValidFloatNumberFormat:
    'Допустимо не больше {digitsAfterDot, plural, one {# знака} few {# знаков} other {# знаков}} после запятой',
} satisfies TMessages
