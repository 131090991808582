import type { ICatalogGtmEventOptions, IGtmEventCatalogProduct } from '@/logic/metrika/catalog/interfaces'
import {
  isFormattedCartItem,
  isFormattedCartItems,
  isFormattedCatalogProduct,
  isFormattedCatalogProducts,
} from '@/logic/metrika/catalog/typeguards'
import { ECatalogProductOfferType } from '@/types/Catalog'
import { pushFormattedGtmEvent } from '../helpers'
import { getFormattedCartItem, getFormattedProduct } from './helpers'

export const pushProductsGtmEvent = (options: ICatalogGtmEventOptions) => {
  const {
    actionField,
    eventNonInteraction,
    pagePart = 'content',
    blockName,
    products,
    itemPosition,
    eventStatus,
    eventAction,
    eventLabel,
    eventEcommerce,
    eventCategory,
    productType = ECatalogProductOfferType.CATALOG,
    ecommerceType,
    cartId,
    orderPosition,
    categoryId,
  } = options

  const productsByPriceCurrency: Record<string, IGtmEventCatalogProduct[]> = {}

  if (products?.some(product => 'price' in product)) {
    products.forEach((product, index) => {
      const currencyCode = product.price?.uom || 'RUB'
      if (!productsByPriceCurrency[currencyCode]) productsByPriceCurrency[currencyCode] = []

      let gtmEventProduct: IGtmEventCatalogProduct | null = null
      if (productType === ECatalogProductOfferType.CART && isFormattedCartItem(product)) {
        gtmEventProduct = getFormattedCartItem(product, orderPosition || index, itemPosition)
      } else if (productType === ECatalogProductOfferType.CATALOG && isFormattedCatalogProduct(product)) {
        gtmEventProduct = getFormattedProduct(product, orderPosition || index, itemPosition)
      }

      const selectedProduct = productsByPriceCurrency[currencyCode]
      if (selectedProduct && gtmEventProduct) selectedProduct.push(gtmEventProduct)
    })
  } else if (productType === ECatalogProductOfferType.CART && isFormattedCartItems(products)) {
    productsByPriceCurrency.RUB = products.map((product, index) => getFormattedCartItem(product, orderPosition || index, itemPosition))
  } else if (productType === ECatalogProductOfferType.CATALOG && isFormattedCatalogProducts(products)) {
    productsByPriceCurrency.RUB = products.map((product, index) => getFormattedProduct(product, orderPosition || index, itemPosition))
  }

  Object.entries(productsByPriceCurrency).forEach(([currencyCode, resultProducts]) => {
    pushFormattedGtmEvent({
      event: 'userEvent',
      pagePart,
      blockName,
      eventCategory,
      eventAction,
      eventLabel,
      eventContext: `prd:${resultProducts.reduce((result, { id }, index) => `${result}${index !== 0 ? ';' : ''}${id}`, '')}${
        itemPosition ? `&lst:${itemPosition}` : ''
      }${cartId ? `&cartId:${cartId}` : ''}${categoryId ? `$ctgr:${categoryId}` : ''}`,
      eventStatus,
      eventEcommerce,
      eventNonInteraction,
      firingOptions: 'oncePerEvent',
      ecommerce: {
        currencyCode,
        [ecommerceType]: ecommerceType === 'impressions' ? resultProducts : { actionField, products: resultProducts },
      },
    })
  })
}
