import { ESkuCharacteristicType } from '@/types/Catalog/ESkuCharacteristicType'
import type { IAgricultureCatalogFilterMultiSelect, TAgricultureCatalogFilterMultiSelectValue } from './AgricultureCatalogFilterMultiSelect'
import type { IAgricultureCatalogFilterNumber, TAgricultureCatalogFilterNumberValue } from './AgricultureCatalogFilterNumber'
import type {
  IAgricultureCatalogFilterSingleSelect,
  TAgricultureCatalogFilterSingleSelectValue,
} from './AgricultureCatalogFilterSingleSelect'
import type { TAgricultureCatalogFilter, TAgricultureCatalogFilterValue } from './types'

export const isMultiSelectFilter = (filter: TAgricultureCatalogFilter): filter is IAgricultureCatalogFilterMultiSelect => {
  return filter.type === ESkuCharacteristicType.MULTI_SELECT
}

export const isSingleSelectFilter = (filter: TAgricultureCatalogFilter): filter is IAgricultureCatalogFilterSingleSelect => {
  return filter.type === ESkuCharacteristicType.SINGLE_SELECT
}

export const isNumberFilter = (filter: TAgricultureCatalogFilter): filter is IAgricultureCatalogFilterNumber => {
  return filter.type === ESkuCharacteristicType.NUMBER
}

export const isMultiSelectValue = (
  filter: TAgricultureCatalogFilter,
  value: TAgricultureCatalogFilterValue,
): value is TAgricultureCatalogFilterMultiSelectValue => isMultiSelectFilter(filter)

export const isSingleSelectValue = (
  filter: TAgricultureCatalogFilter,
  value: TAgricultureCatalogFilterValue,
): value is TAgricultureCatalogFilterSingleSelectValue => isSingleSelectFilter(filter)

export const isNumberValue = (
  filter: TAgricultureCatalogFilter,
  value: TAgricultureCatalogFilterValue,
): value is TAgricultureCatalogFilterNumberValue => isNumberFilter(filter)
