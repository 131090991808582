import loadable from '@loadable/component'

export const SyngentaPromoLanding = loadable(
  /* webpackChunkName: "SyngentaPromoLanding" */
  /* webpackPrefetch: "true" */
  /* #__LOADABLE__ */
  () => import('./SyngentaPromoLanding'),
)

export const vezdehodPromoLanding = loadable(
  /* webpackChunkName: "SyngentaPromoLanding" */
  /* webpackPrefetch: "true" */
  /* #__LOADABLE__ */
  () => import('../MultiCart/components/promo/VezdehodPromoLanding/'),
)
