import type { PropsWithChildren } from 'react'
import React, { memo, useEffect, useState } from 'react'
import classNames from 'classnames'
import { InnerModal } from '@/components/composed/modals'
import { Fading } from '../animations'
import { BubblingTooltip, Button, InjectHtml } from '..'
import type { IHintWrapperProps } from './interfaces'
import './HintWrapper.scss'

const HintWrapper: React.FC<IHintWrapperProps> = props => {
  const {
    header,
    message,
    messageId,
    shouldDisplayHint,
    children,
    shouldBeHintInModal,
    className,
    messageClass,
    messageValues,
    tooltipProps,
    onToggleHint,
  } = props
  const [isOpenModal, setOpenModalState] = useState(false)

  useEffect(() => {
    const shouldLockScroll = shouldBeHintInModal && isOpenModal
    document.body.style.overflow = shouldLockScroll ? 'hidden' : ''
    onToggleHint?.(isOpenModal)
  }, [isOpenModal, shouldBeHintInModal, onToggleHint])

  if (!shouldDisplayHint) return <>{children}</>

  if (shouldBeHintInModal) {
    const onTapContent = () => setOpenModalState(true)

    return (
      <>
        <Fading isVisible={isOpenModal}>
          <InnerModal header={header} options={{ className: 'hint-modal' }} closeModal={() => setOpenModalState(false)}>
            <InjectHtml textId={messageId} html={message} textValues={messageValues} className={messageClass} TagName="p" />
          </InnerModal>
        </Fading>
        <Button onClick={onTapContent} isUnstyled className={className}>
          {children}
        </Button>
      </>
    )
  }

  return (
    <BubblingTooltip
      content={
        <>
          {header}
          {(messageId || message) && (
            <InjectHtml textId={messageId} html={message} textValues={messageValues} className={messageClass} TagName="p" />
          )}
        </>
      }
      tooltipProps={tooltipProps}
    >
      <span className={classNames('row_inline', className)}>{children}</span>
    </BubblingTooltip>
  )
}

export default memo<PropsWithChildren<IHintWrapperProps>>(HintWrapper)
