import React from 'react'
import { StatusMarker } from '@/components/ui'
import { EStatus } from '@/components/ui/StatusMarker/interfaces'
import { injectMessages } from '@/logic/translation/utils'
import { EDocumentStatus } from '@/types/Documents'
import type { IDocumentStatusProps } from '../interfaces'
import messages from './messages.json'

injectMessages(messages)

const DocumentStatus: React.FC<IDocumentStatusProps> = ({ document, categoryGroups, typedCategories }) => (
  <div className="document-statuses">
    {categoryGroups.map((categories, index) => {
      const shownCategory: string | boolean | undefined = Array.isArray(typedCategories)
        ? typedCategories.find(category => categories.includes(category))
        : true
      if (!shownCategory) return null

      let marker = EStatus.NOT_LOADED
      if (document) marker = document?.status === EDocumentStatus.REJECTED ? EStatus.REJECTED : EStatus.LOADED

      return <StatusMarker key={index} status={marker} mobileLabelId={`documents.status.mobile.label.${shownCategory}`} />
    })}
  </div>
)

export default DocumentStatus
