import type { FC } from 'react'
import React from 'react'
import classNames from 'classnames'
import type { INoticeProps } from './interfaces'
import './styles.scss'

/**
 * Компонент для отображения информационного блока
 */
const Notice: FC<INoticeProps> = ({ icon, backgroundColor, className, children }) => (
  <div className={classNames('notice', className)} style={{ backgroundColor }}>
    <div className="notice__body">{children}</div>
    <div className="notice__icon">{icon}</div>
  </div>
)

export default Notice
