import client from "@/api/client/axios";
import type { ResponseConfig } from "@/api/client/axios";
import type { GetApprovedRegionsQueryResponse, GetApprovedRegionsQueryParams } from "../../types/GetApprovedRegions";

/**
     * @summary Получение списка регионов доступных для финансирования
     * @link /api/funding/region
     */
export async function getApprovedRegions (params: GetApprovedRegionsQueryParams, options: Partial<Parameters<typeof client>[0]> = {}): Promise<ResponseConfig<GetApprovedRegionsQueryResponse>["data"]> {
    const { data: resData } = await client<GetApprovedRegionsQueryResponse>({
        method: "get",
        url: `/api/funding/region`,
        params,
        ...options
    });
    
    return resData;
};