import type { PayloadAction } from '@reduxjs/toolkit'
import { createReducer, createSelector } from '@reduxjs/toolkit'
import { clearOrderState, setAgrarianOrder, setEditorModeState } from '@/logic/order/actions'
import type { IInputsOrderState, ISetAgrarianOrderPayload } from '@/logic/order/interfaces'
import { getInitialState } from '@/redux/helpers'
import type { RootState } from '@/redux/interfaces'
import { reducerManager } from '@/redux/reducerManager'

export const initialState: IInputsOrderState = {
  editorModeState: false,
}

const reducerName = 'inputsOrder'
export const reducer = createReducer(getInitialState(reducerName, initialState), {
  [setAgrarianOrder.type]: (state, { payload }: PayloadAction<ISetAgrarianOrderPayload>) => {
    state.order = payload.order
  },
  [clearOrderState.type]: () => initialState,
  [setEditorModeState.type]: (state, { payload }: PayloadAction<boolean>) => {
    state.editorModeState = payload
  },
})

reducerManager.add(reducerName, reducer, true)

export const getAgrarianOrder = () => (state: RootState) => state[reducerName].order
export const getAgrarianOrderEditorModeState = () => (state: RootState) => state[reducerName].editorModeState
export const getAgrarianOrderVersion = createSelector(getAgrarianOrder(), order => order?.updateDate)
