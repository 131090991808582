import { CanceledError } from 'axios'
import { ExecutionEnvironment } from '@/utils/ExecutionEnvironment'
import { createAxiosClient } from './axiosFactory'

const { isSsr } = ExecutionEnvironment

const baseURL = isSsr ? `${process.env.API_HOST}:${process.env.API_PORT}` : ''

const axiosClient = createAxiosClient({
  baseURL,
})

export * from './types'
export { CanceledError }
export default axiosClient
