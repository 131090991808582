import type { IMetaInfoScript } from '@/interfaces'

export enum EMetaInfoType {
  WEBSITE = 'website',
  ARTICLE = 'article',
}

export interface IMetaInfo {
  type?: EMetaInfoType
  title?: string
  description?: string
  image?: string
  keywords?: string
  robots?: 'noindex'
  script?: IMetaInfoScript[]
}

export interface IMetaInfoProps {
  metaInfo?: IMetaInfo
}
