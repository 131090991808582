import client from "@/api/client/axios";
import type { ResponseConfig } from "@/api/client/axios";
import type { SignOrderCryptoMutationRequest, SignOrderCryptoMutationResponse, SignOrderCryptoPathParams, SignOrderCryptoQueryParams } from "../../types/SignOrderCrypto";

/**
     * @summary Подписание договора
     * @link /api/orders/:orderId/sign-crypto
     */
export async function signOrderCrypto (orderId: SignOrderCryptoPathParams["orderId"], params: SignOrderCryptoQueryParams, data?: SignOrderCryptoMutationRequest, options: Partial<Parameters<typeof client>[0]> = {}): Promise<ResponseConfig<SignOrderCryptoMutationResponse>["data"]> {
    const { data: resData } = await client<SignOrderCryptoMutationResponse, SignOrderCryptoMutationRequest>({
        method: "post",
        url: `/api/orders/${orderId}/sign-crypto`,
        params,
        data,
        ...options
    });
    
    return resData;
};