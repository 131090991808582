import client from "@/api/client/axios";
import type { ResponseConfig } from "@/api/client/axios";
import type { GetRegionsQueryResponse, GetRegionsQueryParams } from "../../types/GetRegions";

/**
     * @summary Получение регионов
     * @link /api/regions
     */
export async function getRegions (params?: GetRegionsQueryParams, options: Partial<Parameters<typeof client>[0]> = {}): Promise<ResponseConfig<GetRegionsQueryResponse>["data"]> {
    const { data: resData } = await client<GetRegionsQueryResponse>({
        method: "get",
        url: `/api/regions`,
        params,
        ...options
    });
    
    return resData;
};