import Loadable from '@loadable/component'

export { default as RegistrationDocument } from './RegistrationDocument'
export { default as SuggestionCompany } from './Suggestions/SuggestionCompany'
export { default as SuggestionFio } from './Suggestions/SuggestionFio'

export const PhoneNumberStep = Loadable(
  /* webpackChunkName: "AuthPhoneNumberStep" */
  /* webpackPrefetch: "true" */
  /* #__LOADABLE__ */
  () => import('./PhoneNumberStep'),
)

export const RegistrationStep = Loadable(
  /* webpackChunkName: "AuthRegistrationStep" */
  /* webpackPrefetch: "true" */
  /* #__LOADABLE__ */
  () => import('./RegistrationStep'),
)

export const SmsCodeStep = Loadable(
  /* webpackChunkName: "AuthSmsCodeStep" */
  /* webpackPrefetch: "true" */
  /* #__LOADABLE__ */
  () => import('./SmsCodeStep'),
)

export const SelectRoleStep = Loadable(
  /* webpackChunkName: "AuthSelectRoleStep" */
  /* webpackPrefetch: "true" */
  /* #__LOADABLE__ */
  () => import('./SelectRoleStep'),
)
