import { isStatusOk } from '@/api/helpers'
import { requestData } from '@/api/request'
import { ECreditFlowApiUrl } from '@/api/urls/ECreditFlowApiUrl'
import type { IInstallmentCalculatorRequest, IInstallmentCalculatorResponse } from '@/logic/financing/interfaces'

export default class InstallmentCalculator {
  create = async ({ onlySber, ...body }: IInstallmentCalculatorRequest) => {
    const response = await requestData<IInstallmentCalculatorResponse>({
      url: onlySber ? ECreditFlowApiUrl.installmentCalculatorSber : ECreditFlowApiUrl.installmentCalculator,
      method: 'POST',
      body,
    })

    return isStatusOk(response) ? response : Promise.reject(response)
  }
}
