import type { QueryKey, UseQueryResult, UseQueryOptions, QueryOptions } from "@tanstack/react-query";
import { useQuery } from "@tanstack/react-query";
import client from "@/api/client/axios";
import type { GetOrganizationQueryResponse, GetOrganizationPathParams, GetOrganization403, GetOrganization404, GetOrganization500 } from "../types/GetOrganization";

export const getOrganizationQueryKey = (organizationId: GetOrganizationPathParams["organizationId"]) =>     [{url:`/cc/operators/organizations/${organizationId}`,params:{organizationId:organizationId}}] as const;
      export function getOrganizationQueryOptions <TData = GetOrganizationQueryResponse, TError = GetOrganization403 | GetOrganization404 | GetOrganization500>(organizationId: GetOrganizationPathParams["organizationId"], options: Partial<Parameters<typeof client>[0]> = {}): UseQueryOptions<TData, TError> {
        const queryKey = getOrganizationQueryKey(organizationId, );

        return {
          queryKey,
          queryFn: () => {
            
            return client<TData, TError>({
              method: "get",
              url: `/cc/operators/organizations/${organizationId}`,
              
              
              ...options,
            }).then(res => res.data);
          },
        };
      };
      
/**
 * @summary Получение организации по id
 * @link /cc/operators/organizations/:organizationId
 */

export function useGetOrganization <TData = GetOrganizationQueryResponse, TError = GetOrganization403 | GetOrganization404 | GetOrganization500>(organizationId: GetOrganizationPathParams["organizationId"], options: {
          query?: UseQueryOptions<TData, TError>,
          client?: Partial<Parameters<typeof client<TData, TError>>[0]>,
        } = {}): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const { query: queryOptions, client: clientOptions = {} } = options ?? {};
  const queryKey = queryOptions?.queryKey ?? getOrganizationQueryKey(organizationId, );

  const query = useQuery<TData, TError>({
    ...getOrganizationQueryOptions<TData, TError>(organizationId, clientOptions),
    ...queryOptions
  }) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey as QueryKey;

  return query;
};
