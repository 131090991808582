import Cookies from 'js-cookie'
import { EUserRegionStorageCookieNames } from './constants'

// установка и чтение кук через `js-cookie` работает только в браузере
const options = { expires: 365 }
export const UserRegionStorage = {
  set(code: number) {
    Cookies.set(EUserRegionStorageCookieNames.REGION_ID, String(code), options)
  },

  confirm() {
    Cookies.set(EUserRegionStorageCookieNames.REGION_CONFIRMED, String(1), options)
  },

  reset() {
    Cookies.remove(EUserRegionStorageCookieNames.REGION_ID)
    Cookies.remove(EUserRegionStorageCookieNames.REGION_CONFIRMED)
  },
}
