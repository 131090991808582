import { getMaxShownItemsCount } from '@/components/ui/PaginationDeprecated/reducer'
import { getLocationQuery, isMobile } from '@/logic/app/reducer'
import type { TGetPaginationQuery } from './interfaces'

export const getPaginationQuery: TGetPaginationQuery = options => state => {
  const { itemsPerPage = 6 } = options || {}
  const { pageNumber = 1 } = getLocationQuery(state) || {}
  const limit = getMaxShownItemsCount()(state) || itemsPerPage
  const offset = isMobile()(state) ? 0 : limit * ((pageNumber as number) - 1)

  return { offset, limit }
}
