export const formats = {
  number: {
    roundTo2: {
      minimumFractionDigits: 0,
      maximumFractionDigits: 2,
    },
  },
}

export const appLocales = ['ru']
