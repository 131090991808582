import React from 'react'
import type { FieldRenderProps } from 'react-final-form'
import { Field } from 'react-final-form'
import type { FieldValidator } from 'final-form'
import omit from 'lodash/omit'
import { getValidateFunction, isAutosuggestFieldRequired } from '@/utils/validators'
import AutosuggestView from './AutosuggestView'
import type { IAutosuggestProps } from './interfaces'

function AutosuggestContainer<GSuggest>(props: IAutosuggestProps<GSuggest>) {
  const {
    isRequired,
    customValidator,
    fieldSubscription,
    errorCustomMessage,
    isRequiredWithoutSign,
    className,
    allowRawValue,
    onSuggestChange,
    onInputChange,
  } = props

  const validate = getValidateFunction(() => {
    //eslint-disable-next-line @typescript-eslint/no-explicit-any
    const validators: FieldValidator<any>[] = []
    if (isRequired || isRequiredWithoutSign) validators.push(isAutosuggestFieldRequired(errorCustomMessage))
    if (customValidator) validators.push(customValidator)

    return validators
  }) as FieldValidator<GSuggest>

  return (
    <Field<GSuggest> validate={validate} subscription={fieldSubscription} {...props}>
      {/*eslint-disable-next-line @typescript-eslint/no-explicit-any*/}
      {({ input, meta }: FieldRenderProps<GSuggest, any>) => (
        <AutosuggestView<GSuggest>
          className={className}
          {...props}
          meta={meta}
          onSuggestChange={value => {
            onSuggestChange?.(value)
            if (!allowRawValue || value !== null) input.onChange(value)
          }}
          onInputFocus={() => input.onFocus()}
          onInputBlur={() => input.onBlur()}
          onInputChange={(value, event) => {
            onInputChange?.(value, event)
            if (allowRawValue) input.onChange(event)
          }}
        />
      )}
    </Field>
  )
}

export default React.memo(AutosuggestContainer, (prev, next) => {
  const prevValue = omit(prev, 'customInputProps.bubblingTooltipProps')
  const nextValue = omit(next, 'customInputProps.bubblingTooltipProps')

  return JSON.stringify(prevValue) === JSON.stringify(nextValue)
}) as typeof AutosuggestContainer
