import client from "@/api/client/axios";
import type { ResponseConfig } from "@/api/client/axios";
import type { AddRequestChatMessageV1MutationRequest, AddRequestChatMessageV1MutationResponse, AddRequestChatMessageV1QueryParams } from "../../types/AddRequestChatMessageV1";

/**
     * @summary Добавить новое сообщение в чат
     * @link /api/monolith/v1/message
     */
export async function addRequestChatMessageV1 (data: AddRequestChatMessageV1MutationRequest, params: AddRequestChatMessageV1QueryParams, options: Partial<Parameters<typeof client>[0]> = {}): Promise<ResponseConfig<AddRequestChatMessageV1MutationResponse>["data"]> {
    const { data: resData } = await client<AddRequestChatMessageV1MutationResponse, AddRequestChatMessageV1MutationRequest>({
        method: "post",
        url: `/api/monolith/v1/message`,
        params,
        data,
        ...options
    });
    
    return resData;
};