import type { QueryKey, UseQueryResult, UseQueryOptions, QueryOptions } from "@tanstack/react-query";
import { useQuery } from "@tanstack/react-query";
import client from "@/api/client/axios";
import type { GetCategoryProductsQueryResponse, GetCategoryProductsQueryParams, GetCategoryProducts400, GetCategoryProducts500 } from "../types/GetCategoryProducts";

export const getCategoryProductsQueryKey = (params?: GetCategoryProductsQueryParams) =>     [{url:`/api/catalog/mainPage/products`},...(params ? [params] : [])] as const;
      export function getCategoryProductsQueryOptions <TData = GetCategoryProductsQueryResponse, TError = GetCategoryProducts400 | GetCategoryProducts500>(params?: GetCategoryProductsQueryParams, options: Partial<Parameters<typeof client>[0]> = {}): UseQueryOptions<TData, TError> {
        const queryKey = getCategoryProductsQueryKey(params);

        return {
          queryKey,
          queryFn: () => {
            
            return client<TData, TError>({
              method: "get",
              url: `/api/catalog/mainPage/products`,
              params,
              
              ...options,
            }).then(res => res.data);
          },
        };
      };
      
/**
 * @summary Получение списка товаров по категории
 * @link /api/catalog/mainPage/products
 */

export function useGetCategoryProducts <TData = GetCategoryProductsQueryResponse, TError = GetCategoryProducts400 | GetCategoryProducts500>(params?: GetCategoryProductsQueryParams, options: {
          query?: UseQueryOptions<TData, TError>,
          client?: Partial<Parameters<typeof client<TData, TError>>[0]>,
        } = {}): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const { query: queryOptions, client: clientOptions = {} } = options ?? {};
  const queryKey = queryOptions?.queryKey ?? getCategoryProductsQueryKey(params);

  const query = useQuery<TData, TError>({
    ...getCategoryProductsQueryOptions<TData, TError>(params, clientOptions),
    ...queryOptions
  }) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey as QueryKey;

  return query;
};
