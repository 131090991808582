import type { TMessages } from '@/types/TMessages'

export const messages = {
  title: 'Рассчитайте рассрочку',
  more: 'Подробнее',
  labels: {
    sum: 'Какая сумма вам нужна',
    days: 'На какой срок',
  },
  product: {
    limit: {
      maxSum: 'До {sumInMillions} млн ₽',
    },
  },
  products: {
    sberBusiness: {
      firstLine: 'Ежемесячное\nпогашение',
      lastLine: 'Залог не требуется',
    },
    agrointegrator: {
      firstLine: 'Погашение\nв конце срока',
      lastLine: 'Залог земли/\nтехники',
    },
    moneykapital: {
      firstLine: 'Сезонное\nпогашение',
      lastLine: 'Без залога\nдо 1 млн ₽',
    },
    poleCredit: {
      firstLine: 'Погашение\nв конце срока',
      lastLine: 'Залог земли/\nтехники',
    },
  },
  format: {
    daysNoun: '{days, plural, one {день} few {дня} other {дней}}',
    periodTail: '{daysNoun} (до {until})',
  },
  refundableAmount: 'Сумма к возврату',
  footnote:
    // eslint-disable-next-line max-len
    'Предложение носит информационный характер и не является публичной офертой по ст.437 (2) ГК РФ. Все приведенные расчеты являются предварительными. Для получения точных условий обратитесь, пожалуйста, к менеджерам. Предложение носит информационный характер',
  common: {
    select: 'Выбрать',
  },
  banner: {
    title: 'Сумма к возврату:',
    button: 'Перейти к покупкам',
  },
  button: {
    priceRequest: 'Отправить запрос цен',
  },
} satisfies TMessages
