import type { ChangeEventHandler, PropsWithChildren } from 'react'
import React, { forwardRef, memo, useRef } from 'react'
import { FormattedMessage } from 'react-intl'
import classNames from 'classnames'
import { Button } from '../'
import type { TUploadButtonProps } from './interfaces'

const UploadButton = memo(
  forwardRef<HTMLInputElement, PropsWithChildren<TUploadButtonProps>>((props, forwardedRef) => {
    const {
      children,
      name,
      onFocus,
      onBlur,
      onChange,
      multiple,
      text,
      textId,
      buttonProps,
      input,
      accept = '',
      closeModal,
      textValues,
    } = props
    const inputRef = useRef<HTMLInputElement>()

    const isDisabled = props.isDisabled || buttonProps?.isDisabled

    const chooseFile = () => inputRef.current?.click()

    const onFileChosen: ChangeEventHandler<HTMLInputElement> = event => {
      const { files } = event.target

      if (multiple) {
        input?.onChange(files ?? undefined)
        onChange?.(files?.length ? files : undefined)
      } else {
        input?.onChange(files?.[0] ?? undefined)
        onChange?.(files?.[0])
      }

      if (closeModal) closeModal()
    }

    return (
      <Button
        onClick={chooseFile}
        {...buttonProps}
        isDisabled={isDisabled}
        className={classNames(buttonProps?.className, 'upload-file-button')}
      >
        <input
          id={name}
          name={name}
          className="sr-only"
          type="file"
          disabled={isDisabled}
          multiple={multiple}
          onChange={onFileChosen}
          onBlur={onBlur}
          onFocus={onFocus}
          accept={accept}
          ref={e => {
            if (!e) return

            if (typeof forwardedRef === 'function') {
              forwardedRef?.(e)
            } else if (forwardedRef) {
              forwardedRef.current = e
            }

            inputRef.current = e
          }}
        />
        {children || text || (textId && <FormattedMessage id={textId} values={textValues} />)}
      </Button>
    )
  }),
)

export default UploadButton
