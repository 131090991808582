import React from 'react'
import Autosuggest from '../Autosuggest/Autosuggest'
import type { IAutosuggestProps } from '../Autosuggest/interfaces'
import {
  getSuggestionValue,
  onSuggestionSelectedWithAll,
  onSuggestionSelectedWithCode,
  onSuggestionSelectedWithName,
  onSuggestionSelectedWithNameAndRegionKladrId,
  onSuggestionsFetchRequestedFromAlfa,
  onSuggestionsFetchRequestedFromDadata,
} from './helpers'
import type {
  IAlfaSuggestionCity,
  IDadataSuggestionCity,
  ISearchCitiesWithSuggestionsProps,
  TSearchingCitiesApiType,
  TSearchingCitiesFormatType,
} from './interfaces'
import { messages } from './messages'
import { isCitySuggestionFormAlfa } from './typeguard'

const SuggestionCity: React.FC<IAlfaSuggestionCity | IDadataSuggestionCity> = cityInfo => {
  if (isCitySuggestionFormAlfa(cityInfo)) {
    const { region, name } = cityInfo

    return <div>{`${region?.name}, ${name}`}</div>
  }

  const { city } = cityInfo

  return <div>{`${city}`}</div>
}

const SuccessRequestStrategy: Record<
  TSearchingCitiesApiType,
  IAutosuggestProps<IAlfaSuggestionCity | IDadataSuggestionCity>['onSuggestionsFetchRequested']
> = {
  alfa: onSuggestionsFetchRequestedFromAlfa,
  dadata: onSuggestionsFetchRequestedFromDadata,
}

const SelectingSuggestionStrategy: Record<
  TSearchingCitiesFormatType,
  IAutosuggestProps<IAlfaSuggestionCity | IDadataSuggestionCity>['onSuggestionSelected']
> = {
  all: onSuggestionSelectedWithAll,
  name: onSuggestionSelectedWithName,
  code: onSuggestionSelectedWithCode,
  nameAndRegionKladrId: onSuggestionSelectedWithNameAndRegionKladrId,
}

const SearchCitiesWithSuggestions: React.FC<ISearchCitiesWithSuggestionsProps> = props => {
  const {
    name = 'city',
    isRequired = true,
    valueFormattingVariant = 'code',
    apiType = 'alfa',
    isRequiredWithoutSign,
    requiredError = messages.error.incorrectCity,
    onChange,
    className,
    customValidator,
  } = props

  return (
    <Autosuggest<IAlfaSuggestionCity | IDadataSuggestionCity>
      name={name}
      suggestionComponent={SuggestionCity}
      isRequired={isRequired}
      errorCustomMessage={requiredError}
      getSuggestionValue={getSuggestionValue}
      onInputChange={onChange}
      onSuggestionsFetchRequested={SuccessRequestStrategy[apiType]}
      onSuggestionSelected={SelectingSuggestionStrategy[valueFormattingVariant]}
      customInputProps={{
        label: messages.placeholder,
        className: 'space-holder8',
      }}
      isRequiredWithoutSign={isRequiredWithoutSign}
      shouldHighlightFirstSuggestion
      className={className}
      customValidator={customValidator}
    />
  )
}

export default SearchCitiesWithSuggestions
