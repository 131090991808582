import type { PayloadAction } from '@reduxjs/toolkit'
import { createReducer } from '@reduxjs/toolkit'
import { getInitialState } from '@/redux/helpers'
import type { RootState } from '@/redux/interfaces'
import { reducerManager } from '@/redux/reducerManager'
import { setCookieAgreement } from './actions'
import type { ICookieAgreementState } from './interfaces'

const initialState: ICookieAgreementState = {
  isAgreed: true,
}

const reducerName = 'cookieAgreement'

const reducer = createReducer(getInitialState(reducerName, initialState), {
  [setCookieAgreement.type]: (state, { payload: isAgreed }: PayloadAction<boolean>) => {
    state.isAgreed = isAgreed
  },
})

reducerManager.add(reducerName, reducer, true)

export const isCookieAgreed = () => (state: RootState) => state[reducerName].isAgreed
