import { createReducer } from '@reduxjs/toolkit'
import type { IBreadcrumb } from '@/components/ui/Breadcrumbs/Breadcrumb/interfaces'
import { getInitialState } from '@/redux/helpers'
import type { RootState } from '@/redux/interfaces'
import { reducerManager } from '@/redux/reducerManager'
import { clearBreadcrumbs, setBreadcrumbs } from './actions'

export interface BreadcrumbsState {
  breadcrumbs: IBreadcrumb[]
}

const initialState: BreadcrumbsState = {
  breadcrumbs: [],
}

const reducerName = 'breadcrumbs'

const reducer = createReducer(getInitialState(reducerName, initialState), {
  [setBreadcrumbs.type]: (state, action) => {
    state.breadcrumbs = action.payload
  },
  [clearBreadcrumbs.type]: state => {
    state.breadcrumbs = []
  },
})

reducerManager.add(reducerName, reducer, true)

export const getBreadcrumbs = () => (state: RootState) => state.breadcrumbs.breadcrumbs
