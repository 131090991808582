import type { QueryKey, UseQueryResult, UseQueryOptions, QueryOptions } from "@tanstack/react-query";
import { useQuery } from "@tanstack/react-query";
import client from "@/api/client/axios";
import type { GetAccreditationQueryResponse, GetAccreditationPathParams, GetAccreditation403, GetAccreditation500 } from "../types/GetAccreditation";

export const getAccreditationQueryKey = (accreditationId: GetAccreditationPathParams["accreditationId"]) =>     [{url:`/api/accreditations/${accreditationId}`,params:{accreditationId:accreditationId}}] as const;
      export function getAccreditationQueryOptions <TData = GetAccreditationQueryResponse, TError = GetAccreditation403 | GetAccreditation500>(accreditationId: GetAccreditationPathParams["accreditationId"], options: Partial<Parameters<typeof client>[0]> = {}): UseQueryOptions<TData, TError> {
        const queryKey = getAccreditationQueryKey(accreditationId, );

        return {
          queryKey,
          queryFn: () => {
            
            return client<TData, TError>({
              method: "get",
              url: `/api/accreditations/${accreditationId}`,
              
              
              ...options,
            }).then(res => res.data);
          },
        };
      };
      
/**
 * @summary Запрос на получение аккредитации
 * @link /api/accreditations/:accreditationId
 */

export function useGetAccreditation <TData = GetAccreditationQueryResponse, TError = GetAccreditation403 | GetAccreditation500>(accreditationId: GetAccreditationPathParams["accreditationId"], options: {
          query?: UseQueryOptions<TData, TError>,
          client?: Partial<Parameters<typeof client<TData, TError>>[0]>,
        } = {}): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const { query: queryOptions, client: clientOptions = {} } = options ?? {};
  const queryKey = queryOptions?.queryKey ?? getAccreditationQueryKey(accreditationId, );

  const query = useQuery<TData, TError>({
    ...getAccreditationQueryOptions<TData, TError>(accreditationId, clientOptions),
    ...queryOptions
  }) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey as QueryKey;

  return query;
};
