import classNames from 'classnames'
import styled, { css } from 'styled-components'
import { getStaticGlassMorphIconPath } from './helpers'
import type { IGlassMorphIconProps } from './interfaces'
import './styles.scss'

const GlassMorphIcon = styled.span.attrs<IGlassMorphIconProps>(({ className, modifiers, ...props }) => ({
  className: classNames(
    'glass-morph-icon',
    className,
    modifiers?.reduce((result, modifier) => `${result} glass-morph-icon_${modifier}`, ''),
  ),
  ...props,
}))<IGlassMorphIconProps>`
  background-image: ${({ image }) => `url("${getStaticGlassMorphIconPath(image)}")`};
  ${({ hoverImage }) =>
    hoverImage &&
    css`
      &::before {
        background-image: url('${getStaticGlassMorphIconPath(hoverImage)}');
      }
    `}
`

export default GlassMorphIcon
