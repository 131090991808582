import type { FC } from 'react'
import React from 'react'
import { DocumentCard } from '@/components/resolvers'
import type { IDocument } from '@/types/Documents'
import { EDocumentStatus } from '@/types/Documents'
import { categoryGroups } from '../../constants'
import DocumentStatus from '../DocumentStatus'
import type { IDocumentViewProps } from './interfaces'

const DocumentView: FC<IDocumentViewProps> = props => {
  const {
    document,
    typesById,
    shouldHideUpdatingDocumentButton,
    shouldDisplayDocumentStatusElement,
    canUpdateDocument,
    canDeleteDocument,
    rejectingDocumentButtonName,
    downloadDoc,
    onUpdateDocument,
    onRejectDocument,
  } = props
  const { id: documentId, status: documentStatus } = document

  const DocumentStatusElement = shouldDisplayDocumentStatusElement ? (
    <DocumentStatus
      typedCategories={typesById[documentId]?.categories || []}
      categoryGroups={categoryGroups}
      document={document as IDocument}
    />
  ) : undefined

  return (
    <DocumentCard
      onDownloadDocument={() => downloadDoc(document)}
      onUpdateDocument={(action, file) => onUpdateDocument(action, document, file)}
      document={document}
      canUpdateDocument={canUpdateDocument}
      canDeleteDocument={canDeleteDocument}
      updateDocumentRequestName={`updateDocument_${documentId}`}
      deleteDocumentRequestName={`deleteDocument_${documentId}`}
      downloadDocumentRequestName={`downloadDocument_${documentId}`}
      openRejectingDocumentModal={onRejectDocument}
      isDocumentRejected={documentStatus === EDocumentStatus.REJECTED}
      rejectingDocumentButtonText={rejectingDocumentButtonName}
      documentStatus={document.status}
      DocumentStatusElement={DocumentStatusElement}
      shouldHideUpdatingDocumentButton={shouldHideUpdatingDocumentButton}
    />
  )
}

export default DocumentView
