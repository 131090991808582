import React from 'react'
import DownloadMobileApp from '@/components/composed/Footer/components/DownloadMobileApp/DownloadMobileApp'
// TODO: переиспользуемый хук, вынести выше при выносе футера
// https://jira.poleinvest.ru/browse/BETS-320
import { useDeviceType } from '@/utils/hooks/useDeviceType'
import { FooterHiddenPhoneNumbersForCrawlers, FooterSection } from './components'
import { EFooterSection } from './interfaces'
import { messages } from './messages'
import { links } from './utils'
import './styles.scss'

const Footer: React.FC = () => {
  const { isMobile } = useDeviceType()

  return (
    <footer className="footer">
      <div className="footer__wrapper">
        <div className="footer__section">
          <FooterSection section={EFooterSection.MAIN} links={links.main} />
        </div>
        <div className="footer__section">
          <FooterSection section={EFooterSection.COMPANY} links={links.company} isHidden={isMobile} />
          <FooterSection section={EFooterSection.SOCIAL} links={links.social} />
        </div>
        <div className="footer__section footer__contacts">
          <FooterSection section={EFooterSection.CONTACTS} links={links.contacts} />
          <FooterHiddenPhoneNumbersForCrawlers />
          <FooterSection section={EFooterSection.COMPANY} links={links.company} isExpandable isHidden={!isMobile} />
          <FooterSection section={EFooterSection.CONTACT_US} links={links.contactUs} isExpandable />
        </div>
        <div className="footer__section footer__mobileBlock">
          <DownloadMobileApp />
        </div>
      </div>
      <div className="footer__signs">
        <div>{messages.allRightsReserved}</div>
        <div>{`© ${new Date().getFullYear()} ${messages.site}`}</div>
      </div>
    </footer>
  )
}

export default Footer
