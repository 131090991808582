import type { TDatePickerValue } from '@frontend/pole-ui/lib/components/DatePicker'
import { format } from 'date-fns'
import type { FieldValidator } from 'final-form'
import type { IDatePickerProps } from '@/components/ui/fields/DatePicker/interfaces'
import { SERVER_DATE_FORMAT } from '@/constants/common'
import { getValidateFunction, isFieldRequired } from '@/utils/validators'

export const useDatePickerFieldProps = (props: IDatePickerProps) => {
  const { parsingFormat = SERVER_DATE_FORMAT } = props

  const validate = getValidateFunction(() => {
    const validators: FieldValidator<TDatePickerValue>[] = []
    if (props.isRequired || props.isRequiredWithoutSign) validators.push(isFieldRequired)

    return validators
  }) as FieldValidator<TDatePickerValue>

  const parseDate = (date: Date | Date[]) => {
    if (date instanceof Date) return format(date, parsingFormat)
    else if (Array.isArray(date)) return date.map(parsingDate => format(parsingDate, parsingFormat))

    return date
  }

  return { validate, parseDate }
}
