import type { IDropdownOption } from '@frontend/pole-ui/lib/components/Dropdown'
import type { PayloadAction } from '@reduxjs/toolkit'
import { createReducer, createSelector } from '@reduxjs/toolkit'
import {
  clearHarvestSale,
  clearSaleRequestBuyerOptions,
  clearSavedSaleRequestFormValues,
  requestHarvestSale,
  requestingHarvestSaleFailed,
  requestingHarvestSaleFinished,
  resetRequestingHarvestSaleRequestState,
  saveSaleRequestBuyerOptions,
  saveSaleRequestFormValues,
  setHarvestSaleDeliveryBasis,
  setHarvestSaleFilterOptions,
} from '@/logic/harvestSale/actions'
import { getInitialState } from '@/redux/helpers'
import type { RootState } from '@/redux/interfaces'
import { reducerManager } from '@/redux/reducerManager'
import { templatesPerPage } from './constants'
import type { IHarvestSaleState, ISetTemplatesPayload, NHarvestSaleAction, TFilterOptions } from './interfaces'
import { fetchTemplates } from './thunks'

const initialState: IHarvestSaleState = {
  isFetching: false,
  templates: [],
  filters: {
    all: [],
    top: [],
    aside: [],
  },
  indicators: [],
  filterOptions: {} as TFilterOptions,
  pagination: {
    total: 0,
    offset: 0,
    limit: templatesPerPage,
  },
  isFailure: false,
  isRequestInProcess: false,
  isRequestedSuccessfully: false,
  isRequestedUnsuccessfully: false,
  savedSaleRequestForm: {},
  savedSaleRequestBuyerOptions: [],
}

const reducerName = 'harvestSale'

const reducer = createReducer(getInitialState(reducerName, initialState), {
  [fetchTemplates.pending.type]: state => {
    state.isFetching = true
    state.isFailure = false
  },
  [fetchTemplates.fulfilled.type]: (state, { payload }: PayloadAction<ISetTemplatesPayload>) => {
    const { templates, filters, pagination, topFilters, asideFilters, filterOptions, indicators } = payload
    state.templates = templates

    state.filters = {
      all: filters,
      top: topFilters,
      aside: asideFilters,
    }

    state.indicators = indicators
    state.filterOptions = filterOptions
    state.pagination = pagination
    state.isFetching = false
  },
  [fetchTemplates.rejected.type]: state => {
    state.isFetching = false
    state.isFailure = true
  },
  [clearHarvestSale.type]: state => ({
    ...initialState,
    isRequestedUnsuccessfully: state.isRequestedUnsuccessfully,
  }),
  [requestHarvestSale.type]: state => {
    state.isRequestInProcess = true
  },
  [requestingHarvestSaleFailed.type]: state => {
    state.isRequestInProcess = false
    state.isRequestedUnsuccessfully = true
  },
  [requestingHarvestSaleFinished.type]: state => {
    state.isRequestInProcess = false
    state.isRequestedSuccessfully = true
  },
  [resetRequestingHarvestSaleRequestState.type]: state => {
    state.isRequestInProcess = false
    state.isRequestedSuccessfully = false
    state.isRequestedUnsuccessfully = false
  },
  [saveSaleRequestFormValues.type]: (state, { payload }: PayloadAction<NHarvestSaleAction.ISaveSaleRequestFormValues>) => {
    state.savedSaleRequestForm = payload
  },
  [saveSaleRequestBuyerOptions.type]: (state, { payload }: PayloadAction<IDropdownOption[]>) => {
    state.savedSaleRequestBuyerOptions = payload
  },
  [clearSaleRequestBuyerOptions.type]: state => {
    state.savedSaleRequestBuyerOptions = []
  },
  [clearSavedSaleRequestFormValues.type]: state => {
    state.savedSaleRequestForm = {}
  },
  [setHarvestSaleFilterOptions.type]: (state, { payload }: PayloadAction<TFilterOptions>) => {
    state.filterOptions = payload
  },
  [setHarvestSaleDeliveryBasis.type]: (state, { payload }: PayloadAction<IDropdownOption[]>) => {
    state.filterOptions.deliveryBasis = payload
  },
})

reducerManager.add(reducerName, reducer, true)

export const isTemplatesFetching = () => (state: RootState) => state.harvestSale.isFetching
export const getTemplates = () => (state: RootState) => state.harvestSale.templates
export const getFilters = () => (state: RootState) => state.harvestSale.filters
export const getIndicators = () => (state: RootState) => state.harvestSale.indicators
export const getPaginationInfo = () => (state: RootState) => state.harvestSale.pagination
export const getFilterOptions = () => (state: RootState) => state.harvestSale.filterOptions
export const isFetchingTemplatesFailed = () => (state: RootState) => state.harvestSale.isFailure
export const getPagesQuantity = createSelector(getPaginationInfo(), pagination => Math.ceil(pagination.total / templatesPerPage))
export const isRequestInProcess = () => (state: RootState) => state.harvestSale.isRequestInProcess
export const isRequestedSuccessfully = () => (state: RootState) => state.harvestSale.isRequestedSuccessfully
export const isRequestedUnsuccessfully = () => (state: RootState) => state.harvestSale.isRequestedUnsuccessfully
export const getSavedSaleRequestForm = () => (state: RootState) => state.harvestSale.savedSaleRequestForm
export const getSavedSaleRequestBuyerOptions = () => (state: RootState) => state.harvestSale.savedSaleRequestBuyerOptions
