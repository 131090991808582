import client from "@/api/client/axios";
import type { ResponseConfig } from "@/api/client/axios";
import type { GetTags1QueryResponse, GetTags1QueryParams } from "../../types/GetTags1";

/**
     * @link /api/ms-content-infoportal-backend/v1/admin/tags
     */
export async function getTags1 (params?: GetTags1QueryParams, options: Partial<Parameters<typeof client>[0]> = {}): Promise<ResponseConfig<GetTags1QueryResponse>["data"]> {
    const { data: resData } = await client<GetTags1QueryResponse>({
        method: "get",
        url: `/api/ms-content-infoportal-backend/v1/admin/tags`,
        params,
        ...options
    });
    
    return resData;
};