import type { PropsWithChildren } from 'react'
import React, { useMemo } from 'react'
import { FormattedMessage } from 'react-intl'
import type { ITabProps } from '@frontend/pole-ui/lib/components/Tab'
import { Tabs } from '@frontend/pole-ui/lib/components/Tabs'
import type { ISorterButtonsProps } from './interfaces'
import './styles.scss'

function SorterButtons<GSorterId>(props: PropsWithChildren<ISorterButtonsProps<GSorterId>>) {
  const { sorterButtons } = props

  const tabs: ITabProps<GSorterId>[] = useMemo(
    () => sorterButtons.map(({ textId, text, id: value }) => ({ value, text: text ?? <FormattedMessage id={textId} /> })),
    [sorterButtons],
  )

  return <Tabs<GSorterId> {...props} tabs={tabs} />
}

export default SorterButtons
