import type { UseMutationOptions, UseMutationResult } from "@tanstack/react-query";
import { useMutation } from "@tanstack/react-query";
import client from "@/api/client/axios";
import type { ResponseConfig } from "@/api/client/axios";
import type { DeleteDocumentRequestShippingMutationResponse, DeleteDocumentRequestShippingPathParams, DeleteDocumentRequestShippingQueryParams, DeleteDocumentRequestShipping404, DeleteDocumentRequestShipping500 } from "../types/DeleteDocumentRequestShipping";

/**
 * @summary Запрос на удаление документа в карточке Отгрузки
 * @link /api/salerequests/:requestId/shippings/:shippingId/documents/:documentId
 */

export function useDeleteDocumentRequestShipping <TData = DeleteDocumentRequestShippingMutationResponse, TError = DeleteDocumentRequestShipping404 | DeleteDocumentRequestShipping500>(requestId: DeleteDocumentRequestShippingPathParams["requestId"], shippingId: DeleteDocumentRequestShippingPathParams["shippingId"], documentId: DeleteDocumentRequestShippingPathParams["documentId"], params?: DeleteDocumentRequestShippingQueryParams, options: {
          mutation?: UseMutationOptions<ResponseConfig<TData>, TError, void>,
          client?: Partial<Parameters<typeof client<TData, TError, void>>[0]>,
      } = {}): UseMutationResult<ResponseConfig<TData>, TError, void> {
  const { mutation: mutationOptions, client: clientOptions = {} } = options ?? {};

  return useMutation<ResponseConfig<TData>, TError, void>({
    mutationFn: () => {
      
      return client<TData, TError, void>({
        method: "delete",
        url: `/api/salerequests/${requestId}/shippings/${shippingId}/documents/${documentId}`,
        
        params,
        
        ...clientOptions
      });
    },
    ...mutationOptions
  });
};
