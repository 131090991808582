import { ALREADY_RELOADED_FLAG_STORAGE_KEY, ALREADY_RELOADED_FLAG_UP_VALUE } from './constants'

export const setAlreadyReloadedFlag = () => {
  window.sessionStorage.setItem(ALREADY_RELOADED_FLAG_STORAGE_KEY, ALREADY_RELOADED_FLAG_UP_VALUE)
}

export const isAlreadyReloadedFlagUp = () => {
  const flag = window.sessionStorage.getItem(ALREADY_RELOADED_FLAG_STORAGE_KEY)

  return flag === ALREADY_RELOADED_FLAG_UP_VALUE
}
