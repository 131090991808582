import client from "@/api/client/axios";
import type { ResponseConfig } from "@/api/client/axios";
import type { GetCulturesQueryResponse } from "../../types/GetCultures";

/**
     * @summary Получение культур
     * @link /api/requests/cultures
     */
export async function getCultures (options: Partial<Parameters<typeof client>[0]> = {}): Promise<ResponseConfig<GetCulturesQueryResponse>["data"]> {
    const { data: resData } = await client<GetCulturesQueryResponse>({
        method: "get",
        url: `/api/requests/cultures`,
        ...options
    });
    
    return resData;
};