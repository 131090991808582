import client from "@/api/client/axios";
import type { ResponseConfig } from "@/api/client/axios";
import type { GetRubrics1QueryResponse, GetRubrics1QueryParams } from "../../types/GetRubrics1";

/**
     * @link /api/ms-content-infoportal-backend/v1/admin/rubrics
     */
export async function getRubrics1 (params?: GetRubrics1QueryParams, options: Partial<Parameters<typeof client>[0]> = {}): Promise<ResponseConfig<GetRubrics1QueryResponse>["data"]> {
    const { data: resData } = await client<GetRubrics1QueryResponse>({
        method: "get",
        url: `/api/ms-content-infoportal-backend/v1/admin/rubrics`,
        params,
        ...options
    });
    
    return resData;
};