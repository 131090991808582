import type { UseMutationOptions, UseMutationResult } from "@tanstack/react-query";
import { useMutation } from "@tanstack/react-query";
import client from "@/api/client/axios";
import type { ResponseConfig } from "@/api/client/axios";
import type { DeleteMultiRequestDiscountMutationResponse, DeleteMultiRequestDiscountPathParams, DeleteMultiRequestDiscount400, DeleteMultiRequestDiscount404, DeleteMultiRequestDiscount500 } from "../types/DeleteMultiRequestDiscount";

/**
 * @summary Удаление скидки
 * @link /api/multirequests/:multiRequestId/discounts/:discountId
 */

export function useDeleteMultiRequestDiscount <TData = DeleteMultiRequestDiscountMutationResponse, TError = DeleteMultiRequestDiscount400 | DeleteMultiRequestDiscount404 | DeleteMultiRequestDiscount500>(multiRequestId: DeleteMultiRequestDiscountPathParams["multiRequestId"], discountId: DeleteMultiRequestDiscountPathParams["discountId"], options: {
          mutation?: UseMutationOptions<ResponseConfig<TData>, TError, void>,
          client?: Partial<Parameters<typeof client<TData, TError, void>>[0]>,
      } = {}): UseMutationResult<ResponseConfig<TData>, TError, void> {
  const { mutation: mutationOptions, client: clientOptions = {} } = options ?? {};

  return useMutation<ResponseConfig<TData>, TError, void>({
    mutationFn: () => {
      
      return client<TData, TError, void>({
        method: "delete",
        url: `/api/multirequests/${multiRequestId}/discounts/${discountId}`,
        
        
        
        ...clientOptions
      });
    },
    ...mutationOptions
  });
};
