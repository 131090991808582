export enum EDocumentCategory {
  PRIVATE_ORDER_VALIDATION_DOCUMENTS = 'PRIVATE_ORDER_VALIDATION_DOCUMENTS',
  COMPANY_ORDER_VALIDATION_DOCUMENTS = 'COMPANY_ORDER_VALIDATION_DOCUMENTS',
  PRIVATE_VALIDATION_INPUTS = 'PRIVATE_VALIDATION_INPUTS',
  COMPANY_VALIDATION_INPUTS = 'COMPANY_VALIDATION_INPUTS',
  PRIVATE_VALIDATION_OUTPUTS = 'PRIVATE_VALIDATION_OUTPUTS',
  COMPANY_VALIDATION_OUTPUTS = 'COMPANY_VALIDATION_OUTPUTS',
  ORDER = 'ORDER',
  REQUEST = 'REQUEST',
}
