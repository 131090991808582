import React, { useCallback, useRef } from 'react'
import type { FieldPath, FieldValues } from 'react-hook-form'
import { useController } from 'react-hook-form'
import { RangeSlider } from '@frontend/pole-ui/lib/components/RangeSlider'
import { getMappedFieldMetaState, splitHookFormControllerProps } from '@/utils/fields'
import type { IRangeSliderProps } from './types'

export function HookFormRangeSlider<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>(props: IRangeSliderProps<TFieldValues, TName>) {
  const [controllerProps, { isDisabled, onBlur, onChange, onFocus, ...componentProps }] = splitHookFormControllerProps(props)

  const disabled = controllerProps.disabled || isDisabled

  const inputRef = useRef<HTMLInputElement | null>(null)

  const { field, fieldState } = useController({ ...controllerProps, disabled })

  const onInputBlur: NonNullable<typeof onBlur> = useCallback(() => {
    field.onBlur()
    onBlur?.()

    const maximumAllowedValue = componentProps.sliderProps?.maxValue ?? 0
    const minimumAllowedValue = componentProps.sliderProps?.minValue ?? 0
    const currentValue = field.value

    if (currentValue < minimumAllowedValue) {
      field.onChange(minimumAllowedValue)
    }

    if (currentValue > maximumAllowedValue) {
      field.onChange(maximumAllowedValue)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [field, onBlur])

  const onInputChange: NonNullable<typeof onChange> = useCallback(
    event => {
      field.onChange(event)
      onChange?.(event)
    },
    [field, onChange],
  )

  const onInputFocus: NonNullable<typeof onFocus> = useCallback(() => {
    inputRef.current?.focus()
    onFocus?.()
  }, [onFocus])

  return (
    <RangeSlider
      errorText={fieldState.error?.message}
      {...componentProps}
      {...field}
      isDisabled={field.disabled}
      onBlur={onInputBlur}
      onChange={onInputChange}
      onFocus={onInputFocus}
      meta={getMappedFieldMetaState(fieldState)}
      ref={e => {
        field.ref(e)
        inputRef.current = e
      }}
    />
  )
}
