import type { ReactNode } from 'react'
import type { EChipModifier } from '@frontend/pole-ui/lib/components/Chip'

export enum EAgriculturalProductPromoLabelSize {
  BIG = 'big',
  MEDIUM = 'medium',
  SMALL = 'small',
}

export interface IAgriculturalProductPromoLabelProps {
  shouldShowLabel?: boolean
  className?: string
  textId?: string
  text?: string
  //eslint-disable-next-line @typescript-eslint/no-explicit-any
  textValues?: Record<string, any>
  size?: EAgriculturalProductPromoLabelSize
  color?: EChipModifier
  promoExplanationMessageId?: string
  promoExplanationMessage?: string
  children?: ReactNode
  url?: string
}
