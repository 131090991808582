import type { FC } from 'react'
import React from 'react'
import Swiper from 'react-id-swiper/lib/ReactIdSwiper'
import type { ReactIdSwiperProps } from 'react-id-swiper/lib/types'
import { EIconName } from '@frontend/pole-ui/lib/components/Icon'
import classNames from 'classnames'
import { ReviewsNote } from '@/components/composed/Reviews/ReviewsNote'
import { SwiperArrow } from '@/components/ui'
import { useSwiper } from '@/components/ui/SwiperArrows/useSwiper'
import { EGtmLabel } from '@/logic/metrika/financing/types'
import { messages } from './messages'
import type { IReviewsProps } from './types'
import './styles.scss'

export const Reviews: FC<IReviewsProps> = props => {
  const { sectionId, className, title, reviews, wrapperClassName, titleClassName, sliderClassName, slideClassName, onEvent } = props

  const { swiperRef, slideNext, slidePrevious } = useSwiper()

  const swiperParams: ReactIdSwiperProps = {
    loop: true,
    simulateTouch: false,
    breakpoints: { 600: { slidesPerView: 1, spaceBetween: 0 }, 1200: { slidesPerView: 2, spaceBetween: 4 } },
  }

  const onSwipeLeft = () => {
    slidePrevious()
    onEvent?.(EGtmLabel.ARROW_LEFT)
  }

  const onSwipeRight = () => {
    slideNext()
    onEvent?.(EGtmLabel.ARROW_RIGHT)
  }

  return (
    <section className={classNames('reviews', className)} id={sectionId}>
      <div className={wrapperClassName}>
        <h2 className={classNames(titleClassName, 'reviews__title')}>{title}</h2>

        <div className={classNames('reviews__slider', sliderClassName)}>
          <Swiper ref={swiperRef} {...swiperParams}>
            {reviews.map(({ name, text, location, farm }) => (
              <div key={name} className={classNames(slideClassName, 'swiper-slide reviewsSlide')}>
                <h4 className="reviewsSlide__author">{name}</h4>
                <p className="reviewsSlide__text">{text}</p>
                <div className="reviewsSlide__notes">
                  <ReviewsNote icon={EIconName.Location2} label={messages.location} value={location} />
                  <ReviewsNote icon={EIconName.Size} label={messages.farm} value={farm} />
                </div>
              </div>
            ))}
          </Swiper>

          <SwiperArrow
            modifier="swiper-with-white-background"
            direction="left"
            onClick={onSwipeLeft}
            className="reviews__arrow reviews__arrow--left"
          />

          <SwiperArrow
            modifier="swiper-with-white-background"
            direction="right"
            onClick={onSwipeRight}
            className="reviews__arrow reviews__arrow--right"
          />
        </div>
      </div>
    </section>
  )
}
