import React, { memo, useMemo } from 'react'
import { useFormState } from 'react-final-form'
import get from 'lodash/get'
import partition from 'lodash/partition'
import type { ICheckboxesFilterCommonProps } from '@/components/composed/CheckboxesFilter/interfaces'
import { Checkbox } from '@/components/ui/fields'
import { MAX_CHECKBOXES_WHILE_COLLAPSED } from '../constants'

const CheckboxesFilterCommon: React.FC<ICheckboxesFilterCommonProps> = props => {
  const { checkboxProps, checkboxes, shouldExpand, controlStyle, onChange } = props
  const { values } = useFormState()

  const shownCheckboxes = useMemo(() => {
    const [checked, uncheckedRaw] = partition(checkboxes, checkbox => get(values, checkbox.name))
    const shownCheckboxesCount = MAX_CHECKBOXES_WHILE_COLLAPSED - checked.length
    const unchecked = shouldExpand ? uncheckedRaw : uncheckedRaw.slice(0, shownCheckboxesCount > 0 ? shownCheckboxesCount : 0)

    return [...checked, ...unchecked]
  }, [checkboxes, shouldExpand, values])

  return (
    <>
      {shownCheckboxes.map(checkbox => (
        <Checkbox
          key={`commonCheckboxes-${checkbox.id}`}
          onChange={e => (onChange ? onChange(e, { label: checkbox.label }) : null)}
          {...checkbox}
          {...checkboxProps}
          controlStyle={controlStyle}
        />
      ))}
    </>
  )
}

export default memo(CheckboxesFilterCommon)
