import { combineEpics, ofType } from 'redux-observable'
import { filter, mergeMap, race, skip, take, tap } from 'rxjs'
import type { Epic, TActionStream } from '@/logic/interfaces'
import { pushViewingProductGtmEvent } from '@/logic/metrika/agriculturalProduct/gtmEvents'
import { getMergedOfferWithSku } from '@/logic/metrika/catalog/helpers'
import { requestFailed, requestFinished } from '@/logic/requestStatus/actions'
import type { IRequestStatusPayload } from '@/logic/requestStatus/interfaces'
import { fetchAgriculturalProduct } from '@/pages/AgriculturalProduct/thunks'
import type { IFetchAgriculturalProductPayload, IProductDetailedResponse } from '@/pages/AgriculturalProduct/types'
import { EAgriculturalProductRequestName } from '@/pages/AgriculturalProduct/types'
import { getErrorTextFromResponseMeta } from '@/utils/metrika/helpers'

export type TFetchProductsPendingPayload = { type: string; meta: { arg: IFetchAgriculturalProductPayload } }
export type TFetchProductsPendingActionCreator = () => TFetchProductsPendingPayload

const onProductFetchedDoPushGtmEvent: Epic<IFetchAgriculturalProductPayload, TFetchProductsPendingActionCreator> = action$ =>
  action$.pipe(
    filter(fetchAgriculturalProduct.pending.match),
    mergeMap(() =>
      race(
        (
          action$ as unknown as TActionStream<
            IRequestStatusPayload<IProductDetailedResponse>,
            typeof requestFinished | typeof requestFailed
          >
        ).pipe(
          ofType(requestFinished.type, requestFailed.type),
          filter(({ payload }) => payload.name.includes(EAgriculturalProductRequestName.FETCH)),
          take(1),
          tap(({ payload, type }) => {
            const { data, meta } = payload

            if (typeof data?.product !== 'undefined') {
              pushViewingProductGtmEvent(
                requestFailed.type === type ? (`err:${getErrorTextFromResponseMeta(meta)}` as const) : 'success',
                data.product.offers.map(offer => getMergedOfferWithSku(offer, data.product)),
                data.product.category.id,
              )
            }
          }),
          skip(1),
        ),
      ),
    ),
  )
//eslint-disable-next-line @typescript-eslint/no-explicit-any
export default <any>combineEpics(onProductFetchedDoPushGtmEvent)
