import client from "@/api/client/axios";
import type { ResponseConfig } from "@/api/client/axios";
import type { GetTopNewsHarvestQueryResponse } from "../../types/GetTopNewsHarvest";

/**
     * @link /api/ms-content-infoportal-backend/v1/publications/topNews/harvest
     */
export async function getTopNewsHarvest (options: Partial<Parameters<typeof client>[0]> = {}): Promise<ResponseConfig<GetTopNewsHarvestQueryResponse>["data"]> {
    const { data: resData } = await client<GetTopNewsHarvestQueryResponse>({
        method: "get",
        url: `/api/ms-content-infoportal-backend/v1/publications/topNews/harvest`,
        ...options
    });
    
    return resData;
};