import type { FC } from 'react'
import { createPortal } from 'react-dom'
import { usePortal } from '@/utils/hooks'
import { PORTAL_ID } from './constants'
import type { IPortalProps } from './types'

const Portal: FC<IPortalProps> = ({ id = PORTAL_ID, children }) => {
  const target = usePortal(id)
  if (!target) return null

  return createPortal(children, target)
}

export default Portal
