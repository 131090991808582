import { pushProductsGtmEvent } from '@/logic/metrika/catalog/gtmEvents'
import type { ICatalogGtmEventOptions, TMergedOfferWithSku } from '@/logic/metrika/catalog/interfaces'

export const pushViewingProductGtmEvent = (
  eventStatus: ICatalogGtmEventOptions['eventStatus'],
  products: TMergedOfferWithSku[],
  categoryId: number,
) =>
  pushProductsGtmEvent({
    blockName: 'blockProductCard',
    eventCategory: 'ecommerce',
    ecommerceType: 'detail',
    eventAction: 'viewing',
    eventLabel: 'productCard',
    eventStatus,
    eventEcommerce: 'viewDetail',
    eventNonInteraction: '1',
    products,
    categoryId,
  })
