export enum EPriceUom {
  RUB = 'RUB',
  EUR = 'EUR',
  USD = 'USD',
}

export enum EPriceCurrency {
  RUB = '₽',
  EUR = 'у.е.',
  USD = 'у.е.',
}
