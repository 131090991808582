import { useEffect, useMemo, useState } from 'react'
import type { IUseSliderProps } from './interfaces'

function useSlider(props: IUseSliderProps) {
  const { refObject, total, totalWidth = 0, activeIndex = 0, scrolledItemsPerClick = 1 } = props

  const containerWidth = refObject?.getBoundingClientRect().width || 0
  const itemWidth = totalWidth / total
  const visibleItemsPerView = Math.round(containerWidth / itemWidth)
  const maxOffset = (total - visibleItemsPerView) / scrolledItemsPerClick
  const maxScrollLeft = totalWidth - containerWidth

  const initialOffset = useMemo(() => {
    const centerIndex = Math.ceil(visibleItemsPerView / 2)
    const isItemAtBeginning = activeIndex < centerIndex

    if (isItemAtBeginning) return 0

    return Math.min(activeIndex - centerIndex + 1, maxOffset)
  }, [activeIndex, maxOffset, visibleItemsPerView])

  const [offset, setOffset] = useState(initialOffset)

  useEffect(() => {
    setOffset(initialOffset)
  }, [initialOffset])

  const goNext = () => setOffset(offset + 1)
  const goPrev = () => setOffset(offset - 1)

  const [isPrevAvailable, setPrevAvailable] = useState<boolean>()
  const [isNextAvailable, setNextAvailable] = useState<boolean>()

  const scrollHandler = () => {
    const currentPosition = refObject?.scrollLeft

    if (currentPosition !== undefined) {
      setPrevAvailable(currentPosition > 0)
      setNextAvailable(currentPosition < maxScrollLeft)
    }
  }

  useEffect(() => {
    if (refObject) {
      refObject.addEventListener('scroll', scrollHandler)
      setPrevAvailable(refObject?.scrollLeft > 0)
      setNextAvailable(refObject?.scrollLeft < maxScrollLeft)
    }

    return () => refObject?.removeEventListener('scroll', scrollHandler)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refObject])

  useEffect(() => {
    const slideWidth = offset === initialOffset ? itemWidth : itemWidth * scrolledItemsPerClick

    if (refObject)
      refObject.scrollTo({
        left: offset * slideWidth,
        behavior: 'smooth',
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemWidth, offset])

  return {
    goPrev,
    goNext,
    isNextAvailable,
    isPrevAvailable,
  }
}

export default useSlider
