import client from "@/api/client/axios";
import type { ResponseConfig } from "@/api/client/axios";
import type { SignSaleRequestCryptoMutationRequest, SignSaleRequestCryptoMutationResponse, SignSaleRequestCryptoPathParams, SignSaleRequestCryptoQueryParams } from "../../types/SignSaleRequestCrypto";

/**
     * @summary Подписание УКЭП-ом
     * @link /api/salerequests/:requestId/sign/crypto/
     */
export async function signSaleRequestCrypto (requestId: SignSaleRequestCryptoPathParams["requestId"], params: SignSaleRequestCryptoQueryParams, data?: SignSaleRequestCryptoMutationRequest, options: Partial<Parameters<typeof client>[0]> = {}): Promise<ResponseConfig<SignSaleRequestCryptoMutationResponse>["data"]> {
    const { data: resData } = await client<SignSaleRequestCryptoMutationResponse, SignSaleRequestCryptoMutationRequest>({
        method: "post",
        url: `/api/salerequests/${requestId}/sign/crypto/`,
        params,
        data,
        ...options
    });
    
    return resData;
};