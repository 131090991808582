import type { TRejectedEntity } from '@/components/resolvers/DocumentCard/components/RejectedEntityHintModal/interfaces'
import type { mainStatuses } from '@/logic/accreditation/constants'
import type {
  IConfirmAccreditationParameters,
  IEditingDisagreementProtocolParameters,
  IRejectAccreditationParameters,
  ISignCodeRequestParameters,
  ISignRequestParameters,
} from '@/services/accreditation/interfaces'
import type {
  AccreditationStatusId,
  EAccreditationType,
  IAccreditationStatusActions,
  IAccreditationStatusParameters,
} from '@/types/Accreditation'
import type { IDocument, IDocumentType } from '@/types/Documents'
import type { TEntityId } from '@/types/TEntityId'

export type TAccreditationMainStatus = (typeof mainStatuses)[number]

export type TConfirmAccreditation = (config?: {
  validTo?: string
  shouldOpenSuccessConfirmationModal?: boolean
  shouldRedirectToMyAccreditation?: boolean
}) => void

export type TOpenRejectedEntityHintModal = (comment: string, entity: TRejectedEntity) => void

/**
 * Контекст аккредитации
 *
 * @prop {string} version - Версия аккредитации, updateDate
 */
export interface IAccreditationContext {
  openSigningAccreditationModal: () => void
  openChoosingSigningTypeModal: () => void
  openCryptoSigningAccreditationModal: () => void
  accreditationId: TEntityId
  version: string
  confirmAccreditation: TConfirmAccreditation
  archive?: IDocument
  editAccreditation: () => void
  accreditationType: EAccreditationType
  accreditationActions: IAccreditationStatusActions
  documents?: IDocument[]
  isManager: boolean
  isAgrarian: boolean
  isBuyer: boolean
  isImpersonated: boolean
  isLogisticsOperator: boolean
  isLogistics: boolean
  mainStatus?: TAccreditationMainStatus
  statusParameters: Partial<IAccreditationStatusParameters>
  openRejectedEntityHintModal: TOpenRejectedEntityHintModal
  isMobile: boolean | null
  validTo?: string
  statusId: AccreditationStatusId
  bannerText?: string
}

export enum EAccreditationDocumentAction {
  DELETE = 'delete',
  UPDATE = 'update',
  UPLOAD = 'upload',
  UPLOAD_FROM_MODAL = 'uploadFromModal',
  UPDATE_FROM_MODAL = 'updateFromModal',
}

export enum EAccreditationAction {
  CONFIRM = 'confirm',
  SIGN = 'sign',
  CODE = 'code',
  DISAGREEMENT = 'disagreement',
  REJECT = 'reject',
  EDIT = 'edit',
}

export declare namespace NAccreditationAction {
  interface IReloadAccreditation {
    id: string
  }

  interface ICreateAccreditation {
    organizationId: string
    accreditationType: EAccreditationType
  }

  interface IRejectAccreditationDocument {
    version: string
    comment: string
    documentId: string
    accreditationId: TEntityId
  }

  interface ISaveTokenId {
    tokenId: string
  }
  type IMakeAccreditationProcess = {
    accreditationId: TEntityId
    version: string
    shouldOpenSuccessConfirmationModal?: boolean
    shouldRedirectToMyAccreditation?: boolean
  } & (
    | {
        action: EAccreditationAction.CONFIRM
        parameters: IConfirmAccreditationParameters
      }
    | { action: EAccreditationAction.EDIT }
    | {
        action: EAccreditationAction.CODE
        parameters: ISignCodeRequestParameters
      }
    | {
        action: EAccreditationAction.SIGN
        parameters: ISignRequestParameters
      }
    | {
        action: EAccreditationAction.REJECT
        parameters: IRejectAccreditationParameters
      }
    | {
        action: EAccreditationAction.DISAGREEMENT
        parameters: IEditingDisagreementProtocolParameters
      }
  )

  type IUpdateAccreditationDocument =
    | {
        file: File
        documentType: string
        accreditationId: TEntityId
        documentId: string
        action: EAccreditationDocumentAction.UPDATE
        version: string
        documentName: string
        shouldCloseModal?: boolean
      }
    | {
        file: File
        documentType: string
        accreditationId: TEntityId
        action: EAccreditationDocumentAction.UPLOAD
        version: string
        documentName?: string
        shouldCloseModal?: boolean
      }
    | {
        accreditationId: TEntityId
        documentType: string
        action: EAccreditationDocumentAction.DELETE
        documentId: string
        version: string
        shouldCloseModal?: boolean
      }
    | {
        file: File
        documentType: string
        accreditationId: TEntityId
        action: EAccreditationDocumentAction.UPLOAD_FROM_MODAL
        version: string
        documentName?: string
        shouldCloseModal?: boolean
      }
    | {
        file: File
        documentType: string
        accreditationId: TEntityId
        documentId: string
        action: EAccreditationDocumentAction.UPDATE_FROM_MODAL
        version: string
        documentName: string
        shouldCloseModal?: boolean
      }

  interface IDownloadAccreditationDocument {
    url: string
    fileName: string
  }
}
export interface IAccreditationTypedDocumentCardProps {
  typedDocument: IDocumentType
  index?: number
}

export interface IAccreditationDocumentCardProps {
  document: IDocument
  index?: number
}
