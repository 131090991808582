import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { changeUserOrganization } from '@/api/kubik/monolith'
import { openModal } from '@/components/composed/Modal/actions'
import { getLocation } from '@/logic/app/reducer'
import { setJwt } from '@/logic/auth/actions'
import type { IThunkProps, RootState } from '@/redux/interfaces'
import { reducerManager } from '@/redux/reducerManager'
import { AuthCookiesService } from '@/services'
import { EUserProfileRoute } from '@/types'
import { getTokenInfo } from '@/utils/helpers'
import { createRouterLink } from '@/utils/url'
import type { IChangeActiveOrganisationModalStore } from './types'

const initialState: IChangeActiveOrganisationModalStore = {
  isLoading: false,
  isError: false,
}
const reducerName = 'changeActiveOrganisation'

export const changeActiveOrganisation = createAsyncThunk<Awaited<ReturnType<typeof changeUserOrganization>>, { id: string }, IThunkProps>(
  'changeHoldingOrgModal/changeActiveOrganisation',
  async ({ id }, thunkAPI) => {
    const { getState, dispatch } = thunkAPI

    const state = getState()
    const { pathname } = getLocation()(state) ?? {}

    const onCloseAction = () => {
      const isSomeProfilePage = pathname?.startsWith(EUserProfileRoute.main)

      if (isSomeProfilePage) {
        location.href = createRouterLink(EUserProfileRoute.main)
      } else {
        location.reload()
      }
    }

    const response = await changeUserOrganization(id)
    const { token } = response?.data || {}
    const { organizationName } = (token && getTokenInfo(token)?.payload) || {}

    if (token && organizationName) {
      dispatch(setJwt({ jwt: token }))
      AuthCookiesService.setJwt(token)

      dispatch(
        openModal({
          options: {
            dialogId: 'SuccessChangeActiveOrganisationModal',
            onCloseAction,
          },
          contentOptions: {
            titleId: 'successChangeActiveOrganisationModal.title',
            titleValues: { orgName: organizationName },
            messageId: 'successChangeActiveOrganisationModal.subtitle',
            buttonsBlockStyle: 'successChangeActiveOrganisationSuccessModal__button',
            buttons: [
              {
                textId: 'successChangeActiveOrganisationModal.button',
                modifiers: ['green'],
                onClick: onCloseAction,
              },
            ],
          },
        }),
      )
    }

    return response
  },
)

const changeActiveOrganisationModal = createSlice({
  name: reducerName,
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(changeActiveOrganisation.pending, state => {
        state.isLoading = true
        state.isError = false
      })
      .addCase(changeActiveOrganisation.fulfilled, state => {
        state.isLoading = false
      })
      .addCase(changeActiveOrganisation.rejected, state => {
        state.isLoading = false
        state.isError = true
      })
  },
})

export const getChangeHoldingOrgModalStore = () => (state: RootState) => state[reducerName]

export default changeActiveOrganisationModal.reducer

reducerManager.add(reducerName, changeActiveOrganisationModal.reducer)
