import { useCallback } from 'react'
import { useSelector } from 'react-redux'
import type { ViewByOptionsQueryParamsOptions } from '@/api/kubik/credit'
import type { FundingRequestData } from '@/api/kubik/monolith'
import type { IUserGtmInfoPayload } from '@/interfaces'
import { getUserRegion } from '@/logic/userRegion'
import type { RootState } from '@/redux/interfaces'
import type { ELoanProductName, ELoanProductOption } from '@/types'
import { EFinancingProductId } from '@/types'
import { translit } from '@/utils/helpers'
import { getUserGtmInfo, pushGtmEvent } from '../helpers'
import type {
  EGtmLabel,
  EGtmProductName,
  IBaseGtmEventFinancing,
  IFinancingGtm,
  IFinancingProductInfo,
  ILeasingFormGtmEvent,
  ILendingFinancingOfferGtmEvent,
} from './types'
import { EGtmAction, EGtmBlock } from './types'

const mapState = (state: RootState) => ({
  userGtmInfo: getUserGtmInfo()(state),
  userRegion: getUserRegion()(state),
})

export const getEventContextGtmFinancingOfferForm = (path?: string, financingProductInfo?: IFinancingProductInfo) => {
  return `cd:${financingProductInfo?.productNameOnLending || null};bank:${financingProductInfo?.bank || null};pp:${path || null}`
}

export const getEventContextGtmFinancingOfferLendingShow = (path?: string, financingProductInfo?: IFinancingProductInfo) => {
  const previousPagePath = path !== '/' ? path : document.referrer

  return `cd:${financingProductInfo?.productNameOnLending || null};bank:${financingProductInfo?.bank || null};pp:${
    path || null
  };oldpp:${previousPagePath}`
}

export const getListProductsIdsAsString = (productIds?: (EFinancingProductId | undefined)[]) => productIds?.join(',')

export const pushGtmEventOnStartChangeLeasingForm = (
  blockName: string,
  formName: string,
  isAuthenticated: boolean,
  userGtmInfo: IUserGtmInfoPayload,
) => {
  pushGtmEvent<ILeasingFormGtmEvent>({
    event: 'userEvent',
    pagePart: 'content',
    blockName,
    eventCategory: 'interactions',
    eventAction: 'input',
    eventLabel: formName,
    eventContext: null,
    eventEcommerce: null,
    userSegment: null,
    eventNonInteraction: 0,
    firingOptions: 'oncePerEvent',
    ecommerce: null,
    ...userGtmInfo,
  })
}

export const getGtmScrollEventPayloadFinancingOfferForm = (
  userGtmInfo: IUserGtmInfoPayload,
  userRegionName: string,
  productId?: EFinancingProductId,
): IBaseGtmEventFinancing => {
  return {
    event: 'userEvent',
    regionSelected: userRegionName,
    eventCategory: 'interactions',
    eventAction: 'viewing',
    eventNonInteraction: 0,
    blockName: EGtmBlock.FORM,
    eventLabel: null,
    eventContext: null,
    fundingProduct: productId,
    requestID: null,
    targetRequestID: null,
    ...userGtmInfo,
  }
}

export const pushGtmEventOnShowLendingFinancingProducts = (userGtmInfo: IUserGtmInfoPayload, eventContext?: string) => {
  pushGtmEvent<ILendingFinancingOfferGtmEvent>({
    event: 'userEvent',
    pagePart: 'content',
    blockName: 'null',
    eventCategory: 'interactions',
    eventAction: 'viewing',
    eventLabel: 'pageViewCreditLanding',
    eventContext: eventContext || null,
    eventEcommerce: 'null',
    eventNonInteraction: 0,
    userSegment: null,
    firingOptions: 'oncePerEvent',
    ecommerce: null,
    ...userGtmInfo,
  })
}

export const pushGtmEventOnClickOrder = (userGtmInfo: IUserGtmInfoPayload, userRegionName: string, productId?: EFinancingProductId) => {
  pushGtmEvent<IBaseGtmEventFinancing>({
    event: 'userEvent',
    regionSelected: userRegionName,
    eventCategory: 'interactions',
    eventAction: 'click',
    eventNonInteraction: 0,
    blockName: EGtmBlock.PRODUCT,
    eventLabel: 'financing-application-actions_button',
    eventContext: null,
    fundingProduct: productId,
    requestID: null,
    targetRequestID: null,
    ...userGtmInfo,
  })
}

export const pushGtmEventOnRouteProductPage = (
  userGtmInfo: IUserGtmInfoPayload,
  userRegionName: string,
  eventLabel: string,
  productId?: EFinancingProductId,
) => {
  pushGtmEvent<IBaseGtmEventFinancing>({
    event: 'userEvent',
    regionSelected: userRegionName,
    eventCategory: 'interactions',
    eventAction: 'click',
    eventNonInteraction: 0,
    blockName: EGtmBlock.PRODUCT,
    eventLabel,
    eventContext: null,
    fundingProduct: productId,
    requestID: null,
    targetRequestID: null,
    ...userGtmInfo,
  })
}

export const pushGtmEventOnRouteAuthFromFormOrder = (
  userGtmInfo: IUserGtmInfoPayload,
  userRegionName: string,
  eventContext?: Maybe<string>,
  productId?: EFinancingProductId,
) => {
  pushGtmEvent<IBaseGtmEventFinancing>({
    event: 'userEvent',
    regionSelected: userRegionName,
    eventCategory: 'interactions',
    eventAction: 'backgroundProcess',
    eventNonInteraction: 0,
    blockName: EGtmBlock.FORM,
    eventLabel: 'step_to-authorization',
    eventContext: eventContext || null,
    fundingProduct: productId,
    requestID: null,
    targetRequestID: null,
    ...userGtmInfo,
  })
}

export const pushGtmEventOnSubmitOrder = (
  userGtmInfo: IUserGtmInfoPayload,
  userRegionName: string,
  responseCreatedOffer: FundingRequestData[],
  isMultiLeasing?: boolean,
  productsIds?: (EFinancingProductId | undefined)[],
  productId?: EFinancingProductId,
) => {
  const productsIdsList = getListProductsIdsAsString(productsIds) || null
  const isMultyLeasing = responseCreatedOffer.length > 1

  const targetRequestID = isMultyLeasing ? responseCreatedOffer.map(order => order.id).join(',') : null
  const requestIds = isMultyLeasing ? responseCreatedOffer[0]?.multiId : responseCreatedOffer[0]?.id

  pushGtmEvent<IBaseGtmEventFinancing>({
    event: 'userEvent',
    regionSelected: userRegionName,
    eventCategory: 'interactions',
    eventAction: 'formSend',
    eventNonInteraction: 0,
    blockName: EGtmBlock.FORM,
    eventLabel: null,
    eventContext: isMultiLeasing ? productsIdsList : null,
    fundingProduct: isMultiLeasing ? EFinancingProductId.MULTI_LEASING : productId,
    requestID: String(requestIds) || null,
    targetRequestID,
    ...userGtmInfo,
  })
}

export const pushGtmEventOnClickApplyOnFundingOfferPage = (
  userGtmInfo: IUserGtmInfoPayload,
  userRegionName: string,
  productId?: EFinancingProductId,
) => {
  pushGtmEvent<IBaseGtmEventFinancing>({
    event: 'userEvent',
    regionSelected: userRegionName,
    eventCategory: 'interactions',
    eventAction: 'click',
    eventNonInteraction: 0,
    blockName: null,
    eventLabel: 'financing-application-actions_button',
    eventContext: null,
    fundingProduct: productId,
    requestID: null,
    targetRequestID: null,
    ...userGtmInfo,
  })
}

export const useGtmEventWithUserInfo = () => {
  const { userGtmInfo, userRegion } = useSelector(mapState)

  const pushGtmEventWithUserInfo = useCallback(
    payload =>
      pushGtmEvent({
        regionSelected: userRegion.name,
        eventCategory: 'interactions',
        eventNonInteraction: 0,
        eventContext: null,
        requestID: null,
        targetRequestID: null,
        ...userGtmInfo,
        ...payload,
      }),
    [userGtmInfo, userRegion.name],
  )

  return {
    pushGtmEventWithUserInfo,
  }
}

export const useGtm = () => {
  const { pushGtmEventWithUserInfo } = useGtmEventWithUserInfo()

  const pushUserEvent = (payload: IFinancingGtm) =>
    pushGtmEventWithUserInfo({
      event: 'userEvent',
      eventAction: EGtmAction.CLICK,
      fundingProduct: null,
      ...payload,
    })

  const pushGtmProduct = (fundingProduct: ELoanProductName | EGtmProductName | EFinancingProductId, eventLabel: EGtmLabel) =>
    pushUserEvent({
      blockName: EGtmBlock.PRODUCT,
      fundingProduct,
      eventLabel,
      eventAction: EGtmAction.CLICK,
    })

  const pushGtmFormView = (fundingProduct: ELoanProductName | EGtmProductName | EFinancingProductId) =>
    pushUserEvent({
      blockName: EGtmBlock.FORM,
      eventLabel: null,
      eventAction: EGtmAction.VIEWING,
      fundingProduct,
    })

  const pushGtmLoanProductModalAuth = (fundingProduct: ELoanProductName | EGtmProductName | EFinancingProductId) =>
    pushUserEvent({
      blockName: EGtmBlock.FORM,
      eventLabel: 'step_to-authorization',
      eventAction: EGtmAction.BACKGROUND,
      fundingProduct,
    })

  const pushGtmFilter = (label: ELoanProductOption | ViewByOptionsQueryParamsOptions | EGtmLabel, context: Maybe<boolean> = null) =>
    pushUserEvent({
      blockName: EGtmBlock.FILTER,
      eventLabel: label,
      ...(context !== null && { eventContext: context ? 'on' : 'off' }),
    })

  const pushGtmSuccessfulModal = (eventLabel: EGtmLabel) =>
    pushUserEvent({
      blockName: EGtmBlock.SUCCESS,
      eventLabel,
    })

  const pushGtmFormInteraction = (fundingProduct: ELoanProductName | EGtmProductName | EFinancingProductId, eventLabel: string) =>
    pushUserEvent({
      blockName: EGtmBlock.FORM,
      eventAction: EGtmAction.INPUT,
      fundingProduct,
      eventLabel,
    })

  const pushGtmExpandFaq = (question: string) =>
    pushUserEvent({
      blockName: EGtmBlock.FAQ,
      eventLabel: translit(question),
    })

  const pushGtmReviewsEvent = (eventLabel: EGtmLabel) =>
    pushUserEvent({
      blockName: EGtmBlock.RESPONSES,
      eventLabel,
    })

  const pushGtmPageView = (eventLabel: EGtmLabel) =>
    pushGtmEventWithUserInfo({
      event: 'pageViewEvent',
      eventLabel,
    })

  return {
    pushGtmProduct,
    pushGtmPageView,
    pushGtmSuccessfulModal,
    pushGtmExpandFaq,
    pushGtmFormInteraction,
    pushGtmReviewsEvent,
    pushGtmFilter,
    pushGtmFormView,
    pushGtmLoanProductModalAuth,
  }
}
