import type { ReactNode } from 'react'
import type { IButtonProps } from '@/components/ui/Button/interfaces'
import type { IModalContentInjectedProps } from '../../Modal/interfaces'

export enum EInfoModalType {
  SUCCESS = 'SUCCESS',
  SUCCESS_STAR = 'SUCCESS_STAR',
  ERROR = 'ERROR',
  ERROR_RED = 'ERROR_RED',
}

export interface IInfoModalOptions {
  className?: Maybe<string>
  type: EInfoModalType
  title?: Maybe<string>
  text?: Maybe<MaybeArray<string>>
  children?: Maybe<ReactNode>
  buttons?: MaybeArray<IButtonProps>
}

export interface IInfoModalProps extends IModalContentInjectedProps {
  options?: IInfoModalOptions
}
