import type { QueryKey, UseQueryResult, UseQueryOptions, QueryOptions } from "@tanstack/react-query";
import { useQuery } from "@tanstack/react-query";
import client from "@/api/client/axios";
import type { GetOrderOfferTemplateListQueryResponse, GetOrderOfferTemplateListPathParams, GetOrderOfferTemplateList400, GetOrderOfferTemplateList500 } from "../types/GetOrderOfferTemplateList";

export const getOrderOfferTemplateListQueryKey = (orderId: GetOrderOfferTemplateListPathParams["orderId"]) =>     [{url:`/api/orders/${orderId}/offer/template`,params:{orderId:orderId}}] as const;
      export function getOrderOfferTemplateListQueryOptions <TData = GetOrderOfferTemplateListQueryResponse, TError = GetOrderOfferTemplateList400 | GetOrderOfferTemplateList500>(orderId: GetOrderOfferTemplateListPathParams["orderId"], options: Partial<Parameters<typeof client>[0]> = {}): UseQueryOptions<TData, TError> {
        const queryKey = getOrderOfferTemplateListQueryKey(orderId, );

        return {
          queryKey,
          queryFn: () => {
            
            return client<TData, TError>({
              method: "get",
              url: `/api/orders/${orderId}/offer/template`,
              
              
              ...options,
            }).then(res => res.data);
          },
        };
      };
      
/**
 * @summary Получение списка шаблонов оферт
 * @link /api/orders/:orderId/offer/template
 */

export function useGetOrderOfferTemplateList <TData = GetOrderOfferTemplateListQueryResponse, TError = GetOrderOfferTemplateList400 | GetOrderOfferTemplateList500>(orderId: GetOrderOfferTemplateListPathParams["orderId"], options: {
          query?: UseQueryOptions<TData, TError>,
          client?: Partial<Parameters<typeof client<TData, TError>>[0]>,
        } = {}): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const { query: queryOptions, client: clientOptions = {} } = options ?? {};
  const queryKey = queryOptions?.queryKey ?? getOrderOfferTemplateListQueryKey(orderId, );

  const query = useQuery<TData, TError>({
    ...getOrderOfferTemplateListQueryOptions<TData, TError>(orderId, clientOptions),
    ...queryOptions
  }) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey as QueryKey;

  return query;
};
