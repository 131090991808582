import { requestData, requestDataWithRawResponse } from '@/api/request'
import { ELkApiUrl } from '@/api/urls'
import { DEFAULT_LIMIT_DOCUMENTS, DEFAULT_OFFSET_DOCUMENTS } from '@/services/documents/constants'
import type { IDocumentsResponse } from '@/services/documents/interfaces'
import { downloadFileFromResponse } from '@/utils/files'
import { getUrlWithQuery } from '@/utils/url'

interface IUploadDocumentOptions {
  file: File
  name: string
  type: string
}

interface IUpdateDocumentOptions {
  file: File
  name: string
  type: string
  documentId: string
}

interface IDeleteDocumentOptions {
  documentId: string
}

export interface IFetchOrganizationDocumentOptions {
  limit?: number
  offset?: number
}

/**
 * Сервис работы с документами организации/пользователя
 *
 * TODO: Избавиться от сервиса получится, только когда появится описание OpenAPI
 *   для POST, PUT в монолите. До этого для uploadDocument, updateDocument
 *   нет генерации для API
 */
export default class OrganizationDocumentsService {
  /**
   * Загрузка документа организации/пользователя
   * @param  {Object} options
   * @prop  {File} options.file - файл
   * @prop  {string} options.name - имя файла
   * @prop  {string} options.type - тип файла
   */
  uploadDocument = ({ file, name, type }: IUploadDocumentOptions) => {
    const formData = new FormData()
    formData.append('documentFile', file)
    formData.append('documentTitle', name)
    formData.append('documentType', type)

    return requestData({
      url: ELkApiUrl.organizationDocuments,
      method: 'POST',
      body: formData,
      isJson: false,
    })
  }
  /**
   * Обновление документа организации/пользователя
   * @param  {Object} options
   * @prop  {File} options.file - файл
   * @prop  {string} options.name - имя файла
   * @prop  {string} options.type - тип файла
   * @prop {string} options.documentId - идентификатор документа
   */
  updateDocument = ({ file, name, type, documentId }: IUpdateDocumentOptions) => {
    const formData = new FormData()
    formData.append('documentFile', file)
    formData.append('documentTitle', name)
    formData.append('documentType', type)

    return requestData({
      url: `${ELkApiUrl.organizationDocuments}/${documentId}`,
      method: 'PUT',
      body: formData,
      isJson: false,
    })
  }

  /**
   * Удаление документа организации/пользователя
   * @param  {Object} options
   * @prop {string} options.documentId - идентификатор документа
   */
  deleteDocument = ({ documentId }: IDeleteDocumentOptions) =>
    requestData({
      url: `${ELkApiUrl.organizationDocuments}/${documentId}`,
      method: 'DELETE',
    })

  /**
   * Скачивание документа организации/пользователя
   * @param  {string} url - версия запроса, поле updateDate организации/пользователя
   * @param {string} fileName - идентификатор документа
   */
  downloadDocument = (url: string, fileName: string) =>
    requestDataWithRawResponse<Blob>({ url, method: 'GET' }).then(async response => {
      await downloadFileFromResponse(response, fileName)
    })

  /**
   * Запрос документов заказа
   * @param {number} id - идентификатор заявки
   * @param {number} limit - количество документов на странице
   * @param {number} offset - смещение для пагинации
   */
  fetchDocuments = ({ limit = DEFAULT_LIMIT_DOCUMENTS, offset = DEFAULT_OFFSET_DOCUMENTS }: IFetchOrganizationDocumentOptions) =>
    requestData<IDocumentsResponse>({
      url: getUrlWithQuery(ELkApiUrl.organizationDocuments, { limit, offset }),
      method: 'GET',
    })
}
