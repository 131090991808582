import { createAction } from '@reduxjs/toolkit'
import type { IClearRequestStatusesPayload, IRequestStatusPayload } from './interfaces'

export const requestStarted = createAction<IRequestStatusPayload>('@@requestStatus/REQUEST_STARTED')

export const requestFailed = createAction<IRequestStatusPayload>('@@requestStatus/REQUEST_FAILED')

export const requestFinished = createAction<IRequestStatusPayload>('@@requestStatus/REQUEST_FINISHED')

export const setInitialStatus = createAction<IRequestStatusPayload>('@@requestStatus/SET_INITIAL_STATUS')

export const clearRequestStatuses = createAction<IClearRequestStatusesPayload>('@@requestStatus/CLEAR_REQUEST_STATUSES')
