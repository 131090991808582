import type { QueryKey, UseQueryResult, UseQueryOptions, QueryOptions } from "@tanstack/react-query";
import { useQuery } from "@tanstack/react-query";
import client from "@/api/client/axios";
import type { GetFundingDocumentsQueryResponse, GetFundingDocumentsQueryParams, GetFundingDocuments400, GetFundingDocuments401, GetFundingDocuments403, GetFundingDocuments422, GetFundingDocuments429, GetFundingDocuments500 } from "../types/GetFundingDocuments";

export const getFundingDocumentsQueryKey = (params: GetFundingDocumentsQueryParams) =>     [{url:`/api/credit-flow-backend/v1/documents`},...(params ? [params] : [])] as const;
      export function getFundingDocumentsQueryOptions <TData = GetFundingDocumentsQueryResponse, TError = GetFundingDocuments400 | GetFundingDocuments401 | GetFundingDocuments403 | GetFundingDocuments422 | GetFundingDocuments429 | GetFundingDocuments500>(params: GetFundingDocumentsQueryParams, options: Partial<Parameters<typeof client>[0]> = {}): UseQueryOptions<TData, TError> {
        const queryKey = getFundingDocumentsQueryKey(params);

        return {
          queryKey,
          queryFn: () => {
            
            return client<TData, TError>({
              method: "get",
              url: `/api/credit-flow-backend/v1/documents`,
              params,
              
              ...options,
            }).then(res => res.data);
          },
        };
      };
      
/**
 * @link /api/credit-flow-backend/v1/documents
 */

export function useGetFundingDocuments <TData = GetFundingDocumentsQueryResponse, TError = GetFundingDocuments400 | GetFundingDocuments401 | GetFundingDocuments403 | GetFundingDocuments422 | GetFundingDocuments429 | GetFundingDocuments500>(params: GetFundingDocumentsQueryParams, options: {
          query?: UseQueryOptions<TData, TError>,
          client?: Partial<Parameters<typeof client<TData, TError>>[0]>,
        } = {}): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const { query: queryOptions, client: clientOptions = {} } = options ?? {};
  const queryKey = queryOptions?.queryKey ?? getFundingDocumentsQueryKey(params);

  const query = useQuery<TData, TError>({
    ...getFundingDocumentsQueryOptions<TData, TError>(params, clientOptions),
    ...queryOptions
  }) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey as QueryKey;

  return query;
};
