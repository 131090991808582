import { useMemo, useState } from 'react'
import { ERequestStatus } from '@/types'

export const useRequestStateManager = () => {
  const [status, setStatus] = useState<ERequestStatus>(ERequestStatus.INITIAL)

  const actions = useMemo(
    () => ({
      requestStarted: () => setStatus(ERequestStatus.LOADING),
      requestEnded: () => setStatus(ERequestStatus.LOADED),
      requestFailed: () => setStatus(ERequestStatus.FAILURE),
    }),
    [setStatus],
  )

  const state = useMemo(
    () => ({
      isLoading: status === ERequestStatus.LOADING,
      isLoaded: status === ERequestStatus.LOADED,
      isIdle: status === ERequestStatus.INITIAL,
      isFailure: status === ERequestStatus.FAILURE,
    }),
    [status],
  )

  return { actions, state }
}
