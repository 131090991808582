import React, { useContext } from 'react'
import { FormattedMessage } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { EColor } from '@frontend/pole-ui/lib/common/constants'
import { EIconName, EIconSize } from '@frontend/pole-ui/lib/components/Icon'
import { BubblingTooltip, Button, ErrorMessage, Loader, TextWithIcon, UploadButton } from '@/components/ui'
import { AcceptedFileType } from '@/constants/common'
import { downloadAccreditationDocument, updateAccreditationDocument } from '@/logic/accreditation/actions'
import { AccreditationContext } from '@/logic/accreditation/constants'
import { EAccreditationDocumentAction } from '@/logic/accreditation/interfaces'
import { setInitialStatus } from '@/logic/requestStatus/actions'
import { getRequestState } from '@/logic/requestStatus/reducer'
import type { RootState } from '@/redux/interfaces'
import { useDeviceType } from '@/utils/hooks/useDeviceType'

const mapState = (type: string, url: string) => (state: RootState) => {
  const { isLoading: isArchiveUpdating, isFailure: isUpdatingArchiveFailed } = getRequestState(`updateAccreditationDocument_${type}`)(state)

  const { isLoading: isArchiveDownloading, isFailure: isDownloadingArchiveFailed } = getRequestState(
    `downloadAccreditationDocument_${url}`,
  )(state)
  const { isLoading: isArchiveDeleting, isFailure: isDeletingArchiveFailed } = getRequestState(`deleteAccreditationDocument_${type}`)(state)

  return {
    isProcessing: isArchiveUpdating || isArchiveDownloading || isArchiveDeleting,
    isUpdatingArchiveFailed,
    isDownloadingArchiveFailed,
    isDeletingArchiveFailed,
  }
}

const ArchiveCard: React.FC = () => {
  const { accreditationId, archive, version, accreditationActions } = useContext(AccreditationContext)
  const { deleteDocument: canDeleteDocument, updateDocument: canUpdateDocument } = accreditationActions
  const dispatch = useDispatch()
  const isMobile = useDeviceType()

  const { isProcessing, isDeletingArchiveFailed, isDownloadingArchiveFailed, isUpdatingArchiveFailed } = useSelector(
    archive
      ? mapState(archive.type, archive.url)
      : () => ({
          isProcessing: false,
          isDeletingArchiveFailed: false,
          isDownloadingArchiveFailed: false,
          isUpdatingArchiveFailed: false,
        }),
  )
  if (!archive) return null

  const { id: documentId, type, url, fileName } = archive

  const updateDocument = (action: EAccreditationDocumentAction, file?: File) => {
    dispatch(
      setInitialStatus({ name: `${action === EAccreditationDocumentAction.UPDATE ? 'delete' : 'update'}AccreditationDocument_${type}` }),
    )

    dispatch(setInitialStatus({ name: `downloadAccreditationDocument_${url}` }))
    switch (action) {
      case EAccreditationDocumentAction.DELETE:
        dispatch(
          updateAccreditationDocument({
            accreditationId,
            documentId,
            documentType: type,
            action: EAccreditationDocumentAction.DELETE,
            version,
          }),
        )

        break
      case EAccreditationDocumentAction.UPDATE:
      default:
        if (!file) {
          console.error('file field is required for "upload document" action')
          break
        }

        dispatch(
          updateAccreditationDocument({
            accreditationId,
            documentId,
            documentType: type,
            action: EAccreditationDocumentAction.UPDATE,
            version,
            file,
            documentName: '',
          }),
        )

        break
    }
  }

  const downloadDocument = () => {
    dispatch(setInitialStatus({ name: `updateAccreditationDocument_${type}` }))
    dispatch(setInitialStatus({ name: `deleteAccreditationDocument_${type}` }))
    dispatch(downloadAccreditationDocument({ url, fileName }))
  }

  return (
    <div className="document-card document-card_active document-archive-card">
      <Loader isVisible={isProcessing} />
      <div className="document-card-first-col">
        <p className="text_small-from-sm document-card__name color_pale-black space-holder8">
          <FormattedMessage id="userAccreditation.uploadedArchiveFile.text" />
        </p>
        <TextWithIcon
          iconProps={{ style: { color: EColor.GREEN }, size: isMobile ? EIconSize.XS : EIconSize.M, name: EIconName.OkFill }}
          text={fileName}
        />
      </div>
      <div className="document-card-actions">
        <BubblingTooltip content={<FormattedMessage id="documentCard.hint.downloadButton" />}>
          <span className="row">
            <Button
              onClick={downloadDocument}
              iconProps={{
                name: EIconName.Download,
                size: isMobile ? EIconSize.XS : EIconSize.M,
                className: 'archiveCard--iconColorBlack',
              }}
              className="document-card-actions__button"
              isUnstyled
            />
          </span>
        </BubblingTooltip>
        {canUpdateDocument && (
          <BubblingTooltip content={<FormattedMessage id="documentCard.hint.updateButton" />}>
            <span className="row">
              <UploadButton
                name="uploadArchive"
                buttonProps={{
                  iconProps: {
                    name: EIconName.Update,
                    size: isMobile ? EIconSize.XS : EIconSize.M,
                    className: 'archiveCard--iconColorBlack',
                  },
                  className: 'document-card-actions__button',
                  isUnstyled: true,
                  modifiers: [],
                }}
                accept={AcceptedFileType.ARCHIVE}
                onChange={file => updateDocument(EAccreditationDocumentAction.UPDATE, file)}
              />
            </span>
          </BubblingTooltip>
        )}
        {canDeleteDocument && (
          <BubblingTooltip content={<FormattedMessage id="documentCard.hint.deleteButton" />}>
            <span className="row">
              <Button
                onClick={() => updateDocument(EAccreditationDocumentAction.DELETE)}
                iconProps={{
                  name: EIconName.Delete,
                  size: isMobile ? EIconSize.XS : EIconSize.M,
                  className: 'archiveCard--iconColorBlack',
                }}
                className="document-card-actions__button"
                isUnstyled
              />
            </span>
          </BubblingTooltip>
        )}
      </div>

      <div className="document-card-third-col document-card-third-col_not-shrunk">
        <p className="text_small">
          <FormattedMessage id="userAccreditation.archive.uploaded" />
        </p>
        <ErrorMessage
          isVisible={isUpdatingArchiveFailed}
          textId="userAccreditation.updateArchive.error"
          className="space-holder-top8 space-holder-top8-sm"
        />
        <ErrorMessage
          isVisible={isDownloadingArchiveFailed}
          textId="userAccreditation.downloadArchive.error"
          className="space-holder-top8 space-holder-top8-sm"
        />
        <ErrorMessage
          isVisible={isDeletingArchiveFailed}
          textId="userAccreditation.deleteArchive.error"
          className="space-holder-top8 space-holder-top8-sm"
        />
      </div>
    </div>
  )
}

export default ArchiveCard
