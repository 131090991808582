import client from "@/api/client/axios";
import type { ResponseConfig } from "@/api/client/axios";
import type { DeleteSaleRequestShippingSurchargeMutationResponse, DeleteSaleRequestShippingSurchargePathParams } from "../../types/DeleteSaleRequestShippingSurcharge";

/**
     * @summary Запрос на удаление доплаты
     * @link /api/salerequests/:requestId/shippings/surcharge/:surchargeId
     */
export async function deleteSaleRequestShippingSurcharge (requestId: DeleteSaleRequestShippingSurchargePathParams["requestId"], surchargeId: DeleteSaleRequestShippingSurchargePathParams["surchargeId"], options: Partial<Parameters<typeof client>[0]> = {}): Promise<ResponseConfig<DeleteSaleRequestShippingSurchargeMutationResponse>["data"]> {
    const { data: resData } = await client<DeleteSaleRequestShippingSurchargeMutationResponse>({
        method: "delete",
        url: `/api/salerequests/${requestId}/shippings/surcharge/${surchargeId}`,
        ...options
    });
    
    return resData;
};