import type { QueryKey, UseQueryResult, UseQueryOptions, QueryOptions } from "@tanstack/react-query";
import { useQuery } from "@tanstack/react-query";
import client from "@/api/client/inputs-catalog";
import type { GetCatalogQueryResponse, GetCatalogQueryParams, GetCatalog400, GetCatalog404, GetCatalog500 } from "../types/GetCatalog";

export const getCatalogQueryKey = (params?: GetCatalogQueryParams) =>     [{url:`/v1/catalog`},...(params ? [params] : [])] as const;
      export function getCatalogQueryOptions <TData = GetCatalogQueryResponse, TError = GetCatalog400 | GetCatalog404 | GetCatalog500>(params?: GetCatalogQueryParams, options: Partial<Parameters<typeof client>[0]> = {}): UseQueryOptions<TData, TError> {
        const queryKey = getCatalogQueryKey(params);

        return {
          queryKey,
          queryFn: () => {
            
            return client<TData, TError>({
              method: "get",
              url: `/v1/catalog`,
              params,
              
              ...options,
            }).then(res => res.data);
          },
        };
      };
      
/**
 * @link /v1/catalog
 */

export function useGetCatalog <TData = GetCatalogQueryResponse, TError = GetCatalog400 | GetCatalog404 | GetCatalog500>(params?: GetCatalogQueryParams, options: {
          query?: UseQueryOptions<TData, TError>,
          client?: Partial<Parameters<typeof client<TData, TError>>[0]>,
        } = {}): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const { query: queryOptions, client: clientOptions = {} } = options ?? {};
  const queryKey = queryOptions?.queryKey ?? getCatalogQueryKey(params);

  const query = useQuery<TData, TError>({
    ...getCatalogQueryOptions<TData, TError>(params, clientOptions),
    ...queryOptions
  }) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey as QueryKey;

  return query;
};
