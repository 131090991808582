import type { QueryKey, UseQueryResult, UseQueryOptions, QueryOptions } from "@tanstack/react-query";
import { useQuery } from "@tanstack/react-query";
import client from "@/api/client/axios";
import type { GetRequestDiscountDataQueryResponse, GetRequestDiscountDataPathParams, GetRequestDiscountData400, GetRequestDiscountData404, GetRequestDiscountData500 } from "../types/GetRequestDiscountData";

export const getRequestDiscountDataQueryKey = (cultureId: GetRequestDiscountDataPathParams["cultureId"]) =>     [{url:`/api/requests/data/discount/${cultureId}`,params:{cultureId:cultureId}}] as const;
      export function getRequestDiscountDataQueryOptions <TData = GetRequestDiscountDataQueryResponse, TError = GetRequestDiscountData400 | GetRequestDiscountData404 | GetRequestDiscountData500>(cultureId: GetRequestDiscountDataPathParams["cultureId"], options: Partial<Parameters<typeof client>[0]> = {}): UseQueryOptions<TData, TError> {
        const queryKey = getRequestDiscountDataQueryKey(cultureId, );

        return {
          queryKey,
          queryFn: () => {
            
            return client<TData, TError>({
              method: "get",
              url: `/api/requests/data/discount/${cultureId}`,
              
              
              ...options,
            }).then(res => res.data);
          },
        };
      };
      
/**
 * @summary Получение шаблона и модели данных для скидки заявки / мультизаявки
 * @link /api/requests/data/discount/:cultureId
 */

export function useGetRequestDiscountData <TData = GetRequestDiscountDataQueryResponse, TError = GetRequestDiscountData400 | GetRequestDiscountData404 | GetRequestDiscountData500>(cultureId: GetRequestDiscountDataPathParams["cultureId"], options: {
          query?: UseQueryOptions<TData, TError>,
          client?: Partial<Parameters<typeof client<TData, TError>>[0]>,
        } = {}): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const { query: queryOptions, client: clientOptions = {} } = options ?? {};
  const queryKey = queryOptions?.queryKey ?? getRequestDiscountDataQueryKey(cultureId, );

  const query = useQuery<TData, TError>({
    ...getRequestDiscountDataQueryOptions<TData, TError>(cultureId, clientOptions),
    ...queryOptions
  }) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey as QueryKey;

  return query;
};
