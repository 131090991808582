import React, { memo } from 'react'
import { useDispatch } from 'react-redux'
import { Button } from '@/components/ui'
import { injectMessages } from '@/logic/translation/utils'
import { openModal } from '../../../../Modal/actions'
import type { IChoosingLocationModalOptions } from '../../interfaces'
import type { IAddingLocationButtonProps } from './interfaces'
import messages from './messages.json'

injectMessages(messages)

const AddingLocationButton: React.FC<IAddingLocationButtonProps> = props => {
  const {
    context = 'makeOrder',
    position = 'modal',
    isSavingAddressAvailable,
    onClick = async () => true,
    onAddAddress,
    deliveryBasisId,
  } = props
  const dispatch = useDispatch()

  const onClickHandler = async () => {
    const isAddAddressAvailable = await onClick()

    if (!isAddAddressAvailable) return

    dispatch(
      openModal({
        options: {
          dialogId: 'ChoosingLocationModal',
        },
        contentOptions: {
          context,
          position,
          isSavingAddressAvailable,
          setChosenAddress: onAddAddress,
          deliveryBasisId,
        } as IChoosingLocationModalOptions,
      }),
    )
  }

  return <Button onClick={onClickHandler} modifiers={['outline', 'small']} textId="deliveryAddressListFooter.button" />
}

export default memo(AddingLocationButton)
