import { get } from 'react-hook-form'
import type { AuthState, IUser } from '@/logic/auth/interfaces'
import type { EUserPermission } from '@/types'
import type { TAccessControlRule, TAccessControlRuleKey, TAccessControlRules } from './types'
import { EAccessControlValue } from './types'

export const resolveAccessControlRule = <
  TRules extends TAccessControlRules<TContext, TValue>,
  TContext,
  TValue extends string = EAccessControlValue,
>(
  context: TContext,
  rules: TRules,
  extra: unknown,
  key: TAccessControlRuleKey<TRules, TContext, TValue>,
): TValue => {
  const rule = get(rules, key) as TAccessControlRule<TContext, TValue>

  return rule(context, extra)
}

type TContextWithAuth = {
  auth: AuthState
}

export const hasUserPermissions = (permissions: EUserPermission[], user: IUser | undefined): boolean => {
  const permissionSet = new Set(permissions)

  return user?.permissions.some(userPermission => permissionSet.has(userPermission)) ?? false
}

export const visibleForPermissionsRule = (permissions: EUserPermission[]) => {
  return ({ auth: { user } }: TContextWithAuth): EAccessControlValue =>
    hasUserPermissions(permissions, user) ? EAccessControlValue.VISIBLE : EAccessControlValue.HIDDEN
}

export const visibleForAllRule = () => EAccessControlValue.VISIBLE
