import { combineEpics } from 'redux-observable'
import { concat, from, of, throwError, timer } from 'rxjs'
import { catchError, delayWhen, filter, map, mergeMap, switchMap } from 'rxjs/operators'
import { isStatusOk } from '@/api/helpers'
import { closeModal } from '@/components/composed/Modal/actions'
import { getLocation, getLocationQuery } from '@/logic/app/reducer'
import type { Epic } from '@/logic/interfaces'
import { fetchAgrarianOrder } from '@/logic/order/actions'
import { requestFailed, requestFinished, requestStarted } from '@/logic/requestStatus/actions'
import { OrderDocumentsService, OrganizationDocumentsService, SaleRequestDocumentsService } from '@/services/documents'
import { DEFAULT_LIMIT_DOCUMENTS, DEFAULT_OFFSET_DOCUMENTS } from '@/services/documents/constants'
import type { IFetchOrganizationDocumentOptions } from '@/services/documents/OrganizationDocumentsService'
import type { IFetchDocumentOptions } from '@/services/documents/SaleRequestDocumentsService'
import type { IDocument } from '@/types/Documents'
import { EDocumentEntityType } from '@/types/Documents'
import type { IUpdateDocumentPayload, IUploadDocumentPayload } from './components/UploadDocumentModal/interfaces'
import {
  deleteDocument,
  downloadDocument,
  fetchDocuments,
  reloadDocuments,
  setDocuments,
  setPending,
  updateDocument,
  uploadDocument,
} from './actions'
import { EntityVersionGetter } from './helpers'
import type { IFetchDocumentsPayload, IFileDownloadPayload } from './interfaces'

const DocumentsServices = {
  [EDocumentEntityType.MY_DOCUMENTS]: new OrganizationDocumentsService(),
  [EDocumentEntityType.ORDERS]: new OrderDocumentsService(),
  [EDocumentEntityType.REQUESTS]: new SaleRequestDocumentsService(),
}

const onUploadDocumentDoMakeRequest: Epic<IUploadDocumentPayload, typeof uploadDocument> = (action$, state$) =>
  action$.pipe(
    filter(uploadDocument.match),
    mergeMap(({ payload }) => {
      const state = state$.value
      const { documentFile, documentName, documentType, entity, entityId } = payload
      const requestStatus = { name: `uploadDocument_${documentType}` }

      if (!entityId) return of(requestFailed(requestStatus))

      return concat(
        of(requestStarted(requestStatus)),
        from(
          DocumentsServices[entity].uploadDocument({
            id: entityId,
            file: documentFile,
            version: EntityVersionGetter[entity]?.(state),
            name: documentName,
            type: documentType,
          }),
        ).pipe(
          mergeMap(response => {
            if (!isStatusOk(response)) return throwError(response)

            return [
              requestFinished(requestStatus),
              closeModal(),
              fetchDocuments({ entity, entityId, commonTail: [EDocumentEntityType.REQUESTS, EDocumentEntityType.ORDERS].includes(entity) }),
            ]
          }),
          catchError(() => [closeModal(), requestFailed(requestStatus)]),
        ),
      )
    }),
  )

const onUpdateDocumentDoMakeRequest: Epic<IUpdateDocumentPayload, typeof updateDocument> = (action$, state$) =>
  action$.pipe(
    filter(updateDocument.match),
    mergeMap(({ payload }) => {
      const state = state$.value
      const { documentFile, documentName, document, entityId, entity, orderId } = payload
      const { type, id } = document
      const requestStatus = { name: `updateDocument_${type}` }

      if (!entityId) return of(requestFailed(requestStatus))

      return concat(
        of(requestStarted(requestStatus)),
        from(
          DocumentsServices[entity].updateDocument({
            documentId: id,
            id: entityId,
            file: documentFile,
            version: EntityVersionGetter[entity]?.(state),
            name: documentName,
            type,
          }),
        ).pipe(
          mergeMap(response => {
            if (!isStatusOk(response)) return throwError(response)

            return [
              requestFinished(requestStatus),
              closeModal(),
              fetchDocuments({ entityId, entity, commonTail: [EDocumentEntityType.REQUESTS, EDocumentEntityType.ORDERS].includes(entity) }),
              orderId ? fetchAgrarianOrder({ id: orderId, shouldNotShowLoadingState: true }) : undefined,
            ]
          }),
          catchError(() => [closeModal(), requestFailed(requestStatus)]),
        ),
      )
    }),
  )

const onDownloadDocumentDoMakeRequest: Epic<IFileDownloadPayload, typeof downloadDocument> = action$ =>
  action$.pipe(
    filter(downloadDocument.match),
    mergeMap(({ payload }) => {
      const { url, fileName, entity, id } = payload
      const requestStatus = { name: `downloadDocument_${id}` }

      return concat(
        of(requestStarted(requestStatus)),
        from(DocumentsServices[entity].downloadDocument(url, fileName)).pipe(
          map(() => requestFinished(requestStatus)),
          catchError(() => of(requestFailed(requestStatus))),
        ),
      )
    }),
  )

const onFetchDocumentsDoMakeRequest: Epic<IFetchDocumentsPayload, typeof fetchDocuments> = action$ =>
  action$.pipe(
    filter(fetchDocuments.match),
    mergeMap(({ payload }) => {
      const { entity, entityId, offset = DEFAULT_OFFSET_DOCUMENTS, limit = DEFAULT_LIMIT_DOCUMENTS, commonTail } = payload

      if (entity === EDocumentEntityType.MY_DOCUMENTS) {
        const fetchDocumentsPayload: IFetchOrganizationDocumentOptions = { offset, limit }

        return from(DocumentsServices[entity].fetchDocuments(fetchDocumentsPayload)).pipe(
          mergeMap(response => (isStatusOk(response) ? [setDocuments(response.data)] : throwError(null))),
          catchError(() => [setPending(false)]),
        )
      } else if (entity === EDocumentEntityType.REQUESTS || entity === EDocumentEntityType.ORDERS) {
        if (entityId === undefined) {
          return of(setPending(false))
        }

        const fetchDocumentsPayload: IFetchDocumentOptions = { id: entityId, offset, limit, commonTail }

        return from(DocumentsServices[entity].fetchDocuments(fetchDocumentsPayload)).pipe(
          mergeMap(response => (isStatusOk(response) ? [setDocuments(response.data)] : throwError(null))),
          catchError(() => [setPending(false)]),
        )
      }

      return of(setPending(false))
    }),
  )

const onReloadDocumentsDoSetReloadingByInterval: Epic<IFetchDocumentsPayload, typeof reloadDocuments> = (action$, state$) =>
  action$.pipe(
    filter(reloadDocuments.match),
    delayWhen(() => timer(10000)),
    filter(({ payload }) => {
      const { value: state } = state$

      return (getLocation()(state$.value).pathname || '').includes(String(payload.entityId)) && getLocationQuery(state)?.tab === 'documents'
    }),
    switchMap(({ payload }) => [
      fetchDocuments({
        ...payload,
        shouldNotShowLoadingState: true,
        commonTail: [EDocumentEntityType.REQUESTS, EDocumentEntityType.ORDERS].includes(payload.entity),
      }),
      reloadDocuments(payload),
    ]),
  )

const onDeleteDocumentDoMakeRequest: Epic<IDocument, typeof deleteDocument> = (action$, state$) =>
  action$.pipe(
    filter(deleteDocument.match),
    mergeMap(({ payload }) => {
      const state = state$.value
      const { id, entityId, entity, type } = payload
      const requestStatus = { name: `deleteDocument_${type}` }

      if (!entityId) return of(requestFailed(requestStatus))

      return concat(
        of(requestStarted(requestStatus)),
        from(
          DocumentsServices[entity].deleteDocument({ id: entityId, documentId: id, version: EntityVersionGetter[entity]?.(state) }),
        ).pipe(
          mergeMap(response =>
            isStatusOk(response)
              ? [
                  fetchDocuments({
                    entityId,
                    entity,
                    commonTail: [EDocumentEntityType.REQUESTS, EDocumentEntityType.ORDERS].includes(entity),
                  }),
                  requestFinished(requestStatus),
                ]
              : throwError(null),
          ),
          catchError(() => of(requestFailed(requestStatus))),
        ),
      )
    }),
  )

//eslint-disable-next-line @typescript-eslint/no-explicit-any
export default (<any>combineEpics)(
  onUploadDocumentDoMakeRequest,
  onUpdateDocumentDoMakeRequest,
  onFetchDocumentsDoMakeRequest,
  onDeleteDocumentDoMakeRequest,
  onDownloadDocumentDoMakeRequest,
  onReloadDocumentsDoSetReloadingByInterval,
)
