import type { QueryKey, UseQueryResult, UseQueryOptions, QueryOptions } from "@tanstack/react-query";
import { useQuery } from "@tanstack/react-query";
import client from "@/api/client/axios";
import type { GetRubrics1QueryResponse, GetRubrics1QueryParams } from "../types/GetRubrics1";

export const getRubrics_1QueryKey = (params?: GetRubrics1QueryParams) =>     [{url:`/api/ms-content-infoportal-backend/v1/admin/rubrics`},...(params ? [params] : [])] as const;
      export function getRubrics_1QueryOptions <TData = GetRubrics1QueryResponse, TError = unknown>(params?: GetRubrics1QueryParams, options: Partial<Parameters<typeof client>[0]> = {}): UseQueryOptions<TData, TError> {
        const queryKey = getRubrics_1QueryKey(params);

        return {
          queryKey,
          queryFn: () => {
            
            return client<TData, TError>({
              method: "get",
              url: `/api/ms-content-infoportal-backend/v1/admin/rubrics`,
              params,
              
              ...options,
            }).then(res => res.data);
          },
        };
      };
      
/**
 * @link /api/ms-content-infoportal-backend/v1/admin/rubrics
 */

export function useGetRubrics1 <TData = GetRubrics1QueryResponse, TError = unknown>(params?: GetRubrics1QueryParams, options: {
          query?: UseQueryOptions<TData, TError>,
          client?: Partial<Parameters<typeof client<TData, TError>>[0]>,
        } = {}): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const { query: queryOptions, client: clientOptions = {} } = options ?? {};
  const queryKey = queryOptions?.queryKey ?? getRubrics_1QueryKey(params);

  const query = useQuery<TData, TError>({
    ...getRubrics_1QueryOptions<TData, TError>(params, clientOptions),
    ...queryOptions
  }) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey as QueryKey;

  return query;
};
