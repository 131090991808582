import React, { memo, useContext, useMemo } from 'react'
import { FormattedMessage } from 'react-intl'
import { useSelector } from 'react-redux'
import { CommonLink } from '@frontend/pole-ui/lib/components/CommonLink'
import classNames from 'classnames'
import { AuthButton, BubblingTooltip, Button, Chip, InjectHtml } from '@/components/ui'
import { isAuthenticated, isUserAgrarian } from '@/logic/auth/reducer'
import type { RootState } from '@/redux/interfaces'
import { useWebpExtension } from '@/utils/hooks/image'
import { useResponsiveValue } from '@/utils/hooks/responsive'
import { useDeviceType } from '@/utils/hooks/useDeviceType'
import { getStaticImagePath } from '@/utils/images'
import { AdvertisingBannerContext } from './constants'
import type { IAdvertisingBannerContext, IAdvertisingBannerProps, IBannerTitleProps } from './interfaces'
import './styles.scss'

const BannerTitle: React.FC<IBannerTitleProps> = ({ size, className }) => {
  const { titleSize, titleSizeXs, title, titleId, isMobile } = useContext(AdvertisingBannerContext)

  return (
    <div>
      <InjectHtml
        className={classNames(`advertising-banner-block-content__title`, className, {
          [`h${size}`]: size && !titleSize,
          [`h${titleSize}`]: (titleSize && !isMobile) || (titleSize && !titleSizeXs),
          [`h${titleSizeXs}`]: titleSizeXs && isMobile,
        })}
        textId={titleId}
        html={title}
      />
    </div>
  )
}

const BannerButton: React.FC = () => {
  const {
    isMobile,
    isTopBanner,
    isAuthButton,
    buttonTextId,
    buttonText,
    onButtonClick,
    linkUrl,
    isButtonSmall,
    isUserAuthenticated,
    isAgrarian,
    buttonTooltipMessage,
    isWithoutButton,
  } = useContext(AdvertisingBannerContext)

  if (isWithoutButton) return null

  const shouldDisableButton = isUserAuthenticated && !isAgrarian

  const commonButtonClass = classNames('advertising-banner-button', {
    button_small: isButtonSmall,
    text_small: !isMobile && isTopBanner,
  })

  const submitButton = (
    <Button
      className={commonButtonClass}
      modifiers={['white']}
      textId={buttonTextId}
      onClick={onButtonClick}
      isDisabled={Boolean(buttonTooltipMessage) && shouldDisableButton}
    >
      {buttonText}
    </Button>
  )

  if (linkUrl)
    return (
      <CommonLink className={`button button_white ${commonButtonClass}`} url={linkUrl}>
        {buttonTextId ? <FormattedMessage id={buttonTextId} /> : buttonText}
      </CommonLink>
    )

  if (!isAuthButton) {
    return buttonTooltipMessage && shouldDisableButton ? (
      <BubblingTooltip content={buttonTooltipMessage}>
        <span className={'inline-block'}>{submitButton}</span>
      </BubblingTooltip>
    ) : (
      submitButton
    )
  }

  if (isUserAuthenticated) return <div className="advertising-banner-button_litter" />

  return (
    <AuthButton
      buttonProps={{
        isUnstyled: false,
        className: commonButtonClass,
        textId: buttonTextId,
        modifiers: ['white'],
      }}
      shouldHideForAuthenticatedUser
    >
      {buttonText}
    </AuthButton>
  )
}

const BannerPriceInfo: React.FC = () => {
  const { priceInfo } = useContext(AdvertisingBannerContext)

  return (
    <div className="advertisingBannerBlockContentPrice">
      {!!priceInfo?.initialPrice && <div className="advertisingBannerBlockContentPrice__initial">{priceInfo.initialPrice}</div>}
      {!!priceInfo?.price && <div className="advertisingBannerBlockContentPrice__value">{priceInfo.price}</div>}
      {priceInfo?.priceDescription && <div className="advertisingBannerBlockContentPrice__description">{priceInfo.priceDescription}</div>}
    </div>
  )
}

const BannerContentContainer: React.FC<{ className?: string }> = ({ className, children }) => {
  const { isTopBanner, isMobile } = useContext(AdvertisingBannerContext)

  return (
    <div className={classNames('advertising-banner-block-content', className, { 'page-wrapper': isTopBanner && !isMobile })}>
      {children}
    </div>
  )
}

const AdvertisingBannerContentWithBenefits: React.FC = () => {
  const { benefits = [] } = useContext(AdvertisingBannerContext)

  const benefitItems = useMemo(() => {
    if (!Array.isArray(benefits)) return benefits

    return (
      <ul className="list-with-marks list-with-marks_white">
        {benefits.map((benefitId, index) => (
          <li key={index} className="space-holder8">
            <FormattedMessage id={benefitId} />
          </li>
        ))}
      </ul>
    )
  }, [benefits])

  return (
    <BannerContentContainer>
      <BannerTitle className="space-holder24" />
      <div className="advertising-banner-block-content__description">
        {benefitItems}
        <div className="advertising-banner-block-content__description-button row-sm row_centered-sm">
          <BannerButton />
        </div>
      </div>
    </BannerContentContainer>
  )
}

const AdvertisingBannerContentWithPrice: React.FC = () => {
  return (
    <BannerContentContainer>
      <BannerTitle size={2} />
      <div className="row row-align_end row-justify_between-only-md">
        <BannerPriceInfo />
        <BannerButton />
      </div>
    </BannerContentContainer>
  )
}

const AdvertisingBannerContentWithDescription: React.FC = () => {
  const { descriptionId, textClass = 'space-holder32-from-sm space-holder24-sm' } = useContext(AdvertisingBannerContext)

  return (
    <BannerContentContainer>
      <BannerTitle size={1} className="space-holder16" />
      <p className={classNames('text_small advertising-banner-block-content__text', textClass)}>
        <InjectHtml textId={descriptionId} TagName="span" />
      </p>
      <div className="row-sm row_centered-sm">
        <BannerButton />
      </div>
    </BannerContentContainer>
  )
}

const AdvertisingBannerContent: React.FC = () => {
  return (
    <BannerContentContainer className="row row-align_center">
      <BannerTitle size={3} />
      <div className="row-sm row_centered-sm space-holder-left-auto advertising-banner-slim-block__button">
        <BannerButton />
      </div>
    </BannerContentContainer>
  )
}

const AdvertisingBannerContentWithFooter: React.FC = () => {
  const { Footer } = useContext(AdvertisingBannerContext)

  return (
    <BannerContentContainer>
      <BannerTitle size={3} />
      <div>{Footer}</div>
    </BannerContentContainer>
  )
}

const mapState = (state: RootState) => ({
  isUserAuthenticated: isAuthenticated()(state),
  isAgrarian: isUserAgrarian()(state),
})

const AdvertisingBanner: React.FC<IAdvertisingBannerProps> = props => {
  const { isUserAuthenticated, isAgrarian } = useSelector(mapState)

  const { isDesktop, isMobile } = useDeviceType()

  const {
    isAuthButton,
    onButtonClick,
    buttonTextId,
    buttonText,
    className,
    title,
    titleId,
    backgroundImage,
    benefits,
    descriptionId,
    isTopBanner,
    offerSignId,
    isSlimBlock,
    linkUrl,
    isButtonSmall,
    titleSize,
    textClass,
    isSqueezed,
    titleSizeXs,
    blockId,
    Footer,
    buttonTooltipMessage,
    isWithoutButton,
    isBannerWithPrice,
    priceInfo,
  } = props
  const imageExtension = useWebpExtension()
  let Content: React.FC = AdvertisingBannerContent

  if (benefits) Content = AdvertisingBannerContentWithBenefits
  else if (descriptionId) Content = AdvertisingBannerContentWithDescription
  else if (Footer) Content = AdvertisingBannerContentWithFooter
  else if (isBannerWithPrice) Content = AdvertisingBannerContentWithPrice

  const context: IAdvertisingBannerContext = useMemo(
    () => ({
      benefits,
      title,
      titleId,
      isAuthButton,
      buttonText,
      buttonTextId,
      onButtonClick,
      descriptionId,
      isTopBanner,
      linkUrl,
      isButtonSmall,
      titleSize,
      titleSizeXs,
      textClass,
      isUserAuthenticated,
      isMobile,
      Footer,
      isAgrarian,
      buttonTooltipMessage,
      isWithoutButton,
      priceInfo,
    }),
    [
      Footer,
      benefits,
      buttonText,
      buttonTextId,
      buttonTooltipMessage,
      descriptionId,
      isAgrarian,
      isAuthButton,
      isButtonSmall,
      isMobile,
      isTopBanner,
      isUserAuthenticated,
      isWithoutButton,
      linkUrl,
      onButtonClick,
      priceInfo,
      textClass,
      title,
      titleId,
      titleSize,
      titleSizeXs,
    ],
  )

  const { desktop, tablet, mobile } = useMemo(() => {
    return {
      desktop: getStaticImagePath(`${backgroundImage}.${imageExtension}`),
      tablet: getStaticImagePath(`${backgroundImage}Tablet.${imageExtension}`),
      mobile: getStaticImagePath(`${backgroundImage}Mobile.${imageExtension}`),
    }
  }, [backgroundImage, imageExtension])

  const background = useResponsiveValue({
    desktop: `url("${desktop}")`,
    tablet: `url("${tablet}")`,
    mobile: `url("${mobile}")`,
  })

  return (
    <AdvertisingBannerContext.Provider value={context}>
      <section className={classNames({ 'page-wrapper': isDesktop && isSqueezed })} id={blockId}>
        <div
          className={classNames(
            'with-blurred-bottom-block advertising-banner-block with-blurred-bottom-block_visible',
            {
              'advertising-banner-block_with-benefits': benefits,
              'advertising-banner-block_with-description': descriptionId,
              'advertising-banner-block_price': isBannerWithPrice,
              'advertising-banner-block_slim': isSlimBlock,
              'advertising-banner-block_squeezed': isSqueezed,
              'advertising-banner-block_with-offer-sign': offerSignId,
              'advertising-banner-block_top-banner': isTopBanner,
              'advertising-banner-block_with-footer': Footer,
            },
            className,
          )}
          style={{ backgroundImage: background }}
        >
          {offerSignId && <Chip className="advertising-banner-banner-sign" textId={offerSignId} />}
          <Content />
        </div>
      </section>
    </AdvertisingBannerContext.Provider>
  )
}

export default memo(AdvertisingBanner)
