import type { CoverImageV1 } from "./CoverImageV1";

export enum PublicationPatchParamsDataV1Status {
    "DRAFT" = "DRAFT",
    "PUBLISHED" = "PUBLISHED",
    "ARCHIVE" = "ARCHIVE"
}
export enum PublicationPatchParamsDataV1Type {
    "ARTICLE" = "ARTICLE",
    "NEWS" = "NEWS"
}
export type PublicationPatchParamsDataV1 = {
    /**
     * @type string | undefined
    */
    status?: PublicationPatchParamsDataV1Status;
    /**
     * @type string | undefined
    */
    type?: PublicationPatchParamsDataV1Type;
    /**
     * @type string | undefined
    */
    title?: string;
    /**
     * @type string | undefined
    */
    subtitle?: string;
    /**
     * @type string | undefined
    */
    lead?: string;
    /**
     * @type string | undefined
    */
    text?: string;
    coverImage?: CoverImageV1;
    /**
     * @type string | undefined
    */
    author?: string;
    /**
     * @type string | undefined
    */
    source?: string;
    /**
     * @type boolean | undefined
    */
    showCover?: boolean;
    /**
     * @type array | undefined
    */
    rubrics?: number[];
    /**
     * @type string | undefined date-time
    */
    publicationDate?: string;
    /**
     * @type array | undefined
    */
    tags?: number[];
};
