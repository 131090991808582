import type { FC } from 'react'
import React from 'react'
import { CommonLink } from '@frontend/pole-ui/lib/components/CommonLink'
import classNames from 'classnames'
import { Accordion } from '@/components/ui'
import type { IAccordionItemProps } from '@/components/ui/AccordionItem/interfaces'
import { pushFormattedGtmEvent } from '@/logic/metrika/helpers'
import { useLocation } from '@/router/hooks'
import type { TUuid } from '../../../../interfaces'
import { getTransliteratedGtmEvents } from '../../gtmHelpers'
import type { ITransliteratedGtmEventsOptions } from '../../types'
import { FaqTreeQuestions } from '..'
import type { IFaqTreeCategoryProps } from './interfaces'

const FaqTreeCategory: FC<IFaqTreeCategoryProps> = props => {
  const location = useLocation()
  const { items, questions, isSpecialization, categoryId, initialParams, hasChosenQuestion, loadQuestions, activeLink, selectLink } = props
  if (!items?.length) return null

  const handleSpecializationClick = (event: Event, id: string, gtmOptions: ITransliteratedGtmEventsOptions) => {
    // Реализация загрузки вопросов не через параметр в ссылке, чтобы сохранялася
    // по клику на специализацию в дереве текущий вопрос. Сама ссылка на специализацию
    // нужна для поисковых движков
    if (isSpecialization) {
      event.preventDefault()
      loadQuestions(id)
    } else pushFormattedGtmEvent(getTransliteratedGtmEvents(gtmOptions))
  }

  return (
    <Accordion
      accordionProps={{
        preExpanded: [initialParams?.categoryId, initialParams?.specializationId] as TUuid[],
        className: 'space-holder16',
        onChange: selectLink,
      }}
      items={items.map(({ name, children: specializations, id }): IAccordionItemProps => {
        const isDisabled = !isSpecialization && !specializations?.length
        const isLinkActive = isSpecialization || hasChosenQuestion
        const isSpecializationSelected = isSpecialization && location.pathname?.includes(activeLink) && id === activeLink
        const gtmOptions: ITransliteratedGtmEventsOptions = { blockName: 'blockQuestionsAndAnswers', context: { name } }

        if (!isSpecialization) {
          gtmOptions.eventLabel = 'buttonQuestionCategory'
          if (gtmOptions.context) gtmOptions.context.prefix = 'ttl'
        }

        return {
          header: isDisabled ? null : (
            <CommonLink
              activeClassName={!isSpecialization || isSpecializationSelected ? '' : undefined}
              url={`/faq/categories/${isSpecialization ? `${categoryId}/specialization/${id}` : id}`}
              onClick={event => handleSpecializationClick(event, id, gtmOptions)}
              className={classNames('space-holder16 accordion__link', { 'no-hover-link': isLinkActive, h4: !isSpecialization })}
            >
              {name}
            </CommonLink>
          ),
          isDisabled,
          content: (
            <>
              <FaqTreeCategory
                items={specializations}
                questions={questions}
                categoryId={id}
                initialParams={initialParams}
                loadQuestions={loadQuestions}
                isSpecialization
                hasChosenQuestion={hasChosenQuestion}
                activeLink={activeLink}
                selectLink={selectLink}
              />
              <FaqTreeQuestions
                questions={questions[id]}
                categoryId={categoryId as string}
                specializationId={id}
                isSpecialization={isSpecialization}
              />
            </>
          ),
          itemProps: { uuid: id },
        }
      })}
    />
  )
}

export default FaqTreeCategory
