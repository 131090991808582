import type { ErrorResult } from "./ErrorResult";
import type { CreateChatMessageData } from "./CreateChatMessageData";
import type { Unit } from "./Unit";

export enum AddRequestChatMessageV1QueryParamsEntityType {
    "ORDER" = "ORDER",
    "SALE_REQUEST" = "SALE_REQUEST",
    "MULTI_REQUEST" = "MULTI_REQUEST",
    "FUNDING_REQUEST" = "FUNDING_REQUEST"
}
export type AddRequestChatMessageV1QueryParams = {
    /**
     * @description Тип чата
     * @type string
    */
    entityType: AddRequestChatMessageV1QueryParamsEntityType;
    /**
     * @description Id сущности
     * @type integer int64
    */
    entityId: number;
};

/**
 * @description Некорректный запрос
*/
export type AddRequestChatMessageV1400 = ErrorResult;

/**
 * @description Внутренняя ошибка
*/
export type AddRequestChatMessageV1500 = ErrorResult;

export type AddRequestChatMessageV1MutationRequest = CreateChatMessageData;

/**
 * @description Сообщение добавлено
*/
export type AddRequestChatMessageV1MutationResponse = Unit;
