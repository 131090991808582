import { combineEpics } from 'redux-observable'
import { concat, from, of, throwError } from 'rxjs'
import { catchError, debounceTime, distinctUntilChanged, filter, mergeMap } from 'rxjs/operators'
import { isStatusOk } from '@/api/helpers'
import type { IResponse } from '@/api/interfaces'
import { openModal } from '@/components/composed/Modal/actions'
import type { IMessageModalOptions } from '@/components/composed/modals/MessageModal/interfaces'
import { CartService } from '@/services'
import type { ICart } from '@/types/Cart'
import { requestFailed, requestFinished, requestStarted } from '../requestStatus/actions'
import type { IRequestStatusPayload } from '../requestStatus/interfaces'
import type { Epic } from './../interfaces'
import type { fetchCart } from './actions'
import { addProductToCart, changeCartItemQuantity, clearCart, deleteCartItem, fetchMultiCart } from './actions'
import { ECartRequestName } from './constants'
import { getChangingProductQuantityRequestName, getClearingCartRequestName } from './helpers'
import type { IAddProductToCartPayload, IClearCartPayload, TDeleteICartItemPayload } from './interfaces'

const cartService = new CartService()

const onClearCartDoMakeRequest: Epic<IClearCartPayload, typeof clearCart> = action$ =>
  action$.pipe(
    filter(clearCart.match),
    mergeMap(({ payload }) => {
      const { id } = payload
      const requestStatus: IRequestStatusPayload = { name: getClearingCartRequestName(id) }

      return concat(
        of(requestStarted(requestStatus)),
        from(cartService.clearCart({ cartId: id })).pipe(
          mergeMap(response => {
            if (!isStatusOk(response)) return throwError(response)

            return of(requestFinished(requestStatus), fetchMultiCart())
          }),
          catchError(({ meta }) =>
            of(
              openModal({
                options: {
                  dialogId: 'MessageModal',
                  headerId: 'cartError.deletingOrderError.title',
                },
                contentOptions: {
                  messages: [{ id: 'cartError.deletingOrderError.text', className: 'space-holder32' }],
                  buttons: [{ textId: 'cartError.deletingOrderError.button', modifiers: ['outline'] }],
                } as IMessageModalOptions,
              }),
              requestFailed({ ...requestStatus, meta }),
            ),
          ),
        ),
      )
    }),
  )

const onAddProductToCartDoMakeRequest: Epic<IAddProductToCartPayload, typeof fetchCart> = action$ =>
  action$.pipe(
    filter(addProductToCart.match),
    mergeMap(({ payload }) => {
      const { offer, quantity } = payload
      const requestStatus: IRequestStatusPayload = { name: ECartRequestName.ADD_PRODUCT_TO_CART }

      return concat(
        of(requestStarted(requestStatus)),
        from(cartService.addProduct({ productId: offer.id, quantity })).pipe(
          mergeMap(response => {
            if (!isStatusOk(response)) return throwError(response)

            return [requestFinished(requestStatus), fetchMultiCart()]
          }),
          catchError(() =>
            of(
              openModal({
                options: {
                  dialogId: 'MessageModal',
                  headerId: 'cartError.addCartItemError.title',
                },
                contentOptions: {
                  messages: [{ id: 'cartError.addCartItemError.text', className: 'space-holder32' }],
                  buttons: [{ textId: 'cartError.addCartItemError.button', modifiers: ['outline'] }],
                } as IMessageModalOptions,
              }),
              requestFailed(requestStatus),
            ),
          ),
        ),
      )
    }),
  )

const onChangeCartItemQuantityDoMakeRequest: Epic<IAddProductToCartPayload, typeof changeCartItemQuantity> = action$ =>
  action$.pipe(
    filter(changeCartItemQuantity.match),
    debounceTime(500),
    distinctUntilChanged(),
    mergeMap(({ payload }) => {
      const { offer, quantity } = payload
      const requestStatus: IRequestStatusPayload = { name: getChangingProductQuantityRequestName(offer.id) }

      return concat(
        of(requestStarted(requestStatus)),
        from(cartService.changeProductQuantity({ productId: offer.id, quantity })).pipe(
          mergeMap(response => {
            if (!isStatusOk(response)) return throwError(response)

            return [requestFinished(requestStatus), fetchMultiCart()]
          }),
          catchError(({ meta }) =>
            of(
              requestFailed({ ...requestStatus, meta }),
              openModal({
                options: {
                  dialogId: 'MessageModal',
                  headerId: 'cartError.addCartItemError.title',
                },
                contentOptions: {
                  messages: [{ id: 'cartError.addCartItemError.text', className: 'space-holder32' }],
                  buttons: [{ textId: 'cartError.addCartItemError.button', modifiers: ['outline'] }],
                } as IMessageModalOptions,
              }),
            ),
          ),
        ),
      )
    }),
  )

const onCartDeleteItemDoMakeRequest: Epic<TDeleteICartItemPayload, typeof deleteCartItem> = action$ =>
  action$.pipe(
    filter(deleteCartItem.match),
    mergeMap(({ payload }) => {
      const { offer } = payload
      const requestStatus = { name: ECartRequestName.DELETE_PRODUCT_FROM_CART }

      return concat(
        of(requestStarted(requestStatus)),
        from(cartService.deleteProduct({ productId: offer.id })).pipe(
          mergeMap(response => {
            if (!isStatusOk(response)) return throwError(response)

            return [fetchMultiCart(), requestFinished(requestStatus)]
          }),
          catchError(({ meta }: IResponse<ICart>) =>
            of(
              requestFailed({ ...requestStatus, meta }),
              fetchMultiCart(),
              openModal({
                options: {
                  dialogId: 'MessageModal',
                  headerId: 'cartError.deleteCartItemError.title',
                },
                contentOptions: {
                  messages: [{ id: 'cartError.deleteCartItemError.text', className: 'space-holder32' }],
                  buttons: [{ textId: 'cartError.deleteCartItemError.button', modifiers: ['outline'] }],
                } as IMessageModalOptions,
              }),
            ),
          ),
        ),
      )
    }),
  )

//eslint-disable-next-line @typescript-eslint/no-explicit-any
export default (<any>combineEpics)(
  onAddProductToCartDoMakeRequest,
  onCartDeleteItemDoMakeRequest,
  onChangeCartItemQuantityDoMakeRequest,
  onClearCartDoMakeRequest,
)
