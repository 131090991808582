import { connectRouter } from 'connected-react-router'
import dealAgreementForm from '@/components/composed/forms/DealAgreementForm/reduce'
import app from '@/logic/app/reducer'
import auth from '@/logic/auth/reducer'
import data from '@/logic/data/reducer'
import intl from '@/logic/translation/reducer'
import userRegion from '@/logic/userRegion'
import { history } from '../browserHistory'
import createReducerManager from './createReducerManager'
import type { IIntegratedReducers, TReduxReduce, TRootReducers } from './interfaces'

const reducers: Pick<TRootReducers & IIntegratedReducers, TReduxReduce> = {
  app,
  auth,
  data,
  userRegion,
  router: connectRouter(history),
  intl,
  dealAgreementForm,
}

const reducerManager = createReducerManager(reducers)

export { reducerManager }
