import loadable from '@loadable/component'

export { default as FaqCategoriesTree } from './FaqCategoriesTree'
export { default as FaqCategory } from './FaqCategory'
export { default as FaqArticle } from './FaqArticle'
export { default as FaqBreadcrumbs } from './FaqBreadcrumbs'
export { default as NearestArticle } from './NearestArticle'
export { default as FaqTreeQuestions } from './FaqTreeQuestions'
export { default as FaqTreeCategory } from './FaqTreeCategory'
export { default as FaqRoutes } from './FaqRoutes'

export const FaqDesktop = loadable(
  /* webpackChunkName: "FaqDesktop" */
  /* webpackPrefetch: "true" */
  /* #__LOADABLE__ */
  () => import('./FaqDesktop'),
)

export const FaqMobile = loadable(
  /* webpackChunkName: "FaqMobile" */
  /* webpackPrefetch: "true" */
  /* #__LOADABLE__ */
  () => import('./FaqMobile'),
)

export const FaqSpecialization = loadable(
  /* webpackChunkName: "FaqSpecialization" */
  /* webpackPrefetch: "true" */
  /* #__LOADABLE__ */
  () => import('./FaqSpecialization'),
)

export const FaqCategorySpecializations = loadable(
  /* webpackChunkName: "FaqCategorySpecializations" */
  /* webpackPrefetch: "true" */
  /* #__LOADABLE__ */
  () => import('./FaqCategorySpecializations'),
)

export const FaqAuthor = loadable(
  /* webpackChunkName: "FaqAuthor" */
  /* webpackPrefetch: "true" */
  /* #__LOADABLE__ */
  () => import('./FaqAuthor'),
)
