import client from "@/api/client/axios";
import type { ResponseConfig } from "@/api/client/axios";
import type { GetSaleTemplatesBestOffersQueryResponse, GetSaleTemplatesBestOffersQueryParams } from "../../types/GetSaleTemplatesBestOffers";

/**
     * @summary Получение списка лучших предложений на главной странице
     * @link /api/requests/list/templates/bestoffers
     */
export async function getSaleTemplatesBestOffers (params?: GetSaleTemplatesBestOffersQueryParams, options: Partial<Parameters<typeof client>[0]> = {}): Promise<ResponseConfig<GetSaleTemplatesBestOffersQueryResponse>["data"]> {
    const { data: resData } = await client<GetSaleTemplatesBestOffersQueryResponse>({
        method: "get",
        url: `/api/requests/list/templates/bestoffers`,
        params,
        ...options
    });
    
    return resData;
};