import type { ErrorResult } from "./ErrorResult";
import type { MainPageBannersListDataResult } from "./MainPageBannersListDataResult";

export enum GetMainPageBannersQueryParamsType {
    "INPUTS_FARMING" = "INPUTS_FARMING",
    "INPUTS_MACHINERY" = "INPUTS_MACHINERY",
    "INPUTS_PROMO" = "INPUTS_PROMO",
    "OUTPUTS" = "OUTPUTS",
    "FUNDING" = "FUNDING",
    "FINANCE" = "FINANCE",
    "PARTNERS" = "PARTNERS"
}
export type GetMainPageBannersQueryParams = {
    /**
     * @description Код региона
     * @type integer | undefined int32
    */
    regionCode?: number;
    /**
     * @description Тип баннера
     * @type string | undefined
    */
    type?: GetMainPageBannersQueryParamsType;
};

/**
 * @description Некорректный запрос
*/
export type GetMainPageBanners400 = ErrorResult;

/**
 * @description Внутренняя ошибка
*/
export type GetMainPageBanners500 = ErrorResult;

/**
 * @description Список баннеров предоставлен
*/
export type GetMainPageBannersQueryResponse = MainPageBannersListDataResult;
