import type { ResponseManagersPublicationsWithPaginationDtoV1 } from "./ResponseManagersPublicationsWithPaginationDtoV1";

export enum GetManagerPublicationsQueryParamsType {
    "ARTICLE" = "ARTICLE",
    "NEWS" = "NEWS"
}
export enum GetManagerPublicationsQueryParamsStatus {
    "DRAFT" = "DRAFT",
    "PUBLISHED" = "PUBLISHED",
    "ARCHIVE" = "ARCHIVE"
}
export type GetManagerPublicationsQueryParams = {
    /**
     * @type string
    */
    type: GetManagerPublicationsQueryParamsType;
    /**
     * @type string
    */
    status: GetManagerPublicationsQueryParamsStatus;
    /**
     * @type integer | undefined int64
    */
    rubricId?: number;
    /**
     * @type integer | undefined int64
    */
    tagId?: number;
    /**
     * @type string | undefined
    */
    text?: string;
    /**
     * @type integer | undefined int32
    */
    offset?: number;
    /**
     * @type integer | undefined int32
    */
    limit?: number;
};

/**
 * @description OK
*/
export type GetManagerPublicationsQueryResponse = ResponseManagersPublicationsWithPaginationDtoV1;
