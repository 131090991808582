import type { PropsWithChildren } from 'react'
import React, { useCallback, useMemo, useState } from 'react'
import type { FieldPath, FieldValues } from 'react-hook-form'
import { useController } from 'react-hook-form'
import { Dropdown } from '@frontend/pole-ui/lib/components/Dropdown'
import { getMappedFieldMetaState, splitHookFormControllerProps } from '@/utils/fields'
import type { IDropdownProps } from './types'

export function HookFormDropdown<
  GValue = string,
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>(props: PropsWithChildren<IDropdownProps<GValue, TFieldValues, TName>>) {
  const [controllerProps, { isDisabled, onFocus, onBlur, onChange, ...componentProps }] = splitHookFormControllerProps(props)

  const disabled = controllerProps.disabled || isDisabled

  const {
    // eslint-disable-next-line no-restricted-syntax
    field: { ref, ...field },
    fieldState,
  } = useController({ ...controllerProps, disabled })

  /* TODO: active state пока недоступен из коробки
     Можно будет удалить, как только будет залит этот pr: https://github.com/react-hook-form/react-hook-form/pull/11024
     https://jira.poleinvest.ru/browse/IFA-856
   */
  const [active, setActive] = useState(false)
  const meta = useMemo(() => getMappedFieldMetaState(fieldState, { active }), [fieldState, active])

  const onInputFocus: NonNullable<typeof onFocus> = useCallback(() => {
    onFocus?.()
    setActive(true)
  }, [onFocus])

  const onInputBlur: NonNullable<typeof onBlur> = useCallback(() => {
    field.onBlur()
    onBlur?.()
    setActive(false)
  }, [field, onBlur])

  const onInputChange: NonNullable<typeof onChange> = useCallback(
    (changedValue, option, event) => {
      field.onChange(changedValue)
      onChange?.(changedValue, option, event)
    },
    [field, onChange],
  )

  return (
    <Dropdown<GValue>
      errorText={fieldState.error?.message}
      {...field}
      {...componentProps}
      meta={meta}
      isDisabled={field.disabled}
      onFocus={onInputFocus}
      onBlur={onInputBlur}
      onChange={onInputChange}
      mRef={ref}
    />
  )
}
