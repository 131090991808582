import client from "@/api/client/axios";
import type { ResponseConfig } from "@/api/client/axios";
import type { AddMultiRequestChatMessageMutationRequest, AddMultiRequestChatMessageMutationResponse, AddMultiRequestChatMessagePathParams } from "../../types/AddMultiRequestChatMessage";

/**
     * @summary Добавить сообщение в чат в мультизаявке
     * @link /api/multirequests/:requestId/chat
     */
export async function addMultiRequestChatMessage (requestId: AddMultiRequestChatMessagePathParams["requestId"], data: AddMultiRequestChatMessageMutationRequest, options: Partial<Parameters<typeof client>[0]> = {}): Promise<ResponseConfig<AddMultiRequestChatMessageMutationResponse>["data"]> {
    const { data: resData } = await client<AddMultiRequestChatMessageMutationResponse, AddMultiRequestChatMessageMutationRequest>({
        method: "post",
        url: `/api/multirequests/${requestId}/chat`,
        data,
        ...options
    });
    
    return resData;
};