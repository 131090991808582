import React, { memo } from 'react'
import { CommonLink } from '@frontend/pole-ui/lib/components/CommonLink'
import classNames from 'classnames'
import { Button, Image } from '@/components/ui'
import type { IAgroserviceGeneralCardProps } from './interfaces'
import './styles.scss'

const AgroserviceGeneralCard: React.FC<IAgroserviceGeneralCardProps> = props => {
  const {
    url,
    logoUrl,
    onClick,
    className,
    backgroundImageUrl = '',
    isBackgroundImageInStatic = true,
    isHideDescriptionText = false,
    buttonText,
    titleText,
    descriptionText,
  } = props

  return (
    <div className={classNames('agroserviceGeneralCard agroservice-card-wrapper', className)}>
      <div className="agroserviceGeneralCard__logo">{logoUrl && <Image src={logoUrl} isInStatic />}</div>
      <section
        className={classNames(
          'with-blurred-bottom-block with-blurred-bottom-block_visible agroservice-card-item-wrapper',
          'agroserviceGeneralCard__item',
        )}
      >
        <h4 className="agroserviceGeneralCard__title">{titleText}</h4>
        {!isHideDescriptionText && <div className="agroserviceGeneralCard__text">{descriptionText}</div>}
        <CommonLink className="agroserviceGeneralCard__button" url={url}>
          <Button onClick={onClick} modifiers={['white', 'small']}>
            {buttonText}
          </Button>
        </CommonLink>
      </section>
      <Image src={backgroundImageUrl} className="agroservice-card-background-image" isInStatic={isBackgroundImageInStatic} />
    </div>
  )
}

export default memo(AgroserviceGeneralCard)
