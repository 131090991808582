import React from 'react'
import { SUPPORT_EMAIL, SUPPORT_PHONE, SUPPORT_PHONE_FORMATTED } from './constants'
import { messages } from './messages'
import './GeneralErrorModalContent.scss'

const GeneralErrorModalContent = () => (
  <div className="error-modal-content">
    <p className="error-modal-content__text">{messages.noAccountsErrorModal.mainMessage}:</p>
    <p className="error-modal-content__text">
      {messages.noAccountsErrorModal.phone}:{' '}
      <a href={`tel:${SUPPORT_PHONE}`} className="error-modal-content__link error-modal-content__link_type_phone">
        {SUPPORT_PHONE_FORMATTED}
      </a>
    </p>
    <p className="error-modal-content__text">
      {messages.noAccountsErrorModal.email}:{' '}
      <a href={`mailto:${SUPPORT_EMAIL}`} className="error-modal-content__link">
        {SUPPORT_EMAIL}
      </a>
    </p>
  </div>
)

export default GeneralErrorModalContent
