import type { PropsWithChildren } from 'react'
import React, { useState } from 'react'
import { FormattedMessage } from 'react-intl'
import classNames from 'classnames'
import findIndex from 'lodash/findIndex'
import { Steps } from 'rsuite'
import { HorizontalScrollableBlock } from '..'
import type { IProgressSteps } from './interfaces'
import './styles.scss'

function BaseProgressSteps<T>({ steps, activeStep, className }: PropsWithChildren<IProgressSteps<T>>) {
  const activeIndex = findIndex(steps, ({ id }) => activeStep === id)

  return (
    <div className={classNames('progress-steps-container', className)}>
      <Steps current={activeIndex}>
        {steps.map(({ textId, text, id, title }) => {
          const key = typeof text === 'string' ? text : textId || (id as string | number)

          return <Steps.Item description={textId ? <FormattedMessage id={textId} /> : text} title={title} key={key} />
        })}
      </Steps>
    </div>
  )
}

function ScrollableProgressSteps<T>({ steps, activeStep, className }: PropsWithChildren<IProgressSteps<T>>) {
  const [ref, setRef] = useState<HTMLElement | null>(null)
  const activeIndex = findIndex(steps, ({ id }) => activeStep === id)

  return (
    <HorizontalScrollableBlock refObject={ref} totalWidth={ref?.children[0]?.scrollWidth} total={steps.length} activeIndex={activeIndex}>
      <div className={classNames('progress-steps-container progress-steps-container_swipeable', className)} ref={setRef}>
        <Steps current={activeIndex}>
          {steps.map(({ textId, text, id, title }) => {
            const key = typeof text === 'string' ? text : textId || (id as string | number)

            return <Steps.Item description={textId ? <FormattedMessage id={textId} /> : text} title={title} key={key} />
          })}
        </Steps>
      </div>
    </HorizontalScrollableBlock>
  )
}

function ProgressSteps<T>(props: PropsWithChildren<IProgressSteps<T>>) {
  return props.withSwipeControls ? <ScrollableProgressSteps {...props} /> : <BaseProgressSteps {...props} />
}

export default ProgressSteps
