import React from 'react'
import { FormattedMessage } from 'react-intl'
import classNames from 'classnames'
import type { IPageNoticeTextProps } from './interfaces'
import './styles.scss'

const PageNoticeText: React.FC<IPageNoticeTextProps> = ({ textId, children, className, textValues }) => (
  <section className={classNames('page-notice-text page-wrapper', className)}>
    {children}
    {textId && <FormattedMessage id={textId} values={textValues} />}
  </section>
)

export default PageNoticeText
