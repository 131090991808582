import React from 'react'
import { EColor } from '@frontend/pole-ui/lib/common/constants'
import { EIconName, Icon } from '@frontend/pole-ui/lib/components/Icon'
import classNames from 'classnames'
import InjectHtml from '../InjectHtml'
import type { IHeadingProps } from './interfaces'

const TableHeading: React.FC<IHeadingProps> = ({ title, titleId, className, textValues }) => {
  const headingTitle = title ?? <InjectHtml textId={titleId} textValues={textValues} />

  return (
    <div className={classNames('row row-align_center color_pale-black', className)}>
      {headingTitle}
      <span className="space-holder-left8 row hidden">
        <Icon name={EIconName.Filter} style={{ color: EColor.LIGHT_GRAY }} />
      </span>
    </div>
  )
}

export default TableHeading
