import type { TMinioImagePath } from '@/types'
import { getStaticPath } from '@/utils/getStaticPath'
import { getUrlWithQuery } from './url'

export const getMinioImagePath = ({ src, version }: TMinioImagePath) =>
  src ? getUrlWithQuery(`${process.env.REACT_APP_STATIC_PATH_MEDIA}/${src}`, { version }) : ''

export const createImageResolver = (context: __WebpackModuleApi.RequireContext, assetPathPrefix: string) => {
  const resolvedByOriginalPath: Record<string, string | undefined> = {}

  context.keys().forEach(path => {
    const cleanPath = path.replace('./', '')

    resolvedByOriginalPath[cleanPath] = context(path)
  })

  return (imagePath: string) => {
    if (process.env.NODE_ENV === 'development') {
      return getStaticPath(`${assetPathPrefix}${imagePath}`)
    }

    return resolvedByOriginalPath[imagePath] ?? ''
  }
}

export const getStaticImagePath = createImageResolver(
  require.context('../../assets/images', true, /\.(png|svg|jpg|jpeg|gif|webp)$/i),
  'images/',
)
