import React from 'react'
import { FormattedMessage } from 'react-intl'
import { CommonLink } from '@frontend/pole-ui/lib/components/CommonLink'
import classNames from 'classnames'
import { useLocation } from '@/router/hooks'
import type { IHeadingBreadcrumbsProps } from './interfaces'
import './styles.scss'

const HeadingBreadcrumbs: React.FC<IHeadingBreadcrumbsProps> = ({ headings, className, isLinksDisabled }) => {
  const { pathname } = useLocation()

  return (
    <div className={classNames('heading-breadcrumbs', className)}>
      {headings.map(({ titleId, url }) => {
        const isCurrent = pathname === url

        return (
          <CommonLink
            url={isCurrent || isLinksDisabled ? undefined : url}
            spanIfEmpty
            activeClassName=""
            key={titleId}
            className={classNames('heading-breadcrumb button button_tab', {
              'button_tab-active': isCurrent,
              'color_pale-black': !isCurrent,
            })}
          >
            <FormattedMessage id={titleId} />
          </CommonLink>
        )
      })}
    </div>
  )
}

export default HeadingBreadcrumbs
