import type { PayloadAction } from '@reduxjs/toolkit'
import { createReducer } from '@reduxjs/toolkit'
import { getInitialState } from '@/redux/helpers'
import type { RootState } from '@/redux/interfaces'
import { reducerManager } from '@/redux/reducerManager'
import type { NAction } from './actions'
import { clearModalOptions, closeModal, openModal } from './actions'
import type { IModalState } from './interfaces'

const initialState: IModalState = {
  options: {
    isOpened: false,
    header: '',
    headerId: '',
    dialogId: '',
    shouldCloseOnMissingClick: true,
    modalClassName: '',
    isDraggable: false,
  },
}

const reducerName = 'modal'

const reducer = createReducer(getInitialState(reducerName, initialState), {
  [openModal.type]: (state, { payload }: PayloadAction<NAction.IOpenModalPayload>) => {
    state.options.isOpened = true
    state.options.header = payload.options.header || ''
    state.options.headerId = payload.options.headerId || ''
    state.options.dialogId = payload.options.dialogId || ''
    state.options.headerIcon = payload.options.headerIcon
    state.options.onCloseAction = payload.options.onCloseAction
    state.options.onCloseModal = payload.options.onCloseModal
    state.options.modalClassName = payload.options.modalClassName || ''
    state.options.isDraggable = payload.options.isDraggable || false

    state.options.shouldCloseOnMissingClick = Object.prototype.hasOwnProperty.call(payload.options, 'shouldCloseOnMissingClick')
      ? Boolean(payload.options?.shouldCloseOnMissingClick)
      : true

    state.contentOptions = payload.contentOptions
    state.options.exitIcon = payload.options.exitIcon
    state.options.textId = payload.options.textId
    state.options.onConfirm = payload.options.onConfirm
    state.options.onClickCloseIcon = payload.options.onClickCloseIcon
  },
  [closeModal.type]: state => {
    state.options.isOpened = false
    state.options.header = ''
    state.options.headerId = ''
  },
  [clearModalOptions.type]: state => {
    state.options.dialogId = ''
    state.contentOptions = undefined
  },
})

reducerManager.add(reducerName, reducer, true)

export const getModalOptions = () => (state: RootState) => state.modal.options
export const getContentOptions = () => (state: RootState) => state.modal.contentOptions
export const getDialogId = () => (state: RootState) => state.modal.options.dialogId
