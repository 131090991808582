import { PHONE_NUMBER_FIELD_PREFIX } from './constants'
import type { TTextInputTransformer } from './types'

export const normalizePhoneNumber: TTextInputTransformer = value => {
  const phoneNumberNormalized = String(value).replace(/\D/g, '')

  return phoneNumberNormalized.length === 10 && !phoneNumberNormalized.startsWith(PHONE_NUMBER_FIELD_PREFIX)
    ? `${PHONE_NUMBER_FIELD_PREFIX}${phoneNumberNormalized}`
    : phoneNumberNormalized
}

export const normalizeNumber: TTextInputTransformer = value => value.replace(',', '.')
