import type { FC } from 'react'
import React, { memo } from 'react'
import { useIntl } from 'react-intl'
import { EChipModifier } from '@frontend/pole-ui/lib/components/Chip'
import classNames from 'classnames'
import { PriceInfo } from '@/components/composed'
import { Chip } from '@/components/ui'
import { OfferPrice } from '@/components/ui/inputs'
import { injectMessages } from '@/logic/translation/utils'
import { EAgriculturalProductDiscountType } from '@/types/Catalog'
import type { IOfferPriceWithDiscountProps } from './interfaces'
import messages from './messages.json'
import './styles.scss'

injectMessages(messages)

const OfferPriceWithDiscount: FC<IOfferPriceWithDiscountProps> = props => {
  const {
    priceClassName,
    discountPriceClassName,
    actualPriceClassName,
    isMobileDevice,
    isUserAuthenticated,
    sku,
    offer,
    packagePriceClassName,
    unitPriceClassName,
    vatRate,
    shouldShowVatRate,
    shouldHideConditionalCurrencyHint,
    isMainPageCard,
  } = props
  const { formatMessage } = useIntl()
  const { packageType, pieceProduct } = sku
  const { price, unitPrice, discountType, discountPercent, discountPrice, discountUnitPrice, showPriceForUnauth } = offer
  const isNotAuthPriceHidden = !isUserAuthenticated && !showPriceForUnauth
  const isDiscount = discountType !== EAgriculturalProductDiscountType.NONE
  const isFixedDiscount = discountType === EAgriculturalProductDiscountType.FIXED
  const discountChipText = formatMessage({ id: `OfferPriceWithDiscount.chip.${discountType}` }, { discountPercent })
  const shouldHideChip = isMainPageCard && !isFixedDiscount
  const shouldShowPriceWithDiscount = unitPrice && isDiscount && !isNotAuthPriceHidden && !!discountPercent && !shouldHideChip

  return (
    <>
      {shouldShowPriceWithDiscount && (
        <div className={classNames('offerPriceWithDiscount__discount', discountPriceClassName)}>
          {isFixedDiscount && (
            <div className="offerPriceWithDiscount__discountPrice">
              <PriceInfo price={unitPrice} />
              {packageType.unitCode && !pieceProduct && <span>/{packageType.unitCode}</span>}
            </div>
          )}
          <Chip className="offerPriceWithDiscount__chip" color={EChipModifier.WARNING} modifiers={[EChipModifier.RECT]}>
            {discountChipText}
          </Chip>
        </div>
      )}

      <OfferPrice
        className={classNames(priceClassName, actualPriceClassName)}
        unitPrice={discountUnitPrice || unitPrice}
        unitCode={packageType.unitCode}
        price={discountPrice || price}
        pieceProduct={pieceProduct}
        isPriceHidden={isNotAuthPriceHidden}
        isMobileDevice={isMobileDevice}
        packagePriceClassName={packagePriceClassName}
        unitPriceClassName={unitPriceClassName}
        vatRate={vatRate}
        shouldShowVatRate={shouldShowVatRate && !shouldShowPriceWithDiscount}
        shouldHideConditionalCurrencyHint={shouldHideConditionalCurrencyHint}
        shouldHidePackagePrice={shouldShowPriceWithDiscount}
      />
    </>
  )
}

export default memo(OfferPriceWithDiscount)
