import { useEffect } from 'react'

export const useMount = (onMount?: () => void, onUnmount?: () => void) =>
  useEffect(() => {
    if (onUnmount) {
      onMount?.()

      return onUnmount
    }

    return onMount?.()
    //eslint-disable-next-line
  }, [])
