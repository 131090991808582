import { EAgrarianOrderStatus } from '@/types/Order'

export const canceledStatuses = [EAgrarianOrderStatus.CANCELED, EAgrarianOrderStatus.CLOSED]

export enum EProfileRequestType {
  REQUESTS = 'salerequests',
  ORDERS = 'orders',
}

export enum EProfileRequestStatusName {
  changeStatus = 'changeStatus',
}
