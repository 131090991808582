import type { FC } from 'react'
import React, { memo } from 'react'
import { EIconName } from '@frontend/pole-ui/lib/components/Icon'
import { Button } from '@/components/ui'
import type { ISearchInputWithSuggestionsWrapperProps } from './interfaces'

const SearchInputWithSuggestionsWrapper: FC<ISearchInputWithSuggestionsWrapperProps> = props => {
  const { shouldOpenModal, onCloseModal, children } = props

  if (!shouldOpenModal) return <>{children}</>

  return (
    <div className="search-input-with-suggestions-modal">
      <Button
        isUnstyled
        onClick={onCloseModal}
        iconProps={{ name: EIconName.ArrowLeft2 }}
        className="search-input-with-suggestions-modal__close-button"
      />
      <div className="search-input-with-suggestions-modal__content">{children}</div>
    </div>
  )
}

export default memo(SearchInputWithSuggestionsWrapper) as typeof SearchInputWithSuggestionsWrapper
