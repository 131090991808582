import client from "@/api/client/dadata";
import type { ResponseConfig } from "@/api/client/dadata";
import type { SuggestCompanyMutationRequest, SuggestCompanyMutationResponse } from "../../types/SuggestCompany";

/**
     * @link /v1/suggest_company
     */
export async function suggestCompany (data: SuggestCompanyMutationRequest, options: Partial<Parameters<typeof client>[0]> = {}): Promise<ResponseConfig<SuggestCompanyMutationResponse>["data"]> {
    const { data: resData } = await client<SuggestCompanyMutationResponse, SuggestCompanyMutationRequest>({
        method: "post",
        url: `/v1/suggest_company`,
        data,
        ...options
    });
    
    return resData;
};