import client from "@/api/client/axios";
import type { ResponseConfig } from "@/api/client/axios";
import type { FilterFundingRequestsMutationRequest, FilterFundingRequestsMutationResponse } from "../../types/FilterFundingRequests";

/**
     * @link /api/credit-flow-backend/v1/fundings
     */
export async function filterFundingRequests (data: FilterFundingRequestsMutationRequest, options: Partial<Parameters<typeof client>[0]> = {}): Promise<ResponseConfig<FilterFundingRequestsMutationResponse>["data"]> {
    const { data: resData } = await client<FilterFundingRequestsMutationResponse, FilterFundingRequestsMutationRequest>({
        method: "post",
        url: `/api/credit-flow-backend/v1/fundings`,
        data,
        ...options
    });
    
    return resData;
};