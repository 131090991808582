export enum ELegalDocumentType {
  registrationRules = 'USER_REGISTRATION_RULES',
  registrationPolicy = 'USER_REGISTRATION_POLICY',
  registrationRegulation = 'USER_REGISTRATION_REGULATION',
  cookiesPolicy = 'COOKIES_POLICY',
}

export interface ILegalDocument {
  id: string
  typeId: ELegalDocumentType
  name: string
  url: string
}

export type TLegalDocuments = ILegalDocument[]
