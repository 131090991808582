import type { UseMutationOptions, UseMutationResult } from "@tanstack/react-query";
import { useMutation } from "@tanstack/react-query";
import client from "@/api/client/axios";
import type { ResponseConfig } from "@/api/client/axios";
import type { AddSaleRequestShippingSurchargeMutationRequest, AddSaleRequestShippingSurchargeMutationResponse, AddSaleRequestShippingSurchargePathParams, AddSaleRequestShippingSurcharge404, AddSaleRequestShippingSurcharge500 } from "../types/AddSaleRequestShippingSurcharge";

/**
 * @summary Запрос счёта на доплату
 * @link /api/salerequests/:requestId/shippings/surcharge
 */

export function useAddSaleRequestShippingSurcharge <TData = AddSaleRequestShippingSurchargeMutationResponse, TError = AddSaleRequestShippingSurcharge404 | AddSaleRequestShippingSurcharge500, TVariables = AddSaleRequestShippingSurchargeMutationRequest>(requestId: AddSaleRequestShippingSurchargePathParams["requestId"], options: {
          mutation?: UseMutationOptions<ResponseConfig<TData>, TError, TVariables>,
          client?: Partial<Parameters<typeof client<TData, TError, TVariables>>[0]>,
      } = {}): UseMutationResult<ResponseConfig<TData>, TError, TVariables> {
  const { mutation: mutationOptions, client: clientOptions = {} } = options ?? {};

  return useMutation<ResponseConfig<TData>, TError, TVariables>({
    mutationFn: (data) => {
      
      return client<TData, TError, TVariables>({
        method: "post",
        url: `/api/salerequests/${requestId}/shippings/surcharge`,
        data,
        
        
        ...clientOptions
      });
    },
    ...mutationOptions
  });
};
