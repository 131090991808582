import type { QueryKey, UseQueryResult, UseQueryOptions, QueryOptions } from "@tanstack/react-query";
import { useQuery } from "@tanstack/react-query";
import client from "@/api/client/axios";
import type { GetSaleRequestShippingSurchargeDocumentContentQueryResponse, GetSaleRequestShippingSurchargeDocumentContentPathParams, GetSaleRequestShippingSurchargeDocumentContent404, GetSaleRequestShippingSurchargeDocumentContent500 } from "../types/GetSaleRequestShippingSurchargeDocumentContent";

export const getSaleRequestShippingSurchargeDocumentContentQueryKey = (requestId: GetSaleRequestShippingSurchargeDocumentContentPathParams["requestId"], surchargeId: GetSaleRequestShippingSurchargeDocumentContentPathParams["surchargeId"]) =>     [{url:`/api/salerequests/${requestId}/shippings/surcharge/${surchargeId}/document/content`,params:{requestId:requestId,surchargeId:surchargeId}}] as const;
      export function getSaleRequestShippingSurchargeDocumentContentQueryOptions <TData = GetSaleRequestShippingSurchargeDocumentContentQueryResponse, TError = GetSaleRequestShippingSurchargeDocumentContent404 | GetSaleRequestShippingSurchargeDocumentContent500>(requestId: GetSaleRequestShippingSurchargeDocumentContentPathParams["requestId"], surchargeId: GetSaleRequestShippingSurchargeDocumentContentPathParams["surchargeId"], options: Partial<Parameters<typeof client>[0]> = {}): UseQueryOptions<TData, TError> {
        const queryKey = getSaleRequestShippingSurchargeDocumentContentQueryKey(requestId, surchargeId, );

        return {
          queryKey,
          queryFn: () => {
            
            return client<TData, TError>({
              method: "get",
              url: `/api/salerequests/${requestId}/shippings/surcharge/${surchargeId}/document/content`,
              
              
              ...options,
            }).then(res => res.data);
          },
        };
      };
      
/**
 * @summary Предпросмотр счета на доплату
 * @link /api/salerequests/:requestId/shippings/surcharge/:surchargeId/document/content
 */

export function useGetSaleRequestShippingSurchargeDocumentContent <TData = GetSaleRequestShippingSurchargeDocumentContentQueryResponse, TError = GetSaleRequestShippingSurchargeDocumentContent404 | GetSaleRequestShippingSurchargeDocumentContent500>(requestId: GetSaleRequestShippingSurchargeDocumentContentPathParams["requestId"], surchargeId: GetSaleRequestShippingSurchargeDocumentContentPathParams["surchargeId"], options: {
          query?: UseQueryOptions<TData, TError>,
          client?: Partial<Parameters<typeof client<TData, TError>>[0]>,
        } = {}): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const { query: queryOptions, client: clientOptions = {} } = options ?? {};
  const queryKey = queryOptions?.queryKey ?? getSaleRequestShippingSurchargeDocumentContentQueryKey(requestId, surchargeId, );

  const query = useQuery<TData, TError>({
    ...getSaleRequestShippingSurchargeDocumentContentQueryOptions<TData, TError>(requestId, surchargeId, clientOptions),
    ...queryOptions
  }) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey as QueryKey;

  return query;
};
