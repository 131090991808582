import type { UseMutationOptions, UseMutationResult } from "@tanstack/react-query";
import { useMutation } from "@tanstack/react-query";
import client from "@/api/client/axios";
import type { ResponseConfig } from "@/api/client/axios";
import type { DeleteSaleRequestShippingMutationResponse, DeleteSaleRequestShippingPathParams, DeleteSaleRequestShipping404, DeleteSaleRequestShipping500 } from "../types/DeleteSaleRequestShipping";

/**
 * @summary Запрос на получение общей информации об отгрузке
 * @link /api/salerequests/:requestId/shippings/:shippingId
 */

export function useDeleteSaleRequestShipping <TData = DeleteSaleRequestShippingMutationResponse, TError = DeleteSaleRequestShipping404 | DeleteSaleRequestShipping500>(requestId: DeleteSaleRequestShippingPathParams["requestId"], shippingId: DeleteSaleRequestShippingPathParams["shippingId"], options: {
          mutation?: UseMutationOptions<ResponseConfig<TData>, TError, void>,
          client?: Partial<Parameters<typeof client<TData, TError, void>>[0]>,
      } = {}): UseMutationResult<ResponseConfig<TData>, TError, void> {
  const { mutation: mutationOptions, client: clientOptions = {} } = options ?? {};

  return useMutation<ResponseConfig<TData>, TError, void>({
    mutationFn: () => {
      
      return client<TData, TError, void>({
        method: "delete",
        url: `/api/salerequests/${requestId}/shippings/${shippingId}`,
        
        
        
        ...clientOptions
      });
    },
    ...mutationOptions
  });
};
