import client from "@/api/client/axios";
import type { ResponseConfig } from "@/api/client/axios";
import type { SignAccreditationCryptoMutationRequest, SignAccreditationCryptoMutationResponse, SignAccreditationCryptoPathParams, SignAccreditationCryptoQueryParams } from "../../types/SignAccreditationCrypto";

/**
     * @summary Запрос на подписании аккредитации УКЭП
     * @link /api/accreditations/:accreditationId/actions/sign/crypto
     */
export async function signAccreditationCrypto (accreditationId: SignAccreditationCryptoPathParams["accreditationId"], params: SignAccreditationCryptoQueryParams, data?: SignAccreditationCryptoMutationRequest, options: Partial<Parameters<typeof client>[0]> = {}): Promise<ResponseConfig<SignAccreditationCryptoMutationResponse>["data"]> {
    const { data: resData } = await client<SignAccreditationCryptoMutationResponse, SignAccreditationCryptoMutationRequest>({
        method: "post",
        url: `/api/accreditations/${accreditationId}/actions/sign/crypto`,
        params,
        data,
        ...options
    });
    
    return resData;
};