import type { FC } from 'react'
import React, { memo } from 'react'
import classNames from 'classnames'
import { GlassMorphIcon, InjectHtml } from '@/components//ui'
import { injectMessages } from '@/logic/translation/utils'
import type { IBannerToBargainProps } from './interfaces'
import messages from './messages.json'
import './styles.scss'

injectMessages(messages)

const BannerToBargain: FC<IBannerToBargainProps> = ({ className, titleClassName, textClassName }) => {
  return (
    <div className={classNames('banner-to-bargain', className)}>
      <GlassMorphIcon image="black/bargain" className="banner-to-bargain__icon space-holder-right16" modifiers={['medium-sm']} />

      <section>
        <InjectHtml TagName="h4" className={classNames(titleClassName, 'banner-to-bargain__title')} textId="bannerToBargain.title" />
        <InjectHtml TagName="p" className={classNames(textClassName, 'text_small color_pale-black')} textId="bannerToBargain.text" />
      </section>
    </div>
  )
}

export default memo(BannerToBargain)
