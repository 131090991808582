import type { FC } from 'react'
import React, { useEffect } from 'react'
import { EColor } from '@frontend/pole-ui/lib/common/constants'
import { EIconName, EIconSize } from '@frontend/pole-ui/lib/components/Icon'
import classNames from 'classnames'
import { useHandleMenuVisibility } from '@/components/composed/Header/hooks'
import { Button } from '@/components/ui'
import { useLocation } from '@/router/hooks'
import { useDeviceType } from '@/utils/hooks/useDeviceType'
import type { IMobileNavigationContainer } from './interfaces'
import './styles.scss'

const MobileNavigationContainer: FC<IMobileNavigationContainer> = ({ children, menuButtonTextId }) => {
  const { isMobile } = useDeviceType()

  const { isMenuShown, toggleMenuVisibility, hideMenu } = useHandleMenuVisibility()
  const { pathname } = useLocation()
  //eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(hideMenu, [pathname])

  return (
    <>
      <Button
        iconProps={{
          name: isMenuShown ? EIconName.Exit : EIconName.Menu2,
          style: { color: isMenuShown ? EColor.GREEN : undefined, marginRight: '4px' },
          size: isMobile ? EIconSize.XS : EIconSize.M,
        }}
        textId={menuButtonTextId}
        className="header-menu-button row"
        onClick={event => {
          toggleMenuVisibility()
          event.stopPropagation()
        }}
        isUnstyled
      />

      <div className={classNames('mobile-navigation-container', { 'mobile-navigation-container_active': isMenuShown })}>{children}</div>
    </>
  )
}

export default MobileNavigationContainer
