import type { UseMutationOptions, UseMutationResult } from "@tanstack/react-query";
import { useMutation } from "@tanstack/react-query";
import client from "@/api/client/axios";
import type { ResponseConfig } from "@/api/client/axios";
import type { CreateSaleRequestShippingMutationRequest, CreateSaleRequestShippingMutationResponse, CreateSaleRequestShippingPathParams, CreateSaleRequestShipping404, CreateSaleRequestShipping500 } from "../types/CreateSaleRequestShipping";

/**
 * @summary Запрос на создание отгрузки
 * @link /api/salerequests/:requestId/shippings
 */

export function useCreateSaleRequestShipping <TData = CreateSaleRequestShippingMutationResponse, TError = CreateSaleRequestShipping404 | CreateSaleRequestShipping500, TVariables = CreateSaleRequestShippingMutationRequest>(requestId: CreateSaleRequestShippingPathParams["requestId"], options: {
          mutation?: UseMutationOptions<ResponseConfig<TData>, TError, TVariables>,
          client?: Partial<Parameters<typeof client<TData, TError, TVariables>>[0]>,
      } = {}): UseMutationResult<ResponseConfig<TData>, TError, TVariables> {
  const { mutation: mutationOptions, client: clientOptions = {} } = options ?? {};

  return useMutation<ResponseConfig<TData>, TError, TVariables>({
    mutationFn: (data) => {
      
      return client<TData, TError, TVariables>({
        method: "post",
        url: `/api/salerequests/${requestId}/shippings`,
        data,
        
        
        ...clientOptions
      });
    },
    ...mutationOptions
  });
};
