import client from "@/api/client/axios";
import type { ResponseConfig } from "@/api/client/axios";
import type { GetClientPublicationsQueryResponse, GetClientPublicationsQueryParams } from "../../types/GetClientPublications";

/**
     * @link /api/ms-content-infoportal-backend/v1/publications
     */
export async function getClientPublications (params: GetClientPublicationsQueryParams, options: Partial<Parameters<typeof client>[0]> = {}): Promise<ResponseConfig<GetClientPublicationsQueryResponse>["data"]> {
    const { data: resData } = await client<GetClientPublicationsQueryResponse>({
        method: "get",
        url: `/api/ms-content-infoportal-backend/v1/publications`,
        params,
        ...options
    });
    
    return resData;
};