import React, { memo, useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import type { LoadableComponent } from '@loadable/component'
import classNames from 'classnames'
import Cookies from 'js-cookie'
import {
  EUserRegionStorageCookieNames,
  getIsUserRegionConfirmed,
  getRegions,
  getUserRegion,
  setIsUserRegionConfirmed,
  setUserRegion,
} from '@/logic/userRegion'
import type { RootState } from '@/redux/interfaces'
import type { IRegion } from '@/types'
import { useIsomorphicLayoutEffect } from '@/utils/hooks'
import { useDeviceType } from '@/utils/hooks/useDeviceType'
import type { EUserRegionSelectVariation, IUserRegionSelectProps, TUserRegionSelectResponsiveConfig } from './types'
import type { IUserRegionSelectConfirmProps } from './UserRegionSelectConfirm'
import { UserRegionSelectConfirmDesktop, UserRegionSelectConfirmMobile } from './UserRegionSelectConfirm'
import type { IUserRegionSelectPickerProps } from './UserRegionSelectPicker'
import { UserRegionSelectPickerDesktop, UserRegionSelectPickerMobile } from './UserRegionSelectPicker'
import type { IUserRegionSelectSelectorProps } from './UserRegionSelectSelector'
import { UserRegionSelectSelectorDesktop, UserRegionSelectSelectorMobile } from './UserRegionSelectSelector'
import './styles.scss'

const mapState = (state: RootState) => ({
  region: getUserRegion()(state),
  regions: getRegions(state),
  isRegionConfirmed: getIsUserRegionConfirmed()(state),
})

const UserRegionSelect: React.FC<IUserRegionSelectProps> = props => {
  const {
    className,
    label,
    picker: pickerConfig,
    confirm: confirmConfig,
    selector: selectorConfig,
    onClickPicker,
    showSelector,
    genitiveCase,
  } = props

  const dispatch = useDispatch()
  const { region, isRegionConfirmed, regions } = useSelector(mapState)

  const { isDesktop, isTablet, isMobile } = useDeviceType()

  useEffect(() => {
    const regionCodeFromCookies = Number(Cookies.get(EUserRegionStorageCookieNames.REGION_ID)) || null
    const regionFromCookies = regions?.find(reg => reg.code === regionCodeFromCookies)

    const isRegionConfirmedFromCookies = Boolean(Cookies.get(EUserRegionStorageCookieNames.REGION_CONFIRMED))

    if (regionFromCookies && regionCodeFromCookies !== region.code) dispatch(setUserRegion(regionFromCookies))
    if (isRegionConfirmedFromCookies && !isRegionConfirmed) dispatch(setIsUserRegionConfirmed())

    // eslint-disable-next-line
  }, [])

  const confirmRegion = useCallback(() => dispatch(setIsUserRegionConfirmed()), [dispatch])

  const selectRegion = useCallback(
    (newRegion: IRegion) => {
      dispatch(setUserRegion(newRegion))
      confirmRegion()
    },
    [dispatch, confirmRegion],
  )

  const [isConfirmVisible, setIsConfirmVisible] = useState(false)
  const openConfirm = () => setIsConfirmVisible(true)
  const closeConfirm = () => setIsConfirmVisible(false)

  const [isSelectorShown, setIsSelectorShown] = useState(false)
  const openSelector = () => setIsSelectorShown(true)
  const closeSelector = () => setIsSelectorShown(false)

  const pickerProps: IUserRegionSelectPickerProps = useMemo(
    () => ({
      region,
      label,
      onClick: () => openSelector(),
      showSelector,
      genitiveCase,
    }),
    [region, label, showSelector, genitiveCase],
  )

  const confirmProps: Maybe<IUserRegionSelectConfirmProps> = useMemo(() => {
    if (!region) return null

    return {
      region,
      isShown: isConfirmVisible,
      onAccept: () => {
        confirmRegion()
        closeConfirm()
      },
      onDecline: () => openSelector(),
    } as IUserRegionSelectConfirmProps
  }, [region, isConfirmVisible, confirmRegion])

  const selectorProps: IUserRegionSelectSelectorProps = useMemo(
    () => ({
      value: region,
      isShown: isSelectorShown,
      onClose: () => closeSelector(),
      onInput: newRegion => {
        selectRegion(newRegion)
        closeConfirm()
        closeSelector()
      },
    }),
    [region, isSelectorShown, selectRegion],
  )

  useIsomorphicLayoutEffect(() => {
    if (region.code && !isRegionConfirmed) {
      openConfirm()
    }
  }, [region.code, isRegionConfirmed])

  //eslint-disable-next-line @typescript-eslint/no-explicit-any
  function getSuitableComponent<T extends React.FC<any> | LoadableComponent<any>>(
    variations: Record<EUserRegionSelectVariation, T>,
    config: TUserRegionSelectResponsiveConfig,
  ): Maybe<T> {
    if (!config) return null

    if (isDesktop && config.desktop) return variations[config.desktop]
    if (isTablet && config.tablet) return variations[config.tablet]
    if (isMobile && config.mobile) return variations[config.mobile]

    return null
  }

  const Picker = getSuitableComponent({ desktop: UserRegionSelectPickerDesktop, mobile: UserRegionSelectPickerMobile }, pickerConfig)

  const Confirm =
    confirmProps && !isRegionConfirmed
      ? getSuitableComponent({ desktop: UserRegionSelectConfirmDesktop, mobile: UserRegionSelectConfirmMobile }, confirmConfig)
      : null

  const Selector =
    Picker || Confirm
      ? getSuitableComponent({ desktop: UserRegionSelectSelectorDesktop, mobile: UserRegionSelectSelectorMobile }, selectorConfig)
      : null

  const isVisible = !!(Picker || Confirm || Selector)

  const onClickRegionPicker = () => {
    onClickPicker?.()
    pickerProps.onClick()
  }

  return isVisible ? (
    <div className={classNames('userRegionSelect', className)}>
      {Picker && <Picker {...pickerProps} onClick={onClickRegionPicker} />}
      {Confirm && confirmProps && <Confirm {...confirmProps} />}
      {Selector && <Selector {...selectorProps} />}
    </div>
  ) : null
}

export default memo(UserRegionSelect)
