import type { TUserPermissions } from '@/types'
import { ECommonRoute, EUserPermission, EUserProfileRoute } from '@/types'
import { EInfoportalAdministrationRoute } from '@/types/Infoportal'
import { INN_LENGTH_OF_INDIVIDUAL_ENTREPRENEUR, INN_LENGTH_OF_LEGAL_COMPANY } from './constants'

export const hasPermission = (permissions: TUserPermissions, permission: EUserPermission) => permissions.includes(permission)

export const isAnyPermissionIntersected = (permissions: TUserPermissions, userPermissions: TUserPermissions) =>
  userPermissions.some(permission => permissions.includes(permission))

export const getProfilePathByPermissions = (permissions: TUserPermissions) => {
  if (hasPermission(permissions, EUserPermission.ADMIN)) return ECommonRoute.impersonation
  if (hasPermission(permissions, EUserPermission.CONTENT_MANAGER)) return EInfoportalAdministrationRoute.MAIN

  return EUserProfileRoute.main
}

export const getErrorMessageId = (message: string, inn: string | null | undefined) => {
  // TODO: Исправить обработку ошибок. Надо, чтобы с бэка приходило нормальное сообщение
  if (message.includes(String(inn))) return 'inn.exists'
  if (message.includes('Okved')) return 'okved.restricted'
  if (message === 'Email incorrect') return 'email.incorrect'
  if (message.includes('invalid number')) return 'wrongPhone'

  return 'default'
}

export const isInnOfIndividualEntrepreneur = (inn: string): boolean => inn.length === INN_LENGTH_OF_INDIVIDUAL_ENTREPRENEUR

export const isInnOfLegalCompany = (inn?: string): boolean => inn?.length === INN_LENGTH_OF_LEGAL_COMPANY
