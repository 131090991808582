import type { CoverImageV1 } from "./CoverImageV1";

export enum PublicationParamsDataV1Status {
    "DRAFT" = "DRAFT",
    "PUBLISHED" = "PUBLISHED",
    "ARCHIVE" = "ARCHIVE"
}
export enum PublicationParamsDataV1Type {
    "ARTICLE" = "ARTICLE",
    "NEWS" = "NEWS"
}
export type PublicationParamsDataV1 = {
    /**
     * @type string
    */
    status: PublicationParamsDataV1Status;
    /**
     * @type string
    */
    type: PublicationParamsDataV1Type;
    /**
     * @type string
    */
    title: string;
    /**
     * @type string | undefined
    */
    subtitle?: string;
    /**
     * @type string | undefined
    */
    lead?: string;
    /**
     * @type string
    */
    text: string;
    coverImage?: CoverImageV1;
    /**
     * @type string | undefined
    */
    author?: string;
    /**
     * @type string | undefined
    */
    source?: string;
    /**
     * @type boolean | undefined
    */
    showCover?: boolean;
    /**
     * @type array
    */
    rubrics: number[];
    /**
     * @type string | undefined date-time
    */
    publicationDate?: string;
    /**
     * @type array
    */
    tags: number[];
    /**
     * @type string
    */
    slug: string;
};
