import type { Request, Response } from 'express'
import Cookies from 'js-cookie'
import { getCookieExpiration } from '@/services/AuthCookiesService/helpers'
import { EAuthCookieName } from './constants'

const AuthCookiesService = {
  getJwt: (req?: Request): string => req?.cookies[EAuthCookieName.jwt] || Cookies.get(EAuthCookieName.jwt) || '',
  setJwt: (jwt: string, res?: Response): void => {
    const { expressOptions, browserOptions } = getCookieExpiration(jwt)
    res?.cookie(EAuthCookieName.jwt, jwt, expressOptions)
    Cookies.set(EAuthCookieName.jwt, jwt, browserOptions)
  },
  clearJwt: (res?: Response) => {
    res?.clearCookie(EAuthCookieName.jwt)
    Cookies.remove(EAuthCookieName.jwt)
  },
  setRefreshToken: (token: string, res?: Response) => {
    res?.cookie(EAuthCookieName.refreshJwt, token)
    Cookies.set(EAuthCookieName.refreshJwt, token)
  },
  getRefreshToken: (req?: Request): string | undefined =>
    req?.cookies[EAuthCookieName.refreshJwt] || Cookies.get(EAuthCookieName.refreshJwt),
  clearRefreshToken: (res?: Response) => {
    res?.clearCookie(EAuthCookieName.refreshJwt)
    Cookies.remove(EAuthCookieName.refreshJwt)
  },
}

export default AuthCookiesService
