import type { FC } from 'react'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import { CommonLink } from '@frontend/pole-ui/lib/components/CommonLink'
import type { FaqArticleData } from '@/api/kubik/monolith'
import { InjectHtml, MetaInfo, PageNoticeText, Skeleton } from '@/components/ui'
import { pushFormattedGtmEvent } from '@/logic/metrika/helpers'
import { injectMessages } from '@/logic/translation/utils'
import { useGetFaqEntity } from '@/pages/QuestionsAndAnswers/hooks'
import { getTransliteratedGtmEvents } from '../../gtmHelpers'
import { FaqBreadcrumbs, NearestArticle } from '..'
import { faqArticleSkeletons, nearestArticlesSkeletons } from './constants'
import messages from './messages.json'
import './styles.scss'

injectMessages(messages)

const FaqArticle: FC = () => {
  const { data: article, isLoading } = useGetFaqEntity<FaqArticleData>('faqArticle', 'questionId')
  const { question, answer, nextArticle, prevArticle, tags = [], reviewer, author, category, annotation } = article || {}
  const { id: authorId, name: authorName = '' } = author || {}

  return (
    <div className="faq-article-container page-wrapper_tablet space-holder-top32-only-md">
      <FaqBreadcrumbs category={category} />
      <MetaInfo metaInfo={{ title: question, description: answer }} />
      <section className="background_white faq-article">
        <Skeleton skeletons={faqArticleSkeletons} isLoading={isLoading}>
          <h3 className="faq-article__title">{question}</h3>
          <InjectHtml html={annotation} className="space-holder32" TagName="p" />
          <InjectHtml html={answer} className="space-holder32" />
          {author && (
            <p className="text_small-from-sm">
              <FormattedMessage id="faqArticle.author.label" />
              <CommonLink
                className="link-no-hover underline underline_pale-black"
                url={`/faq/authors/${authorId}`}
                onClick={() =>
                  pushFormattedGtmEvent(
                    getTransliteratedGtmEvents({
                      blockName: 'blockExpertPage',
                      context: { name: authorName, prefix: 'nm' },
                      eventLabel: 'buttonExpertName',
                    }),
                  )
                }
              >
                {author.name}
              </CommonLink>
            </p>
          )}
          {reviewer && (
            <p className="space-holder-top8 text_small-from-sm">
              <FormattedMessage id="faqArticle.reviewer.label" />
              <CommonLink className="link-no-hover underline underline_pale-black" url={`/faq/authors/${reviewer.id}`}>
                {reviewer.name}
              </CommonLink>
            </p>
          )}
          <div className="faq-article-tags row">
            {tags.map(({ value }) => (
              <CommonLink
                url={`/faq/search?tags=${encodeURIComponent(value)}`}
                spanIfEmpty
                className="tag faq-article-tags__tag"
                key={value}
                onClick={() =>
                  pushFormattedGtmEvent(
                    getTransliteratedGtmEvents({
                      blockName: 'blockSearch',
                      eventLabel: 'buttonTag',
                      context: { name: value, prefix: 'target' },
                    }),
                  )
                }
              >
                {value}
              </CommonLink>
            ))}
          </div>
          {!article && !isLoading && <PageNoticeText textId="faqArticle.notFound" />}
        </Skeleton>
      </section>
      <Skeleton skeletons={nearestArticlesSkeletons} isLoading={isLoading}>
        <NearestArticle article={prevArticle} iconPosition="left" />
        <NearestArticle article={nextArticle} iconPosition="right" />
      </Skeleton>
    </div>
  )
}

export default FaqArticle
