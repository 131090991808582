/* eslint-disable no-underscore-dangle */
import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-intl-redux'
import { loadableReady } from '@loadable/component'
import { Hydrate, QueryClientProvider } from '@tanstack/react-query'
// import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { ConnectedRouter } from 'connected-react-router'
import throttle from 'lodash/throttle'
import { combineEpics } from 'redux-observable'
import ConditionalScrollRestoration from '@/App/components/ConditionalScrollRestoration/ConditionalScrollRestoration'
import './logger.ts'
import 'core-js'
import 'regenerator-runtime/runtime'
import store from './redux/clientStore'
import { reducerManager } from './redux/reducerManager'
import { epic$, rejectOldEpics } from './redux/rootEpic'
import App from './App'
import { history } from './browserHistory'
import { getPolyfills } from './polyfills'
import { queryClient } from './queryClient'
import ScrollToTop from './ScrollToTop'
import initSentry from './sentry'
import '@frontend/pole-ui/lib/assets/index.css'

initSentry()

// eslint-disable-next-line no-underscore-dangle
const dehydratedState = window.__REACT_QUERY_STATE__

const ConnectedApp = () => (
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <ConditionalScrollRestoration />
      <ScrollToTop />
      <QueryClientProvider client={queryClient}>
        <Hydrate state={dehydratedState}>
          <App />
          {/*<ReactQueryDevtools initialIsOpen={false} />*/}
        </Hydrate>
      </QueryClientProvider>
    </ConnectedRouter>
  </Provider>
)

const renderApp = () => {
  ReactDOM.hydrate(<ConnectedApp />, document.getElementById('root'))
}

getPolyfills().then(() => loadableReady(renderApp))

if (process.env.NODE_ENV !== 'production' && module.hot) {
  const reloadEpics = throttle(() => {
    store.dispatch(rejectOldEpics())
    //eslint-disable-next-line global-require
    const { epicRegistry } = require('./redux/rootEpic')
    //eslint-disable-next-line @typescript-eslint/no-explicit-any
    epic$.next((combineEpics as any)(...epicRegistry))
  }, 200)

  const replaceReducer = throttle(() => {
    store.replaceReducer(reducerManager.reduce)
  }, 200)

  // Если ставить optional chaining, то хот релоад не работает
  module.hot.accept('./App', () => {
    reloadEpics()
    setTimeout(renderApp)
    replaceReducer()
  })

  module.hot.accept('./redux/clientStore', () => {
    reloadEpics()
    replaceReducer()
  })

  module.hot.accept('./redux/rootEpic', () => null)
  module.hot.accept('./redux/reducerManager', () => null)
}
