import type { TMultiSelectValueType } from '@frontend/pole-ui/lib/components/MultiSelect'
import type { FieldValidator } from 'final-form'
import { LABEL_BY_LOAN_PURPOSE } from './constants'
import { messages } from './messages'
import type { IFormWithLoanPurposeSelect } from './types'

export const getHumanReadablePurposes = ({ loanPurpose = [] }: IFormWithLoanPurposeSelect) => {
  const delimiter = ','
  const purposesToJoin = loanPurpose.map(purpose => LABEL_BY_LOAN_PURPOSE[purpose])

  return purposesToJoin.join(delimiter)
}

export const noPurposeValidator: FieldValidator<TMultiSelectValueType[]> = (value?: TMultiSelectValueType[]): string | undefined => {
  if (!value || !value.length) {
    return messages.error.required
  }

  return undefined
}
