import React from 'react'
import { AppErrorDevelopment, AppErrorProduction } from './components'
import type { IAppErrorProps } from './types'

const AppError: React.FC<IAppErrorProps> = ({ errorText }) => {
  const AppErrorComponent = process.env.NODE_ENV === 'production' ? AppErrorProduction : AppErrorDevelopment

  return (
    <section className="error-page page-wrapper">
      <AppErrorComponent errorText={errorText} />
    </section>
  )
}

export default AppError
